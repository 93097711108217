export interface TaskAssignee {
  first_name: string;
  img: string;
  last_name: string;
  nickname: string;
  status: string;
  user_id: string;
}

export type TaskChecklist = {
  is_completed: boolean;
  assignee: TaskAssignee | null;
  name: string;
  order: number;
  due_at: string | null;
  completed_at: string | null;
}[];

export type TaskLabel = {
  id: string;
  text: string;
  color: string;
};

export enum TaskPriority {
  None = "none",
  Low = "low",
  Medium = "medium",
  High = "high",
}

export enum TaskStatus {
  notStarted = "not-started",
  inProgress = "in-progress",
  completed = "completed",
  reopened = "reopened",
}

export enum TaskType {
  voice = "voice_task",
  overdue = "overdue",
  recurring = "recurring",
  template = "template",
}

export enum TaskSortBy {
  DueAt = "due_at",
}

export type TaskAttachment = {
  file_path: string;
  id: string;
};

export type TaskCustomer = {
  id: string;
  logo: string | null;
  fields: { id: string; name: string; value: string }[];
};

export type Task = {
  assignee: TaskAssignee[];
  attachments: TaskAttachment[];
  can_comment: boolean;
  can_assignee_edit: boolean;
  checklist: TaskChecklist;
  comment_count: number;
  completed_on: string;
  created_at: string;
  created_by: {
    first_name: string;
    img: null | null;
    last_name: string;
    nickname: string;
    // TODO: import status from user
    status: unknown;
    user_id: string;
  };
  customer: TaskCustomer | null;
  desc: unknown;
  due_at: string | null;
  duration: number;
  id: string;
  is_recurring: boolean;
  label: TaskLabel | null;
  name: string;
  priority: TaskPriority;
  read_task: boolean;
  status: TaskStatus;
  updated_at: string;
  workspace_id: string | null;
  workspace_title: string | null;
};

type CreatedActionChanges = { title: string; time: string };

export type UpdatedActionChanges = Partial<
  Pick<Task, "name" | "priority" | "label" | "due_at" | "status" | "duration">
> & {
  voice_title?: string;
  assignee?: "updated";
  checklist?: "updated";
  attachments?: "updated";
  customer?: "updated";
};

type DeletedActionChanges = CreatedActionChanges;

export interface BaseTaskActivity {
  action_by: {
    user_id: string;
    first_name: string;
    last_name: string;
    nickname: string;
    img: string | null;
  };
  action_details:
    | { action: "created"; changes: CreatedActionChanges }
    | { action: "updated"; changes: UpdatedActionChanges }
    | { action: "deleted"; changes: DeletedActionChanges };
  inserted_at: string;
}

export interface TaskActivity extends BaseTaskActivity {
  task_id: string;
}

export interface TaskNotification extends BaseTaskActivity {
  read_flag: boolean;
  task: {
    id: string;
    title?: string;
  };
}

export type GetTasksQueryParams = {
  workspace_id: string;
  search_term: string;
  status: TaskStatus;
  type: TaskType;
  sort_by: TaskSortBy;
  date_from: string;
  date_to: string;
  assignee: string;
  date_completed_from: string;
  date_completed_to: string;
  to_due_at: string;
  from_due_at: string;
  label_id: string;
  priority: string;
};

export interface GetTasksQueryMeta {
  allTasks?: true;
}
