import { AppVariant, cx } from "@jugl-web/utils";
import format from "date-fns/format";
import { FC, ReactNode } from "react";
import { InteractiveContainer } from "../../InteractiveContainer";
import { TaskAudioPlayer } from "../TaskAudioPlayer";

export type TaskTitle =
  | { type: "text"; value: string }
  | { type: "audio"; url: string };

export interface TaskNotificationItemProps {
  variant: AppVariant;
  message: ReactNode;
  isRead: boolean;
  date: Date;
  taskTitle?: TaskTitle;
  className?: string;
  onClick: () => void;
}

export const TaskNotificationItem: FC<TaskNotificationItemProps> = ({
  variant,
  message,
  isRead,
  date,
  taskTitle,
  className,
  onClick,
}) => {
  const isMobile = variant === "mobile";

  return (
    <InteractiveContainer
      className={cx(
        "jugl__focusable-outline rounded-xl bg-white px-4 pt-6 pb-4",
        !isMobile && "bg-grey-100",
        className
      )}
      style={{
        boxShadow: isMobile
          ? "0px 0px 24px 0px rgba(0, 0, 0, 0.05)"
          : undefined,
      }}
      onClick={onClick}
    >
      <div className="mb-2 flex items-center justify-between">
        <time className="text-grey text-xs leading-[14px] -tracking-[0.12px]">
          {format(date, "MMMM dd, hh:mm a")}
        </time>
        {!isRead && (
          <div className="bg-gradients-danger h-3 w-3 rounded-full" />
        )}
      </div>
      <p className="m-0 text-xs leading-[20px] text-black">{message}</p>
      {taskTitle && (
        <>
          <div className="mt-2 mb-4 h-px bg-[#E0E0E0]" />
          <div>
            {taskTitle.type === "text" ? (
              <h3 className="text-dark m-0 text-base font-medium leading-3">
                {taskTitle.value}
              </h3>
            ) : (
              <TaskAudioPlayer audioUrl={taskTitle.url} />
            )}
          </div>
        </>
      )}
    </InteractiveContainer>
  );
};
