import { cx, useResizeObserver } from "@jugl-web/utils";
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";

export interface CollapseProps {
  children: ReactNode;
  isOpen: boolean;
  unmountOnExit?: boolean;
  className?: string;
  onTransitionEnd?: () => void;
}

export const Collapse: FC<CollapseProps> = ({
  children,
  isOpen,
  unmountOnExit = true,
  className,
  onTransitionEnd,
}) => {
  const [height, setHeight] = useState(0);
  const [isMounted, setIsMounted] = useState(isOpen);
  const isInitiallyRenderedRef = useRef(false);

  const { ref } = useResizeObserver({
    onResize: useCallback((entry) => {
      setHeight(entry.borderBoxSize[0].blockSize);
    }, []),
  });

  const handleTransitionEnd = () => {
    onTransitionEnd?.();

    if (!isOpen) {
      setIsMounted(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  useEffect(() => {
    // For some reason, with `isInitiallyRenderedRef` set to false, the transition at the first render is still present.
    // Adding a short delay before changing the flag resolves the issue 🤷🏼‍♂️
    setTimeout(() => {
      isInitiallyRenderedRef.current = true;
    }, 10);
  }, []);

  return (
    <div
      className={cx(
        "overflow-hidden",
        isInitiallyRenderedRef.current && "transition-all duration-200"
      )}
      style={{ height: isOpen ? height : 0 }}
      onTransitionEnd={handleTransitionEnd}
    >
      <div ref={ref} className={className}>
        {(!unmountOnExit ? true : isMounted) && children}
      </div>
    </div>
  );
};
