import format from "date-fns/format";

export const humanizePastDate = (date: Date) => {
  const diff = Date.parse(new Date().toUTCString()) - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  if (seconds < 60) {
    return "Just now";
  }
  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }
  if (hours < 24) {
    return `${hours} hours ago`;
  }
  return format(date, "MMM dd, hh:mm a");
};
