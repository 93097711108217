import { cx } from "@jugl-web/utils";

export type DotIconSize = "sm" | "md";

export interface DotIconProps {
  color: string;
  className?: string;
}

export const DotIcon = ({ color, className }: DotIconProps) => (
  <div className={cx("box-border h-4 w-4 p-0.5", className)}>
    <div
      className="h-full w-full rounded-full"
      style={{ backgroundColor: color }}
    />
  </div>
);
