import { cx } from "@jugl-web/utils";
import { ElementType, FC, ReactNode } from "react";

export type DetailBoxSize = "sm" | "md";

export interface DetailBoxProps {
  label: string;
  size: DetailBoxSize;
  children?: ReactNode;
  endSlot?: ReactNode;
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

const sizeToContainerClasses: Record<DetailBoxSize, string> = {
  sm: "h-16 gap-2.5 py-2 px-4",
  md: "h-[88px] gap-1.5 py-[13px] px-4",
};

const sizeToLabelClasses: Record<DetailBoxSize, string> = {
  sm: "text-xs",
  md: "text-sm",
};

export const DetailBox: FC<DetailBoxProps> = ({
  label,
  size,
  children,
  endSlot,
  className,
  isDisabled,
  onClick,
  ...props
}) => {
  const isInteractive = !!onClick && !isDisabled;
  const Component: ElementType = isInteractive ? "button" : "div";

  return (
    <Component
      className={cx(
        "bg-grey-100 flex w-full flex-col rounded-lg border-none",
        sizeToContainerClasses[size],
        isInteractive && "cursor-pointer",
        className
      )}
      onClick={isInteractive ? onClick : undefined}
      {...props}
    >
      <div className="flex w-full items-center justify-between">
        <span
          className={cx(
            "text-onyx leading-2 whitespace-nowrap font-normal",
            sizeToLabelClasses[size]
          )}
        >
          {label}
        </span>
        {endSlot}
      </div>
      {children}
    </Component>
  );
};
