import {
  extractDateFromISO,
  isAfterEndOfDay,
  omit,
  trimHTML,
} from "@jugl-web/utils";
import { TagDescription } from "@reduxjs/toolkit/dist/query";
import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import isUndefined from "lodash/isUndefined";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { TasksApiTag } from "./tags";
import {
  GetTasksQueryMeta,
  GetTasksQueryParams,
  Task,
  TaskActivity,
  TaskChecklist,
  TaskLabel,
  TaskNotification,
  TaskStatus,
  TaskType,
} from "./types";

// #region Tasks query arguments
export type GetTasksQueryArg = {
  entityId: string;
  params?: PaginatedRequestParams<Partial<GetTasksQueryParams>>;
  meta?: GetTasksQueryMeta;
};

type GetTaskQueryArg = {
  entityId: string;
  taskId: string;
};

export type CreateOrUpdateTaskChecklist = (Omit<
  TaskChecklist[number],
  "assignee"
> & {
  user_id: string | null;
})[];

type CreateTaskAttrs = Pick<
  Task,
  | "name"
  | "due_at"
  | "priority"
  | "status"
  | "workspace_id"
  | "can_assignee_edit"
> & {
  assignee: string[];
  checklist: CreateOrUpdateTaskChecklist;
  label_id?: string | null;
  cust_id?: string | null;
};

type CreateTaskQueryArg = {
  entityId: string;
  task: CreateTaskAttrs;
  files?: File[];
  voiceTitleFile?: File;
};

type UpdateTaskAttrs = Omit<
  Task,
  "assignee" | "label" | "checklist" | "customer"
> & {
  assignee: string[];
  checklist: CreateOrUpdateTaskChecklist;
  label_id?: string | null;
  cust_id?: string | null;
};

type UpdateTaskQueryArg = {
  entityId: string;
  taskId: string;
  previousTask: Task;
  updatedTask: Partial<Task>;
  files?: File[];
  voiceTitleFile?: File;
};

type DeleteTaskQueryArg = {
  entityId: string;
  taskId: string;
};
// #endregion

const stripTaskHTML = (task: Task) => ({
  ...task,
  name: trimHTML(task.name, ""),
});

// #region Tags utils
export const TASK_TAGS_CREATORS = {
  ALL_TASKS: { type: TasksApiTag.task, id: "ALL_TASKS" },
  OVERDUE_TASKS: { type: TasksApiTag.task, id: "OVERDUE_TASKS" },
  NO_DUE_DATE_TASKS: { type: TasksApiTag.task, id: "NO_DUE_DATE_TASKS" },
  COMPLETED_TASKS: { type: TasksApiTag.task, id: "COMPLETED_TASKS" },
  TASK_ID: (taskId: string) => ({
    type: TasksApiTag.task,
    id: `ID-${taskId}`,
  }),
  /**
   * @param dueDateKey due date key in yyyy-MM-dd format (e.g. 2023-09-01)
   */
  TASK_DUE_DATE_KEY: (dueDateKey: string) => ({
    type: TasksApiTag.task,
    id: `DUE_DATE-${dueDateKey}`,
  }),
  TASK_LABEL_ID: (labelId: string) => ({
    type: TasksApiTag.task,
    id: `LABEL_ID-${labelId}`,
  }),
};

export const getDueDateInvalidationTags = (dueDate: string | null) => {
  const tagsToInvalidate: TagDescription<TasksApiTag>[] = [];

  if (dueDate) {
    const dateKey = extractDateFromISO(dueDate);
    tagsToInvalidate.push(TASK_TAGS_CREATORS.TASK_DUE_DATE_KEY(dateKey));

    if (isAfterEndOfDay(dateKey)) {
      tagsToInvalidate.push(TASK_TAGS_CREATORS.OVERDUE_TASKS);
    }
  } else {
    tagsToInvalidate.push(TASK_TAGS_CREATORS.NO_DUE_DATE_TASKS);
  }

  return tagsToInvalidate;
};

export const getLabelInvalidationTags = (
  labelOrLabelId: TaskLabel | string | null | undefined
) => {
  const tagsToInvalidate: TagDescription<TasksApiTag>[] = [];

  if (labelOrLabelId) {
    tagsToInvalidate.push(
      TASK_TAGS_CREATORS.TASK_LABEL_ID(
        typeof labelOrLabelId === "string" ? labelOrLabelId : labelOrLabelId.id
      )
    );
  }

  return tagsToInvalidate;
};
// #endregion

export const addTasksApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [TasksApiTag.task, TasksApiTag.taskActivity],
  });

  const tasksApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getTasks: builder.query<PaginatedResponse<Task>, GetTasksQueryArg>({
        query: ({ entityId, params = {} }) => ({
          url: `/api/auth/entity/${entityId}/task`,
          params,
        }),
        transformResponse: (response: PaginatedResponse<Task>) => ({
          ...response,
          // TODO: temporary fix until backend resolve ordering
          data: orderBy(
            uniqBy(response.data, (task) => task.id)
              .map(stripTaskHTML)
              .map((item) => {
                if (item.due_at && !item.due_at?.endsWith("Z")) {
                  item.due_at = `${item.due_at}Z`;
                }
                return item;
              }),
            "due_at"
          ),
        }),
        providesTags: (result, _, arg) => {
          const tagsToProvide: TagDescription<TasksApiTag>[] = [
            TasksApiTag.task,
          ];

          // Provides tags for each particular task id
          result?.data.forEach((task) =>
            tagsToProvide.push(TASK_TAGS_CREATORS.TASK_ID(task.id))
          );

          // Provides tags for label-based columns/tabs
          if (arg.params?.label_id) {
            tagsToProvide.push(
              TASK_TAGS_CREATORS.TASK_LABEL_ID(arg.params.label_id)
            );
          }

          // Provides tags for due-date-based columns/tabs
          if (arg.params?.from_due_at && arg.params?.to_due_at) {
            const { from_due_at: from, to_due_at: to } = arg.params;
            const fromDate = new Date(from);
            const toDate = new Date(to);

            const dateKeys = Array.from(
              { length: differenceInDays(toDate, fromDate) },
              (_el, index) =>
                format(addDays(new Date(from), index), "yyyy-MM-dd")
            ).concat(to);

            tagsToProvide.push(
              ...dateKeys.map((dateKey) =>
                TASK_TAGS_CREATORS.TASK_DUE_DATE_KEY(dateKey)
              )
            );
          }

          // Provide tags for overdue column/tab
          if (arg.params?.type === TaskType.overdue) {
            tagsToProvide.push(TASK_TAGS_CREATORS.OVERDUE_TASKS);
          }

          // Provides tags for no due date column/tab
          if (arg.params?.from_due_at === "" && arg.params?.to_due_at === "") {
            tagsToProvide.push(TASK_TAGS_CREATORS.NO_DUE_DATE_TASKS);
          }

          // Provides tags for "All Tasks" tab (in mobile app)
          if (arg.meta?.allTasks) {
            tagsToProvide.push(TASK_TAGS_CREATORS.ALL_TASKS);
          }

          // Provides tags for "Completed" tab (in mobile app)
          if (arg.params?.status === TaskStatus.completed) {
            tagsToProvide.push(TASK_TAGS_CREATORS.COMPLETED_TASKS);
          }

          return tagsToProvide;
        },
      }),
      getTask: builder.query<Task, GetTaskQueryArg>({
        query: ({ entityId, taskId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}`,
          silentError: true,
        }),
        transformResponse: (task: Task) => {
          if (task.due_at && !task.due_at?.endsWith("Z")) {
            task.due_at = `${task.due_at}Z`;
          }
          return stripTaskHTML(task);
        },
        providesTags: (_result, _error, { taskId }) => [
          TASK_TAGS_CREATORS.TASK_ID(taskId),
        ],
      }),

      getTaskActivities: builder.query<
        PaginatedResponse<TaskActivity>,
        {
          entityId: string;
          params: PaginatedRequestParams<{ task_id: string }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/task_activities`,
          params,
          silentError: true,
        }),
        providesTags: (_result, _error, { params }) => [
          { type: TasksApiTag.taskActivity, id: params.task_id },
        ],
      }),

      getTasksNotifications: builder.query<
        { data: TaskNotification[] },
        {
          entityId: string;
          params?: PaginatedRequestParams<{ read?: boolean }>;
        }
      >({
        query: ({ entityId, params = {} }) => ({
          url: `/api/auth/entity/${entityId}/task_visits`,
          params,
        }),
      }),

      hasUnreadNotifications: builder.query<boolean, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/task_visits`,
          params: {
            entity_level: "y",
          },
        }),
        transformResponse: (response: { read_flag: boolean }) =>
          !response.read_flag,
      }),

      markNotificationsAsRead: builder.mutation<
        { result: string },
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/task_visits`,
          method: "POST",
        }),
      }),

      markTaskAsRead: builder.mutation<
        { result: string },
        { entityId: string; taskId: string }
      >({
        query: ({ entityId, taskId }) => ({
          url: `/api/auth/entity/${entityId}/task_visits`,
          method: "POST",
          data: { task_ids: [taskId] },
        }),
      }),

      createTask: builder.mutation<Task, CreateTaskQueryArg>({
        query: (params) => {
          const formData = new FormData();

          formData.append("task_attrs", JSON.stringify(params.task));

          if (params.voiceTitleFile) {
            formData.append("voice_title_file", params.voiceTitleFile);
          }

          params.files?.forEach((item) => {
            formData.append("files[]", item);
          });

          return {
            url: `/api/auth/entity/${params.entityId}/task`,
            method: "POST",
            data: formData,
          };
        },
      }),

      updateTask: builder.mutation<
        { task: Task; activity: TaskActivity },
        UpdateTaskQueryArg
      >({
        query: (params) => {
          // Adjust request data shape to meet back-end requirements
          const requestTaskAttrs: Partial<UpdateTaskAttrs> = {
            ...omit(params.updatedTask, "label", "customer"),
            // Transform customer
            cust_id: !isUndefined(params.updatedTask.customer)
              ? params.updatedTask.customer?.id || null
              : undefined,
            // Transform task label
            label_id: !isUndefined(params.updatedTask.label)
              ? params.updatedTask.label?.id || ""
              : undefined,
            // Transform task assignees
            assignee: params.updatedTask.assignee?.map(
              (assignee) => assignee.user_id
            ),
            // Transform task checklist items
            checklist: params.updatedTask.checklist?.map((item) => ({
              ...omit(item, "assignee"),
              user_id: item.assignee?.user_id || null,
            })),
          };

          const formData = new FormData();

          formData.append("task_attrs", JSON.stringify(requestTaskAttrs));

          if (params.voiceTitleFile) {
            formData.append("voice_title_file", params.voiceTitleFile);
          }

          params.files?.forEach((item) => {
            formData.append("files[]", item);
          });

          return {
            url: `/api/auth/entity/${params.entityId}/task/${params.taskId}`,
            method: "PUT",
            data: formData,
          };
        },
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTask",
              { entityId: args.entityId, taskId: args.taskId },
              (taskDraft) => {
                Object.assign<Task, Partial<Task>>(taskDraft, args.updatedTask);
              }
            )
          );

          queryFulfilled
            .then(({ data }) => {
              dispatch(
                tasksApi.util.updateQueryData(
                  "getTask",
                  { entityId: args.entityId, taskId: args.taskId },
                  (taskDraft) => {
                    Object.assign<Task, Partial<Task>>(taskDraft, {
                      name: data.task.name,
                      attachments: data.task.attachments || [],
                      customer: data.task.customer,
                    });
                  }
                )
              );
            })
            .catch(patchResult.undo);
        },
      }),

      deleteTask: builder.mutation<void, DeleteTaskQueryArg>({
        query: ({ entityId, taskId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}`,
          method: "DELETE",
        }),
      }),

      deleteTaskAttachment: builder.mutation<
        void,
        { entityId: string; taskId: string; attachmentId: string }
      >({
        query: ({ entityId, taskId, attachmentId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/attachments/${attachmentId}`,
          method: "DELETE",
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTask",
              { entityId: args.entityId, taskId: args.taskId },
              (taskDraft) => {
                taskDraft.attachments = taskDraft.attachments.filter(
                  (attachment) => attachment.id !== args.attachmentId
                );
              }
            )
          );
          queryFulfilled.catch(patchResult.undo);
        },
      }),
    }),

    overrideExisting: true,
  });

  return tasksApi;
};

export type TasksApi = ReturnType<typeof addTasksApi>;
