import {
  addEduAssignmentsApi,
  addEduCommentsApi,
  addEduFeedsApi,
  addEduLessonsApi,
  addLabelsApi,
  addLocationApi,
  addProfilesApi,
  addTasksApi,
  addUsersApi,
  addWorkspacesApi,
  addCustomerFormFieldApi,
  addCustomerApi,
  addDriveApi,
  addSupersetApi,
  baseQuery,
} from "@jugl-web/rest-api";
import { addTasksTemplatesApi } from "@jugl-web/rest-api/tasks-templates";
import { addTasksCommentsApi } from "@jugl-web/rest-api/tasks-comments";
import { createApi } from "@reduxjs/toolkit/query/react";
import { environment } from "@web-src/environments/environment";
import api from "@web-src/features/api/api";
import Geocode from "react-geocode";

Geocode.setApiKey(environment.geocodingKey);

export const baseQueryObj = baseQuery(api);

export const emptySplitApi = createApi({
  baseQuery: baseQuery(api),
  endpoints: (build) => ({
    openGraph: build.query({
      query: (url) => ({
        url: `https://opengraph.io/api/1.1/site/${encodeURIComponent(
          url
        )}?app_id=3c6c16c9-66db-465d-b3e0-ae987f90cda4`,
        silentError: true,
      }),
    }),
  }),
});

export const eduLessonsApi = addEduLessonsApi(emptySplitApi);

export const eduCommentsApi = addEduCommentsApi(emptySplitApi);

export const eduFeedsApi = addEduFeedsApi(emptySplitApi);

export const eduAssignmentsApi = addEduAssignmentsApi(emptySplitApi);

export const locationApi = addLocationApi(emptySplitApi);

export const tasksApi = addTasksApi(emptySplitApi);

export const tasksCommentsApi = addTasksCommentsApi(emptySplitApi);

export const tasksTemplatesApi = addTasksTemplatesApi(emptySplitApi);

export const labelsApi = addLabelsApi(emptySplitApi);

export const profilesApi = addProfilesApi(emptySplitApi);

export const usersApi = addUsersApi(emptySplitApi);

export const workspacesApi = addWorkspacesApi(emptySplitApi);

export const supersetApi = addSupersetApi(emptySplitApi);

export const { useOpenGraphQuery } = emptySplitApi;

export const customerFormFieldApi = addCustomerFormFieldApi(emptySplitApi);

export const customerApi = addCustomerApi(emptySplitApi);
export const driveApi = addDriveApi(emptySplitApi);
