import { useRestApiProvider } from "@jugl-web/rest-api";
import mime from "mime";
import { Buffer } from "buffer";
import { usePreferencesProvider } from "../../preferences";

export const useDownloadFile = () => {
  const { driveApi } = useRestApiProvider();
  const [downloadChunk] = driveApi.useDownloadFileChunkMutation();
  const {
    preferences: { tusDownloadChunkSize },
  } = usePreferencesProvider();
  const downloadFile = async ({
    entityId,
    id,
    fileName,
    fileSize,
    mimeType,
    onProgress,
  }: {
    entityId: string;
    id: string;
    fileName: string;
    fileSize: number;
    mimeType: string;
    chunkSize?: number;
    onProgress?: (bytesSent: number, totalBytes: number) => void;
  }): Promise<{ data: File } | Error> => {
    const chunkByteSize = tusDownloadChunkSize * 1024 * 1024;
    const numChunks = Math.ceil(fileSize / chunkByteSize);
    let file = new File([], fileName, { type: mimeType });
    try {
      for (let i = 0; i < numChunks; i += 1) {
        const start = i * chunkByteSize;
        const end = Math.min(fileSize, start + chunkByteSize) - 1;
        // eslint-disable-next-line no-await-in-loop
        const response = await downloadChunk({
          entityId,
          id,
          range: [start, end],
        });
        onProgress?.(end, fileSize);
        if ("data" in response) {
          const chunkData = Buffer.from(response.data, "base64");
          const chunkBlob = new Blob([chunkData], {
            type: mimeType,
          });

          const fileBlob = new Blob([file.slice(0, start), chunkBlob], {
            type: mimeType,
          });
          file = new File(
            [fileBlob],
            `${fileName}.${mime.getExtension(mimeType)}`,
            {
              type: mimeType,
            }
          );
        }
      }
    } catch (err) {
      return err as Error;
    }
    return { data: file };
  };

  return { downloadFile };
};
