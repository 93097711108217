export enum EduCommentModule {
  lesson = "lesson",
  assignment = "assignment",
  feed = "feed",
}

export type EduComment = {
  attachments?: [
    {
      file_path: string;
    }
  ];
  content: string | null;
  created_by: {
    user_id: string;
    username: string;
  };
  id: string;
  inserted_at: string;
};
