import { emptySplitApi } from "@web-src/features/api/createApi";
import { RawResponse } from "../api/types";

const apiWithTags = emptySplitApi.enhanceEndpoints({});

export const callsApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    agoraToken: build.mutation<string, { channel: string }>({
      query: ({ channel }) => ({
        url: "/api/auth/agora",
        method: "POST",
        data: {
          channel,
        },
        rawResponse: true,
      }),
      transformResponse: (data: RawResponse<unknown>) =>
        data.headers["agora-access-key"],
    }),
  }),
});

export const { useAgoraTokenMutation } = callsApi;
