import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslations } from "@jugl-web/utils";

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslations();

  const showProfileUpdatedSnackbar = useCallback(
    () =>
      enqueueSnackbar(
        t({
          id: "feedback.profile-updated-successfully",
          defaultMessage: "Profile updated successfully",
        }),
        {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
          autoHideDuration: 2000,
        }
      ),
    [enqueueSnackbar]
  );

  return {
    showProfileUpdatedSnackbar,
  };
};
