import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LibraryItemDisplayType,
  FileType,
} from "@jugl-web/rest-api/drive/types";

type LibraryState = {
  displayType: {
    [key: string]: LibraryItemDisplayType;
  };
  objectAction: {
    id?: string;
    type?: FileType;
    name?: string;
    avatars?: { id: string; img: string; name: string }[];
    action?: "edit" | "rename";
  };
  deletedObjects: {
    [key: string]: string;
  };
  visited: { id: string; name: string }[];
  deletedSize: number;
};

const initialState: LibraryState = {
  displayType: {},
  objectAction: {
    id: undefined,
    type: undefined,
    name: undefined,
    avatars: undefined,
    action: undefined,
  },
  deletedObjects: {},
  visited: [],
  deletedSize: 0,
};

const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    change(
      state,
      action: PayloadAction<[id: string, display: LibraryItemDisplayType]>
    ) {
      const [id, display] = action.payload;
      state.displayType[id] = display;
    },
    actionSidebar(state, action: PayloadAction<LibraryState["objectAction"]>) {
      state.objectAction = action.payload;
    },
    addToDeleted(state, action: PayloadAction<[string]>) {
      const [id] = action.payload;
      state.deletedObjects[id] = id;
    },
    setVisited(state, action: PayloadAction<{ id: string; name: string }[]>) {
      const visited = action.payload;
      state.visited = visited;
    },
    addToDeletedSize(state, action: PayloadAction<number>) {
      const deletedSize = action.payload;
      state.deletedSize += deletedSize;
    },
    resetDeletedSize(state) {
      state.deletedSize = 0;
    },
  },
});

export const {
  change,
  actionSidebar,
  addToDeleted,
  setVisited,
  addToDeletedSize,
  resetDeletedSize,
} = librarySlice.actions;
export default librarySlice;
