import { emptySplitApi } from "@web-src/features/api/createApi";
import { RawResponse } from "@web-src/features/api/types";
import { getAppType } from "@web-src/features/app/utils";
import { environment } from "@web-src/environments/environment";
import { DeviceType, Profile } from "./types";

export type AuthResponseProfile = {
  id: string;
  jid: string;
  mobile: string;
  profile: {
    nickname: string;
  } | null;
  username?: string;
};

const apiWithTags = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["Profile"],
});

const authApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    userLogout: build.mutation<
      {
        result: "success";
      },
      {
        app_id: string;
        device_type: DeviceType;
      }
    >({
      query: (data) => ({
        url: "/api/auth/user/logout",
        method: "POST",
        data,
      }),
    }),
    requestOtp: build.mutation<
      AuthResponseProfile,
      {
        country: string;
        country_isd_code: string;
        mobile: string;
        device_details: {
          last_signed: string;
          platform: any;
        };
        device_token: string;
      }
    >({
      query: (data) => ({
        url: "/api/web/request-otp",
        method: "POST",
        data: { ...data, type: getAppType() },
      }),
    }),
    verifyOtp: build.mutation<
      {
        token: string;
        refreshToken: string;
        profile: AuthResponseProfile;
      },
      {
        id: string;
        otp: string;
        dvc: {
          mac: string;
          platform: string;
        };
      }
    >({
      query: (data) => ({
        url: "/api/web/verify-otp",
        method: "POST",
        data: { ...data, type: getAppType() },
        rawResponse: true,
      }),
      transformResponse: (data: RawResponse<AuthResponseProfile>) => ({
        token: data.headers["x-auth-joiint"],
        refreshToken: data.headers["x-auth-refresh-joiint"],
        profile: data.data,
      }),
    }),
    emailLogin: build.mutation<
      {
        token: string;
        refreshToken: string;
        profile: AuthResponseProfile;
      },
      {
        email: string;
        pass: string;
        dvc: {
          mac: string;
          platform: string;
        };
      }
    >({
      query: (data) => ({
        url: "/api/web/verify-email",
        method: "POST",
        data: { ...data, type: getAppType() },
        rawResponse: true,
      }),
      transformResponse: (data: RawResponse<AuthResponseProfile>) => ({
        token: data.headers["x-auth-joiint"],
        refreshToken: data.headers["x-auth-refresh-joiint"],
        profile: data.data,
      }),
    }),
    updateToken: build.mutation<void, string>({
      query: (token) => ({
        url: `/api/auth/user/update_token`,
        method: "POST",
        data: {
          [environment.firebaseTokenId]: {
            service: "fcm",
            type: getAppType(),
            token,
          },
        },
      }),
    }),
    getProfile: build.query<
      Profile,
      { params?: { user_id: string; entity_id?: string } } | void
    >({
      query: (params) => ({
        url: "/api/auth/entity/profile",
        params: params ? params.params : {},
        // TODO: make optional
        silentError: true,
      }),
      providesTags: (e1, e2, params) => [
        {
          type: "Profile",
          id: params?.params?.user_id ? params?.params?.user_id : "",
        },
      ],
    }),
    getUserNames: build.mutation<
      {
        data: {
          entity_id: string;
          role: string;
          status: string;
          user_id: string;
          username: string;
        }[];
      },
      { body: { user_list: string[] }; entityId: string }
    >({
      query: ({ body, entityId }) => ({
        method: "POST",
        url: `/api/auth/entity/${entityId}/usernames`,
        data: body,
        // TODO: make optional
        silentError: true,
      }),
    }),
    createProfile: build.mutation<
      Profile,
      { first_name: string; last_name: string; gender?: string; dob?: string }
    >({
      query: (params) => ({
        url: "/api/auth/entity/profile",
        method: "POST",
        data: {
          type: "general",
          country: "US",
          desc: {},
          visibility: {
            section: "1",
            first_name: "1",
            last_name: "1",
            mobile: "1",
            desc: "1",
          },
          nickname: `${params.first_name}${params.last_name}`,
          ...params,
        },
      }),
      invalidatesTags: ["Profile"],
    }),
    updateProfilePicture: build.mutation<Profile, File>({
      query: (params) => {
        const data = new FormData();
        data.set("display_picture_file", params);
        return {
          url: "/api/auth/entity/profile/profile-picture",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Profile"],
    }),
  }),
});

export const {
  useUserLogoutMutation,
  useRequestOtpMutation,
  useEmailLoginMutation,
  useVerifyOtpMutation,
  useUpdateTokenMutation,
  useGetProfileQuery,
  useCreateProfileMutation,
  useUpdateProfilePictureMutation,
  useLazyGetProfileQuery,
  useGetUserNamesMutation,
} = authApi;
