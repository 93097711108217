import { PaginatedResponse, RtkEmptySplitApi } from "../../types";
import { EduComment } from "../edu-comments/types";
import {
  EduAssignment,
  EduAssignmentCategory,
  EduAssignmentSubmittion,
  EduSubmissionsInfo,
} from "./types";

export const addEduAssignmentsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [
      "Assignment",
      "AssignmentCommentsList",
      "AssignmentsList",
      "AssignmentsSearchList",
      "AssignmentSubmissionsList",
      "Submission",
      "SubmissionsSearchList",
    ],
  });

  const eduAssignemntsApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      createAssignment: build.mutation<
        { data: EduAssignment },
        {
          entityId: string;
          data: FormData;
        }
      >({
        query: ({ data, entityId }) => ({
          url: `/api/auth/entity/${entityId}/edu/assignment`,
          method: "POST",
          data,
        }),
      }),
      getAssignment: build.query<
        { data: EduAssignment },
        { entityId: string; assignmentId: string }
      >({
        query: ({ entityId, assignmentId }) => ({
          url: `/api/auth/entity/${entityId}/edu/assignment/${assignmentId}`,
        }),
      }),
      deleteAssignment: build.mutation<
        null,
        { entityId: string; assignmentId: string }
      >({
        query: ({ entityId, assignmentId }) => ({
          url: `/api/auth/entity/${entityId}/edu/assignment/${assignmentId}`,
          method: "DELETE",
        }),
      }),
      assignmentsList: build.query<
        PaginatedResponse<EduAssignment>,
        {
          entityId: string;
          params?: {
            page?: number;
            page_size?: number;
            spaces?: string | "all";
            subjects?: string | "all";
            category?: EduAssignmentCategory;
          };
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/edu/assignment`,
          params,
        }),
      }),
      assignmentsSearch: build.query<
        PaginatedResponse<EduAssignment>,
        {
          entityId: string;
          params: {
            page?: number;
            page_size?: number;
            spaces?: string | "all";
            subjects?: string | "all";
            category?: EduAssignmentCategory;
            search_term?: string;
          };
        }
      >({
        query: ({ entityId, params }) => ({
          params,
          url: `/api/auth/entity/${entityId}/edu/assignment/assignments/search`,
        }),
      }),
      submissionsList: build.query<
        EduSubmissionsInfo,
        { entityId: string; assignmentId: string; searchQuery?: string }
      >({
        query: ({ entityId, assignmentId }) => ({
          url: `/api/auth/entity/${entityId}/edu/assignment/${assignmentId}/submission`,
        }),
        providesTags: () => ["AssignmentSubmissionsList"],
      }),
      listAssignmentComments: build.query<
        PaginatedResponse<EduComment>,
        { entityId: string; assignmentId: string; pageSize: number }
      >({
        query: ({ assignmentId, entityId, pageSize }) => ({
          url: `/api/auth/entity/${entityId}/edu/assignment/${assignmentId}/comment?page=1&page_size=${pageSize}`,
        }),
      }),
      submissionsSearch: build.query<
        PaginatedResponse<EduAssignmentSubmittion>,
        {
          entityId: string;
          assignmentId: string;
          params: { search_term: string };
        }
      >({
        query: ({ entityId, assignmentId, params }) => ({
          url: `/api/auth/entity/${entityId}/edu/assignment/${assignmentId}/submission/submissions/search`,
          params,
        }),
      }),
      createAssignmentSubmission: build.mutation<
        EduAssignmentSubmittion,
        {
          entityId: string;
          assignmentId: string;
          data: { desc?: string; attachments?: { id: string; data: File }[] };
        }
      >({
        query: ({ entityId, assignmentId, data: { desc, attachments } }) => {
          const formData = new FormData();

          if (desc) {
            formData.append("desc", desc);
          }
          if (attachments) {
            attachments.forEach(({ data: fileData }) => {
              formData.append("files[]", fileData);
            });
          }
          return {
            url: `/api/auth/entity/${entityId}/edu/assignment/${assignmentId}/submission`,
            method: "POST",
            data: formData,
          };
        },
      }),
      showAssignmentSubmission: build.query<
        EduAssignmentSubmittion,
        {
          submissionId: string;
          assignmentId: string;
          entityId: string;
        }
      >({
        query: ({ submissionId, assignmentId, entityId }) => ({
          url: `/api/auth/entity/${entityId}/edu/assignment/${assignmentId}/submission/${submissionId}`,
        }),
      }),
      gradeAssigmentSubmission: build.mutation<
        EduAssignmentSubmittion,
        {
          entityId: string;
          assignmentId: string;
          submissionId: string;
          data: {
            remarks: string;
            grade: string;
          };
        }
      >({
        query: ({
          entityId,
          assignmentId,
          submissionId,
          data: { remarks, grade },
        }) => {
          const formData = new FormData();

          if (remarks) {
            formData.append("remarks", remarks);
          }
          if (grade) {
            formData.append("grade", grade);
          }

          return {
            url: `/api/auth/entity/${entityId}/edu/assignment/${assignmentId}/submission/${submissionId}/grade`,
            method: "PUT",
            data: formData,
          };
        },
        invalidatesTags: () => ["AssignmentSubmissionsList"],
      }),
    }),
  });

  const {
    useCreateAssignmentMutation,
    useGetAssignmentQuery,
    useLazyGetAssignmentQuery,
    useAssignmentsListQuery,
    useLazyAssignmentsListQuery,
    useAssignmentsSearchQuery,
    useLazyAssignmentsSearchQuery,
    useSubmissionsListQuery,
    useLazySubmissionsListQuery,
    useSubmissionsSearchQuery,
    useLazySubmissionsSearchQuery,
    useCreateAssignmentSubmissionMutation,
    useGradeAssigmentSubmissionMutation,
    useDeleteAssignmentMutation,
    useListAssignmentCommentsQuery,
    useShowAssignmentSubmissionQuery,
  } = eduAssignemntsApi;
  return {
    useCreateAssignmentMutation,
    useGetAssignmentQuery,
    useLazyGetAssignmentQuery,
    useAssignmentsListQuery,
    useLazyAssignmentsListQuery,
    useAssignmentsSearchQuery,
    useLazyAssignmentsSearchQuery,
    useSubmissionsListQuery,
    useLazySubmissionsListQuery,
    useSubmissionsSearchQuery,
    useLazySubmissionsSearchQuery,
    useCreateAssignmentSubmissionMutation,
    useGradeAssigmentSubmissionMutation,
    useDeleteAssignmentMutation,
    useListAssignmentCommentsQuery,
    useShowAssignmentSubmissionQuery,
    api: eduAssignemntsApi,
  };
};

export type EduAssignmentsApi = ReturnType<typeof addEduAssignmentsApi>;
