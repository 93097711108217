import React, { useState, memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { ReactComponent as CalendarIcon } from "@web-src/images/icons/calendar.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SettingsPaddingSize } from "@web-src/utils/layout";
import { Profile, ProfileServices } from "@web-src/features/auth/types";
import { useGetProfileQuery } from "@web-src/features/auth/authApi";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import SvgIcon from "@mui/material/SvgIcon";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as BinIcon } from "@web-src/images/icons/delete.svg";
import CustomDateTimePicker from "@web-src/components/CustomDateTimePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import format from "date-fns/format";
import { isoToLocalDate } from "@web-src/utils/helper";
import {
  useAddInformationMutation,
  useDeleteInformationMutation,
  useUpdateInformationMutation,
} from "@web-src/features/settings/settingsApi";
import { useEnqueueSnackbar } from "@web-src/features/settings/hooks/useSnackbar";
import AvatarSelect from "@web-src/components/AvatarSelect";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as ServicesIcon } from "./images/services.svg";
import FormSpinner from "../FormSpinner";
import DeleteModal from "../DeleteModal";

type Inputs = {
  name_service: string;
  service_since: Date | undefined;
  until: Date | undefined;
  reference: string;
};

const schema = yup.object().shape({
  name_service: yup.string().required(),
  service_since: yup.date().required(),
  until: yup.date().required(),
  reference: yup.string().required(),
});

const EditServiceFormContainer = memo(() => {
  const { data } = useGetProfileQuery();
  return <EditServiceFormComponent {...{ data }} />;
});

const EditServiceFormComponent: React.FC<{
  data: Profile | undefined;
}> = ({ data }) => {
  const { t } = useTranslations();
  return (
    <Box sx={{ width: "50%" }}>
      {!data ? (
        <FormSpinner />
      ) : data.services.length === 0 ? (
        <>
          <Typography sx={{ fontWeight: "500", padding: "40px 40px 0 20px" }}>
            {t({
              id: "serive-form-page.add-service",
              defaultMessage: "Add Service",
            })}
          </Typography>
          <EditServiceForm type="add" />
        </>
      ) : (
        <EditServiceList {...{ data }} />
      )}
    </Box>
  );
};

const EditServiceList: React.FC<{ data: Profile }> = ({ data }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const { t } = useTranslations();
  return (
    <Box>
      <Typography sx={{ fontWeight: "500", padding: "20px 0 0 20px" }}>
        {t({
          id: "serive-form-page.add-service",
          defaultMessage: "Add Service",
        })}
      </Typography>
      {data.services.map((el) => (
        <EditServiceListItem key={el.id} data={el} />
      ))}
      {!displayForm && (
        <Button
          sx={{
            marginLeft: "20px",
            marginTop: "20px",
            marginBottom: "40px",
            width: "50%",
          }}
          variant="outlined"
          size="large"
          onClick={() => setDisplayForm(true)}
        >
          {t({
            id: "form-controls.add-new-service.button",
            defaultMessage: "Add New Service",
          })}
        </Button>
      )}
      {displayForm && (
        <Box>
          <Typography sx={{ fontWeight: "500", marginLeft: "20px" }}>
            {t({
              id: "serive-form-page.add-new-service",
              defaultMessage: "Add New Service",
            })}
          </Typography>
          <EditServiceForm type="add" onSuccess={() => setDisplayForm(false)} />
        </Box>
      )}
    </Box>
  );
};

const EditServiceListItem: React.FC<{ data: ProfileServices }> = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteService, { isLoading }] = useDeleteInformationMutation();
  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();
  const { t } = useTranslations();

  const handleDeleteService = async () => {
    const res = await deleteService({
      type: "service",
      item_id: data.id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        style={{
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <List sx={{ width: "100%" }}>
            <ListItem
              secondaryAction={
                <IconButton sx={{ borderRadius: "0", padding: "0" }}>
                  <Button
                    disabled={isLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(true);
                    }}
                    startIcon={
                      <SvgIcon component={BinIcon} sx={{ color: "#E55353" }} />
                    }
                  >
                    <Typography
                      sx={{
                        color: "#E55353",
                        fontSize: "14px",
                        textTransform: "uppercase",
                        fontWeight: "500",
                      }}
                    >
                      {t({
                        id: "common.delete",
                        defaultMessage: "Delete",
                      })}
                    </Typography>
                  </Button>
                </IconButton>
              }
            >
              <ListItemAvatar>
                {data.img ? (
                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                    }}
                    component="img"
                    src={data.img}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      backgroundColor: "rgba(238, 90, 138, 0.1);",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SvgIcon component={ServicesIcon} viewBox="0 0 20 20" />
                  </Box>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={data.service_type}
                secondary={`${format(
                  isoToLocalDate(data.since),
                  "dd/MM/yyyy"
                )} - ${format(isoToLocalDate(data.until), "dd/MM/yyyy")}`}
              />
            </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <EditServiceForm
            {...{ data }}
            type="update"
            onSuccess={() => setExpanded(false)}
          />
        </AccordionDetails>
      </Accordion>
      {openModal && (
        <DeleteModal
          isLoading={isLoading}
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={() => handleDeleteService()}
          text={t({
            id: "service-form-page.service-information-removal-confirmation",
            defaultMessage: "Do you really want to remove Service information?",
          })}
        />
      )}
    </>
  );
};

const EditServiceForm: React.FC<{
  data?: ProfileServices;
  type: "add" | "update";
  onSuccess?: () => void;
}> = ({ data, type, onSuccess }) => {
  const [addService, { isLoading }] = useAddInformationMutation();
  const [selectedAvatar, setSelectedAvatar] = useState<File>();
  const [updateService, { isLoading: isLoadingUpdate }] =
    useUpdateInformationMutation();
  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      name_service: data?.service_type || "",
      service_since: data?.since ? new Date(data.since) : undefined,
      until: data?.until ? new Date(data.until) : undefined,
      reference: data?.ref || "",
    },
  });
  const untilValue = watch("until");
  const sinceValue = watch("service_since");
  const { t } = useTranslations();

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const {
      name_service: NameService,
      service_since: ServiceSince,
      until,
      reference,
    } = { ...params };
    const dataForm = new FormData();
    dataForm.set("type", "service");
    dataForm.set("s_type", NameService);
    dataForm.set("ref", reference);
    dataForm.set("since", ServiceSince?.toISOString() || "");
    dataForm.set("until", until?.toISOString() || "");
    let res;
    if (selectedAvatar) dataForm.set("display_pic_file", selectedAvatar);
    if (type === "add") {
      res = await addService({ data: dataForm });
    } else {
      res = await updateService({
        item_id: data?.id,
        data: dataForm,
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      onSuccess?.();
    }
  };

  return (
    <Box sx={{ padding: "20px 40px 40px 40px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ marginBottom: "20px" }}>
          <AvatarSelect
            onChange={setSelectedAvatar}
            showCameraIcon
            value={selectedAvatar}
            defaultSrc={data?.img || undefined}
            placeholder={ServicesIcon}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "70px",
            marginBottom: "50px",
            position: "relative",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.service-name.label",
                  defaultMessage: "Name of Service",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <TextField
              variant="standard"
              sx={{
                width: "100%",
                input: {
                  "&::placeholder": {
                    fontWeight: "400",
                  },
                },
              }}
              inputProps={{
                style: { fontSize: 16, fontWeight: "500" },
                ...register("name_service"),
              }}
              placeholder={t({
                id: "form-controls.service-name.placeholder",
                defaultMessage: "Name of Service",
              })}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.service-since-date.label",
                  defaultMessage: "Service Since (Date)",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <Controller
              name="service_since"
              control={control}
              render={({ field }) => (
                <CustomDateTimePicker
                  disableCounter
                  title={t({
                    id: "date-time-picker.select-date",
                    defaultMessage: "Select Date",
                  })}
                  {...field}
                  onChange={(newValue) => {
                    setValue("service_since", newValue, {
                      shouldValidate: true,
                    });
                    if (untilValue && newValue > untilValue) {
                      setValue("until", newValue, { shouldValidate: true });
                    }
                  }}
                  renderInput={
                    <Box
                      sx={{
                        width: "100%",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid black",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        "&:hover": {
                          borderBottom: "2px solid black",
                        },
                      }}
                    >
                      <Box>
                        {!field.value ? (
                          <Typography sx={{ color: "grey.900" }}>
                            DD/MM/YYYY
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: 16, fontWeight: "500" }}>
                            {format(field.value, "dd/MM/yyyy")}
                          </Typography>
                        )}
                      </Box>
                      <SvgIcon
                        component={CalendarIcon}
                        sx={{ color: "grey.700" }}
                      />
                    </Box>
                  }
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "70px",
            marginBottom: "50px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.service-until-date.label",
                  defaultMessage: "Until (Date)",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <Controller
              name="until"
              control={control}
              render={({ field }) => (
                <CustomDateTimePicker
                  disableCounter
                  title={t({
                    id: "date-time-picker.select-date",
                    defaultMessage: "Select Date",
                  })}
                  minDate={sinceValue}
                  {...field}
                  renderInput={
                    <Box
                      sx={{
                        width: "100%",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid black",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        "&:hover": {
                          borderBottom: "2px solid black",
                        },
                      }}
                    >
                      <Box>
                        {!field.value ? (
                          <Typography sx={{ color: "grey.900" }}>
                            DD/MM/YYYY
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: 16, fontWeight: "500" }}>
                            {format(field.value, "dd/MM/yyyy")}
                          </Typography>
                        )}
                      </Box>
                      <SvgIcon
                        component={CalendarIcon}
                        sx={{ color: "grey.700" }}
                      />
                    </Box>
                  }
                />
              )}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.reference-link.label",
                  defaultMessage: "Paste Reference Link",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <TextField
              variant="standard"
              sx={{
                width: "100%",
                input: {
                  "&::placeholder": {
                    fontWeight: "400",
                  },
                },
              }}
              inputProps={{
                style: { fontSize: 16, fontWeight: "500" },
                ...register("reference"),
              }}
              placeholder="Paste Reference Link"
            />
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Button
            disabled={!isValid || isLoading || isLoadingUpdate || !isDirty}
            sx={{ width: `calc(100% - ${SettingsPaddingSize}px)` }}
            size="large"
            variant="contained"
            type="submit"
          >
            {t({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditServiceFormContainer;
