import { EduComment, EntityParticipantRole } from "../types";

// TODO: Move to domain-resources
export const canDeleteEduComment = (
  comment: EduComment,
  roles: EntityParticipantRole[],
  entityRole?: EntityParticipantRole,
  meId?: string
) =>
  (entityRole && roles.includes(entityRole)) ||
  meId === comment.created_by.user_id;

const colors = [
  "#71C5E6",
  "#2646BA",
  "#8535C3",
  "#C02566",
  "#D6C525",
  "#71A939",
  "#59C397",
  "#33AEB5",
  "#DF6363",
  "#3C7D97",
];

const colorsAttachedToUsers = new Map();

const detectColor = () => {
  let counter = 0;

  return () => {
    if (counter >= 10) {
      counter = 0;
      return colors[0];
    }
    counter += 1;
    return colors[counter];
  };
};

const colorDetector = detectColor();

export const setUserColor = (userId: string) => {
  if (colorsAttachedToUsers.get(userId)) {
    return colorsAttachedToUsers.get(userId);
  }
  const color = colorDetector();
  colorsAttachedToUsers.set(userId, color);
  return color;
};
