import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@web-src/store";
import { EduAssignment } from "@jugl-web/rest-api";

type AssignmentsState = {
  activeAssignment?: EduAssignment;
  selectedFilters?: {
    classes?: string[];
    subjects?: string[];
  };
};

const initialState: AssignmentsState = {};

const assignmentsSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {
    setActiveAssignment(state, { payload: assignment }) {
      state.activeAssignment = assignment;
    },
    setFiltersCall(state, { payload: filterItem }) {
      state.selectedFilters = filterItem;
    },
  },
});

export const selectAssignment = ({
  assignments: { activeAssignment },
}: RootState) => activeAssignment;

export const selectFilters = ({
  assignments: { selectedFilters },
}: RootState) => selectedFilters;

export const { setActiveAssignment, setFiltersCall } = assignmentsSlice.actions;

export default assignmentsSlice;
