import { EntityPropType } from "./types";

export enum EntityPropsApiTag {
  entityPropsList = "entityPropsList",
}

export const getEntityPropsListTag = (
  type: EntityPropType,
  entityId: string
) => ({
  id: `${type}-${entityId}`,
  type: EntityPropsApiTag.entityPropsList,
});
