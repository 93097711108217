import { KeyboardEvent } from "react";

type OnKeyStrokeCallback<TElement extends HTMLElement = HTMLElement> = (
  callback: (event: KeyboardEvent<TElement>) => void
) => (event: KeyboardEvent<TElement>) => void;

export const onEnter: OnKeyStrokeCallback = (callback) => (event) => {
  if (event.key === "Enter") {
    callback(event);
  }
};

export const onEscape: OnKeyStrokeCallback = (callback) => (event) => {
  if (event.key === "Escape") {
    callback(event);
  }
};
