import { useEffect } from "react";

import { capitalize } from "@mui/material";

import api from "./api";

export const useApi = ({
  onError,
}: {
  onError?: (e: { status?: number; data?: string; message?: string }) => void;
}) => {
  useEffect(() => {
    const interceptor = api.interceptors.response.use(undefined, (error) => {
      let message = capitalize(error.message);

      if (error.request.responseURL.includes("/web/verify-email")) {
        message = "Invalid email or password";
      }

      if (Array.isArray(error.response?.data?.errors)) {
        message = capitalize(error.response?.data?.errors[0].message);
      }

      if (typeof error.response?.data?.errors === "string") {
        message = capitalize(error.response?.data?.errors.replaceAll("_", " "));
      }
      if (!error.config.silentError) {
        onError?.({
          status: error.response?.status,
          data: error.response?.data || error.message,
          message,
        });
      }
      throw error;
    });

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [onError]);
};
