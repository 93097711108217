import { cx } from "@jugl-web/utils";
import { AvatarMember } from "../CollectiveAvatar";

const AvatarImage = ({
  member,
  className,
}: {
  member: AvatarMember;
  className: string;
}) => {
  const hasImage = !!member.imageUrl;
  const initials = member.firstName.charAt(0).concat(member.lastName.charAt(0));

  if (!hasImage) {
    return (
      <span
        className={cx(
          className,
          "flex items-center justify-center text-xs font-semibold uppercase leading-[14px] text-[#333333]"
        )}
        style={{
          background: "linear-gradient(180deg, #CECED3 0%, #ECEFF0 69.27%)",
        }}
      >
        {initials}
      </span>
    );
  }

  return (
    <img
      src={member.imageUrl as string}
      alt={`${member.firstName} ${member.lastName}`}
      className={cx(className, "object-cover")}
    />
  );
};

export type AvatarStackSize = "sm" | "md";

export interface AvatarStackProps {
  members: AvatarMember[];
  maxCount: number;
  size: AvatarStackSize;
  className?: string;
}

// TODO: Make it more responsive and descriptive, according to the design
// 1 - avatar + full name (avatar-name gap=4px)
// 2 - avatar + name + surname initial (avatar-name gap=4px, items-gap=18px)
// 3-6 - avatar (items-gap=8px)
// 7-9 - avatar (items-gap=-2px)
// 10-12 - avatar (items-gap=-4px)
// 12-15+ - avatar (items-gap=-8px)

export const AvatarStack = ({
  members,
  maxCount,
  size,
  className,
}: AvatarStackProps) => {
  const remainingMembers = members.length - maxCount;
  const shouldApplyOverlay = remainingMembers > 0;

  return (
    <div className={cx("inline-flex items-center overflow-hidden", className)}>
      {members.slice(0, maxCount).map((member, index, limitedMembers) => {
        const isFirst = index === 0;
        const isLast = index === limitedMembers.length - 1;

        return (
          <div
            key={+index}
            className={cx(
              "relative overflow-hidden rounded-full border border-solid border-white",
              !isFirst && {
                "-ml-2": size === "md",
                "-ml-[9px]": size === "sm",
              },
              {
                "h-7 w-7": size === "md",
                "h-6 w-6": size === "sm",
              }
            )}
          >
            {isLast && shouldApplyOverlay && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/40">
                <span className="text-xs font-semibold leading-[12px] text-white">
                  +{remainingMembers}
                </span>
              </div>
            )}
            <AvatarImage member={member} className="h-full w-full" />
          </div>
        );
      })}
    </div>
  );
};
