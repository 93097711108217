import { LiveUpdateEvent } from "@jugl-web/domain-resources/live-updates";
import { TaskLiveUpdatesManager } from "@jugl-web/domain-resources/tasks/components/TaskLiveUpdatesManager";
import { cx, usePrevious } from "@jugl-web/utils";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { LiveUpdatesListener } from "@web-src/components/LiveUpdatesListener";
import Loader from "@web-src/components/Loader";
import { useApi } from "@web-src/features/api/useApi";
import { selectIsSideBarVisible } from "@web-src/features/app/appSlice";
import AppInitializer from "@web-src/features/app/components/AppInitializer/AppInitializer";
import HomeSideBar from "@web-src/features/app/components/HomeSideBar";
import PrivateRoute from "@web-src/features/app/components/PrivateRoute";
import PublicRoute from "@web-src/features/app/components/PublicRoute";
import SideBarHidden from "@web-src/features/app/components/SideBarHidden";
import { FirebaseContext } from "@web-src/features/app/providers/Firebase";
import { AppType, PageRoute, TabRoute } from "@web-src/features/app/types";
import { getAppType } from "@web-src/features/app/utils";
import { useUpdateTokenMutation } from "@web-src/features/auth/authApi";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import ActiveCallWrapper from "@web-src/features/calls/components/ActiveCallWrapper/ActiveCallWrapper";
import { PhoenixSocketContext } from "@web-src/features/chats/providers/PheonixSocket";
import { EduFeedsProvider } from "@web-src/features/eduFeeds/providers/EduFeedsProvider";
import { EduLessonsProvider } from "@web-src/features/eduLessons/providers/EduLessonsProvider";
import SettingsEditProfileRouting from "@web-src/features/settings/EditProfile/routing";
import Conference from "@web-src/pages/Conference";
import { useSnackbar } from "notistack";
import React, { lazy, Suspense, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useNetworkState } from "react-use";
import { Subject } from "rxjs";

const LocationPage = lazy(() => import("@web-src/pages/Location"));
const ChatsPage = lazy(() => import("@web-src/pages/Chats"));
const WorkspaceEventsPage = lazy(
  () => import("@web-src/pages/WorkspaceEvents")
);
const LibraryPage = lazy(() => import("@web-src/pages/Library/Library"));
const Tasks = lazy(() => import("@web-src/pages/Tasks"));
const Login = lazy(() => import("@web-src/pages/Login"));
const EduLessonsPage = lazy(() => import("@web-src/pages/EduLessons"));
const AssignmentsPage = lazy(() => import("@web-src/pages/Assignments"));
const AssignmentDetails = lazy(
  () => import("@web-src/pages/AssignmentDetails")
);
const FeedsDetailsPage = lazy(() => import("@web-src/pages/FeedDetails"));
const AttendancePage = lazy(() => import("@web-src/pages/Attendance"));
const Schedule = lazy(() => import("@web-src/pages/Schedule"));
const Settings = lazy(() => import("@web-src/pages/Settings"));
const EditProfile = lazy(
  () => import("@web-src/features/settings/EditProfile")
);

const AdminPage = lazy(() => import("@web-src/pages/Admin"));
const StudentsPage = lazy(() => import("@web-src/pages/AdminStudents"));
const TeachersPage = lazy(() => import("@web-src/pages/AdminTeachers"));
const ClassesPage = lazy(() => import("@web-src/pages/AdminClasses"));
const SubjectsPage = lazy(() => import("@web-src/pages/AdminSubjects"));
const TaskDetails = lazy(() => import("@web-src/pages/TaskDetails"));
const OrgSelectPage = lazy(() => import("@web-src/pages/OrgSelect"));
const ProfileForm = lazy(() => import("@web-src/pages/ProfileForm"));
const OrganizationForm = lazy(() => import("@web-src/pages/OrganizationForm"));
const ReportsPage = lazy(() => import("@web-src/pages/AdminReports"));

const EduSchoolEdit = lazy(() => import("@web-src/pages/EduSchoolEdit"));
const PeoplePage = lazy(() => import("@web-src/pages/People"));
const EduSchoolSelectPage = lazy(
  () => import("@web-src/pages/EduSchoolSelect")
);
const EduFeedsPage = lazy(() => import("@web-src/pages/EduFeeds"));
const Customers = lazy(() => import("@web-src/pages/Customers"));

const TransitionRouter = function TransitionRouter() {
  const location = useLocation();
  const appType = getAppType();

  return (
    <Suspense
      fallback={
        <Box sx={{ width: "100vw", height: "100vh" }}>
          <Loader />
        </Box>
      }
    >
      <Routes location={location} key={location && location.pathname}>
        <Route
          path={PageRoute.login}
          element={<PublicRoute component={<Login />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.chats}`}
          element={<PrivateRoute component={<ChatsPage />} />}
        />

        <Route
          path={`/:entityId/${TabRoute.chats}/:activeChatId`}
          element={<PrivateRoute component={<ChatsPage />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.drive}/`}
          element={<PrivateRoute component={<LibraryPage />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.drive}/:directoryId/:directoryName`}
          element={<PrivateRoute component={<LibraryPage />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.chats}/:activeChatId/${TabRoute.drive}`}
          element={<PrivateRoute component={<LibraryPage />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.chats}/:activeChatId/${TabRoute.drive}/:directoryId/:directoryName`}
          element={<PrivateRoute component={<LibraryPage />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.chats}/:activeChatId/events`}
          element={<PrivateRoute component={<WorkspaceEventsPage />} />}
        />

        <Route
          path={`/:entityId/${TabRoute.tasks}/:tab`}
          element={<PrivateRoute component={<Tasks />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.chats}/:activeChatId/${TabRoute.tasks}/:tab`}
          element={<PrivateRoute component={<Tasks />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.tasks}/details/:taskId`}
          element={<PrivateRoute component={<TaskDetails />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.chats}/:activeChatId/${TabRoute.tasks}/details/:taskId`}
          element={<PrivateRoute component={<TaskDetails />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.schedule}`}
          element={<PrivateRoute component={<Schedule />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.schedule}/:date`}
          element={<PrivateRoute component={<Schedule />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.settings}`}
          element={<PrivateRoute component={<Settings />} />}
        />
        <Route
          path={`/:entityId/${TabRoute.settings}/edit-profile`}
          element={<PrivateRoute component={<EditProfile />} />}
        >
          {SettingsEditProfileRouting}
        </Route>
        <Route
          path={`/:entityId/${PageRoute.conference}/:channelId`}
          element={<PrivateRoute component={<Conference />} />}
        />
        <>
          {appType === AppType.org && (
            <>
              <Route
                path={`/${PageRoute.orgSelect}`}
                element={
                  <SideBarHidden>
                    <PrivateRoute component={<OrgSelectPage />} />
                  </SideBarHidden>
                }
              />
              <Route
                path={`/${PageRoute.orgCreate}`}
                element={
                  <SideBarHidden>
                    <PrivateRoute component={<OrganizationForm />} />
                  </SideBarHidden>
                }
              />
              <Route
                path={`/${PageRoute.orgEdit}/:entityId`}
                element={
                  <SideBarHidden>
                    <PrivateRoute component={<OrganizationForm />} />
                  </SideBarHidden>
                }
              />
              <Route
                path={`/${PageRoute.profileForm}`}
                element={
                  <SideBarHidden>
                    <PrivateRoute
                      component={<ProfileForm />}
                      skipProfileCreateNavigate
                    />
                  </SideBarHidden>
                }
              />
              <Route
                path={`/:entityId/${TabRoute.people}`}
                element={<PrivateRoute component={<PeoplePage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.people}/:tab`}
                element={<PrivateRoute component={<PeoplePage />} />}
              />

              <Route
                path={`/:entityId/${PageRoute.location}/:userId/:date`}
                element={<PrivateRoute component={<LocationPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.clients}`}
                element={<PrivateRoute component={<Customers />} />}
              />
              <Route
                path="/:entityId/reports/:reportType"
                element={<PrivateRoute component={<ReportsPage />} />}
              />
            </>
          )}

          {appType === AppType.edu && (
            <>
              <Route
                path={PageRoute.schoolSelect}
                element={
                  <SideBarHidden>
                    <PrivateRoute component={<EduSchoolSelectPage />} />
                  </SideBarHidden>
                }
              />

              <Route
                path={`/:entityId/${TabRoute.eduSchoolEdit}`}
                element={
                  <PrivateRoute
                    component={
                      <SideBarHidden>
                        <EduSchoolEdit />
                      </SideBarHidden>
                    }
                  />
                }
              />

              <Route
                path={`/:entityId/${TabRoute.eduAdmin}`}
                element={<PrivateRoute component={<AdminPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduAdmin}/classes`}
                element={<PrivateRoute component={<ClassesPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduAdmin}/students`}
                element={<PrivateRoute component={<StudentsPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduAdmin}/teachers`}
                element={<PrivateRoute component={<TeachersPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduAdmin}/subjects`}
                element={<PrivateRoute component={<SubjectsPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduLessons}`}
                element={<PrivateRoute component={<EduLessonsPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduLessons}/:lessonId`}
                element={<PrivateRoute component={<EduLessonsPage />} />}
              />

              <Route
                path={`/:entityId/${TabRoute.eduAssignments}`}
                element={<PrivateRoute component={<AssignmentsPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduAssignments}/:assignmentId`}
                element={<PrivateRoute component={<AssignmentDetails />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduAssignments}/:category/:assignmentId`}
                element={<PrivateRoute component={<AssignmentDetails />} />}
              />

              <Route
                path={`/:entityId/${TabRoute.eduFeeds}`}
                element={<PrivateRoute component={<EduFeedsPage />} />}
              />
              <Route
                path={`/:entityId/${TabRoute.eduFeeds}/:feedId`}
                element={<PrivateRoute component={<FeedsDetailsPage />} />}
              />

              <Route
                path={`/:entityId/${TabRoute.eduAttendance}`}
                element={<PrivateRoute component={<AttendancePage />} />}
              />

              <Route
                path={`/:entityId/${PageRoute.location}/:userId/:date`}
                element={<PrivateRoute component={<LocationPage />} />}
              />
            </>
          )}
        </>
        <Route path="*" element={<Navigate to={`/${PageRoute.login}`} />} />
      </Routes>
    </Suspense>
  );
};

const liveUpdateEvents$ = new Subject<LiveUpdateEvent>();

const App: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useSelector(selectAuthState);
  useApi({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });
  const isSideBarVisible = useSelector(selectIsSideBarVisible);

  const [updateToken] = useUpdateTokenMutation();
  const { token } = useContext(FirebaseContext);

  useEffect(() => {
    if (!token || !isAuthenticated) {
      return;
    }
    updateToken(token);
  }, [updateToken, token, isAuthenticated]);

  const { online } = useNetworkState();
  const { socketDisconnected } = useContext(PhoenixSocketContext);
  const previousOnline = usePrevious(online);
  useEffect(() => {
    // make sure app will be reloaded after user wakes up
    const wakeupWorker = new Worker("detect-wakeup.js");
    wakeupWorker.onmessage = function (ev) {
      if (ev?.data === "app:wakeup") {
        window.location.reload();
      }
    };
  }, []);
  useEffect(() => {
    if (
      (socketDisconnected ||
        (previousOnline !== undefined && !previousOnline)) &&
      online
    ) {
      window.location.reload();
    }
  }, [online, previousOnline, socketDisconnected]);

  const appType = useMemo(() => getAppType(), []);

  const $authenticatedContent = useMemo(
    () =>
      isSideBarVisible ? (
        <>
          <ActiveCallWrapper />
          <Stack
            direction="row"
            sx={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
            }}
          >
            <HomeSideBar />
            <Box sx={{ flex: 1, height: "100%", overflowY: "auto" }}>
              <TransitionRouter />
            </Box>
          </Stack>
        </>
      ) : (
        <TransitionRouter />
      ),
    [isSideBarVisible]
  );
  return (
    <>
      {(!online || socketDisconnected) && (
        <div className="bg-tertiary-500 fixed top-10 left-[50%] z-50 -translate-x-[50%] rounded py-5 px-12 text-center font-bold text-white">
          No internet connection, please reconnect to use Jugl
        </div>
      )}
      <div className={cx({ "opacity-50": !online || socketDisconnected })}>
        {isAuthenticated ? (
          <AppInitializer>
            {/* TODO: don't use for ORG app */}
            {appType === AppType.edu ? (
              <EduFeedsProvider>
                <EduLessonsProvider>{$authenticatedContent}</EduLessonsProvider>
              </EduFeedsProvider>
            ) : (
              <>
                <LiveUpdatesListener events$={liveUpdateEvents$} />
                <TaskLiveUpdatesManager events$={liveUpdateEvents$} />
                {$authenticatedContent}
              </>
            )}
          </AppInitializer>
        ) : (
          <TransitionRouter />
        )}
      </div>
    </>
  );
};

export default App;
