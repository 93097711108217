import React, { memo } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const FormSpinner = memo(() => (
  <Box
    sx={{
      width: "100%",
      marginTop: "50px",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </Box>
));

export default FormSpinner;
