import { useCallback, useMemo } from "react";
import { BroadcastChannel } from "broadcast-channel";
import { useAppDispatch } from "@web-src/store";
import { useUserLogoutMutation } from "@web-src/features/auth/authApi";
import { logout as logoutDispatch } from "@web-src/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { DeviceType } from "@web-src/features/auth/types";
import { getAppId } from "@web-src/features/app/utils";
import { getSessionId } from "@web-src/utils/session";
import { BroadcastChannelName } from "../types";

const channel = new BroadcastChannel(BroadcastChannelName.auth);

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const [userLogout] = useUserLogoutMutation();
  const navigate = useNavigate();
  const emitterId = getSessionId();

  const logout = useCallback(async () => {
    await userLogout({
      app_id: getAppId(),
      device_type: DeviceType.web,
    });
    await dispatch(logoutDispatch());
    navigate("/login", { replace: true });
    channel.postMessage({
      emitterId,
    });
  }, [dispatch, navigate, userLogout]);

  return {
    logout,
  };
};
