import { ReactNode } from "react";
import { AppVariant, useTranslations } from "@jugl-web/utils";
import { Button, ButtonProps } from "../Button";
import { Dialog } from "../Dialog";
import { PlainButton } from "../PlainButton";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { SwipeableDrawer } from "../../mobile";

export interface ConfirmationPopupButtonProps
  extends Pick<ButtonProps, "variant" | "color" | "isDisabled" | "onClick"> {
  label: string;
}

export interface ConfirmationPopupProps {
  variant: AppVariant;
  isOpen: boolean;
  title: string;
  message: ReactNode;
  buttons: ConfirmationPopupButtonProps[];
  hasCancelButton?: boolean;
  onRequestClose: () => void;
}

export const ConfirmationPopup = ({
  variant,
  isOpen,
  title,
  message,
  buttons,
  hasCancelButton,
  onRequestClose,
}: ConfirmationPopupProps) => {
  const { t } = useTranslations();
  return variant === "mobile" ? (
    <SwipeableDrawer
      isOpen={isOpen}
      title={title}
      hasBackdrop
      size="auto"
      onClose={onRequestClose}
    >
      <div className="p-4">
        <p className="text-grey mt-0 mb-8 text-[15px]">{message}</p>
        <div className="flex flex-col gap-[10px]">
          {buttons.map(({ label, ...buttonProps }, index) => (
            <Button key={+index} uppercase {...buttonProps}>
              {label}
            </Button>
          ))}
          {hasCancelButton && (
            <Button variant="text" uppercase onClick={onRequestClose}>
              Cancel
            </Button>
          )}
        </div>
      </div>
    </SwipeableDrawer>
  ) : (
    <Dialog
      isOpen={isOpen}
      onClose={onRequestClose}
      className="px-28 pt-14 pb-10 text-center"
    >
      <PlainButton onClick={onRequestClose} className="absolute right-4 top-4">
        <CloseIcon />
      </PlainButton>
      <h3 className="text-dark m-0 mb-4 text-lg font-medium leading-[22px]">
        {title}
      </h3>
      <p className="text-grey leading-2 mt-0 mb-[58px] text-[15px]">
        {message}
      </p>
      <div className="flex items-center justify-center gap-[30px]">
        {hasCancelButton && (
          <Button
            variant="outlined"
            className="w-64"
            uppercase
            onClick={onRequestClose}
          >
            {t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            })}
          </Button>
        )}
        {buttons.map(({ label, ...buttonProps }, index) => (
          <Button key={+index} className="w-64" uppercase {...buttonProps}>
            {label}
          </Button>
        ))}
      </div>
    </Dialog>
  );
};
