import React, { useState, useEffect, useMemo, useCallback } from "react";

import Button from "@mui/material/Button";
import format from "date-fns/format";
import parse from "date-fns/parse";
import TextField from "@mui/material/TextField";
import startOfMinute from "date-fns/startOfMinute";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import SvgIcon from "@mui/material/SvgIcon";
import { useForm } from "react-hook-form";
import { ReactComponent as ChevronDownIcon } from "@web-src/images/icons/chevron-down.svg";
import { grey, primary, white } from "@web-src/themes/colors";
import { useTranslations } from "@jugl-web/utils";
import setMonth from "date-fns/setMonth";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import setDay from "date-fns/setDay";
import { TimeSeparator, HiddenButton, arrowDownStyle } from "./styles";
import CounterInput from "./CounterInput";
import SwitchDayPeriod from "./SwitchDayPeriod";
import { TimeName } from "./types";

const CustomDateTimePicker: React.FC<{
  value?: Date;
  title: string;
  defaultValue?: Date;
  onChange?: (arg1: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  renderInput?: React.ReactNode;
  disablePast?: boolean;
  disableCounter?: boolean;
  disabled?: boolean;
}> = ({
  value,
  title,
  maxDate,
  defaultValue = maxDate || new Date(),
  onChange,
  minDate,
  renderInput,
  disablePast,
  disableCounter,
  disabled,
}) => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const {
    register,
    formState: { isValid, errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm<{
    date: Date | null;
    dayPeriod: string;
    hour: string;
    minute: string;
    month: number;
    year: number;
  }>({
    mode: "all",
    defaultValues: {
      date: value || defaultValue,
      dayPeriod: format(value || defaultValue, "aa"),
      hour: format(value || defaultValue, "hh"),
      minute: format(value || defaultValue, "mm"),
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
  });
  const [monthAnchorElement, setMonthAnchorElement] = useState<
    (EventTarget & SVGSVGElement) | null
  >(null);
  const [yearAnchorElement, setYearAnchorElement] = useState<
    (EventTarget & SVGSVGElement) | null
  >(null);
  const years = [];
  for (
    let i = new Date().getFullYear() + 2;
    i >= new Date().getFullYear() - 98;
    i -= 1
  ) {
    years.push(i);
  }
  const disablePastMinDate = useMemo(() => startOfMinute(new Date()), []);
  const resetValue = () => {
    setValue("date", value || defaultValue);
    setValue("dayPeriod", format(value || defaultValue, "aa"));
    setValue("hour", format(value || defaultValue, "hh"));
    setValue("minute", format(value || defaultValue, "mm"));
  };
  const [isOpen, setIsOpen] = useState(false);
  const dateValue = watch("date");
  const calendarObj = watch();
  const [isValidTime, setIsValidTime] = useState(true);

  const getParsedTime = (data: {
    date: Date | null;
    dayPeriod: string;
    hour: string;
    minute: string;
  }) =>
    parse(
      `${format(data.date || new Date(), "MM/dd/yyyy")} ${data.hour}:${
        data.minute
      } ${data.dayPeriod}`,
      `MM/dd/yyyy hh:mm aa`,
      new Date()
    );

  useEffect(() => {
    if (isValid && (minDate || disablePast)) {
      setIsValidTime(
        getParsedTime(calendarObj).getTime() >=
          (minDate || disablePastMinDate).getTime()
      );
    }
  }, [calendarObj, minDate, disablePast, isValid, disablePastMinDate]);
  const handleSubmitClick = handleSubmit(
    (data: {
      date: Date | null;
      dayPeriod: string;
      hour: string;
      minute: string;
    }) => {
      onChange?.(getParsedTime(data));
      setIsOpen(false);
    }
  );
  const handleClose = () => {
    resetValue();
    setIsOpen(false);
  };

  const handleChangeYear = useCallback(
    (yearValue: number) => {
      const updatedDate = dateValue;
      if (!updatedDate) return;
      updatedDate.setFullYear(yearValue);
      setValue("date", updatedDate);
    },
    [dateValue, setValue]
  );

  const handleChangeMonth = useCallback(
    (monthValue: number) => {
      const updatedDate = dateValue;
      if (!updatedDate) return;
      updatedDate.setMonth(monthValue);
      setValue("date", updatedDate);
    },
    [dateValue, setValue]
  );

  useEffect(() => {
    const updatedDate = dateValue;
    if (!updatedDate) return;
    setValue("month", updatedDate.getMonth());
    setValue("year", updatedDate.getFullYear());
  }, [dateValue, setValue]);

  const disablePastMonth = useCallback(
    (monthIndex: number) =>
      disablePast &&
      monthIndex < new Date().getMonth() &&
      watch("year") <= new Date().getFullYear(),
    [disablePast, watch]
  );

  const disableMaxMonth = useCallback(
    (monthValue: number) =>
      maxDate &&
      monthValue > maxDate.getMonth() &&
      watch("year") >= maxDate.getFullYear(),
    [maxDate, watch]
  );

  const disablePastYear = useCallback(
    (year: number) => disablePast && year < new Date().getFullYear(),
    [disablePast]
  );

  const disableMaxYear = useCallback(
    (yearValue: number) => maxDate && yearValue > maxDate.getFullYear(),
    [maxDate]
  );

  const handleSelectYear = useCallback(
    (year: number) => {
      if (!disablePastYear(year) && !disableMaxYear(year)) {
        handleChangeYear(year);
        setYearAnchorElement(null);
      }
    },
    [disablePastYear, disableMaxYear, handleChangeYear]
  );

  const handleSelectMonth = useCallback(
    (monthIndex: number) => {
      if (!disablePastMonth(monthIndex) && !disableMaxMonth(monthIndex)) {
        handleChangeMonth(monthIndex);
        setMonthAnchorElement(null);
      }
    },
    [disablePastMonth, disableMaxMonth, handleChangeMonth]
  );

  return (
    <>
      <Box onClick={disabled ? undefined : () => setIsOpen(true)}>
        {renderInput}
      </Box>
      <Dialog
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            maxHeight: "unset",
            maxWidth: "unset",
            margin: 0,
          },
        }}
        hideBackdrop
        open={isOpen}
        onClose={handleClose}
        sx={{
          width: disableCounter ? "400px" : "800px",
          height: "500px",
          backgroundColor: "white",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            textAlign: "center",
            marginTop: "40px",
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              "& .MuiPickersCalendarHeader-root": {
                display: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              width: disableCounter ? "100%" : "50%",
              marginTop: "50px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: primary[500],
                marginLeft: "70px",
                textTransform: "uppercase",
              }}
            >
              {t({
                id: "custom-date-time-picker.select-end-date",
                defaultMessage: "Select End Date",
              })}
            </Typography>
            <Box
              sx={{
                marginLeft: "70px",
                display: "flex",
                gap: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "7px",
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                  {format(setMonth(new Date(), watch("month")), "MMMM", {
                    locale: dateLocale,
                  })}
                </Typography>
                <SvgIcon
                  component={ChevronDownIcon}
                  viewBox="0 0 12 8"
                  sx={{ color: primary[500], width: "12px", cursor: "pointer" }}
                  onClick={(e) => setMonthAnchorElement(e.currentTarget)}
                />
                <Popover
                  open={Boolean(monthAnchorElement)}
                  anchorEl={monthAnchorElement}
                  onClose={() => setMonthAnchorElement(null)}
                >
                  <Box
                    sx={{
                      width: "360px",
                      padding: "20px",
                      border: `1px solid ${grey[500]}`,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontSize: "12px",
                        color: primary[500],
                        marginBottom: "30px",
                        fontWeight: "500",
                        marginLeft: "10px",
                      }}
                    >
                      {t({
                        id: "custom-date-time-picker.select-month",
                        defaultMessage: "Select Month",
                      })}
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", rowGap: "15px" }}
                    >
                      {Array.from({ length: 12 }, (_, idx) => (
                        <Box
                          sx={{
                            width: "33%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          key={idx}
                        >
                          <Typography
                            sx={{
                              padding: "10px",
                              backgroundColor:
                                idx === watch("month") ? primary[500] : "unset",
                              borderRadius: "90px",
                              color:
                                disablePastMonth(idx) || disableMaxMonth(idx)
                                  ? grey[700]
                                  : idx === watch("month")
                                  ? white[500]
                                  : "unset",
                              fontWeight:
                                idx === watch("month") ? 700 : "unset",
                              cursor: "pointer",
                            }}
                            onClick={() => handleSelectMonth(idx)}
                          >
                            {format(setMonth(new Date(), idx), "MMMM", {
                              locale: dateLocale,
                            })}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Popover>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "7px",
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                  {watch("year")}
                </Typography>
                <SvgIcon
                  component={ChevronDownIcon}
                  viewBox="0 0 12 8"
                  sx={{ color: primary[500], width: "12px", cursor: "pointer" }}
                  onClick={(e) => setYearAnchorElement(e.currentTarget)}
                />
                <Popover
                  open={Boolean(yearAnchorElement)}
                  anchorEl={yearAnchorElement}
                  onClose={() => setYearAnchorElement(null)}
                >
                  <Box
                    sx={{
                      width: "360px",
                      padding: "20px",
                      border: `1px solid ${grey[500]}`,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontSize: "12px",
                        color: primary[500],
                        marginBottom: "30px",
                        fontWeight: "500",
                        marginLeft: "10px",
                      }}
                    >
                      {t({
                        id: "custom-date-time-picker.select-year",
                        defaultMessage: "Select Year",
                      })}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        rowGap: "15px",
                        maxHeight: "215px",
                        overflowY: "scroll",
                      }}
                    >
                      {years.map((el) => (
                        <Box
                          sx={{
                            width: "33%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          key={el}
                        >
                          <Typography
                            sx={{
                              padding: "10px",
                              backgroundColor:
                                el === watch("year") ? primary[500] : "unset",
                              borderRadius: "90px",
                              color:
                                disablePastYear(el) || disableMaxYear(el)
                                  ? grey[700]
                                  : el === watch("year")
                                  ? white[500]
                                  : "unset",
                              fontWeight: el === watch("year") ? 700 : "unset",
                              cursor: "pointer",
                            }}
                            onClick={() => handleSelectYear(el)}
                          >
                            {el}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Popover>
              </Box>
            </Box>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              views={["year", "month", "day"]}
              minDate={minDate}
              maxDate={maxDate}
              // TODO: re-check
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dayOfWeekFormatter={(day) => {
                switch (day) {
                  case "Su":
                    return format(setDay(new Date(), 0), "EEE", {
                      locale: dateLocale,
                    });
                  case "Mo":
                    return format(setDay(new Date(), 1), "EEE", {
                      locale: dateLocale,
                    });
                  case "Tu":
                    return format(setDay(new Date(), 2), "EEE", {
                      locale: dateLocale,
                    });
                  case "We":
                    return format(setDay(new Date(), 3), "EEE", {
                      locale: dateLocale,
                    });
                  case "Th":
                    return format(setDay(new Date(), 4), "EEE", {
                      locale: dateLocale,
                    });
                  case "Fr":
                    return format(setDay(new Date(), 5), "EEE", {
                      locale: dateLocale,
                    });
                  case "Sa":
                    return format(setDay(new Date(), 6), "EEE", {
                      locale: dateLocale,
                    });
                  default:
                    return day;
                }
              }}
              onChange={(newValue) => {
                setValue("date", newValue);
              }}
              value={dateValue}
              components={{
                SwitchViewIcon: arrowDownStyle,
                LeftArrowButton: HiddenButton,
                RightArrowButton: HiddenButton,
              }}
              showDaysOutsideCurrentMonth
              disablePast={disablePast}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          {!disableCounter && (
            <>
              <Box
                sx={{
                  height: "80%",
                  width: "2px",
                  margin: "auto 0",
                  backgroundColor: grey[300],
                }}
              />
              <Box sx={{ marginTop: "50px", width: "50%" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: primary[500],
                    marginLeft: "70px",
                    textTransform: "uppercase",
                    fontWeight: "500",
                  }}
                >
                  {t({
                    id: "custom-date-time-picker.select-time",
                    defaultMessage: "Select Time",
                  })}
                </Typography>
                <Box
                  sx={{
                    marginLeft: "70px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                    {watch("hour")}:{watch("minute")} {watch("dayPeriod")}
                  </Typography>
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ margin: "40px 70px 0 70px" }}
                >
                  <CounterInput
                    {...{
                      ...register("hour", {
                        required: true,
                        validate: (val: string) => {
                          if (
                            /^\d+$/.test(val) &&
                            parseInt(val, 10) > 0 &&
                            parseInt(val, 10) <= 12
                          ) {
                            return undefined;
                          }
                          return "error";
                        },
                      }),
                      min: 1,
                      max: 12,
                    }}
                    name={TimeName.Hour}
                    zPad={2}
                    formSetValue={setValue}
                    error={errors.hour}
                    sx={{ flex: "1" }}
                  />
                  <Box>
                    <TimeSeparator>&#58;</TimeSeparator>
                  </Box>
                  <CounterInput
                    {...{
                      ...register("minute", {
                        required: true,
                        validate: (val: string) => {
                          if (
                            /^\d+$/.test(val) &&
                            parseInt(val, 10) >= 0 &&
                            parseInt(val, 10) <= 59
                          ) {
                            return undefined;
                          }
                          return t({
                            id: "common.error",
                            defaultMessage: "Error",
                          });
                        },
                      }),
                      min: 0,
                      max: 59,
                    }}
                    name={TimeName.Minute}
                    zPad={2}
                    formSetValue={setValue}
                    error={errors.minute}
                    sx={{ flex: "1" }}
                  />
                  <Box sx={{ marginLeft: "5px" }}>
                    <SwitchDayPeriod
                      {...register("dayPeriod")}
                      formSetValue={setValue}
                      name={TimeName.DayPeriod}
                      chosen={watch("dayPeriod")}
                    />
                  </Box>
                </Stack>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{ width: "70%", display: "flex", gap: "10px", margin: "0 auto" }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            size="large"
            sx={{ width: "50%" }}
          >
            {t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ width: "50%" }}
            disabled={!isValid || !isValidTime}
            onClick={handleSubmitClick}
            autoFocus
          >
            {t({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default CustomDateTimePicker;
