import useSendMessage from "@web-src/features/chats/hooks/useSendMessage";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuid4 } from "uuid";
import {
  ChatMessage,
  ChatMessagePayloadCallAction,
  ChatMessageType,
  ChatCallType,
} from "@web-src/features/chats/types";
import AgoraRTC, { IAgoraRTCClient } from "agora-rtc-sdk-ng";
import { environment } from "@web-src/environments/environment";
import { useAgoraTokenMutation } from "../callsApi";
import { ActiveCallProps } from "../types";
import useActiveCall from "../hooks/useActiveCall";

type CallsContextType = {
  initiateCall?: (chatId: string, type: ChatMessageType) => void;
  joinCall?: (channel: string, callType?: ChatCallType) => void;
  handleReceivedCallAction?: (message: ChatMessage) => void;
  leaveCall?: () => void;
  client?: IAgoraRTCClient;
  activeCall?: ReturnType<typeof useActiveCall>;
  incomingCall?: ChatMessage;
  videoDevices?: MediaDeviceInfo[];
  audioDevices?: MediaDeviceInfo[];
};

const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

const CallsContext = createContext<CallsContextType>({});

const CallsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const sendMessage = useSendMessage();
  const [activeCallProps, setActiveCallProps] = useState<ActiveCallProps>();
  const activeCall = useActiveCall(client, activeCallProps);
  const [agoraToken] = useAgoraTokenMutation();
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>();
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>();
  const [incomingCall, setIncomingCall] = useState<ChatMessage>();
  useEffect(() => {
    if (activeCallProps?.channel === incomingCall?.payload.call_channel) {
      setIncomingCall(undefined);
    }
  }, [incomingCall, activeCallProps]);
  useEffect(() => {
    if (audioDevices || !activeCall?.audioEnabled) {
      return;
    }
    AgoraRTC.getMicrophones().then(setAudioDevices);
  }, [activeCall, activeCall?.audioEnabled, audioDevices]);

  useEffect(() => {
    if (videoDevices || !activeCall?.videoEnabled) {
      return;
    }
    AgoraRTC.getCameras().then(setVideoDevices);
  }, [activeCall, videoDevices, activeCall?.videoEnabled]);

  const initiateCall = useCallback(
    async (chatId: string, chatMessageType: ChatMessageType) => {
      const channel = uuid4();
      const tokenData = await agoraToken({ channel });
      if (!("data" in tokenData)) {
        throw tokenData.error as Error;
      }
      await sendMessage({
        to: chatId,
        body: "",
        extraPayload: {
          conference: { channel },
        },
        type: chatMessageType,
      });
    },
    [agoraToken, sendMessage]
  );

  const joinCall = useCallback(
    async (channel: string, callType?: ChatCallType) => {
      const tokenData = await agoraToken({ channel });
      if (!("data" in tokenData)) {
        throw tokenData.error as Error;
      }
      setActiveCallProps({
        token: tokenData.data,
        channel,
        appId: environment.agoraAppId,
        video: callType === ChatCallType.video,
      });
    },
    [agoraToken]
  );

  const handleReceivedCallAction = useCallback((message: ChatMessage) => {
    if (
      message?.payload.call_action === ChatMessagePayloadCallAction.call_invite
    ) {
      setIncomingCall(message);
    }
    if (
      message?.payload.call_action ===
      ChatMessagePayloadCallAction.call_declined
    ) {
      setIncomingCall(undefined);
    }
  }, []);

  const leaveCall = useCallback(async () => {
    await activeCall?.leaveCall();
    setActiveCallProps(undefined);
  }, [activeCall]);

  const value: CallsContextType = useMemo(
    () => ({
      initiateCall,
      activeCall,
      incomingCall,
      joinCall,
      handleReceivedCallAction,
      client,
      leaveCall,
      audioDevices,
      videoDevices,
    }),
    [
      initiateCall,
      activeCall,
      incomingCall,
      joinCall,
      handleReceivedCallAction,
      leaveCall,
      audioDevices,
      videoDevices,
    ]
  );

  return (
    <CallsContext.Provider value={value}>{children}</CallsContext.Provider>
  );
};

export { CallsContext, CallsProvider };
