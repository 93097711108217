import { useCallback } from "react";
import { getUniqueId } from "@jugl-web/utils";
import * as tus from "tus-js-client";
import mime from "mime";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { usePreferencesProvider } from "../../preferences";
import { useAuthTokenProvider } from "../../auth/providers/AuthTokenProvider";
import { FilesModule } from "../types";

const DEFAULT_TYPE = "application/octet-stream";

export const useTusUpload = ({
  module,
  entityId,
}: {
  module: FilesModule;
  entityId?: string;
}) => {
  const { getToken } = useAuthTokenProvider();
  const { apiBaseUrl } = useRestApiProvider();
  const { preferences } = usePreferencesProvider();
  const uploadFile = useCallback(
    async ({
      file,
      extraMetaData,
      addPath,
      onProgress,
    }: {
      file: File;
      extraMetaData?: {
        parent_dir_id?: string;
      };
      addPath?: boolean;
      onProgress?: (bytesSend: number, bytesTotal: number) => void;
    }) =>
      new Promise((resolve, reject) => {
        if (!entityId) {
          throw new Error("Entity ID is required");
        }
        const fileContentType = mime.getType(file.name) || DEFAULT_TYPE;
        const fileExtension =
          file.name.indexOf(".") > -1
            ? file.name.split(".").pop()
            : mime.getExtension(fileContentType);

        const fileUid = `${getUniqueId()}`;
        const name = `${fileUid}.${fileExtension}`;
        const path = `${entityId}/${name}`;
        const upload = new tus.Upload(file, {
          endpoint: `${apiBaseUrl}/api/auth/files/${entityId}`,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          parallelUploads: preferences.tusParallelUploads || 1,
          metadata: {
            name:
              module === FilesModule.drive
                ? file.name.replace(/[^a-zA-Z0-9 .]/g, "")
                : name,
            content_type: mime.getType(file.name) || DEFAULT_TYPE,
            module,
            ...(addPath ? { path } : {}),
            ...extraMetaData,
          },
          onError(error) {
            reject(error);
          },
          onSuccess() {
            resolve({
              stream_url: `${apiBaseUrl}/api/auth/attachment/stream?file=${path}`,
              url: `${apiBaseUrl}/api/auth/attachment/download?path=${path}`,
              preview_url: `${apiBaseUrl}/api/auth/attachment/preview?file=${path}`,
              uid: `${fileUid}.${fileExtension}`,
            });
          },
          chunkSize: preferences.tusUploadChunkSize * 1024 * 1024,
          onProgress,
        });
        upload.start();
      }),
    [apiBaseUrl, entityId, getToken, module, preferences]
  );
  return {
    uploadFile,
  };
};
