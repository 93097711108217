import { cx } from "@jugl-web/utils";
import { ReactNode, useMemo } from "react";
import { Drawer } from "../../cross-platform/Drawer";
import { FullScreenHeader } from "./FullScreenHeader";
import { SwipeableHeader } from "./SwipeableHeader";
import { FullScreenHeaderProps, SwipeableDrawerProps } from "./types";

// TODO: Think of a better name as "swipe" feature is not most important anymore
export const SwipeableDrawer = ({
  children,
  isOpen,
  title,
  hasBackdrop,
  size = "auto",
  placement = "bottom",
  hasDetachedHeader,
  initialFocus,
  className,
  onClose,
  onSwipeUp,
}: SwipeableDrawerProps) => {
  const commonHeaderProps = useMemo<FullScreenHeaderProps>(
    () => ({
      title,
      onClose,
      style: hasDetachedHeader
        ? { boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.04)" }
        : undefined,
    }),
    [hasDetachedHeader, onClose, title]
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
      hasBackdrop={hasBackdrop}
      size={size}
      initialFocus={initialFocus}
      className={cx(
        "flex max-h-full flex-col",
        size !== "full-screen" && "rounded-t-2xl",
        className
      )}
    >
      {size === "full-screen" ? (
        <FullScreenHeader {...commonHeaderProps} />
      ) : (
        <SwipeableHeader {...commonHeaderProps} onSwipeUp={onSwipeUp} />
      )}
      {children}
    </Drawer>
  );
};

export interface SwipeableDrawerContentProps {
  children: ReactNode;
  className?: string;
}

SwipeableDrawer.Content = ({
  children,
  className,
}: SwipeableDrawerContentProps) => (
  <div className={cx("mt-1 grow overflow-y-auto px-4 pb-8", className)}>
    {children}
  </div>
);

export interface SwipeableDrawerActionsProps {
  children: ReactNode;
  isHorizontal?: boolean;
  isDetached?: boolean;
  className?: string;
}

SwipeableDrawer.Actions = ({
  children,
  isHorizontal = false,
  isDetached = false,
  className,
}: SwipeableDrawerActionsProps) => (
  <div
    className={cx(
      "flex shrink-0 px-6 pb-4 pt-2",
      isHorizontal ? "items-center gap-2.5" : "flex-col gap-4",
      className
    )}
    style={{
      boxShadow: isDetached
        ? "1px -6px 10px 0px rgba(18, 22, 34, 0.04)"
        : undefined,
    }}
  >
    {children}
  </div>
);
