import { RtkEmptySplitApi } from "../../types";
import { Profile } from "./types";

export const addProfilesApi = (emptySplitApi: RtkEmptySplitApi) => {
  const profilesApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
      getUserProfile: builder.query<Profile, { userId: string }>({
        query: ({ userId }) => ({
          url: "/api/auth/profile-v2",
          params: { user_id: userId },
        }),
      }),
      searchForProfiles: builder.mutation<
        { data: Profile[] },
        {
          data: {
            input: string;
            entity_id?: string;
            workspace_id?: string;
          };
        }
      >({
        query: ({ data }) => ({
          url: "/api/auth/profile/search",
          method: "POST",
          data,
        }),
      }),
    }),
    overrideExisting: true,
  });

  return profilesApi;
};

export type ProfilesApi = ReturnType<typeof addProfilesApi>;
