import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import store from "@web-src/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AppVariantProvider } from "@jugl-web/utils/providers/AppVariantProvider";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { CustomThemeProvider } from "@web-src/themes/CustomThemeProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FirebaseProvider } from "@web-src/features/app/providers/Firebase";
import { PushNotificationsProvider } from "@web-src/features/app/providers/PushNotifications";
import { SingletonHooksContainer } from "react-singleton-hook";
import { RestApiProvider } from "@jugl-web/rest-api";
import { BrowserRouter as Router } from "react-router-dom";
import App from "@web-src/app/app";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";
import "@jugl-web/utils/styles/util-styles.css";
import { PhoenixSocketProvider } from "@web-src/features/chats/providers/PheonixSocket";
import { AuthTokenProvider } from "@jugl-web/domain-resources/auth/providers/AuthTokenProvider";
import { PreferencesProvider } from "@jugl-web/domain-resources/preferences";
import { DownloadManagerProvider } from "@jugl-web/domain-resources/files/providers/DownloadManagerProvider";
import { EnhancedIntlProvider } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { pdfjs } from "react-pdf";
import { CallsProvider } from "./features/calls/providers/Calls";
import { environment } from "./environments/environment";
import { isRunningLocally } from "./features/app/utils";
import {
  profilesApi,
  tasksApi,
  labelsApi,
  usersApi,
  workspacesApi,
  eduAssignmentsApi,
  eduCommentsApi,
  eduFeedsApi,
  eduLessonsApi,
  customerApi as customersApi,
  customerFormFieldApi as customersFormFieldApi,
  tasksCommentsApi,
  driveApi,
  tasksTemplatesApi,
  supersetApi,
} from "./features/api/createApi";
import { getJwtToken } from "./features/auth/utils";
import { DriveProvider } from "./features/library/providers/DriveProvider";
import { AuthBroadcastListener } from "./features/auth/components/AuthBroadcastListener";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const generateClassName = createGenerateClassName();

if (!isRunningLocally()) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    maxBreadcrumbs: 0,
    // TODO: to be removed
    maxValueLength: 2000,
  });
}

const renderApp = () => {
  const $rootElement = document.getElementById("root");
  if (!$rootElement) {
    return;
  }
  const root = ReactDOM.createRoot($rootElement);
  root.render(
    <PreferencesProvider>
      <PushNotificationsProvider>
        <FirebaseProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider maxSnack={3}>
              <StylesProvider generateClassName={generateClassName}>
                <CustomThemeProvider>
                  <Provider store={store}>
                    <AuthTokenProvider
                      getter={() => getJwtToken()?.accessToken || null}
                    >
                      <RestApiProvider
                        apiBaseUrl={environment.apiUrl}
                        apis={{
                          profilesApi,
                          tasksApi,
                          tasksCommentsApi,
                          tasksTemplatesApi,
                          labelsApi,
                          usersApi,
                          workspacesApi,
                          eduAssignmentsApi,
                          eduCommentsApi,
                          eduFeedsApi,
                          eduLessonsApi,
                          customersApi,
                          customersFormFieldApi,
                          supersetApi,
                          driveApi,
                        }}
                      >
                        <AppVariantProvider variant="web">
                          <DownloadManagerProvider>
                            <Router>
                              <DriveProvider>
                                <PhoenixSocketProvider>
                                  <CallsProvider>
                                    <EnhancedIntlProvider>
                                      <AuthBroadcastListener />
                                      <App />
                                    </EnhancedIntlProvider>
                                    <SingletonHooksContainer />
                                  </CallsProvider>
                                </PhoenixSocketProvider>
                              </DriveProvider>
                            </Router>
                          </DownloadManagerProvider>
                        </AppVariantProvider>
                      </RestApiProvider>
                    </AuthTokenProvider>
                  </Provider>
                </CustomThemeProvider>
              </StylesProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </FirebaseProvider>
      </PushNotificationsProvider>
    </PreferencesProvider>
  );
};

renderApp();
