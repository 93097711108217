import { AppType } from "@web-src/features/app/types";
import {
  Environment,
  devAgoraAppId,
  devApiUrl,
  devFirebaseConfig,
  devFirebaseTokenId,
  devFirebaseVapidKey,
  devSentryDsn,
  devWsDomain,
  geocodingKey,
  mapTilerKey,
  devSupersetDomain,
  devSupersetDashboardIds,
} from ".";

export const environment: Environment = {
  production: false,
  appType: AppType.edu,
  firebaseConfig: devFirebaseConfig,
  firebaseVapidKey: devFirebaseVapidKey,
  sentryDsn: devSentryDsn,
  apiUrl: devApiUrl,
  wsDomain: devWsDomain,
  firebaseTokenId: devFirebaseTokenId,
  mapTilerKey,
  geocodingKey,
  agoraAppId: devAgoraAppId,
  supersetDomain: devSupersetDomain,
  isInternationalizationEnabled: true,
  supersetDashboardIds: devSupersetDashboardIds,
};
