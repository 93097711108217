import { memo, useMemo } from "react";
// components
import MuiAvatar from "@mui/material/Avatar";
import {
  StyledFallbackWrapper,
  StyledFallbackText,
} from "@web-src/components/Avatar/styles";

const Avatar = function Avatar({
  src,
  alt,
  sx,
  imgProps,
  fallbackTextProps,
  ...rest
}) {
  const titleWithoutSpecialCharacters = useMemo(
    () => alt?.replace(/\W/g, " "),
    [alt]
  );
  return (
    <MuiAvatar
      {...rest}
      sx={{
        ...sx,
        background:
          "linear-gradient(180deg, #CECED3 0%, #ECEFF0 69.27%), url(image.png)",
      }}
      src={src}
      objectFit="contain"
      imgProps={{ style: { objectFit: "contain" } }}
      alt={alt}
    >
      <StyledFallbackWrapper>
        <StyledFallbackText
          color="black"
          component="span"
          {...fallbackTextProps}
        >
          {titleWithoutSpecialCharacters
            ? titleWithoutSpecialCharacters
                .split(" ")
                .map((n) => n[0])
                .join("")
                .slice(0, 2)
            : ""}
        </StyledFallbackText>
      </StyledFallbackWrapper>
    </MuiAvatar>
  );
};

export default memo(Avatar);
