import {
  PaginationItem,
  usePagination,
  usePaginationUpdate,
} from "@jugl-web/utils";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useLazyGetWorkspaceQuery } from "@web-src/features/entities/entitiesApi";
import { useLazyGetProfileQuery } from "@web-src/features/auth/authApi";
import { getUserProfileDisplayName } from "@web-src/features/users/utils";
import { singletonHook } from "react-singleton-hook";
import { environment } from "@web-src/environments/environment";
import { ChatItem, ChatMessage, ChatType } from "../types";
import { useLazyChatHistoryQuery } from "../chatsApi";
import {
  getMessageChatId,
  messageToConversationLastMessage,
  tokenizeMessagePayload,
} from "../utils";
import { PhoenixSocketContext } from "../providers/PheonixSocket";
import useLiveConversationReceipts from "./useLiveConversationReceipts";

interface UseConversationsType {
  conversations: PaginationItem<ChatItem>[];
  isConversationsLoading: boolean;
  isConversationsError: boolean;
  // TODO: reuse params types
  changeUnreadCount?: (params: {
    id: string;
    reset?: boolean;
    delta?: number;
  }) => void;
  addNewMessage?: (params: {
    id: string;
    message: ChatMessage;
    outgoing?: boolean;
  }) => void;
  addConversation?: (params: {
    params: Pick<
      ChatItem,
      | "id"
      | "img"
      | "title"
      | "type"
      | "lastMessage"
      | "firstUnreadMessage"
      | "unreadCount"
    >;
  }) => void;
}

const useConversations = (): UseConversationsType => {
  const { entity } = useEntity();
  const entityId = entity?.id;
  const { addOrUpdateItem, updateItem: updatePaginationItem } =
    usePaginationUpdate<ChatMessage>();
  const meId = useSelector(selectUserId);
  const [getWorkspace] = useLazyGetWorkspaceQuery();
  const { incomingMessages$, indicatorMessages$ } =
    useContext(PhoenixSocketContext);

  const { liveReceiptsState } = useLiveConversationReceipts();

  const {
    items: conversations,
    addItems,
    lastPageState,
    setIsLoadingState,
    updateItem,
    isLoading: paginationIsLoading,
    isInitialized,
    bumpItem,
  } = usePagination<ChatItem, { lastTime?: string; isLast?: boolean }>(
    `conversations:${entityId}`
  );
  const [getProfile] = useLazyGetProfileQuery();

  const [loadingNewConversation, setLoadingNewConversation] = useState<{
    [key: string]: boolean;
  }>();
  const newConversationsMessages = useRef<{ [key: string]: ChatMessage[] }>({});

  const [
    loadChatHistory,
    { isLoading: isConversationsLoading, isError: isConversationsError },
  ] = useLazyChatHistoryQuery();

  const handleLoadConversations = useCallback(async () => {
    if (!entityId || (lastPageState && lastPageState.isLast) || !meId) {
      return;
    }
    setIsLoadingState(true);
    const response = await loadChatHistory({
      params: {
        entity_id: entityId,
        time:
          lastPageState?.lastTime ||
          `${new Date().getFullYear() + 1}-01-01 00:00:00`,
        limit: 100,
      },
    });
    const selfResponse = await loadChatHistory({
      params: {
        entity_id: entityId,
        time:
          lastPageState?.lastTime ||
          `${new Date().getFullYear() + 1}-01-01 00:00:00`,
        self_chat: "true",
        limit: 1,
      },
    });
    // TODO: optimize
    const selfChat: ChatItem = {
      ...((selfResponse.data
        ? selfResponse.data?.find((item) => item.id === meId)
        : undefined) || {
        id: meId,
        type: ChatType.chat,
      }),
    };
    selfChat.isSelf = true;
    const restChats = response.data
      ? response.data?.filter((item) => item.id !== meId)
      : [];
    addItems(
      [selfChat, ...restChats].map((item) => ({
        id: item?.id || meId,
        data: item,
      }))
    );
    setIsLoadingState(false);
  }, [
    addItems,
    entityId,
    lastPageState,
    loadChatHistory,
    meId,
    setIsLoadingState,
  ]);

  const changeUnreadCount: UseConversationsType["changeUnreadCount"] =
    useCallback(
      ({
        id,
        reset,
        delta,
      }: {
        id: string;
        reset?: boolean;
        delta?: number;
      }) => {
        const conversation = conversations.find((item) => id === item.id);
        if (!conversation || !conversation.data.unreadCount) {
          return;
        }
        let unreadCountToSet = conversation.data.unreadCount + (delta || 0);
        if (unreadCountToSet < 0) {
          unreadCountToSet = 0;
        }
        updateItem({
          ...conversation,
          data: {
            ...conversation.data,
            unreadCount: reset ? 0 : unreadCountToSet,
          },
        });
      },
      [conversations, updateItem]
    );

  const addConversation = useCallback(
    ({
      params,
    }: {
      params: Pick<
        ChatItem,
        | "id"
        | "img"
        | "title"
        | "type"
        | "lastMessage"
        | "firstUnreadMessage"
        | "unreadCount"
      >;
    }) => {
      if (!params.id) {
        return;
      }
      addItems([{ id: params.id, data: params }], "start");
    },
    [addItems]
  );

  const addNewMessage = useCallback(
    ({
      id,
      message,
      outgoing,
    }: {
      id: string;
      message: ChatMessage;
      outgoing?: boolean;
    }) => {
      const conversation = conversations.find((item) => id === item.id);
      if (!conversation) {
        return;
      }
      updateItem({
        ...conversation,
        data: {
          ...conversation.data,
          lastMessage: messageToConversationLastMessage(message),
          firstUnreadMessage:
            outgoing || conversation?.data?.firstUnreadMessage
              ? conversation?.data?.firstUnreadMessage
              : {
                  msgId: message.msg_id,
                  createdAt: message.timestamp,
                },
        },
      });
    },
    [conversations, updateItem]
  );

  useEffect(() => {
    const subscription = indicatorMessages$?.subscribe(({ message, merge }) => {
      const chatId = getMessageChatId(message, meId);
      updatePaginationItem({
        paginationId: `messages:${chatId}`,
        item: { id: message.msg_id, data: message },
        merge,
      });
      const conversation = conversations?.find((item) => chatId === item.id);
      if (!conversation) {
        return;
      }
      if (
        message.edited &&
        conversation.data.lastMessage?.msgId === message.msg_id
      ) {
        updateItem({
          ...conversation,
          data: {
            ...conversation.data,
            lastMessage: {
              ...conversation.data.lastMessage,
              tokenizedMessage: tokenizeMessagePayload(message),
            },
          },
        });
      }
      if (
        message.deleted &&
        conversation.data.lastMessage?.msgId === message.msg_id
      ) {
        updateItem({
          ...conversation,
          data: {
            ...conversation.data,
            lastMessage: {
              ...conversation.data.lastMessage,
              deleted: true,
            },
          },
        });
      }
    });
    return () => subscription?.unsubscribe();
  }, [
    indicatorMessages$,
    meId,
    updatePaginationItem,
    conversations,
    updateItem,
  ]);

  useEffect(() => {
    const subscription = incomingMessages$?.subscribe((newMessage) => {
      const chatId = getMessageChatId(newMessage, meId);
      const conversation = conversations.find((item) => item.id === chatId);
      if (!conversation) {
        newConversationsMessages.current = {
          ...newConversationsMessages.current,
          [chatId]: [
            ...(newConversationsMessages.current[chatId] || []),
            newMessage,
          ],
        };
      }
      if (!conversation && loadingNewConversation?.[chatId]) {
        return;
      }
      if (!conversation) {
        setLoadingNewConversation((prev) => ({ ...prev, [chatId]: true }));
        const messages = newConversationsMessages.current[chatId];
        const firstMessage = messages?.find((item) => item.from !== meId);
        const lastMessage = messages?.[messages.length - 1];
        // TODO: error handling
        if (newMessage.type === "muc") {
          if (!entityId || !chatId) {
            return;
          }
          getWorkspace({ workspaceId: chatId, entityId }).then(
            ({ data: workspace }) => {
              if (!workspace) {
                return;
              }
              addConversation({
                params: {
                  id: workspace.id,
                  title: workspace.title,
                  type: ChatType.muc,
                  img: workspace.display_picture,
                  unreadCount: 0,
                  lastMessage: lastMessage
                    ? messageToConversationLastMessage(lastMessage)
                    : undefined,
                  firstUnreadMessage: firstMessage
                    ? {
                        msgId: firstMessage.msg_id,
                        createdAt: firstMessage.timestamp,
                      }
                    : undefined,
                },
              });
            }
          );
        } else if (newMessage.type === "chat") {
          getProfile({ params: { user_id: chatId } }).then(
            ({ data: profile }) => {
              if (!profile) {
                return;
              }
              addConversation({
                params: {
                  id: chatId,
                  title: getUserProfileDisplayName(profile),
                  type: ChatType.chat,
                  img: profile.general?.img,
                  unreadCount: 0,
                  lastMessage: lastMessage
                    ? messageToConversationLastMessage(lastMessage)
                    : undefined,
                  firstUnreadMessage: firstMessage
                    ? {
                        msgId: firstMessage.msg_id,
                        createdAt: firstMessage.timestamp,
                      }
                    : undefined,
                },
              });
            }
          );
        }
        newConversationsMessages.current = {
          ...newConversationsMessages.current,
          [chatId]: [],
        };
        setLoadingNewConversation((prev) => ({ ...prev, [chatId]: false }));
        return;
      }
      addNewMessage({ id: chatId, message: newMessage });
      addOrUpdateItem({
        paginationId: `messages:${chatId}`,
        item: {
          id: newMessage.msg_id,
          data: newMessage,
        },
      });
      bumpItem(chatId);
    });
    return () => subscription?.unsubscribe();
  }, [
    addConversation,
    addNewMessage,
    addOrUpdateItem,
    bumpItem,
    conversations,
    entityId,
    getProfile,
    getWorkspace,
    incomingMessages$,
    loadingNewConversation,
    meId,
  ]);

  useEffect(() => {
    if (
      isConversationsLoading ||
      conversations?.length ||
      lastPageState ||
      isInitialized ||
      paginationIsLoading
    ) {
      return;
    }
    handleLoadConversations();
  }, [
    conversations?.length,
    handleLoadConversations,
    isConversationsLoading,
    isInitialized,
    lastPageState,
    paginationIsLoading,
  ]);

  const sortedConversations: PaginationItem<ChatItem>[] = useMemo(
    () =>
      [...(conversations || [])]
        ?.sort((a, b) => {
          if (a.data.isSelf && !b.data.isSelf) {
            return -1;
          }
          if (!a.data.isSelf && b.data.isSelf) {
            return 1;
          }
          return 0;
        })
        .map((item) => {
          const liveReceiptState = liveReceiptsState[item.id];
          if (!liveReceiptState) {
            return item;
          }
          return {
            ...item,
            data: {
              ...item.data,
              unreadCount:
                (item.data.unreadCount || 0) +
                (liveReceiptState.unreadCounterDelta || 0),
              lastReadMessage:
                liveReceiptState.lastReadMessage?.msg_id &&
                liveReceiptState.lastReadMessage?.timestamp
                  ? {
                      msgId: liveReceiptState.lastReadMessage.msg_id,
                      createdAt: liveReceiptState.lastReadMessage.timestamp,
                    }
                  : undefined,
            },
          };
        }),
    [conversations, liveReceiptsState]
  );

  const $favicon = useMemo(() => document.getElementById("favicon"), []);
  useEffect(() => {
    const hasUnread = Object.values(sortedConversations).find(
      (item) => !!item.data.unreadCount
    );
    $favicon?.setAttribute(
      "href",
      `/${environment.appType}/${
        hasUnread ? "favicon-notification" : "favicon"
      }.ico`
    );
  }, [$favicon, sortedConversations]);

  return {
    conversations: sortedConversations,
    isConversationsLoading,
    isConversationsError,
    changeUnreadCount,
    addNewMessage,
    addConversation,
  };
};

export default singletonHook(
  {
    conversations: [],
    isConversationsLoading: true,
    isConversationsError: false,
  },
  useConversations
) || {};
