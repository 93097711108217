import { PaginatedResponse, RtkEmptySplitApi } from "../../types";
import { EduLesson } from "./types";
import { EduLessonsApiTag } from "./tags";

export const addEduLessonsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [EduLessonsApiTag.allLessonsList],
  });

  const eduLessonsApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      createLesson: build.mutation<
        { data: EduLesson },
        {
          entityId: string;
          data: { title: string; workspace_id: string; subject: string };
        }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/entity/${entityId}/edu/lesson`,
          method: "POST",
          data,
        }),
        invalidatesTags: [EduLessonsApiTag.allLessonsList],
      }),
      lessonsList: build.query<
        PaginatedResponse<EduLesson>,
        {
          entityId: string;
          params: {
            page: number;
            page_size: number;
            spaces?: string;
            subjects?: string;
          };
        }
      >({
        query: ({ entityId, params }) => ({
          params,
          url: `/api/auth/entity/${entityId}/edu/lesson`,
        }),
      }),
      lessonsSearch: build.query<
        PaginatedResponse<EduLesson>,
        {
          entityId: string;
          params: {
            page: number;
            page_size: number;
            spaces?: string;
            subjects?: string;
            search_term: string;
          };
        }
      >({
        query: ({ entityId, params }) => ({
          params,
          url: `/api/auth/entity/${entityId}/edu/lesson/lessons/search`,
        }),
      }),
      deleteLesson: build.mutation<
        void,
        { entityId: string; lessonId: string }
      >({
        query: ({ entityId, lessonId }) => ({
          url: `/api/auth/entity/${entityId}/edu/lesson/${lessonId}`,
          method: "DELETE",
        }),
        invalidatesTags: [EduLessonsApiTag.allLessonsList],
      }),
      getLesson: build.query<
        {
          data: EduLesson;
        },
        {
          entityId: string;
          lessonId: string;
        }
      >({
        query: ({ entityId, lessonId }) => ({
          url: `/api/auth/entity/${entityId}/edu/lesson/${lessonId}`,
        }),
      }),
    }),
  });

  const {
    useCreateLessonMutation,
    useDeleteLessonMutation,
    useLessonsListQuery,
    useLazyLessonsListQuery,
    useLessonsSearchQuery,
    useLazyLessonsSearchQuery,
    useGetLessonQuery,
    useLazyGetLessonQuery,
  } = eduLessonsApi;
  return {
    useCreateLessonMutation,
    useDeleteLessonMutation,
    useLessonsListQuery,
    useLazyLessonsListQuery,
    useLessonsSearchQuery,
    useLazyLessonsSearchQuery,
    useGetLessonQuery,
    useLazyGetLessonQuery,
    api: eduLessonsApi,
  };
};

export type EduLessonsApi = ReturnType<typeof addEduLessonsApi>;
