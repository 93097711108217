import { AppVariant, cx } from "@jugl-web/utils";
import { FC, ReactNode, useState } from "react";
import { Collapse, CollapseProps } from "../Collapse";
import { InteractiveContainer } from "../InteractiveContainer";
import { ReactComponent as ChevronIcon } from "./icons/chevron.svg";

export interface AccordionProps
  extends Pick<CollapseProps, "children" | "unmountOnExit"> {
  variant: AppVariant;
  title: ReactNode | ((isOpen: boolean) => ReactNode);
  initialIsOpen?: boolean;
  className?: string;
}

export const Accordion: FC<AccordionProps> = ({
  variant,
  title,
  children,
  initialIsOpen = false,
  unmountOnExit,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  return (
    <div>
      <InteractiveContainer
        onClick={() => setIsOpen(!isOpen)}
        className={cx(
          "flex w-full items-center justify-between bg-white",
          {
            "px-8 py-4": variant === "web",
            "px-3 py-3": variant === "mobile",
          },
          className
        )}
      >
        <div
          className={cx("flex-1 text-left", {
            "text-base": variant === "web",
            "text-xs": variant === "mobile",
          })}
        >
          {typeof title === "function" ? title(isOpen) : title}
        </div>
        <ChevronIcon
          className={cx({ "rotate-180": isOpen }, "transition-transform")}
        />
      </InteractiveContainer>
      <Collapse isOpen={isOpen} unmountOnExit={unmountOnExit}>
        {children}
      </Collapse>
    </div>
  );
};
