import { TaskAssignee } from "@jugl-web/rest-api";
import { AvatarMember } from "./CollectiveAvatar";

export const adaptTaskAssigneeToAvatarMember = (
  assignee: TaskAssignee
): AvatarMember => ({
  firstName: assignee.first_name,
  lastName: assignee.last_name,
  imageUrl: assignee.img,
});
