import React, { useContext, useEffect, useState } from "react";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { CallsContext } from "../../providers/Calls";
import UserCallWindow from "../UserWindow";

const LocalUserCallWindow: React.FC<{
  elementRatio?: number;
}> = ({ elementRatio }) => {
  const { me } = useMe();
  const { activeCall } = useContext(CallsContext);
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newVolumeLevel = activeCall?.localAudioTrack?.getVolumeLevel() || 0;
      const newTalkingState = newVolumeLevel > 0.6;
      if (isSpeaking !== newTalkingState) setIsSpeaking(newTalkingState);
    }, 500);

    return () => clearInterval(intervalId);
  }, [activeCall, isSpeaking]);
  return (
    <UserCallWindow
      isSpeaking={isSpeaking}
      elementRatio={elementRatio}
      userId={me?.id || ""}
      isVideoMuted={!activeCall?.videoEnabled}
      isAudioMuted={!activeCall?.audioEnabled}
      playerId="localplayer"
    />
  );
};

export default LocalUserCallWindow;
