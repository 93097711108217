import { singletonHook } from "react-singleton-hook";
import { useCallback, useContext, useEffect, useState } from "react";
import { selectUserId } from "@web-src/features/auth/authSlice";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { usePrevious } from "@jugl-web/utils";
import { useSelector } from "react-redux";
import { PhoenixSocketContext } from "../providers/PheonixSocket";
import { getMessageChatId } from "../utils";

interface LiveConversationReceiptsState {
  unreadCounterDelta?: number;
  lastReadMessage?: {
    msg_id: string;
    timestamp: string;
  };
}

interface UseLiveConversationReceiptsType {
  liveReceiptsState: { [key: string]: LiveConversationReceiptsState };
  reset?: () => void;
}

const useLiveConversationReceipts = (): UseLiveConversationReceiptsType => {
  const { receiptSent$, incomingMessages$ } = useContext(PhoenixSocketContext);
  const [liveReceiptsState, setLiveReceiptsState] = useState<
    UseLiveConversationReceiptsType["liveReceiptsState"]
  >({});
  const meId = useSelector(selectUserId);
  const { entity } = useEntity();
  const previousEntity = usePrevious(entity);
  useEffect(() => {
    if (previousEntity && previousEntity?.id !== entity?.id) {
      setLiveReceiptsState({});
    }
  }, [entity?.id, previousEntity]);

  useEffect(() => {
    const receiptSubscription = receiptSent$?.subscribe((response) => {
      setLiveReceiptsState((prev) => {
        const curentLastReadMessage = prev[response.chat_id]?.lastReadMessage;
        return {
          ...prev,
          [response.chat_id]: {
            ...(prev[response.chat_id] || {}),
            unreadCounterDelta:
              (prev[response.chat_id]?.unreadCounterDelta || 0) -
              response.count,
            lastReadMessage:
              curentLastReadMessage &&
              curentLastReadMessage.timestamp >= response.msg_timestamp
                ? curentLastReadMessage
                : {
                    msg_id: response.msg_id,
                    timestamp: response.msg_timestamp,
                  },
          },
        };
      });
    });
    const incomingMessagesSubscription = incomingMessages$?.subscribe(
      (message) => {
        if (message.from === meId && message.payload?.type !== "group_info") {
          return;
        }
        const chatId = getMessageChatId(message, meId);
        setLiveReceiptsState((prev) => ({
          ...prev,
          [chatId]: {
            ...(prev[chatId] || {}),
            unreadCounterDelta: (prev[chatId]?.unreadCounterDelta || 0) + 1,
          },
        }));
      }
    );
    return () => {
      receiptSubscription?.unsubscribe();
      incomingMessagesSubscription?.unsubscribe();
    };
  }, [receiptSent$, incomingMessages$, meId]);

  const reset = useCallback(() => {
    setLiveReceiptsState({});
  }, []);

  return { liveReceiptsState, reset };
};

export default singletonHook(
  {
    liveReceiptsState: {},
  },
  useLiveConversationReceipts
);
