import { PaginatedResponse, RtkEmptySplitApi } from "../../types";
import { FormField } from "./types";
import { CustomerFormFieldApiTags } from "./tags";

export const addCustomerFormFieldApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [
      CustomerFormFieldApiTags.createDefaultFields,
      CustomerFormFieldApiTags.formFields,
    ],
  });
  const customerFormFieldApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      formFields: build.query<
        PaginatedResponse<FormField>,
        {
          entityId: string;
        }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/form_fields`,
        }),
        providesTags: [CustomerFormFieldApiTags.formFields],
      }),
      addFormField: build.mutation<
        void,
        {
          entityId: string;
          params: Omit<FormField, "entity_id" | "id" | "is_cust_field">;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/form_fields`,
          data: params,
          method: "POST",
        }),
        invalidatesTags: [CustomerFormFieldApiTags.formFields],
      }),
      deleteFormField: build.mutation<
        void,
        {
          entityId: string;
          fieldId: string;
        }
      >({
        query: ({ entityId, fieldId }) => ({
          url: `/api/auth/entity/${entityId}/form_fields/${fieldId}`,
          method: "DELETE",
        }),
        invalidatesTags: [CustomerFormFieldApiTags.formFields],
      }),
    }),
  });

  return customerFormFieldApi;
};

export type CustomerFormFieldApi = ReturnType<typeof addCustomerFormFieldApi>;
