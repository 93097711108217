export type FencePoint = {
  acc: string | null;
  lat: string;
  lon: string;
};
export type Fence = {
  fence_id: string;
  fence_name: string;
  fence_points: FencePoint[];
};

export type LocationPoint = {
  loc_pts: {
    acc: number;
    lat: number;
    lon: number;
    speed: number;
    provider: string;
  };
  time: string;
};
export type Location = {
  device_id: string;
  event_id: string;
  loc_data: LocationPoint[];
};
export type FenceAlert = {
  device_id: string;
  fence_id: string;
  inside_boundary: boolean;
};
export type FenceAlerts = { fence_alerts: FenceAlert[] };
export interface EventsLocations extends LocationPoint {
  device_id: string;
}
export type Event = {
  attachments: string[];
  cached_addresses: string;
  cdesc: string;
  cname: string;
  crcy_type: string;
  crcy_value: string;
  device_id: string;
  end_time: string | null;
  event_id: string;
  event_type: EventTypes;
  is_completed: boolean;
  loc: EventsLocations[];
  notes: string;
  stage: EventStage;
  start_date: string;
  start_time: string;
  time_zone: string;
  type: LocationEvents;
};

export enum LocationEvents {
  clock_in = "clock_in",
  location_enabled = "location_enabled",
  online = "online",
  driving = "driving",
  idle = "idle",
  offline = "offline",
  location_disabled = "location_disabled",
  clock_out = "clock_out",
  paused = "paused",
  resumed = "resumed",
}
export enum EventTypes {
  sales = "Sales",
  service = "Service",
  others = "Others",
}
export enum EventStage {
  open = "Open",
  qualified = "Qualified",
  presentation_completed = "Presentation Completed",
  proposal_sent = "Proposal Sent",
  agreement_sent = "Agreement Sent",
  signed = "Signed",
  lost = "Lost",
}
export type LocationSummary = {
  date: string;
  duration: {
    hrs: number;
    mins: number;
  };
  end_time: string;
  start_time: string;
};
export interface EventSummary {
  date: string;
  dur_in_secs: string;
  duration: {
    hrs: number;
    mins: number;
  };
  end_time: string;
  last_loc: unknown;
  start_time: string;
  task_count: number;
  user_id: string;
}

type DayWiseData = {
  day_wise_data: EventSummary[];
  week_num: string;
  weekly_total_duration: {
    hrs: number;
    mins: number;
  };
  year: string;
};

export type LocationEventsSummary = {
  loc_event_summary: DayWiseData[];
  user_id: string;
};

export type GeocodeResult = {
  types: string[];
  formatted_address: string;
  address_components: {
    short_name: string;
    long_name: string;
    postcode_localities: string[];
    types: string[];
  }[];
  partial_match: boolean;
  place_id: string;
  postcode_localities: string[];
  geometry: {
    location: unknown;
    location_type: unknown;
    viewport: unknown;
    bounds: unknown;
  };
  cordinates: {
    lat: number;
    lng: number;
  };
};
