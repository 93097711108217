import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import SvgIcon from "@mui/material/SvgIcon";
import { fileToUrl } from "@web-src/utils/helper";
import cx from "classnames";
import { ReactComponent as CameraIcon } from "./icons/camera.svg";
import { ReactComponent as GroupLineIcon } from "./icons/group-line.svg";
import { ReactComponent as AvatarPlusIcon } from "./icons/avatar-plus.svg";
import { ReactComponent as RemoveImgIcon } from "./icons/remove-img.svg";

const AvatarSelect: React.FC<{
  onChange: (value?: File) => void;
  size?: "default" | "small" | "large";
  value?: File;
  defaultSrc?: string;
  showCameraIcon?: boolean;
  showRemoveIcon?: boolean;
  type?: "group" | "user";
  placeholder?: React.FC;
  placeholderCustomViewbox?: string;
  onRemove?: () => void;
}> = ({
  onChange,
  value,
  defaultSrc,
  size = "default",
  showCameraIcon = true,
  showRemoveIcon = false,
  type = "group",
  placeholder,
  placeholderCustomViewbox,
  onRemove,
}) => {
  const width = size === "small" ? 80 : size === "large" ? 156 : 100;
  const iconWidth = size === "small" ? 24 : size === "large" ? 48 : 36;
  const theme = useTheme();
  const $input = useRef<HTMLInputElement>(null);
  const icon = type === "group" ? GroupLineIcon : AvatarPlusIcon;
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const file = e.target.files?.[0];
    if (!file) {
      onChange(undefined);
      return;
    }
    onChange(file);
  };
  const [valueSrc, setValueSrc] = useState<string | undefined>(defaultSrc);
  useEffect(() => {
    if (!defaultSrc) return;
    setValueSrc(defaultSrc);
  }, [defaultSrc]);
  useEffect(() => {
    if (value) {
      fileToUrl(value).then(setValueSrc);
    }
  }, [value]);
  const handleClick = () => {
    $input.current?.click();
  };
  const handleRemoveImg = () => {
    setValueSrc(undefined);
    onChange(undefined);
    if ($input.current) {
      $input.current.value = "";
    }
    if (onRemove) {
      onRemove();
    }
  };
  return (
    <Box
      sx={{ position: "relative", width: `${width}px`, height: `${width}px` }}
    >
      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: `${width / 2}px`,
          width: `${width}px`,
          height: `${width}px`,
          bgcolor: placeholder ? "white" : "grey.100",
          border: `1px solid ${theme.palette.grey[200]}`,
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ display: "none" }}>
          <input
            ref={$input}
            onChange={handleInputChange}
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            name="image"
          />
        </Box>
        {!valueSrc && !placeholder ? (
          <SvgIcon
            component={icon}
            sx={{ width: `${iconWidth}px`, height: `${iconWidth}px` }}
            viewBox={icon === AvatarPlusIcon ? "0 0 24 24" : "0 0 34 34"}
          />
        ) : !valueSrc && placeholder ? (
          <SvgIcon
            component={placeholder}
            sx={{ height: "40px", width: "40px", color: "transparent" }}
            viewBox={placeholderCustomViewbox || "0 0 20 20"}
          />
        ) : (
          <Box
            component="img"
            src={valueSrc}
            sx={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
          />
        )}
      </Box>
      {showCameraIcon && !valueSrc && (
        <Box
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            width: size === "large" ? 48 : 24,
            height: size === "large" ? 48 : 24,
            bgcolor: "secondary.500",
            borderRadius: "50%",
            position: "absolute",
            bottom: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SvgIcon
            component={CameraIcon}
            viewBox="0 0 16 16"
            sx={{ width: "16px" }}
          />
        </Box>
      )}
      {showRemoveIcon && valueSrc && (
        <div
          onClick={handleRemoveImg}
          className={cx(
            "absolute bottom-0 right-0 flex cursor-pointer items-center justify-center rounded-full",
            {
              "h-12 w-12": size === "large",
              "h-6 w-6": size !== "large",
            }
          )}
        >
          <RemoveImgIcon />
        </div>
      )}
    </Box>
  );
};

export default AvatarSelect;
