import { emptySplitApi } from "@web-src/features/api/createApi";
import { ProfileGender } from "@web-src/features/auth/types";
import { UpdateProfileData } from "./types";

const apiWithTags = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["Profile"],
});

export const settingsApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    updateProfile: build.mutation<
      UpdateProfileData,
      {
        user_id: string;
        data: {
          type: "general";
          first_name?: string;
          last_name?: string;
          gender?: ProfileGender;
          birth?: string;
        };
      }
    >({
      query: ({ data, user_id }) => ({
        url: `/api/auth/entity/profile/${user_id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["Profile"],
    }),
    addInformation: build.mutation({
      query: ({ data }) => ({
        url: `/api/auth/profile-v2`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["Profile"],
    }),
    updateInformation: build.mutation({
      query: ({ data, item_id }) => ({
        url: `/api/auth/profile-v2/${item_id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["Profile"],
    }),
    deleteInformation: build.mutation({
      query: ({ type, item_id }) => ({
        url: `/api/auth/profile-v2/${item_id}/?type=${type}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Profile"],
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useAddInformationMutation,
  useUpdateInformationMutation,
  useDeleteInformationMutation,
} = settingsApi;
