export enum EntitySpaceType {
  class = "class",
  dept = "dept",
  group = "group",
}

export type EntitySpace = {
  entity_id: string;
  id: string;
  info: { batch: string; title: string };
  type: EntitySpaceType;
};

export enum EntityParticipantGender {
  Male = "Male",
  Female = "Female",
}

export type EntityUserProfile = {
  dob: string;
  first_name: string;
  gender: EntityParticipantGender;
  img: null | string;
  last_name: string;
};

export interface EntityProp {
  id: string;
  value: string;
}

export enum EntityPropType {
  subject = "subject",
}

export enum EntityParticipantRole {
  admin = "admin",
  member = "member",
  teacher = "teacher",
  student = "student",
}
