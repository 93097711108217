import { RtkEmptySplitApi } from "../../types";
import { TaskLabel } from "../tasks";
import { LabelsApiTag } from "./tags";

export const addLabelsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [LabelsApiTag.label],
  });

  const labelsApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getLabels: builder.query<{ data: TaskLabel[] }, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/labels`,
          params: {
            page_size: 100,
          },
        }),
        providesTags: [{ type: LabelsApiTag.label, id: "LIST" }],
      }),

      createLabel: builder.mutation<
        TaskLabel,
        {
          entityId: string;
          text: string;
          color: string;
        }
      >({
        query: ({ entityId, ...label }) => ({
          url: `/api/auth/entity/${entityId}/labels`,
          method: "POST",
          data: label,
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          queryFulfilled
            .then((response) => {
              if (response.data) {
                dispatch(
                  labelsApi.util.updateQueryData(
                    "getLabels",
                    { entityId: args.entityId },
                    (draft) => {
                      draft.data.push(response.data);
                    }
                  )
                );
              }
            })
            .catch(() => {});
        },
      }),

      updateLabel: builder.mutation<
        TaskLabel,
        {
          entityId: string;
          labelId: string;
          text: string;
          color: string;
        }
      >({
        query: ({ entityId, labelId, ...label }) => ({
          url: `/api/auth/entity/${entityId}/labels/${labelId}`,
          method: "PUT",
          data: label,
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          queryFulfilled
            .then((response) => {
              if (response.data) {
                dispatch(
                  labelsApi.util.updateQueryData(
                    "getLabels",
                    { entityId: args.entityId },
                    (draft) => {
                      const labelToUpdateIndex = draft.data.findIndex(
                        (label) => label.id === args.labelId
                      );

                      draft.data[labelToUpdateIndex] = response.data;
                    }
                  )
                );
              }
            })
            .catch(() => {});
        },
      }),

      deleteLabel: builder.mutation<
        string,
        {
          entityId: string;
          labelId: string;
        }
      >({
        query: ({ entityId, labelId }) => ({
          url: `/api/auth/entity/${entityId}/labels/${labelId}`,
          method: "DELETE",
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          queryFulfilled
            .then((response) => {
              if (typeof response.data === "string") {
                dispatch(
                  labelsApi.util.updateQueryData(
                    "getLabels",
                    { entityId: args.entityId },
                    (draft) => {
                      const labelToDeleteIndex = draft.data.findIndex(
                        (label) => label.id === args.labelId
                      );

                      draft.data.splice(labelToDeleteIndex, 1);
                    }
                  )
                );
              }
            })
            .catch(() => {});
        },
      }),
    }),

    overrideExisting: true,
  });

  return labelsApi;
};

export type LabelsApi = ReturnType<typeof addLabelsApi>;
