/* eslint-disable react/destructuring-assignment */
import { cx, omit } from "@jugl-web/utils";
import { Fragment, HTMLAttributes, ReactNode, useEffect, useRef } from "react";
import { PlainButton } from "../../cross-platform/PlainButton";
import { ReactComponent as ArrowLeftIcon } from "./icons/arrow-left.svg";
import { TopBarSearchInput, TopBarSearchInputProps } from "./TopBarSearchInput";

interface TopBarContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

export const TOP_BAR_HEIGHT_PX = 48;

export const TopBarContainer = ({
  children,
  className,
  style,
  ...divProps
}: TopBarContainerProps) => (
  <div
    className={cx(
      className,
      "flex w-full items-center justify-between bg-white px-4 py-2"
    )}
    style={{ height: `${TOP_BAR_HEIGHT_PX}px`, ...style }}
    {...divProps}
  >
    {children}
  </div>
);

interface HeadingTopBarProps {
  type: "heading";
  title: string;
  endSlots?: JSX.Element[];
  onGoBack?: () => void;
}

interface SearchTopBarProps extends TopBarSearchInputProps {
  type: "search";
}

export type TopBarProps = HTMLAttributes<HTMLDivElement> &
  (HeadingTopBarProps | SearchTopBarProps);

export const TopBar = (props: TopBarProps) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.type === "search") {
      searchInputRef.current?.focus();
    }
  }, [props.type]);

  if (props.type === "heading") {
    const { title, endSlots = [], onGoBack, ...restProps } = props;
    const hasGoBackButton = !!onGoBack;

    return (
      <TopBarContainer {...omit(restProps, "type")}>
        <div className="flex items-center gap-4">
          {hasGoBackButton && (
            <PlainButton onClick={onGoBack}>
              <ArrowLeftIcon />
            </PlainButton>
          )}
          <h1 className="text-dark text-xl font-medium leading-4">{title}</h1>
        </div>
        {endSlots.length > 0 && (
          <div className="flex items-center gap-8">
            {endSlots.map((slot, index) => (
              <Fragment key={+index}>{slot}</Fragment>
            ))}
          </div>
        )}
      </TopBarContainer>
    );
  }

  const { onSearch, onClose, ...restProps } = props;

  return (
    <TopBarContainer {...omit(restProps, "type")}>
      <TopBarSearchInput
        ref={searchInputRef}
        onSearch={onSearch}
        onClose={onClose}
      />
    </TopBarContainer>
  );
};
