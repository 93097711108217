import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import Avatar from "@web-src/components/Avatar";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { styled } from "@mui/material/styles";
import logo from "@web-src/images/logo.svg";
import logoWhite from "@web-src/images/logo-white.svg";
import { EntityParticipantRole } from "@web-src/features/entities/types";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { withTransientProps } from "@web-src/utils/mui";
import { useLogout } from "@web-src/features/app/hooks/useLogout";
import { useTranslations } from "@jugl-web/utils";

import { ReactComponent as SettingsIcon } from "@web-src/images/icons/settings2.svg";
import { ReactComponent as People } from "./icons/people.svg";
import { ReactComponent as ChatIcon } from "./icons/chats.svg";
import { ReactComponent as EduChatIcon } from "./icons/edu-chats.svg";
import { ReactComponent as EduChatActiveIcon } from "./icons/edu-chats-active.svg";
import { ReactComponent as AdminIcon } from "./icons/edu-admin.svg";
import { ReactComponent as AdminActiveIcon } from "./icons/edu-admin-active.svg";
import { ReactComponent as LessonsIcon } from "./icons/edu-lessons.svg";
import { ReactComponent as LessonsActiveIcon } from "./icons/edu-lessons-active.svg";
import { ReactComponent as AssignmentsIcon } from "./icons/edu-assignments.svg";
import { ReactComponent as AssignmentsActiveIcon } from "./icons/edu-assignments-active.svg";
import { ReactComponent as FeedsIcon } from "./icons/edu-feeds.svg";
import { ReactComponent as FeedsActiveIcon } from "./icons/edu-feeds-active.svg";
import { ReactComponent as AttendanceIcon } from "./icons/edu-attendance.svg";
import { ReactComponent as AttendanceActiveIcon } from "./icons/edu-attendance-active.svg";
import { ReactComponent as LogOutIcon } from "./icons/logout.svg";
import { ReactComponent as LogOutActiveIcon } from "./icons/logout-active.svg";
import { ReactComponent as EduLogOutIcon } from "./icons/edu-log-out.svg";
import { ReactComponent as EduLogOutActiveIcon } from "./icons/edu-log-out-active.svg";
import { ReactComponent as MySchoolsIcon } from "./icons/edu-my-schools.svg";
import { ReactComponent as MySchoolsActiveIcon } from "./icons/edu-my-schools-active.svg";
import { ReactComponent as TasksIcon } from "./icons/tasks.svg";
import { ReactComponent as CustomersIcon } from "./icons/customers.svg";
import { ReactComponent as DriveIcon } from "./icons/drive.svg";
import { ReactComponent as ScheduleIcon } from "./icons/schedule.svg";
import { ReactComponent as MoreIcon } from "./icons/more.svg";

import { getAppType } from "../../utils";
import { AppType, TabRoute } from "../../types";
import { SideNavigation } from "../SideNavigation";

type HomeSideBarTabItem = {
  id: string;
  title: string;
  tab?: TabRoute;
  icon: React.FC[];
  requiredRole?: EntityParticipantRole[];
  action?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  path?: string;
};

enum TabBarColor {
  blue = "blue",
  white = "white",
}

const HomeSideBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { t } = useTranslations();
  const [isSideNavigationOpen, setIsSideNavigationOpen] = useState(false);
  const { entity, list: entities } = useEntity();
  const activeTab: TabRoute = location.pathname.split("/")[2] as TabRoute;
  const appType = getAppType();
  const tabBarColor: TabBarColor =
    appType === AppType.edu ? TabBarColor.white : TabBarColor.blue;
  const logoImg = tabBarColor === TabBarColor.white ? logo : logoWhite;
  const menuItems: HomeSideBarTabItem[] = useMemo(() => {
    if (!entity) {
      return [];
    }
    let result: HomeSideBarTabItem[] = [];
    const chatItem: HomeSideBarTabItem = {
      id: "chats",
      title: t({
        id: "home-sidebar-component.chats",
        defaultMessage: "Chats",
      }),
      tab: TabRoute.chats,
      icon:
        appType === "org"
          ? [ChatIcon, ChatIcon]
          : [EduChatIcon, EduChatActiveIcon],
    };
    const peopleItem: HomeSideBarTabItem = {
      id: "people",
      title: t({
        id: "home-sidebar-component.people",
        defaultMessage: "People",
      }),
      tab: TabRoute.people,
      icon: [People, People],
    };

    switch (appType) {
      case AppType.edu:
        result = [
          {
            id: "edu-admin",
            title: t({
              id: "home-sidebar-component.admin",
              defaultMessage: "Admin",
            }),
            tab: TabRoute.eduAdmin,
            icon: [AdminIcon, AdminActiveIcon],
            requiredRole: [EntityParticipantRole.admin],
          },
          chatItem,
          {
            id: "edu-lessons",
            title: t({
              id: "home-sidebar-component.lessons",
              defaultMessage: "Lessons",
            }),
            tab: TabRoute.eduLessons,
            icon: [LessonsIcon, LessonsActiveIcon],
          },
          {
            id: "edu-assignments",
            title: t({
              id: "home-sidebar-component.assignments",
              defaultMessage: "Assignments",
            }),
            tab: TabRoute.eduAssignments,
            icon: [AssignmentsIcon, AssignmentsActiveIcon],
          },
          {
            id: "edu-feeds",
            title: t({
              id: "home-sidebar-component.feeds",
              defaultMessage: "Feeds",
            }),
            tab: TabRoute.eduFeeds,
            icon: [FeedsIcon, FeedsActiveIcon],
          },
          {
            id: "edu-attendance",
            title: t({
              id: "home-sidebar-component.attendance",
              defaultMessage: "Attendance",
            }),
            tab: TabRoute.eduAttendance,
            icon: [AttendanceIcon, AttendanceActiveIcon],
          },
        ];
        break;
      case AppType.org:
        result = [
          chatItem,
          {
            id: "Drive",
            title: t({
              id: "home-sidebar-component.drive",
              defaultMessage: "Drive",
            }),
            tab: TabRoute.drive,
            icon: [DriveIcon, DriveIcon],
          },
          {
            id: "Tasks",
            title: t({
              id: "home-sidebar-component.tasks",
              defaultMessage: "Tasks",
            }),
            tab: TabRoute.tasks,
            path: "all",
            icon: [TasksIcon, TasksIcon],
          },
          peopleItem,
          {
            id: "schedule",
            title: t({
              id: "home-sidebar-component.schedule",
              defaultMessage: "Schedule",
            }),
            tab: TabRoute.schedule,
            icon: [ScheduleIcon, ScheduleIcon],
            requiredRole: [EntityParticipantRole.admin],
          },
          {
            id: "customers",
            title: t({
              id: "home-sidebar-component.clients",
              defaultMessage: "Clients",
            }),
            tab: TabRoute.clients,
            icon: [CustomersIcon, CustomersIcon],
            requiredRole: [EntityParticipantRole.admin],
          },
          {
            id: "settings",
            title: t({
              id: "home-sidebar-component.settings",
              defaultMessage: "Settings",
            }),
            tab: TabRoute.settings,
            icon: [SettingsIcon, SettingsIcon],
          },
          {
            id: "more",
            title: t({
              id: "home-sidebar-component.more",
              defaultMessage: "More",
            }),
            action: (e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsSideNavigationOpen(true);
            },
            icon: [MoreIcon, MoreIcon],
          },
        ];
        break;
      default:
        result = [];
        break;
    }
    return result.filter((item) =>
      !item.requiredRole?.length
        ? true
        : item.requiredRole.includes(entity.role)
    );
  }, [entity, appType, t]);

  const bottomMenuItems: HomeSideBarTabItem[] = useMemo(() => {
    const logOutItem: HomeSideBarTabItem = {
      id: "log-out",
      title: t({
        id: "common.log-out",
        defaultMessage: "Log out",
      }),
      icon:
        appType === "org"
          ? [LogOutIcon, LogOutActiveIcon]
          : [EduLogOutIcon, EduLogOutActiveIcon],
      action: (e) => {
        e.preventDefault();
        e.stopPropagation();
        logout();
      },
    };
    let result: HomeSideBarTabItem[] = [];
    switch (appType) {
      case AppType.edu:
        result = [
          ...(entities && entities?.length > 1
            ? [
                {
                  id: "my-schools",
                  title: t({
                    id: "home-sidebar-component.my-schools",
                    defaultMessage: "My schools",
                  }),
                  path: "/school-select",
                  icon: [MySchoolsIcon, MySchoolsActiveIcon],
                },
              ]
            : []),
          logOutItem,
        ];
        break;
      case AppType.org:
        return [logOutItem];
      default:
    }
    return result;
  }, [appType, logout, entities, t]);

  const handleTabClick = (
    item: HomeSideBarTabItem,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (item.tab) {
      navigate(`/${entity?.id}/${item.tab}`);
    } else if (item.path) {
      navigate(item.path);
    } else if (item.action) {
      item.action(e);
    }
  };

  const handleAvatarClick = () => {
    switch (appType) {
      case AppType.edu:
        if (entity?.role === "admin") {
          navigate(`/${entity?.id}/${TabRoute.eduSchoolEdit}`);
        }
        break;
      case AppType.org:
        navigate(`/org-select`);
        break;
      default:
    }
  };

  return (
    <>
      <TabBar $color={tabBarColor}>
        <LogoWrapper $color={tabBarColor}>
          <Box component="img" src={logoImg.toString()} alt="Jugl logo" />
        </LogoWrapper>
        <Stack
          sx={{ flex: 1, minHeight: "min-content", overflowY: "auto" }}
          justifyContent="space-between"
        >
          <Box>
            {menuItems?.map((item) => (
              <TabButton
                onClick={item.action}
                key={item.id}
                to={
                  item.path && item.tab
                    ? `/${entity?.id}/${item.tab}/${item.path}`
                    : `/${entity?.id}/${item.tab}`
                }
                data-active={activeTab === item.tab}
                $color={tabBarColor}
              >
                <SvgIcon
                  component={item.icon[activeTab === item.tab ? 1 : 0]}
                />
                <TabButtonText>{item.title}</TabButtonText>
              </TabButton>
            ))}
          </Box>
          <Box>
            {bottomMenuItems?.map((item) => (
              <TabButton
                onClick={handleTabClick.bind(null, item)}
                key={item.id}
                to={`/${entity?.id}/${item.tab}`}
                data-active={activeTab === item.tab}
                $color={tabBarColor}
              >
                <SvgIcon component={item.icon[0]} />
                <TabButtonText>{item.title}</TabButtonText>
              </TabButton>
            ))}
          </Box>
        </Stack>
        <Box
          onClick={handleAvatarClick}
          sx={{
            padding: "25px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            "& .home-side-bar__avatar-chevron": {
              display: "none",
            },
            "&:hover .home-side-bar__avatar-chevron": {
              display: "inherit",
            },
          }}
        >
          <AvatarWrapper>
            <Avatar
              sx={{
                width: "100%",
                height: "100%",
                transform: "scale(1.2)",
              }}
              src={entity?.display_pic}
              alt={entity?.name}
              imgProps={undefined}
              imageIndex={undefined}
              fallbackTextProps={undefined}
            />
          </AvatarWrapper>
          {entity?.type === "edu" && entity?.role !== "admin" ? null : (
            <ChevronRightRoundedIcon
              className="home-side-bar__avatar-chevron"
              sx={{
                color: tabBarColor === TabBarColor.blue ? "white" : "grey.500",
              }}
            />
          )}
        </Box>
      </TabBar>
      <SideNavigation
        isOpen={isSideNavigationOpen}
        onClose={() => setIsSideNavigationOpen(false)}
      />
    </>
  );
};

const LogoWrapper = styled(
  Box,
  withTransientProps
)<{ $color?: TabBarColor }>(({ theme, $color }) => ({
  height: "57px",
  // TODO: hardcoded color, re-check with design
  borderBottom: `1px solid ${
    $color === TabBarColor.blue ? theme.palette.blue[400] : "#F2F2F4"
  }`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const AvatarWrapper = styled(
  Box,
  withTransientProps
)<{ $color?: TabBarColor }>(({ theme }) => ({
  width: "48px",
  height: "48px",
  border: "2px solid #fff",
  borderColor: theme.palette.grey["300"],
  borderRadius: "24px",
  overflow: "hidden",
  boxShadow: "inset 0px 0px 8.12px rgba(0, 0, 0, 0.07)",
}));

const TabBar = styled(
  Box,
  withTransientProps
)<{ $color?: TabBarColor }>(({ theme, $color }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor:
    $color === TabBarColor.blue ? theme.palette.blue["500"] : "white",
  // TODO: hardcoded color, re-check with design
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
  width: "88px",
  height: "100vh",
  overflowY: "auto",
}));

const TabButton = styled(
  Link,
  withTransientProps
)<{ $color?: TabBarColor }>(({ theme, $color }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  color: $color === TabBarColor.blue ? "white" : theme.palette.grey.A100,
  padding: "18px 0",
  textDecoration: "none",
  cursor: "pointer",
  span: {
    display: "none",
    marginTop: "6px",
  },
  "&:hover": {
    // TODO: hardcoded color, re-check with design
    backgroundColor:
      $color === TabBarColor.blue
        ? theme.palette.blue["600"]
        : "rgba(233, 238, 241, 0.2)",
  },
  [`&[data-active="true"]`]: {
    backgroundColor:
      $color === TabBarColor.blue
        ? theme.palette.blue["700"]
        : theme.palette.primary.main,
    color: "white",
    span: {
      display: "block",
    },
    "&:hover": {
      backgroundColor:
        $color === TabBarColor.blue
          ? theme.palette.blue["700"]
          : theme.palette.primary.main,
    },
  },
}));

const TabButtonText = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
  mt: "5px",
}));

export default HomeSideBar;
