import { cx, useTranslations } from "@jugl-web/utils";
import format from "date-fns/format";
import getDate from "date-fns/getDate";
import isToday from "date-fns/isToday";
import { useMemo } from "react";

export interface DatePillProps {
  date: Date;
  className?: string;
}

export const DatePill = ({ date, className }: DatePillProps) => {
  const { t } = useTranslations();
  const label = useMemo(() => {
    if (isToday(date)) {
      return (
        <span className="text-[16px]">
          {t({
            id: "common.today",
            defaultMessage: "Today",
          })}
        </span>
      );
    }

    const month = format(date, "LLL");
    const day = getDate(date);

    return (
      <div className="flex flex-col items-center justify-center">
        <span className="relative top-[1px] text-xs leading-[14px]">
          {month}
        </span>
        <span className="relative bottom-[3px] text-[24px] leading-3">
          {day}
        </span>
      </div>
    );
  }, [date, t]);

  return (
    <div
      className={cx(
        "bg-grey-700 inline-flex h-[34px] items-center rounded-tl-2xl rounded-tr-2xl rounded-br-2xl px-4 font-bold uppercase text-white",
        className
      )}
      style={{ fontVariantCaps: "all-small-caps" }}
    >
      {label}
    </div>
  );
};
