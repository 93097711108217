import React, { PropsWithChildren } from "react";
import MuiIconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/material";

const IconButton: React.FC<
  PropsWithChildren<{
    children: React.ReactNode;
    color?: string;
    sx: SxProps;
  }>
> = ({ children, color, sx, ...rest }) => (
  <MuiIconButton
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    sx={{
      backgroundColor: `${color}.main`,
      color: "white",
      "&:hover": {
        backgroundColor: `${color}.main`,
        color: "white",
      },
      ...(sx || {}),
    }}
  >
    {children}
  </MuiIconButton>
);

export default IconButton;
