import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  MessagePayload,
  Messaging,
  onMessage,
} from "firebase/messaging";
import { Subject } from "rxjs";
import { logger } from "@web-src/utils/logger";
import { environment } from "@web-src/environments/environment";
import { PushNotificationsContext } from "./PushNotifications";

// NOTE: temporairly disabled
const pushNotificationsEnabled = true;

const FirebaseContext = createContext<{
  app?: FirebaseApp;
  messaging?: Messaging;
  notifications$?: Subject<MessagePayload>;
  broadcastChannel?: BroadcastChannel;
  token?: string;
}>({});

const FirebaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [app, setApp] = useState<FirebaseApp | undefined>();
  const [messaging, setMessaging] = useState<Messaging | undefined>();
  const notifications$ = useMemo(() => new Subject<MessagePayload>(), []);
  const { triggerPushNotification } = useContext(PushNotificationsContext);
  const [token, setToken] = useState<string>();

  const broadcastChannel = useMemo(
    () => new BroadcastChannel("sw-notifications"),
    []
  );
  const swInitChannel = useMemo(() => new BroadcastChannel("sw-init"), []);

  useEffect(() => {
    if (app || messaging) {
      return;
    }

    const fbApp = initializeApp(environment.firebaseConfig);
    setApp(fbApp);
    const fbMessaging = getMessaging(fbApp);
    setMessaging(fbMessaging);
    getToken(fbMessaging, {
      vapidKey: environment.firebaseVapidKey,
    }).then((e) => {
      logger.info("[Firebase token]", e);
      setToken(e);
    });
  }, [messaging, token, app]);

  const [initialized, setInitialized] = useState<boolean>(false);
  useEffect(() => {
    if (!token || !messaging || initialized) {
      return;
    }
    const notificationHandler = (e: any) => {
      const title = e?.data?.title || e?.notification?.title;
      const options = {
        body: e?.data?.body || e?.notification?.body,
      };
      console.log(title, options, pushNotificationsEnabled);
      if (title && options && pushNotificationsEnabled) {
        triggerPushNotification?.(title, options);
      }
      notifications$.next(e);
    };
    onMessage(messaging, (e) => {
      logger.info("[foregroundMessage]", e);
      notificationHandler(e);
    });
    const broadcastChannelHandler = (e: any) => {
      logger.info("[backgroundMessage]", e?.data?.notification);
      notificationHandler(e?.data?.notification);
    };
    broadcastChannel?.addEventListener("message", broadcastChannelHandler);
    setInitialized(true);
  }, [
    broadcastChannel,
    messaging,
    notifications$,
    token,
    triggerPushNotification,
    app,
    initialized,
  ]);

  useEffect(() => {
    swInitChannel.postMessage(environment.firebaseConfig);
  }, [swInitChannel]);

  const value = useMemo(
    () => ({ app, messaging, notifications$, broadcastChannel, token }),
    [app, messaging, notifications$, broadcastChannel, token]
  );
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContext, FirebaseProvider };
