import React, { useEffect, useMemo } from "react";
import { BroadcastChannel } from "broadcast-channel";
import { getSessionId } from "@web-src/utils/session";
import { BroadcastChannelName } from "../../../app/types";

type AuthBroadcastChannelMessage = {
  emitterId: string;
};

const channel: BroadcastChannel<AuthBroadcastChannelMessage> =
  new BroadcastChannel(BroadcastChannelName.auth);

export const AuthBroadcastListener = () => {
  useEffect(() => {
    const messageHandler = (e: AuthBroadcastChannelMessage) => {
      const emitterId = getSessionId();
      if (emitterId !== e.emitterId) {
        window.location.reload();
      }
    };
    channel.addEventListener("message", messageHandler);
    return () => {
      channel.removeEventListener("message", messageHandler);
    };
  }, [channel]);

  return null;
};
