import React, { useMemo } from "react";
import { Menu } from "@headlessui/react";
import { useFloating, flip, shift, autoUpdate } from "@floating-ui/react";
import format from "date-fns/format";
import { EduComment as EduCommentType } from "@jugl-web/rest-api";
import { isoToLocalDate } from "@jugl-web/utils/date-time";
import { getFileTypeByUrl } from "@jugl-web/utils/utils/files";
import { setUserColor } from "@jugl-web/utils/utils/user";
import Video from "../Video/Video";
import FileItem from "../FileItem/FileItem";
import { ReactComponent as OptionsIcon } from "./icons/options.svg";
import { ReactComponent as DeleteIcon } from "./icons/delete.svg";

export interface EduCommentProps {
  comment: EduCommentType;
  onDelete?: () => void;
  onFileClick?: () => void;
}

const EduComment: React.FC<EduCommentProps> = ({
  onDelete,
  onFileClick,
  comment: {
    attachments,
    content,
    created_by: { user_id: authorId, username: authorName },
    inserted_at: date,
  },
}) => {
  const { refs, floatingStyles } = useFloating({
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
  });

  const formattedDate = useMemo(
    () => format(isoToLocalDate(date), "do MMM yyyy, hh:mm aa"),
    [date]
  );

  const fileAttachment = useMemo(
    () =>
      attachments?.filter(({ file_path: filePath }) =>
        ["doc", "pdf", "file"].includes(getFileTypeByUrl(filePath))
      ),
    [attachments]
  );

  const videoAttachments = useMemo(
    () =>
      attachments?.filter(({ file_path: filePath }) =>
        ["video"].includes(getFileTypeByUrl(filePath))
      ),
    [attachments]
  );

  const imageAttachments = useMemo(
    () =>
      attachments?.filter(({ file_path: filePath }) =>
        ["image"].includes(getFileTypeByUrl(filePath))
      ),
    [attachments]
  );

  return (
    <div className="mb-1 box-border w-full bg-white p-2">
      <div className="mb-1 flex h-5 items-center justify-between">
        <span
          className="truncate text-[15px] font-medium"
          style={{ color: setUserColor(authorId) }}
        >
          {authorName}
        </span>
        {!!onDelete && (
          <Menu as="div" className="relative">
            <Menu.Button
              className="cursor-pointer border-none bg-transparent outline-none"
              ref={refs.setReference}
            >
              <OptionsIcon />
            </Menu.Button>
            <Menu.Items
              className="absolute z-10 rounded-lg bg-white p-2 shadow-2xl outline-none"
              ref={refs.setFloating}
              style={{ ...floatingStyles }}
            >
              <Menu.Item>
                <div
                  role="button"
                  tabIndex={0}
                  className="flex h-[48px] w-[170px] cursor-pointer items-center gap-3"
                  onClick={onDelete}
                  onKeyDown={onDelete}
                >
                  <DeleteIcon className="ml-3" />
                  <span>Delete</span>
                </div>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        )}
      </div>
      <div
        className={`truncate text-[14px] ${
          attachments && attachments.length > 0 && "mb-3"
        }`}
      >
        {content}
      </div>
      {((fileAttachment && fileAttachment.length > 0) ||
        (videoAttachments && videoAttachments.length > 0)) && (
        <div
          className={`flex flex-col gap-2 ${
            imageAttachments && imageAttachments.length > 0 && "mb-2"
          }`}
        >
          {fileAttachment?.map((file) => (
            <FileItem
              url={file.file_path}
              key={file.file_path}
              onFileClick={onFileClick}
            />
          ))}
          {videoAttachments?.map((video) => (
            <Video url={video.file_path} key={video.file_path} />
          ))}
        </div>
      )}
      {imageAttachments && imageAttachments.length > 0 && (
        <div className="flex w-full flex-row gap-1 overflow-x-auto">
          {imageAttachments.map((image) => (
            <img
              key={image.file_path}
              onClick={onFileClick}
              src={image.file_path}
              alt={image.file_path}
              className="h-[180px] w-full rounded-[10px] object-cover"
            />
          ))}
        </div>
      )}
      <div className="text-grey-700 mt-4 truncate text-sm">{formattedDate}</div>
    </div>
  );
};

export default EduComment;
