import { cx } from "@jugl-web/utils";
import { forwardRef, HTMLAttributes } from "react";

export type PillSize = "md" | "sm";

export interface PillProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  size: PillSize;
  isActive?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  className?: string;
}

const sizeToContainerClasses: Record<PillSize, string> = {
  sm: "py-1 px-2",
  md: "py-2 px-2.5",
};

const sizeToLabelClasses: Record<PillSize, string> = {
  sm: "text-xs leading-[11px]",
  md: "text-sm leading-[11px]",
};

export const Pill = forwardRef<HTMLDivElement, PillProps>(
  ({ label, size, isActive, startIcon, endIcon, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        "inline-flex items-center gap-1.5 rounded-lg",
        isActive ? "bg-primary-50" : "bg-grey-200",
        sizeToContainerClasses[size],
        className
      )}
      {...props}
    >
      {startIcon}
      <span
        className={cx(
          "text-dark font-normal leading-[11px]",
          sizeToLabelClasses[size]
        )}
      >
        {label}
      </span>
      {endIcon}
    </div>
  )
);
