import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import Avatar from "@web-src/components/Avatar";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { getEntityUserDisplayName } from "@web-src/features/entities/utils";
import { IAgoraRTCRemoteUser, UID } from "agora-rtc-sdk-ng";
import cx from "classnames";
import React, { useCallback, useMemo } from "react";

const UsersAvatars: React.FC<{ users: IAgoraRTCRemoteUser[] }> = ({
  users,
}) => {
  const { participantsMap } = useEntity();
  const getAvatar = useCallback(
    (userId: UID) => {
      const id = userId?.toString().replace("screenSharing_", "");
      if (participantsMap[id]) {
        return participantsMap[id].profile?.img;
      }
      return "";
    },
    [participantsMap]
  );

  const getName = useCallback(
    (userId: UID) => {
      const id = userId?.toString().replace("screenSharing_", "");
      if (participantsMap[id]) {
        return getEntityUserDisplayName(participantsMap[id]);
      }
      return userId;
    },
    [participantsMap]
  );
  const extraUsersCounter = useMemo(() => users.length - 6, [users]);
  return (
    <div className="absolute bottom-24 right-9">
      <div className="flex flex-row">
        {users.map((user, index) => {
          if (index > 5) {
            return "";
          }
          return (
            <div className="relative" key={user.uid}>
              {extraUsersCounter > 0 && (
                <div
                  className={cx({
                    "bg-grey/60 border-1 absolute top-0 right-0 left-0 bottom-0 z-30 ml-[-25px] flex place-content-center items-center rounded-full border-solid border-white":
                      index === 5,
                    hidden: index !== 5,
                  })}
                >
                  <Text
                    variant="h4"
                    className="overflow-hidden text-ellipsis text-white"
                  >
                    + {extraUsersCounter}
                  </Text>
                </div>
              )}
              <Avatar
                src={getAvatar(user.uid)}
                alt={getName(user.uid)}
                className={cx({
                  "border-1 border-solid border-white": !(
                    extraUsersCounter > 0 && index < 5
                  ),
                  "ml-[-25px]": index > 0,
                })}
                sx={{
                  width: "50px",
                  height: "50px",
                  aspectRatio: "1 / 1",
                }}
                fallbackTextProps={{
                  sx: {
                    fontSize: "30px",
                  },
                }}
                imgProps={undefined}
                imageIndex={undefined}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UsersAvatars;
