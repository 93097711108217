import { serializeSearchParams } from "@web-src/utils/helper";
import { baseQueryObj } from "./createApi";

export const fetchAllPages = async <T>(
  url: string,
  params?: { [key: string]: string | number }
) => {
  let mergedItems: T[] = [];
  const resp = await baseQueryObj(
    `${url}${params ? `?${serializeSearchParams(params)}` : ""}`
  );
  if ("error" in resp) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw resp.error;
  }
  mergedItems = [...resp.data.data];
  const totalPages = resp.data.total_pages;
  let nextPage = 2;
  const extraQueries = [];

  while (nextPage <= totalPages) {
    extraQueries.push(
      serializeSearchParams({
        page: nextPage,
        ...(params || {}),
      })
    );
    nextPage += 1;
  }

  const extraResponses = await Promise.all(
    extraQueries.map((query) => baseQueryObj(`${url}?${query}`))
  );
  extraResponses.forEach((extraResp) => {
    mergedItems = [...mergedItems, ...extraResp.data.data];
  });
  return mergedItems;
};
