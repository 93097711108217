export enum EditProfileTiles {
  PersonalInfo = "personal-info",
  Education = "education",
  CurrentCity = "current-city",
  BirthPlace = "birth-place",
  Awards = "awards",
  Certifications = "certifications",
  Achievements = "achievements",
  Service = "service",
  Experience = "experience",
}

export enum SettingsRoutes {
  EditProfile = "edit-profile",
}

export type UpdateProfileData = {
  item_type: "general";
  profile_item: {
    desc: unknown;
    dob: string;
    first_name: string;
    gender: string;
    id: string;
    img: string | null;
    lastName: string;
    mobile: string | null;
    nickname: string;
  };
};
