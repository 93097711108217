export enum EduAssignmentCategory {
  pending = "pending",
  completed = "completed",
}

export enum EduSubmissionStatus {
  graded = "graded",
  submitted = "submitted",
}

export type EduAssignment = {
  attachments: {
    file_path: string;
    id: string;
  }[];
  created_by: {
    user_id: string;
    username: string;
  };
  desc: string;
  due_date: string;
  id: string;
  max_grade: number;
  space: {
    id: string;
    info: { batch: string; title: string };
  };
  subject_id: string;
  title: string;
  unread_assign: boolean;
  unread_cmnt: boolean;
  unread_sub: boolean;
  updated_at: string;
  submission_data?: {
    grade: number | null;
    graded_by: { user_id: null | string; username: null | string };
    status: EduSubmissionStatus;
    sub_id: string;
    submitted_at: string;
    updated_at: string;
  }[];
};

export type EduAssignmentSubmittion = {
  assign_id: string;
  attachments: {
    file_path: string;
    id: string;
    updated_at: string;
  }[];
  desc: string;
  grade: null | number;
  graded_by: { user_id: null | string; username: null | string };
  remarks: null | string;
  status: EduSubmissionStatus;
  sub_id: string;
  submitted_at: string;
  submitted_by: {
    user_id: string;
    username: string;
  };
  updated_at: string;
};

export type EduSubmissionsInfo = {
  assigned: number;
  graded: number;
  submissions_list: [
    {
      submission_data: {
        assign_id: string;
        attachments: {
          file_path: string;
          id: string;
          updated_at: string;
        }[];
        desc: string;
        grade: number;
        graded_by: {
          user_id: string;
          username: string;
        };
        remarks: string;
        status: EduSubmissionStatus;
        sub_id: string;
        submitted_at: string;
        submitted_by: {
          user_id: string;
          username: string;
        };
        updated_at: string;
      } | null;
      submitted_by?: {
        user_id: string;
        username: string;
      };
    }
  ];
  submitted: number;
  user: string;
};
