import React, { memo, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import format from "date-fns/format";
import CustomDateTimePicker from "@web-src/components/CustomDateTimePicker";
import { ReactComponent as CalendarIcon } from "@web-src/images/icons/calendar.svg";
import SvgIcon from "@mui/material/SvgIcon";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetProfileQuery } from "@web-src/features/auth/authApi";
import { Profile } from "@web-src/features/auth/types";
import { SettingsPaddingSize } from "@web-src/utils/layout";
import {
  useAddInformationMutation,
  useDeleteInformationMutation,
  useUpdateInformationMutation,
} from "@web-src/features/settings/settingsApi";
import { useEnqueueSnackbar } from "@web-src/features/settings/hooks/useSnackbar";
import { useTranslations } from "@jugl-web/utils";
import FormSpinner from "../FormSpinner";
import DeleteModal from "../DeleteModal";

type Inputs = {
  country: string;
  state: string;
  city: string;
  start_date: Date | undefined;
};

const schema = yup.object().shape({
  country: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
  start_date: yup.date().required(),
});

const EditCurrentCityFormContainer = memo(() => {
  const { data } = useGetProfileQuery();
  return <EditCurrentCityFormComponent {...{ data }} />;
});

const EditCurrentCityFormComponent: React.FC<{
  data: Profile | undefined;
}> = ({ data }) => (
  <Box sx={{ width: "50%" }}>
    {!data ? (
      <FormSpinner />
    ) : (
      <EditCurrentCityForm
        data={data}
        type={data.locations.length === 0 ? "add" : "update"}
      />
    )}
  </Box>
);

const EditCurrentCityForm: React.FC<{
  data: Profile;
  type: "add" | "update";
}> = ({ data, type }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      start_date: data.locations[0]?.moved_in
        ? new Date(data.locations[0].moved_in)
        : undefined,
      country: data.locations[0]?.country || "",
      state: data.locations[0]?.state || "",
      city: data.locations[0]?.city || "",
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const [addCity, { isLoading }] = useAddInformationMutation();
  const [updateCity, { isLoading: isLoadingUpdate }] =
    useUpdateInformationMutation();
  const [deleteCity, { isLoading: isLoadingDelete }] =
    useDeleteInformationMutation();
  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const { city, state, country, start_date: startDate } = { ...params };
    let res;
    if (type === "add") {
      res = await addCity({
        data: {
          type: "location",
          moved_in: startDate,
          city,
          state,
          country_name: country,
        },
      });
    } else {
      res = await updateCity({
        item_id: data?.locations[0].id,
        data: {
          type: "location",
          moved_in: startDate,
          city,
          state,
          country_name: country,
        },
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
    }
  };

  const onDeleteCity = async () => {
    const res = await deleteCity({
      type: "location",
      item_id: data.locations[0].id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };
  const { t } = useTranslations();

  return (
    <>
      <Typography sx={{ fontWeight: "500", padding: "40px 40px 0 20px" }}>
        {t({
          id: "current-city-form-page.add-current-city",
          defaultMessage: "Add Current City",
        })}
      </Typography>
      <Box sx={{ padding: "20px 40px 40px 40px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "70px",
              marginBottom: "50px",
              position: "relative",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <InputLabel>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                  component="span"
                >
                  {t({
                    id: "form-controls.country.label",
                    defaultMessage: "Country",
                  })}
                </Typography>{" "}
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </InputLabel>
              <TextField
                placeholder={t({
                  id: "form-controls.country-name.placeholder",
                  defaultMessage: "Country Name",
                })}
                variant="standard"
                sx={{
                  width: "100%",
                  input: {
                    "&::placeholder": {
                      fontWeight: "400",
                    },
                  },
                }}
                inputProps={{
                  style: { fontSize: 16, fontWeight: "500" },
                  ...register("country"),
                }}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <InputLabel>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                  component="span"
                >
                  {t({
                    id: "form-controls.state.label",
                    defaultMessage: "State",
                  })}
                </Typography>{" "}
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </InputLabel>
              <TextField
                placeholder={t({
                  id: "form-controls.state-name.placeholder",
                  defaultMessage: "State Name",
                })}
                variant="standard"
                sx={{
                  width: "100%",
                  input: {
                    "&::placeholder": {
                      fontWeight: "400",
                    },
                  },
                }}
                inputProps={{
                  style: { fontSize: 16, fontWeight: "500" },
                  ...register("state"),
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "70px",
              marginBottom: "50px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <InputLabel>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                  component="span"
                >
                  {t({
                    id: "form-controls.city.label",
                    defaultMessage: "City",
                  })}
                </Typography>{" "}
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </InputLabel>
              <TextField
                placeholder={t({
                  id: "form-controls.city-name.placeholder",
                  defaultMessage: "City Name",
                })}
                variant="standard"
                sx={{
                  width: "100%",
                  input: {
                    "&::placeholder": {
                      fontWeight: "400",
                    },
                  },
                }}
                inputProps={{
                  style: { fontSize: 16, fontWeight: "500" },
                  ...register("city"),
                }}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <InputLabel>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                  component="span"
                >
                  {t({
                    id: "form-controls.start-date.label",
                    defaultMessage: "Start Date",
                  })}
                </Typography>{" "}
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </InputLabel>
              <Controller
                name="start_date"
                control={control}
                render={({ field }) => (
                  <CustomDateTimePicker
                    disableCounter
                    title={t({
                      id: "date-time-picker.select-date",
                      defaultMessage: "Select Date",
                    })}
                    {...field}
                    renderInput={
                      <Box
                        sx={{
                          width: "100%",
                          height: "32px",
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid black",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          "&:hover": {
                            borderBottom: "2px solid black",
                          },
                        }}
                      >
                        <Box>
                          {!field.value ? (
                            <Typography sx={{ color: "grey.900" }}>
                              DD/MM/YYYY
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ fontSize: 16, fontWeight: "500" }}
                            >
                              {format(field.value, "dd/MM/yyyy")}
                            </Typography>
                          )}
                        </Box>
                        <SvgIcon
                          component={CalendarIcon}
                          sx={{ color: "grey.700" }}
                        />
                      </Box>
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              marginBottom: "50px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <Button
                disabled={!isValid || isLoading || isLoadingUpdate || !isDirty}
                size="large"
                variant="contained"
                type="submit"
                sx={{ width: `calc(100% - ${SettingsPaddingSize}px)` }}
              >
                {t({
                  id: "common.save",
                  defaultMessage: "Save",
                })}
              </Button>
            </Box>
            {data.locations.length !== 0 && (
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  disabled={isLoadingDelete}
                  variant="outlined"
                  size="large"
                  sx={{
                    width: `calc(100% - ${SettingsPaddingSize}px)`,
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  {t({
                    id: "current-city-form-page.delete-current-city-info",
                    defaultMessage: "Delete Current City Info",
                  })}
                </Button>
              </Box>
            )}
          </Box>
        </form>
      </Box>
      {openModal && (
        <DeleteModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={() => onDeleteCity()}
          isLoading={isLoadingDelete}
          text={t({
            id: "current-city-form-page.want-remove-current-city-information",
            defaultMessage:
              "Do you really want to remove current city information?",
          })}
        />
      )}
    </>
  );
};

export default EditCurrentCityFormContainer;
