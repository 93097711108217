import { cx } from "@jugl-web/utils";
import React, { ButtonHTMLAttributes } from "react";

export type FabVariant = "default" | "primary" | "success" | "danger";

export type FabSize = "xs" | "sm" | "md" | "lg";

export type FabProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: JSX.Element;
  variant?: FabVariant;
  size?: FabSize;
};

const variantToClasses: Record<FabVariant, string> = {
  default: "",
  primary: "bg-primary",
  success: "bg-secondary",
  danger: "bg-gradients-danger",
};

const sizeToClasses: Record<FabSize, string> = {
  xs: "h-[36px] w-[36px]",
  sm: "h-14 w-14",
  md: "h-16 w-16",
  lg: "h-[70px] w-[70px]",
};

export const Fab: React.FC<FabProps> = ({
  icon,
  variant = "default",
  size = "md",
  className,
  ...props
}) => (
  <button
    type="button"
    className={cx(
      "flex cursor-pointer items-center justify-center rounded-full border-transparent hover:brightness-90 active:brightness-110",
      props.disabled ? "bg-grey-200" : variantToClasses[variant],
      sizeToClasses[size],
      className
    )}
    {...props}
  >
    {icon}
  </button>
);
