import { PaginatedResponse, RtkEmptySplitApi } from "../../types";
import { EduFeed } from "./types";

export const addEduFeedsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({});

  const eduFeedsApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      createFeed: build.mutation<
        { data: EduFeed },
        {
          entityId: string;
          data: {
            title: string;
            content: string;
            can_comment: string;
            files?: File[];
          };
        }
      >({
        query: ({ entityId, data: { title, content, can_comment, files } }) => {
          const formData = new FormData();
          formData.append("title", title);
          formData.append("content", content);
          formData.append("can_comment", can_comment);
          files?.forEach((item) => formData.append("files[]", item));
          return {
            url: `/api/auth/entity/${entityId}/edu/feed`,
            method: "POST",
            data: formData,
          };
        },
      }),
      deleteFeed: build.mutation<void, { entityId: string; feedId: string }>({
        query: ({ entityId, feedId }) => ({
          url: `/api/auth/entity/${entityId}/edu/feed/${feedId}`,
          method: "DELETE",
        }),
      }),
      feedsList: build.query<
        PaginatedResponse<EduFeed>,
        {
          entityId: string;
          params: {
            page: number;
            page_size: number;
            spaces?: string;
            subjects?: string;
          };
        }
      >({
        query: ({ entityId, params }) => ({
          params,
          url: `/api/auth/entity/${entityId}/edu/feed`,
        }),
      }),
      feedsSearch: build.query<
        PaginatedResponse<EduFeed>,
        {
          entityId: string;
          params: {
            page: number;
            page_size: number;
            spaces?: string;
            subjects?: string;
            search_term: string;
          };
        }
      >({
        query: ({ entityId, params }) => ({
          params,
          url: `/api/auth/entity/${entityId}/edu/feed/feeds/search`,
        }),
      }),
      getFeed: build.query<
        {
          data: EduFeed;
        },
        {
          entityId: string;
          feedId: string;
        }
      >({
        query: ({ entityId, feedId }) => ({
          url: `/api/auth/entity/${entityId}/edu/feed/${feedId}`,
        }),
      }),
    }),
  });

  const {
    useCreateFeedMutation,
    useDeleteFeedMutation,
    useFeedsListQuery,
    useLazyFeedsListQuery,
    useFeedsSearchQuery,
    useLazyFeedsSearchQuery,
    useGetFeedQuery,
    useLazyGetFeedQuery,
  } = eduFeedsApi;
  return {
    useCreateFeedMutation,
    useDeleteFeedMutation,
    useFeedsListQuery,
    useLazyFeedsListQuery,
    useFeedsSearchQuery,
    useLazyFeedsSearchQuery,
    useGetFeedQuery,
    useLazyGetFeedQuery,
  };
};

export type EduFeedsApi = ReturnType<typeof addEduFeedsApi>;
