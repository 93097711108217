import {
  PaginatedResponse,
  RtkEmptySplitApi,
  PaginatedRequestParams,
} from "../../types";
import { TaskComment, TaskCommentAttachment } from "./types";

export const addTasksCommentsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [],
  });

  const tasksApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getTaskComments: builder.query<
        PaginatedResponse<TaskComment>,
        {
          entityId: string;
          taskId: string;
          repliesCommentId?: string;
          params?: PaginatedRequestParams<{ from?: string }>;
        }
      >({
        query: ({ entityId, taskId, params, repliesCommentId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comment${
            repliesCommentId ? `/${repliesCommentId}/reply` : ""
          }`,
          params,
          silentError: true,
        }),
      }),

      createTaskComment: builder.mutation<
        { data: TaskComment[] },
        {
          taskId: string;
          entityId: string;
          replyToCommentId?: string;
          data: {
            comment_attachments?: TaskCommentAttachment[];
            content?: string;
          };
        }
      >({
        query: ({ taskId, entityId, data, replyToCommentId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comment${
            replyToCommentId ? `/${replyToCommentId}/reply` : ""
          }`,
          method: "POST",
          data,
        }),
      }),

      editComment: builder.mutation<
        { data: TaskComment[] },
        {
          taskId: string;
          entityId: string;
          commentId: string;
          parentCommentId?: string;
          data: {
            comment_attachments?: TaskCommentAttachment[];
            content?: string;
          };
        }
      >({
        query: ({ taskId, entityId, commentId, data, parentCommentId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comment/${
            parentCommentId || commentId
          }${parentCommentId ? `/reply/${commentId}` : ""}`,
          method: "PUT",
          data,
        }),
      }),

      deleteTaskComment: builder.mutation<
        void,
        {
          entityId: string;
          taskId: string;
          commentId: string;
          parentCommentId?: string;
        }
      >({
        query: ({ entityId, taskId, commentId, parentCommentId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/comment/${
            parentCommentId || commentId
          }${parentCommentId ? `/reply/${commentId}` : ""}`,
          method: "DELETE",
        }),
      }),
    }),
  });

  return tasksApi;
};

export type TasksCommentsApi = ReturnType<typeof addTasksCommentsApi>;
