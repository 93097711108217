import { HookOutOfContextError } from "@jugl-web/utils/errors";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { EduAssignmentsApi } from "./features/edu-assignments/eduAssignmentsApi";
import { EduCommentsApi } from "./features/edu-comments/eduCommentsApi";
import { EduFeedsApi } from "./features/edu-feeds/eduFeedsApi";
import { EduLessonsApi } from "./features/edu-lessons/eduLessonsApi";
import { EntityPropsApi } from "./features/entity-props/entityPropsApi";
import { EntitySpacesApi } from "./features/entity-spaces/entitySpacesApi";
import { ProfilesApi } from "./features/profiles";
import { LabelsApi } from "./features/labels";
import { TasksApi } from "./features/tasks";
import { UsersApi } from "./features/users";
import { WorkspacesApi } from "./features/workspaces";
import { CustomerApi } from "./features/customer";
import { CustomerFormFieldApi } from "./features/customer-form-field";
import { TasksCommentsApi } from "./features/tasks-comments/tasksCommentsApi";
import { DriveApi } from "./features/drive";
import { TasksTemplatesApi } from "./features/tasks-templates";
import { SupersetApi } from "./features/superset";

interface RestApiContextValue {
  apiBaseUrl: string;

  profilesApi: ProfilesApi;
  tasksApi: TasksApi;
  tasksCommentsApi: TasksCommentsApi;
  tasksTemplatesApi: TasksTemplatesApi;
  labelsApi: LabelsApi;
  usersApi: UsersApi;
  workspacesApi: WorkspacesApi;
  eduCommentsApi: EduCommentsApi;
  eduAssignmentsApi: EduAssignmentsApi;
  eduFeedsApi: EduFeedsApi;
  eduLessonsApi: EduLessonsApi;
  entityPropsApi: EntityPropsApi;
  entitySpacesApi: EntitySpacesApi;
  customersApi: CustomerApi;
  customersFormFieldApi: CustomerFormFieldApi;
  driveApi: DriveApi;
  supersetApi: SupersetApi;
}

const RestApiContext = createContext<RestApiContextValue | null>(null);

export const RestApiProvider: FC<
  PropsWithChildren<{ apis: Partial<RestApiContextValue>; apiBaseUrl: string }>
> = ({ children, apis, apiBaseUrl }) => {
  const value = useMemo(
    () => ({ ...apis, apiBaseUrl } as RestApiContextValue),
    [apis, apiBaseUrl]
  );
  return (
    <RestApiContext.Provider value={value}>{children}</RestApiContext.Provider>
  );
};

export const useRestApiProvider = () => {
  const context = useContext(RestApiContext);

  if (!context) {
    throw new HookOutOfContextError("useRestApiProvider", "RestApiContext");
  }

  return context;
};
