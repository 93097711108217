import React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ReactComponent as ExitIcon } from "@web-src/images/icons/exit.svg";
import { SvgIcon } from "@mui/material";
import { useTranslations } from "@jugl-web/utils";

const DeleteModal: React.FC<{
  open: boolean;
  text: string;
  onClose: () => void;
  onClick: () => void;
  isLoading: boolean;
}> = ({ open, onClose, onClick, text, isLoading }) => {
  const { t } = useTranslations();
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "800px",
          height: "240px",
          borderRadius: "8px",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <SvgIcon
            component={ExitIcon}
            viewBox="0 0 14 14"
            sx={{
              width: "15px",
              margin: "20px 20px 0 0",
              color: "gray.500",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "500",
            marginTop: "10px",
          }}
        >
          {t({
            id: "delete-modal.delete-confirmation-title",
            defaultMessage: "Delete Confirmation",
          })}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "15px",
            marginTop: "20px",
            color: "grey.900",
          }}
        >
          {text}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "30px",
            marginTop: "30px",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ width: "265px" }}
            size="large"
          >
            {t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button
            disabled={isLoading}
            style={{
              backgroundColor: "#EA5353",
            }}
            onClick={onClick}
            variant="contained"
            sx={{ width: "265px" }}
            size="large"
          >
            {t({
              id: "common.delete",
              defaultMessage: "Delete",
            })}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
