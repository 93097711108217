import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import { AppType, PageRoute } from "@web-src/features/app/types";
import Loader from "@web-src/components/Loader";
import { useGetProfileQuery } from "@web-src/features/auth/authApi";
import { getAppType } from "@web-src/features/app/utils";
import { usePrevious } from "@web-src/utils/helper";

const PrivateRoute: React.FC<{
  component: React.ReactNode;
  skipProfileCreateNavigate?: boolean;
}> = ({ component, skipProfileCreateNavigate }) => {
  const { isAuthenticated } = useSelector(selectAuthState);
  const appType = getAppType();
  const { error, isLoading } = useGetProfileQuery(undefined, {
    skip: !isAuthenticated || appType !== AppType.org,
  });
  const previousIsLoading = usePrevious(isLoading);
  const dontHaveProfile = error && "status" in error && error?.status === 404;
  if (isAuthenticated === false) {
    return (
      <Navigate
        to={{
          pathname: `/${PageRoute.login}`,
        }}
      />
    );
  }
  if (!isAuthenticated || (isLoading && previousIsLoading === undefined)) {
    return <Loader />;
  }
  if (
    skipProfileCreateNavigate ||
    !dontHaveProfile ||
    appType !== AppType.org
  ) {
    return <>{component}</>;
  }
  return (
    <Navigate
      to={{
        pathname: `/${PageRoute.profileForm}`,
      }}
    />
  );
};

export default PrivateRoute;
