export const TASK_MODULE = "task";

type SupportedLiveUpdateModule = typeof TASK_MODULE;

export interface LiveUpdateEvent<
  TAction extends string = string,
  TData extends object = object,
  TModule extends string = SupportedLiveUpdateModule | string
> {
  action: TAction;
  data: TData;
  module: TModule;
}

export const isLiveUpdateEvent = (event: unknown): event is LiveUpdateEvent =>
  !!(
    event &&
    typeof event === "object" &&
    "action" in event &&
    "data" in event &&
    "module" in event
  );
