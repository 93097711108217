import { cx } from "@jugl-web/utils";
import { InputHTMLAttributes, forwardRef } from "react";

// TODO: Implement rest of features and states according to the design

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type">;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => (
    <input
      ref={ref}
      type="text"
      className={cx(
        "border-t-0 border-l-0 border-r-0 border-b border-solid border-b-black/20 p-2.5 outline-none",
        "text-base font-medium leading-4 text-[#363636] transition-colors",
        "placeholder:text-grey placeholder:font-normal",
        "focus:border-b-primary",
        className
      )}
      {...props}
    />
  )
);
