import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  EduFeed,
  EduMobileNotification,
  useRestApiProvider,
  useEduFeeds,
} from "@jugl-web/rest-api";
import { PaginationItem } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { FirebaseContext } from "@web-src/features/app/providers/Firebase";
import { matchRoutes, useLocation } from "react-router-dom";
import { TabRoute } from "@web-src/features/app/types";

type EduFeedsContextType = {
  feeds?: PaginationItem<EduFeed>[];
  isLoading?: boolean;
  setSearchQuery: (query: string) => void;
  load: () => Promise<void>;
};

const EduFeedsContext = createContext<EduFeedsContextType>(
  {} as EduFeedsContextType
);

const EduFeedsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { entity } = useEntity();
  const [searchQuery, setSearchQuery] = useState<string>();
  const { eduFeedsApi } = useRestApiProvider();
  const [isInitialLoadTriggered, setIsInitialLoadTriggered] =
    useState<boolean>();

  const {
    items: feeds,
    isLoading,
    load,
    addFeedById,
    bumpFeedCommentsCount,
  } = useEduFeeds({
    searchQuery,
    eduFeedsApi,
    entityId: entity?.id,
  });
  const location = useLocation();
  const matchedRoutes = matchRoutes(
    [
      { path: `/:entityId/${TabRoute.eduFeeds}` },
      { path: `/:entityId/${TabRoute.eduFeeds}/:feedId` },
    ],
    location
  );
  useEffect(() => {
    if (isInitialLoadTriggered || !matchedRoutes?.length) {
      return;
    }
    setIsInitialLoadTriggered(true);
    load();
  }, [matchedRoutes, isInitialLoadTriggered, load]);

  const { notifications$ } = useContext(FirebaseContext);
  useEffect(() => {
    const notificationsSubscription = notifications$?.subscribe((e) => {
      const rawNotification = e.data;
      if (!rawNotification || !rawNotification.result) {
        return;
      }
      const notification = {
        ...rawNotification,
        payload: JSON.parse(rawNotification.result),
      } as EduMobileNotification;
      switch (notification.type) {
        case "post": {
          if (!notification.payload.id) {
            break;
          }
          addFeedById(notification.payload.id);
          break;
        }
        case "post_cmnt": {
          if (!notification.payload.id) {
            break;
          }
          bumpFeedCommentsCount(notification.payload.id);
          break;
        }
        default:
      }
    });
    return () => {
      notificationsSubscription?.unsubscribe();
    };
  }, [addFeedById, bumpFeedCommentsCount, notifications$]);

  const value: EduFeedsContextType = useMemo(
    () => ({
      feeds,
      isLoading,
      setSearchQuery,
      load,
    }),
    [feeds, isLoading, load]
  );

  return (
    <EduFeedsContext.Provider value={value}>
      {children}
    </EduFeedsContext.Provider>
  );
};

export { EduFeedsContext, EduFeedsProvider };
