import { cx, getUsernameInitials } from "@jugl-web/utils";
import { FC, useState } from "react";
import { ReactComponent as SmallCheckIcon } from "./icons/small-check.svg";

export type AvatarSize = "xs" | "sm" | "md" | "lg";

export interface AvatarProps {
  username: string;
  size: AvatarSize;
  imageUrl?: string | null;
  className?: string;
  isSelected?: boolean;
}

const sizeToClasses: Record<AvatarSize, string> = {
  xs: "h-4 w-4",
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-12 w-12",
};

const sizeToTextClasses: Record<AvatarSize, string> = {
  xs: "text-[10px]",
  sm: "text-sm",
  md: "text-base",
  lg: "text-base",
};

export const Avatar: FC<AvatarProps> = ({
  username,
  size,
  imageUrl,
  className,
  isSelected,
}) => {
  const [hasImageLoadingError, setHasImageLoadingError] = useState(false);

  const hasImage = !!imageUrl;
  const initials = getUsernameInitials(username);

  return (
    <div className="relative inline-flex">
      <div
        className={cx(
          "relative inline-flex overflow-hidden rounded-full",
          isSelected && "outline-primary outline outline-1",
          sizeToClasses[size],
          className
        )}
      >
        {hasImage && !hasImageLoadingError ? (
          <img
            src={imageUrl}
            alt={username}
            className="h-full w-full object-cover"
            onError={() => setHasImageLoadingError(true)}
          />
        ) : (
          <span
            className={cx(
              "text-dark flex h-full w-full items-center justify-center text-base font-semibold uppercase leading-3",
              sizeToTextClasses[size]
            )}
            style={{
              background: "linear-gradient(180deg, #CECED3 0%, #ECEFF0 69.27%)",
            }}
          >
            {initials}
          </span>
        )}
      </div>
      {isSelected && (
        <div className="bg-primary absolute bottom-0 right-0 flex h-[18px] w-[18px] items-center justify-center rounded-full">
          <SmallCheckIcon />
        </div>
      )}
    </div>
  );
};
