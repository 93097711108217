import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { EntitySpace, EntitySpaceType } from "../entities";

enum EntityPropsApiTagTypes {
  entityPropsList = "entityPropsList",
}

const getEntitySpacesListTag = (type: EntitySpaceType, entityId: string) => ({
  id: `${type}-${entityId}`,
  type: EntityPropsApiTagTypes.entityPropsList,
});

export const addEntitySpacesApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [EntityPropsApiTagTypes.entityPropsList],
  });

  const entityPropsApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      entitySpacesList: build.query<
        PaginatedResponse<EntitySpace>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            type: EntitySpaceType;
            user_id?: string;
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/spaces`,
          params,
        }),
        providesTags: (e1, e2, { entityId, params: { type } }) => [
          getEntitySpacesListTag(type, entityId),
        ],
      }),
      entityCreateSpace: build.mutation<
        EntitySpace,
        { data: EntitySpace; entityId: string }
      >({
        query: ({ data, entityId }) => ({
          url: `/api/auth/entity/${entityId}/spaces`,
          method: "POST",
          data,
        }),
        invalidatesTags: (e1, e2, { entityId, data: { type } }) => [
          getEntitySpacesListTag(type, entityId),
        ],
      }),
      entityRemoveSpace: build.mutation<
        void,
        { entityId: string; spaceId: string; type: EntitySpaceType }
      >({
        query: ({ entityId, spaceId }) => ({
          url: `/api/auth/entity/${entityId}/spaces/${spaceId}`,
          method: "DELETE",
          data: { entity_id: entityId, workspace_id: spaceId },
        }),
        invalidatesTags: (e1, e2, { entityId, type }) => [
          getEntitySpacesListTag(type, entityId),
        ],
      }),
      entityUpdateSpace: build.mutation<
        EntitySpace,
        { entityId: string; data: EntitySpace; type: EntitySpaceType }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/entity/${entityId}/spaces/${data.id}`,
          method: "PUT",
          data,
        }),
        invalidatesTags: (e1, e2, { entityId, type }) => [
          getEntitySpacesListTag(type, entityId),
        ],
      }),
    }),
  });

  const {
    useEntitySpacesListQuery,
    useLazyEntitySpacesListQuery,
    useEntityCreateSpaceMutation,
    useEntityRemoveSpaceMutation,
    useEntityUpdateSpaceMutation,
  } = entityPropsApi;
  return {
    api: entityPropsApi,
    useEntitySpacesListQuery,
    useLazyEntitySpacesListQuery,
    useEntityCreateSpaceMutation,
    useEntityRemoveSpaceMutation,
    useEntityUpdateSpaceMutation,
  };
};

export type EntitySpacesApi = ReturnType<typeof addEntitySpacesApi>;
