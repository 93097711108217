import { useEffect, useState } from "react";

export const useTabIsActive = () => {
  const [isActive, setIsActive] = useState(!document.hidden);
  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      setIsActive(!document.hidden);
    });
  }, []);
  return isActive;
};
