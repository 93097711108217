import { useState, useEffect, useMemo, useCallback } from "react";
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalVideoTrack,
  IRemoteVideoTrack,
  IRemoteAudioTrack,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ILocalAudioTrack,
} from "agora-rtc-sdk-ng";
import { usePrevious } from "@web-src/utils/helper";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { logger } from "@web-src/utils/logger";
import VirtualBackgroundExtension, {
  IVirtualBackgroundProcessor,
} from "agora-extension-virtual-background";
import {
  AIDenoiserExtension,
  AIDenoiserProcessorLevel,
  AIDenoiserProcessorMode,
} from "agora-extension-ai-denoiser";
import { ActiveCallProps } from "../types";

const virtualBackgroundExtension = new VirtualBackgroundExtension();
const denoiser = new AIDenoiserExtension({
  assetsPath: "",
});
AgoraRTC.registerExtensions([virtualBackgroundExtension, denoiser]);
const processor = virtualBackgroundExtension.createProcessor();
const audioProcessor = denoiser.createProcessor();
processor.init("../assets/agora-wasm.wasm");
processor.enable();
type UserTracks = {
  video?: IRemoteVideoTrack;
  audio?: IRemoteAudioTrack;
};

type ActiveCallParams = {
  localAudioTrack: IMicrophoneAudioTrack | undefined;
  localVideoTrack: ICameraVideoTrack | undefined;
  remoteUsers: IAgoraRTCRemoteUser[] | undefined;
  userTracks: {
    [key: string]: UserTracks;
  };
  toggleScreenShare: () => void;
  toggleVideo: () => void;
  toggleAudio: () => void;
  toggleProcessor: () => void;
  leaveCall: () => void;

  screenSharingEnabled: boolean;
  videoEnabled: boolean;
  audioEnabled: boolean;
  processor: IVirtualBackgroundProcessor;
};

const screenSharingClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

const useActiveCall = (
  client: IAgoraRTCClient,
  rtcProps: ActiveCallProps | undefined
): ActiveCallParams | null => {
  const [localAudioTrack, setLocalAudioTrack] =
    useState<IMicrophoneAudioTrack>();
  const [localVideoTrack, setLocalVideoTrack] = useState<ICameraVideoTrack>();
  const [localScreenShareTrack, setLocalScreenShareTrack] =
    useState<ILocalVideoTrack>();
  const [localScreenShareAudioTrack, setLocalScreenShareAudioTrack] =
    useState<ILocalAudioTrack>();
  const [cameraEnableInProgress, setCameraEnableInProgress] = useState(false);

  const [userTracks, setUserTracks] = useState<ActiveCallParams["userTracks"]>(
    {}
  );
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const meId = useSelector(selectUserId);

  const previousRtcProps = usePrevious(rtcProps);

  const [screenSharingEnabled, setScreenSharingEnabled] =
    useState<boolean>(false);
  const [videoEnabled, setVideoEnabled] = useState<boolean>(true);
  const [audioEnabled, setAudioEnabled] = useState<boolean>(false);
  const [processorEnabled, setProcessorEnabled] = useState<boolean>();

  useEffect(() => {
    const storedDevicesSettings =
      localStorage.getItem("calls_settings") &&
      JSON.parse(localStorage.getItem("calls_settings") || "{}");
    if (
      !rtcProps ||
      (previousRtcProps && isEqual(rtcProps, previousRtcProps))
    ) {
      return;
    }
    client
      .join(rtcProps.appId, rtcProps.channel, rtcProps.token, meId)
      .then(async () => {
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "high_quality_stereo",
        });
        setAudioEnabled(true);
        setLocalAudioTrack(audioTrack);
        if (storedDevicesSettings?.microphone_id) {
          audioTrack.setDevice(storedDevicesSettings?.microphone_id);
        }
        await client.publish(audioTrack);
      });
    setVideoEnabled(false);
    if (rtcProps?.video) {
      (async () => {
        const videoTrack = await AgoraRTC.createCameraVideoTrack();
        setLocalVideoTrack(videoTrack);
        await client.publish(videoTrack);
        setVideoEnabled(true);
      })();
    }
  }, [rtcProps, previousRtcProps, client, meId]);
  useEffect(() => {
    if (!client) return () => {};
    setRemoteUsers(client.remoteUsers);
    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      await client.subscribe(user, mediaType);
      setUserTracks((prev) => {
        const currentUserTracks = prev[user.uid] || {};
        if (mediaType === "audio") {
          currentUserTracks.audio = user.audioTrack;
        }
        if (mediaType === "video") {
          currentUserTracks.video = user.videoTrack;
        }
        return {
          ...prev,
          [user.uid]: currentUserTracks,
        };
      });
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      setUserTracks((prev) => {
        const currentUserTracks = prev[user.uid] || {};
        if (mediaType === "audio") {
          currentUserTracks.audio = user.audioTrack;
        }
        if (mediaType === "video") {
          currentUserTracks.video = user.videoTrack;
        }
        return {
          ...prev,
          [user.uid]: currentUserTracks,
        };
      });
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserJoined = () => {
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserLeft = () => {
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);
  useEffect(() => {
    if (videoEnabled && localVideoTrack) {
      localVideoTrack
        ?.pipe(processor)
        .pipe(localVideoTrack?.processorDestination);
    }
  }, [localVideoTrack, videoEnabled, processorEnabled]);

  useEffect(() => {
    if (audioEnabled && localAudioTrack) {
      localAudioTrack
        .pipe(audioProcessor)
        .pipe(localAudioTrack.processorDestination);
      audioProcessor.setMode(AIDenoiserProcessorMode.NSNG);

      audioProcessor.setLevel(AIDenoiserProcessorLevel.AGGRESSIVE);
      audioProcessor.enable();
    }
  }, [audioEnabled, localAudioTrack]);
  useEffect(() => {
    remoteUsers.forEach((user) => {
      client.setStreamFallbackOption(user.uid, 2);
    });
  }, [remoteUsers, client]);
  const handleStopScreenShare = useCallback(async () => {
    localScreenShareAudioTrack?.close();
    localScreenShareTrack?.close();
    await screenSharingClient.leave();
    setLocalScreenShareTrack(undefined);
    setScreenSharingEnabled(false);
  }, [localScreenShareAudioTrack, localScreenShareTrack]);

  const toggleScreenShare = useCallback(async () => {
    if (screenSharingEnabled || localScreenShareTrack) {
      handleStopScreenShare();
      return;
    }
    client.enableDualStream();
    const screenShareTrack = await AgoraRTC.createScreenVideoTrack(
      {
        optimizationMode: "detail",
      },
      "auto"
    );
    const screenShareVideoTrack = Array.isArray(screenShareTrack)
      ? screenShareTrack[0]
      : screenShareTrack;
    const screenShareAudioTrack = Array.isArray(screenShareTrack)
      ? screenShareTrack[1]
      : undefined;
    setLocalScreenShareTrack(screenShareVideoTrack);
    setLocalScreenShareAudioTrack(screenShareAudioTrack);
    if (!rtcProps) return;
    screenSharingClient.enableDualStream();
    screenSharingClient
      .join(
        rtcProps?.appId,
        rtcProps?.channel,
        rtcProps?.token,
        `screenSharing_${meId}`
      )
      .then(async () => {
        screenSharingClient.publish(screenShareVideoTrack);
      });
    setScreenSharingEnabled(true);
  }, [
    screenSharingEnabled,
    localScreenShareTrack,
    client,
    rtcProps,
    meId,
    handleStopScreenShare,
  ]);

  localScreenShareTrack?.on("track-ended", () => {
    handleStopScreenShare();
  });
  const toggleVideo = useCallback(async () => {
    const storedDevicesSettings =
      localStorage.getItem("calls_settings") &&
      JSON.parse(localStorage.getItem("calls_settings") || "{}");
    if (!localVideoTrack) {
      if (cameraEnableInProgress) {
        return;
      }
      setCameraEnableInProgress(true);
      try {
        const videoTrack = await AgoraRTC.createCameraVideoTrack({
          optimizationMode: "motion",
        });
        setLocalVideoTrack(videoTrack);

        if (storedDevicesSettings?.camera_id) {
          const devices = await AgoraRTC.getCameras();
          const isDevicePresent = devices.find(
            (camera) => camera.deviceId === storedDevicesSettings?.camera_id
          );
          if (isDevicePresent) {
            videoTrack.setDevice(storedDevicesSettings?.camera_id);
          }
        }
        await client.publish(videoTrack);
        setVideoEnabled(true);
      } catch (error) {
        logger.error(error);
      } finally {
        setCameraEnableInProgress(true);
      }
      return;
    }
    const nextIsMuted = !localVideoTrack.muted;
    await localVideoTrack?.setMuted(nextIsMuted);
    setVideoEnabled(!nextIsMuted);
  }, [localVideoTrack, client, cameraEnableInProgress]);

  const toggleAudio = useCallback(async () => {
    const nextIsMuted = !localAudioTrack?.muted;
    await localAudioTrack?.setMuted(nextIsMuted);
    setAudioEnabled(!nextIsMuted);
  }, [localAudioTrack]);

  const toggleProcessor = useCallback(async () => {
    if (processor?.enabled) {
      processor.disable();
    } else {
      processor?.enable();
    }
    setProcessorEnabled(!processorEnabled);
  }, [processorEnabled]);
  const leaveCall = useCallback(async () => {
    if (localAudioTrack) {
      localAudioTrack.close();
      setLocalAudioTrack(undefined);
    }
    if (localVideoTrack) {
      localVideoTrack.close();
      setLocalVideoTrack(undefined);
    }
    if (localScreenShareTrack) {
      handleStopScreenShare();
    }
    setUserTracks({});
    setRemoteUsers([]);
    await client.leave();
    await screenSharingClient.leave();
  }, [client, localAudioTrack, localScreenShareTrack, localVideoTrack]);

  const params: ActiveCallParams | null = useMemo(
    () =>
      rtcProps
        ? {
            localAudioTrack,
            localVideoTrack,
            remoteUsers,
            userTracks,
            toggleScreenShare,
            toggleVideo,
            toggleAudio,
            toggleProcessor,
            leaveCall,
            screenSharingEnabled,
            videoEnabled,
            audioEnabled,
            processor,
          }
        : null,
    [
      localAudioTrack,
      localVideoTrack,
      remoteUsers,
      rtcProps,
      toggleScreenShare,
      userTracks,
      toggleVideo,
      toggleAudio,
      toggleProcessor,
      leaveCall,
      screenSharingEnabled,
      videoEnabled,
      audioEnabled,
    ]
  );

  return params;
};

export default useActiveCall;
