import React, { useMemo, useCallback } from "react";
import { twMerge } from "tailwind-merge";

export enum TypographyVariant {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  body1 = "body1",
  body2 = "body2",
  body3 = "body3",
  buttonNormal = "buttonNormal",
  buttonMedium = "buttonMedium",
  buttonSmall = "buttonSmall",
  text1Regular = "text1Regular",
  text1Medium = "text1Medium",
  text2Regular = "text2Regular",
  text2Medium = "text2Medium",
  messageTextLarge = "messageTextLarge",
  messageTextDefault = "messageTextDefault",
  timestamp = "timestamp",
}

export type TextProps = {
  variant: keyof typeof TypographyVariant;
  className?: string;
  app?: "mobile" | "desktop";
  children: React.ReactNode;
};

const TextComponent: React.FC<TextProps> = ({
  variant,
  className,
  app,
  children,
}) => {
  const getClassName = useCallback(
    (textVariant: keyof typeof TypographyVariant) => {
      const appType = app || process.env.NX_PLATFORM;
      switch (textVariant) {
        case TypographyVariant.h1:
          return appType === "mobile"
            ? "font-medium text-3xl leading-7"
            : "font-medium text-6xl leading-10";
        case TypographyVariant.h2:
          return appType === "mobile"
            ? "font-medium text-xl leading-5"
            : "font-medium text-4xl leading-8";
        case TypographyVariant.h3:
          return appType === "mobile"
            ? "font-medium text-lg leading-4"
            : "font-medium text-3xl leading-7";
        case TypographyVariant.h4:
          return appType === "mobile"
            ? "font-medium text-base leading-3"
            : "font-medium text-xl leading-5";
        case TypographyVariant.body1:
          return appType === "mobile"
            ? "font-normal text-base leading-4 "
            : "text-lg leading-5 font-normal";
        case TypographyVariant.body2:
          return appType === "mobile"
            ? "font-normal text-sm leading-3"
            : "font-normal text-base leading-4";
        case TypographyVariant.body3:
          return appType === "mobile"
            ? "font-normal text-xs leading-base"
            : "font-normal text-sm leading-3";
        case TypographyVariant.buttonNormal:
          return "font-normal text-lg leading-4 uppercase";
        case TypographyVariant.buttonMedium:
          return "font-normal text-base leading-3 uppercase";
        case TypographyVariant.buttonSmall:
          return "font-normal text-sm leading-base uppercase";
        case TypographyVariant.text1Regular:
          return "font-normal text-base leading-4";
        case TypographyVariant.text1Medium:
          return "font-medium text-base leading-4";
        case TypographyVariant.text2Regular:
          return "font-normal text-xs leading-base ";
        case TypographyVariant.text2Medium:
          return "font-medium text-xs leading-base";
        case TypographyVariant.messageTextLarge:
          return appType === "mobile"
            ? "font-normal text-lg leading-4"
            : "font-normal text-lg leading-5";
        case TypographyVariant.messageTextDefault:
          return appType === "mobile"
            ? "font-normal text-base leading-3 "
            : "font-normal text-base leading-4";
        case TypographyVariant.timestamp:
          return "font-normal text-xs leading-5";
        default:
          return "";
      }
    },
    [app]
  );

  const getVariant = useMemo(() => {
    const variantClassName = getClassName(variant);
    const headers = ["h1", "h2", "h3", "h4"];
    if (variantClassName) {
      const tag = headers.includes(variant) ? variant : "span";
      return { tag, className: twMerge(variantClassName, className) };
    }
    return { tag: "span", className };
  }, [variant, className, getClassName]);
  const choosenVariant = useMemo(() => getVariant, [getVariant]);
  return React.createElement(
    choosenVariant.tag,
    { className: choosenVariant.className },
    children
  );
};

const Text = React.memo(TextComponent);

export { Text };
