import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RootStateWithPagination,
  addItems as addItemsAction,
  deleteItem as deleteItemAction,
  resetItems,
  selectPaginationIsLoading,
  selectPaginationItems,
  selectPaginationLastPageState,
  setIsLoadingState as setIsLoadingStateAction,
  setLastPageState as setLastPageStateAction,
  updateItem as updateItemAction,
  bumpItem as bumpItemAction,
  setInitState as setInitStateAction,
  selectPaginationIsInit,
} from "./paginationSlice";
import { PaginationItem } from "./types";

const usePagination = <T, S>(paginationId: string) => {
  const dispatch = useDispatch();

  const items: PaginationItem<T>[] = useSelector(
    (state: RootStateWithPagination) =>
      selectPaginationItems(state, paginationId)
  );

  const isLoading = useSelector((state: RootStateWithPagination) =>
    selectPaginationIsLoading(state, paginationId)
  );

  const isInitialized = useSelector((state: RootStateWithPagination) =>
    selectPaginationIsInit(state, paginationId)
  );

  const lastPageState: S = useSelector(
    (state: RootStateWithPagination) =>
      selectPaginationLastPageState(state, paginationId) as S
  );

  const addItems = useCallback(
    (itemsToAdd: PaginationItem<T>[], position?: "start" | "end") => {
      dispatch(addItemsAction({ paginationId, items: itemsToAdd, position }));
    },
    [dispatch, paginationId]
  );

  const updateItem = useCallback(
    (item: PaginationItem<T>) => {
      dispatch(updateItemAction({ paginationId, item }));
    },
    [dispatch, paginationId]
  );

  const deleteItem = useCallback(
    ({ itemId }: { itemId: string }) => {
      dispatch(deleteItemAction({ paginationId, itemId }));
    },
    [dispatch, paginationId]
  );

  const setIsLoadingState = useCallback(
    (isLoadingToSet: boolean) => {
      dispatch(
        setIsLoadingStateAction({ paginationId, isLoading: isLoadingToSet })
      );
    },
    [dispatch, paginationId]
  );

  const setLastPageState = useCallback(
    (pageState: S) => {
      dispatch(setLastPageStateAction({ paginationId, pageState }));
    },
    [dispatch, paginationId]
  );

  const reset = useCallback(() => {
    dispatch(setLastPageStateAction({ paginationId, pageState: undefined }));
    dispatch(resetItems({ paginationId }));
  }, [dispatch, paginationId]);

  const bumpItem = useCallback(
    (itemId: string) => {
      dispatch(bumpItemAction({ paginationId, itemId }));
    },
    [dispatch, paginationId]
  );

  const setInitState = useCallback(
    (initState: boolean) => {
      dispatch(setInitStateAction({ paginationId, initState }));
    },
    [dispatch, paginationId]
  );

  return {
    items,
    addItems,
    lastPageState,
    setLastPageState,
    isLoading,
    setIsLoadingState,
    reset,
    deleteItem,
    updateItem,
    bumpItem,
    isInitialized,
    setInitState,
  };
};

export default usePagination;
