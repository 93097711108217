import React, { useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { FirebaseContext } from "@web-src/features/app/providers/Firebase";
import { useDispatch } from "react-redux";

const AppInitializer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { initialized } = useEntity();
  const { notifications$, broadcastChannel } = useContext(FirebaseContext);
  const dispatch = useDispatch();
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // TODO:
    const resetData = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        timeout.current = null;
      }, 1000);
    };
    broadcastChannel?.addEventListener("message", () => {
      resetData();
    });
    const subscription = notifications$?.subscribe(() => {
      resetData();
    });
    //
    return () => subscription?.unsubscribe();
  }, [notifications$, dispatch, broadcastChannel]);
  if (!initialized) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return <>{children}</>;
};

export default AppInitializer;
