import { useEffect, useMemo, useState } from "react";
import {
  useEntitiesListQuery,
  useEntityUsersAllQuery,
} from "@web-src/features/entities/entitiesApi";
import { EntityUser } from "@web-src/features/entities/types";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import uniqBy from "lodash/uniqBy";
import { entityIdStorageKey } from "../utils";
import { setEntity, selectEntity } from "../appSlice";

const useEntity = () => {
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState<boolean>(false);
  const params = useParams();
  const { entityId: paramsEntityId } = params;
  const selectedEntity = useSelector(selectEntity);
  const activeEntityId = paramsEntityId || selectedEntity?.id;
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(selectAuthState);
  const { data, isLoading } = useEntitiesListQuery(undefined, {
    skip: !isAuthenticated,
  });
  const { data: participants, isLoading: participantsIsLoading } =
    useEntityUsersAllQuery(
      { entityId: activeEntityId },
      { skip: !activeEntityId }
    );
  const participantsMap = useMemo(() => {
    const result: { [key: string]: EntityUser } = {};
    participants?.forEach((item) => {
      result[item.id] = item;
    });
    return result;
  }, [participants]);
  const list = data?.data;
  const active = activeEntityId
    ? list?.find(({ id }) => id === activeEntityId)
    : undefined;

  useEffect(() => {
    if (active && selectedEntity?.id !== active.id) {
      dispatch(setEntity(active));
    }
  }, [active, dispatch, selectedEntity]);

  useEffect(() => {
    if (active) {
      if (!initialized) {
        setInitialized(true);
      }
      return;
    }
    if (!list) {
      return;
    }
    const savedSelectedEntityId = localStorage.getItem(entityIdStorageKey);
    if (!savedSelectedEntityId) {
      setInitialized(true);
      return;
    }
    const entity = list.find((e) => e.id === savedSelectedEntityId);
    if (entity) {
      dispatch(setEntity(entity));
    }
    setInitialized(true);
  }, [list, initialized, dispatch, active]);
  const navigateFunc = (path: string, forceNonEntity: boolean) => {
    navigate(
      `${!forceNonEntity && active?.id ? `/${active?.id}` : ""}/${path}`
    );
  };
  return {
    participants: participants ? uniqBy(participants, "id") : [],
    participantsMap,
    participantsIsLoading,
    initialized,
    list,
    // TODO: deprecated, to remove
    active,
    entity: active,
    isLoading,
    navigate: navigateFunc,
    select: (id: string) => {
      const activeEntity = list?.find((e) => e.id === id);
      if (activeEntity) {
        dispatch(setEntity(activeEntity));
        let path = "chats";
        if (active?.role === "admin" && active?.type === "edu") {
          path = "edu-admin";
        }
        navigate(`/${activeEntity?.id}/${path}`);
      }
    },
  };
};

export default useEntity;
