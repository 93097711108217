import { useRef, useEffect } from "react";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import generator from "generate-password-browser";
import mime from "mime";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries = require("countries-phone-masks");

// TODO: refactor to typescript

export function parseSearchQuery(queryString) {
  const query = {};
  const pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  pairs.forEach((p) => {
    const pair = p.split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  });
  return query;
}

export const cssSafeString = (name) =>
  name.replace(/[^a-z0-9]/g, (s) => {
    const c = s.charCodeAt(0);
    if (c === 32) return "-";
    if (c >= 65 && c <= 90) return `_${s.toLowerCase()}`;
    return `__${`000${c.toString(16)}`.slice(-4)}`;
  });

export function serializeSearchParams(obj) {
  if (!obj) {
    return "";
  }
  const str = [];
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    if (key && obj[key] !== undefined) {
      str.push(
        `${encodeURIComponent(key).replace(/%2C/, ",")}=${encodeURIComponent(
          obj[key]
        ).replace(/%2C/, ",")}`
      );
    }
  });
  return str.join("&");
}

export function trimString(str, maxLength) {
  if (str.length > maxLength) {
    return `${str.substr(0, maxLength)}...`;
  }
  return str;
}

export function isoToLocalDate(str) {
  return utcToZonedTime(
    new Date(str?.[str.length - 1] !== "Z" ? `${str}Z` : str),
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
}

export function isElementScrolledTop($el, offset = 0) {
  return $el.scrollTop <= offset;
}

export function isElementScrolledBottom($el, offset = 0) {
  return (
    Math.ceil($el.scrollTop) >=
    Math.ceil($el.scrollHeight - $el.offsetHeight) - offset
  );
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const getDisplayName = (member) =>
  member.first_name || member.last_name
    ? `${member.first_name || ""} ${member.last_name || ""}`
    : "unknown";

export const generatePassword = (options = {}) =>
  generator.generate({
    length: 10,
    numbers: true,
    ...options,
  });

export const isUrl = (str) => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const isUrlImage = (str) =>
  isUrl(str) && str.match(/\.(jpeg|jpg|gif|png)$/) != null;

export function isImageUrl(url) {
  return [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".tiff", ".svg"].find(
    (type) => url?.toLowerCase().endsWith(type)
  );
}
export function isAudioUrl(url) {
  return mime.getType(url).startsWith("audio");
}
export function isImageType(contentType) {
  return contentType?.startsWith("image");
}
export function isVideoUrl(url) {
  return [".mp4", ".webm"].find((type) => url?.toLowerCase().endsWith(type));
}

export function isPdfUrl(url) {
  return [".pdf"].find((type) => url?.toLowerCase().endsWith(type));
}

export function isDocUrl(url) {
  return [".docx", ".doc", ".xls"].find((type) =>
    url?.toLowerCase().endsWith(type)
  );
}

export function getFileNameFromUrl(url) {
  const encodedName = url?.split("/").pop();
  try {
    return decodeURIComponent(encodedName);
  } catch {
    return encodedName;
  }
}

export function getFileTypeByUrl(url) {
  if (isImageUrl(url)) {
    return "image";
  }
  if (isVideoUrl(url)) {
    return "video";
  }
  if (isPdfUrl(url)) {
    return "pdf";
  }
  if (isDocUrl(url)) {
    return "doc";
  }
  if (isUrl(url)) {
    return "link";
  }
  return "file";
}

export const getUrlFromText = (text) =>
  text
    ? text.match(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
      )?.[0] || null
    : null;

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return email.match(re);
};

export const urlToFile = (url) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "blob";
    request.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = () => {
        reject();
      };
    };
    request.onerror = () => {
      reject();
    };
    request.send();
  });

export const fileToUrl = (input) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(input);
  });

export const escapeHtml = (html) => {
  const text = document.createTextNode(html);
  const p = document.createElement("p");
  p.appendChild(text);
  return p.innerHTML;
};

export const linkify = (inputText) => {
  // URLs starting with http://, https://, or ftp://
  const replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  let replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  // Change email addresses to mailto:: links.
  const replacePattern3 =
    /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
};

export const getUrlExtension = (url) =>
  url.split(/[#?]/)[0].split(".").pop().trim();

export const useMemoCompare = (next, compare) => {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
};

export const getCountryMaskByPhone = (phone) => {
  if (phone?.indexOf("+91") === 0) {
    return countries.find((item) => item.iso === "IN");
  }
  if (phone?.indexOf("+1") === 0) {
    return countries.find((item) => item.iso === "US");
  }
  return countries.find((item) => phone?.indexOf(item.code) === 0);
};
