import { useSwipe } from "@jugl-web/utils";
import { PlainButton } from "../../cross-platform/PlainButton";
import { Text } from "../../cross-platform/Text";
import { ReactComponent as CloseIcon } from "./icons/close.svg";

export interface SwipeableDrawerHeaderProps {
  title: string;
  onClose: () => void;
  onSwipeUp?: () => void;
}

export const SwipeableDrawerHeader = ({
  title,
  onClose,
  onSwipeUp,
}: SwipeableDrawerHeaderProps) => {
  const { touchProps } = useSwipe({
    onSwipeDown: onClose,
    onSwipeUp,
  });

  return (
    <div className="flex items-center justify-between px-4 pt-[30px] pb-5">
      <div className="absolute inset-x-0 top-0 h-10" {...touchProps}>
        <div className="absolute left-1/2 top-4 h-1 w-14 -translate-x-1/2 rounded-sm bg-[#F2F2F2]" />
      </div>
      <Text app="mobile" variant="h3" className="m-0">
        {title}
      </Text>
      <PlainButton onClick={onClose} className="relative">
        <CloseIcon />
      </PlainButton>
    </div>
  );
};
