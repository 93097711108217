import { emptySplitApi } from "@web-src/features/api/createApi";
import {
  PaginatedResponse,
  PaginatedListParams,
} from "@web-src/features/api/types";
import { fetchAllPages } from "@web-src/features/api/helpers";
import { getAppType } from "@web-src/features/app/utils";
import { serializeSearchParams } from "@web-src/utils/helper";
import {
  Entity,
  EntityProp,
  EntityPropType,
  EntitySpace,
  EntitySpaceType,
  EntityUser,
  EntityUpdate,
  EntityUsersUpdate,
} from "./types";

export interface CreateUserPyload {
  role: string;
  space: string[];
  username: string;
  email?: string;
  mobile?: string;
  pass?: string;
  info: {
    mobile?: string;
    email?: string;
  };
}

const apiWithTags = emptySplitApi.enhanceEndpoints({
  addTagTypes: [
    "EntityList",
    "EntityUsersList",
    "EntityUsersListAll",
    "EntityUsersSearch",
    "EntityProps",
    "EntitySpaces",
  ],
});

export const entitiesApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getWorkspace: build.query<
      {
        configs: {
          allow_link_invites: boolean;
        };
        created_at: string | null;
        created_by: string | null;
        description: string;
        display_picture: string | null;
        entity_id: string;
        id: string;
        invite_code: string;
        title: string;
        xmpp_muc_host: string;
      },
      { workspaceId: string; entityId: string }
    >({
      query: ({ workspaceId, entityId }) => ({
        url: `/api/auth/entity/${entityId}/workspaces/${workspaceId}`,
      }),
    }),
    entitiesList: build.query<PaginatedResponse<Entity>, void>({
      query: () => ({
        url: `/api/auth/entity/list?type=${getAppType()}`,
      }),
      providesTags: ["EntityList"],
    }),
    entityUsers: build.query<
      PaginatedResponse<EntityUser>,
      PaginatedListParams<{
        entityId: string;
        spaces?: string;
        sort_by?: string;
      }>
    >({
      query: ({ entityId, ...params }) => ({
        url: `/api/auth/entity/${entityId}/users?${serializeSearchParams({
          status: "active",
          ...params,
        })}`,
      }),
      providesTags: ["EntityUsersList"],
    }),
    entityUsersAll: build.query<EntityUser[], { entityId: string }>({
      queryFn: async ({ entityId }, _queryApi, _extraOptions, baseQuery) => {
        // NOTE: load all pages
        let mergedUsers: EntityUser[] = [];
        const resp = await baseQuery(
          `/api/auth/entity/${entityId}/users?page_size=50&status=active`
        );
        mergedUsers = [...resp.data.data];
        const totalPages = resp.data.total_pages;
        let nextPage = 2;
        const extraQueries = [];
        while (nextPage <= totalPages) {
          extraQueries.push(
            serializeSearchParams({
              page: nextPage,
              page_size: 50,
            })
          );
          nextPage += 1;
        }
        const extraResponses = await Promise.all(
          extraQueries.map((query) =>
            baseQuery(`/api/auth/entity/${entityId}/users?${query}`)
          )
        );
        extraResponses.forEach((extraResp) => {
          mergedUsers = [...mergedUsers, ...extraResp.data.data];
        });
        return {
          data: mergedUsers,
        };
      },
      providesTags: ["EntityUsersList"],
    }),
    entityUsersSearch: build.query<
      PaginatedResponse<EntityUser>,
      { entityId: string; search_term: string; type: EntitySpaceType }
    >({
      query: ({ entityId, ...params }) => ({
        url: `/api/auth/entity/${entityId}/search?${serializeSearchParams({
          ...params,
        })}`,
      }),
      providesTags: ["EntityUsersSearch"],
    }),
    entityUsersCreate: build.mutation<
      EntityUser[],
      { entityId: string; data: { users: CreateUserPyload[] } }
    >({
      query: ({ data, entityId }) => ({
        url: `/api/auth/entity/${entityId}/users`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["EntityUsersList", "EntityUsersListAll"],
    }),
    entityUsersUpdate: build.mutation<
      EntityUser[],
      {
        entityId: string;
        data: { users: EntityUsersUpdate[] };
      }
    >({
      query: ({ data, entityId }) => ({
        url: `/api/auth/entity/${entityId}/users`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["EntityUsersList"],
    }),
    entityUsersRemove: build.mutation<
      void,
      { entityId: string; data: { users: { user_id: string }[] } }
    >({
      query: ({ data, entityId }) => ({
        url: `/api/auth/entity/${entityId}/users`,
        method: "DELETE",
        data,
      }),
      invalidatesTags: ["EntityUsersList"],
    }),
    entityPropsList: build.query<
      { propId: string; list: EntityProp[] },
      { entityId: string; type: EntityPropType }
    >({
      query: ({ type, entityId }) => ({
        url: `/api/auth/entity/${entityId}/props?type=${type}`,
      }),
      transformResponse: (resp) => ({
        propId: resp.id,
        list: resp.props.data,
      }),
      providesTags: (e1, e2, { entityId, type }) => [
        { type: "EntityProps", id: `${type}-${entityId}` },
      ],
    }),
    createEntityProp: build.mutation<
      void,
      {
        entityId: string;
        type: EntityPropType;
        propId?: string;
        data: { value: string };
      }
    >({
      query: ({ data: { value }, entityId, type, propId }) => ({
        url: `/api/auth/entity/${entityId}/props${propId ? `/${propId}` : ""}`,
        method: propId ? "PUT" : "POST",
        data: {
          type,
          props: propId
            ? [
                {
                  value,
                },
              ]
            : {
                value: [value],
              },
        },
      }),
      invalidatesTags: (e1, e2, { entityId, type }) => [
        { type: "EntityProps", id: `${type}-${entityId}` },
      ],
    }),
    updateEntityProp: build.mutation<
      void,
      {
        entityId: string;
        id: string;
        propId: string;
        type: EntityPropType;
        data: { value: string };
      }
    >({
      query: ({ data: { value }, type, entityId, propId, id }) => ({
        url: `/api/auth/entity/${entityId}/props/${propId}`,
        method: "PUT",
        data: {
          type,
          props: [{ id, value }],
        },
      }),
      invalidatesTags: (e1, e2, { entityId, type }) => [
        { type: "EntityProps", id: `${type}-${entityId}` },
      ],
    }),
    deleteEntityProp: build.mutation<
      void,
      { entityId: string; propId: string; type: EntityPropType; ids: string[] }
    >({
      query: ({ propId, entityId, ids, type }) => ({
        url: `/api/auth/entity/${entityId}/props/${propId}`,
        method: "PUT",
        data: {
          type,
          props: ids.map((id) => ({ id })),
        },
      }),
      invalidatesTags: (e1, e2, { entityId, type }) => [
        { type: "EntityProps", id: `${type}-${entityId}` },
      ],
    }),
    entityAllSpacesList: build.query<
      EntitySpace[],
      { entityId: string; type: EntitySpaceType; user_id?: string }
    >({
      queryFn: async ({ entityId, type, user_id }) => ({
        data: await fetchAllPages<EntitySpace>(
          `/api/auth/entity/${entityId}/spaces?type=${type}${
            user_id ? `&user_id=${user_id}` : ""
          }`
        ),
      }),
      providesTags: (e1, e2, { entityId, type }) => [
        { type: "EntitySpaces", id: `${type}-${entityId}` },
      ],
    }),
    entityCreateAttachment: build.mutation<
      void,
      { type: "display_picture" | "banner_image"; file: File; entityId: string }
    >({
      query({ type, file, entityId }) {
        const binData = new FormData();
        binData.append("type", type);
        binData.append("file", file);
        return {
          url: `/api/auth/entity/${entityId}/attachment`,
          method: "POST",
          data: binData,
        };
      },
      invalidatesTags: ["EntityList"],
    }),
    entityEditAttachment: build.mutation<
      void,
      {
        type: "display_picture" | "banner_image";
        file: File;
        entityId: string;
        attachmentId: string;
      }
    >({
      query({ type, file, attachmentId, entityId }) {
        const binData = new FormData();
        binData.append("type", type);
        binData.append("file", file);
        return {
          url: `/api/auth/entity/${entityId}/attachment/${attachmentId}`,
          method: "PUT",
          data: binData,
        };
      },
      invalidatesTags: ["EntityList"],
    }),
    entityCreateOrganization: build.mutation<
      Entity,
      {
        data: EntityUpdate;
        displayImg?: File;
        bannerImg?: File;
      }
    >({
      query: ({ data, displayImg, bannerImg }) => {
        const binData = new FormData();

        binData.append("entity", JSON.stringify(data));
        if (displayImg) {
          binData.append("display_pic_file", displayImg);
        }
        if (bannerImg) {
          binData.append("banner_img_file", bannerImg);
        }

        return {
          url: `/api/auth/entity`,
          method: "POST",
          data: binData,
        };
      },
      invalidatesTags: ["EntityList"],
    }),
    entityEditOrganization: build.mutation<
      Entity,
      {
        data: EntityUpdate;
        id: string;
        displayImg?: File;
        bannerImg?: File;
      }
    >({
      query: ({ data, id, displayImg, bannerImg }) => {
        // TODO: edit not working
        const binData = new FormData();

        binData.append("entity", JSON.stringify(data));
        if (displayImg) {
          binData.append("display_pic_file", displayImg);
        }
        if (bannerImg) {
          binData.append("banner_img_file", bannerImg);
        }

        return {
          url: `/api/auth/entity/${id}`,
          method: "PUT",
          data: binData,
        };
      },
      invalidatesTags: ["EntityList"],
    }),
    entityDeleteOrganization: build.mutation<Entity, { id: string }>({
      query: ({ id }) => ({
        url: `/api/auth/entity/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EntityList"],
    }),
  }),
});

export const {
  useEntitiesListQuery,
  useEntityUsersQuery,
  useEntityUsersAllQuery,
  useEntityUsersSearchQuery,
  useEntityUsersCreateMutation,
  useEntityUsersUpdateMutation,
  useEntityUsersRemoveMutation,
  useEntityPropsListQuery,
  useEntityAllSpacesListQuery,
  useCreateEntityPropMutation,
  useUpdateEntityPropMutation,
  useDeleteEntityPropMutation,
  useEntityCreateOrganizationMutation,
  useEntityEditOrganizationMutation,
  useEntityEditAttachmentMutation,
  useEntityCreateAttachmentMutation,
  useEntityDeleteOrganizationMutation,
  useLazyGetWorkspaceQuery,
} = entitiesApi;
