export type AppVariant = "web" | "mobile";

// TODO: Move to domain-resources
export type EduComment = {
  attachments?: [
    {
      file_path: string;
    }
  ];
  content: string | null;
  created_by: {
    user_id: string;
    username: string;
  };
  id: string;
  inserted_at: string;
};

// TODO: Move to domain-resources
export enum EntityParticipantRole {
  admin = "admin",
  member = "member",
  teacher = "teacher",
  student = "student",
}
