import { PaginatedResponse, RtkEmptySplitApi } from "../../types";
import { EduComment, EduCommentModule } from "./types";

export const addEduCommentsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [],
  });

  const eduCommentsApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      createEduComment: build.mutation<
        { data: EduComment[] },
        {
          entityId: string;
          objectId: string;
          module: EduCommentModule;
          data: { content?: string; attachment?: File };
        }
      >({
        query: ({
          entityId,
          objectId,
          module,
          data: { content, attachment },
        }) => {
          const formData = new FormData();
          if (content) {
            formData.append("content", content);
          }
          if (attachment) {
            let attachmentToSend = attachment;
            if (attachment.name.includes("#")) {
              const blob = attachment.slice(
                0,
                attachment.size,
                attachment.type
              );
              attachmentToSend = new File(
                [blob],
                attachment.name.replaceAll("#", ""),
                { type: attachment.type }
              );
            }
            formData.append("files[]", attachmentToSend);
          }
          return {
            url: `/api/auth/entity/${entityId}/edu/${module}/${objectId}/comment`,
            method: "POST",
            data: formData,
          };
        },
      }),
      deleteEduComment: build.mutation<
        void,
        {
          entityId: string;
          objectId: string;
          module: EduCommentModule;
          commentId: string;
        }
      >({
        query: ({ entityId, objectId, module, commentId }) => ({
          url: `/api/auth/entity/${entityId}/edu/${module}/${objectId}/comment/${commentId}`,
          method: "DELETE",
        }),
      }),
      listEduComments: build.query<
        PaginatedResponse<EduComment>,
        {
          entityId: string;
          objectId: string;
          module: EduCommentModule;
          params: {
            page: number;
            page_size: number;
          };
        }
      >({
        query: ({ entityId, objectId, module, params }) => ({
          url: `/api/auth/entity/${entityId}/edu/${module}/${objectId}/comment`,
          params,
        }),
      }),
      getComment: build.query<
        {
          data: EduComment[];
        },
        {
          entityId: string;
          objectId: string;
          commentId: string;
          module: EduCommentModule;
        }
      >({
        query: ({ entityId, objectId, module, commentId }) => ({
          url: `/api/auth/entity/${entityId}/edu/${module}/${objectId}/comment/${commentId}`,
        }),
      }),
    }),
  });

  const {
    useCreateEduCommentMutation,
    useDeleteEduCommentMutation,
    useLazyListEduCommentsQuery,
    useListEduCommentsQuery,
    useGetCommentQuery,
    useLazyGetCommentQuery,
  } = eduCommentsApi;
  return {
    useCreateEduCommentMutation,
    useDeleteEduCommentMutation,
    useLazyListEduCommentsQuery,
    useListEduCommentsQuery,
    useGetCommentQuery,
    useLazyGetCommentQuery,
    api: eduCommentsApi,
  };
};

export type EduCommentsApi = ReturnType<typeof addEduCommentsApi>;
