import {
  flip,
  offset,
  Placement,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import { Menu as HeadlessUIMenu, Portal } from "@headlessui/react";
import { cx } from "@jugl-web/utils";
import { Fragment, MouseEvent, Ref } from "react";
import { Checkbox } from "../Checkbox";
import { PlainButton } from "../PlainButton";

interface RenderTriggerProps {
  Trigger: (typeof HeadlessUIMenu)["Button"];
  triggerRef: Ref<HTMLButtonElement>;
  isOpen: boolean;
}

export type MenuItem<TValue = unknown> = {
  id: string;
  label: string;
  value?: TValue;
  icon?: JSX.Element;
  isHidden?: boolean;
  isSelected?: boolean;
  asCheckbox?: boolean;
  secondaryButton?: {
    icon: JSX.Element;
    onClick: () => void;
  };
  onSelect?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export type MenuItemSections<TValue = unknown> = MenuItem<TValue>[][];

export type MenuProps<TValue = unknown> = {
  placement: Placement;
  sections: MenuItemSections<TValue>;
  selectedId?: string;
  className?: string;
  renderTrigger: (props: RenderTriggerProps) => JSX.Element;
  onSelect?: (
    item: MenuItem<TValue>,
    event: MouseEvent<HTMLButtonElement>
  ) => void;
};

export function Menu<TValue>({
  placement,
  sections,
  selectedId,
  className,
  renderTrigger,
  onSelect,
}: MenuProps<TValue>) {
  const { refs, floatingStyles } = useFloating<HTMLButtonElement>({
    placement,
    middleware: [flip(), shift(), offset({ mainAxis: 4 })],
  });

  return (
    <HeadlessUIMenu as="div">
      {({ open }) => (
        <>
          {renderTrigger({
            Trigger: HeadlessUIMenu.Button,
            triggerRef: refs.setReference,
            isOpen: open,
          })}
          <Portal>
            <HeadlessUIMenu.Items
              as="div"
              ref={refs.setFloating}
              className={cx(
                "absolute z-50 max-h-[300px] min-w-[200px] overflow-hidden overflow-y-auto rounded-xl bg-white outline-none",
                className
              )}
              style={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.12)",
                ...floatingStyles,
              }}
            >
              {sections.map((section, index) => {
                const hasNextSection = !!sections[index + 1];

                return (
                  <Fragment key={+index}>
                    {section.map((item) => {
                      if (item.isHidden) {
                        return null;
                      }

                      return (
                        <HeadlessUIMenu.Item key={item.id}>
                          {({ active }) => {
                            const isSelected =
                              selectedId === item.id || !!item.isSelected;

                            return (
                              <div
                                className={cx("flex items-center transition", {
                                  "bg-grey-100": active,
                                  "bg-grey-200 font-semibold":
                                    isSelected && !item.asCheckbox,
                                })}
                              >
                                <PlainButton
                                  onClick={(event) => {
                                    if (item.asCheckbox) {
                                      event.preventDefault();
                                    }

                                    onSelect?.(item, event);
                                    item.onSelect?.(event);
                                  }}
                                  className="flex h-full w-full items-center gap-2.5 px-4 py-3"
                                >
                                  {item.icon && (
                                    <span className="shrink-0">
                                      {item.icon}
                                    </span>
                                  )}
                                  <span className="text-dark grow text-left text-sm leading-[16px]">
                                    {item.label}
                                  </span>
                                  {item.asCheckbox && (
                                    <Checkbox
                                      readOnly
                                      isChecked={isSelected}
                                      className="m-0"
                                    />
                                  )}
                                </PlainButton>
                                {item.secondaryButton && (
                                  <PlainButton
                                    className="h-[40px] shrink-0 px-3"
                                    onClick={item.secondaryButton.onClick}
                                  >
                                    {item.secondaryButton.icon}
                                  </PlainButton>
                                )}
                              </div>
                            );
                          }}
                        </HeadlessUIMenu.Item>
                      );
                    })}
                    {hasNextSection && (
                      <div key={+index} className="bg-grey-200 h-px" />
                    )}
                  </Fragment>
                );
              })}
            </HeadlessUIMenu.Items>
          </Portal>
        </>
      )}
    </HeadlessUIMenu>
  );
}
