import { useCallback } from "react";
import {
  ChatItem,
  ChatMessage,
  ChatMessagePayloadAction,
  ChatMessageType,
} from "@web-src/features/chats/types";
import {
  workspacesApi,
  WorkspacesApiTags,
} from "@web-src/features/workspaces/workspacesApi";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { usePaginationUpdate } from "@jugl-web/utils";
import { useLazyGetWorkspaceQuery } from "@web-src/features/entities/entitiesApi";
import useEntity from "@web-src/features/app/hooks/useEntity";

const useGroupInfoMessageHandler = () => {
  const meJid = useSelector(selectUserId);
  const dispatch = useDispatch();
  const { updateItem } = usePaginationUpdate<Partial<ChatItem>>();
  const { entity } = useEntity();
  const [loadWorkspace] = useLazyGetWorkspaceQuery();
  const handler = useCallback(
    async (message: ChatMessage) => {
      if (
        message.type === ChatMessageType.muc &&
        message.payload?.action === "updated" &&
        entity
      ) {
        const response = await loadWorkspace({
          entityId: entity.id,
          workspaceId: message.to,
        });
        if (response?.data) {
          updateItem({
            paginationId: `conversations:${entity.id}`,
            item: {
              id: message.to,
              data: {
                title: response.data.title,
                img: response.data.display_picture,
              },
            },
            merge: true,
          });
        }
      }
      if (
        message.type === ChatMessageType.muc &&
        message.payload?.action &&
        (([
          ChatMessagePayloadAction.added,
          ChatMessagePayloadAction.removed,
          ChatMessagePayloadAction.left,
        ].includes(message.payload?.action) &&
          message.payload?.action_for !== meJid) ||
          ChatMessagePayloadAction.deleted)
      ) {
        dispatch(
          workspacesApi.util.invalidateTags([
            {
              type: WorkspacesApiTags.workspaceParticipantsAll,
              id: `${message.to}-${message.entity_id}`,
            },
          ])
        );
      }
    },
    [entity, meJid, loadWorkspace, updateItem, dispatch]
  );
  return handler;
};

export default useGroupInfoMessageHandler;
