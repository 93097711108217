import { environment } from "@web-src/environments/environment";

enum LogLevel {
  debug = "debug",
  info = "info",
  warn = "warn",
  error = "error",
}

const loggerFunc = (
  level: LogLevel = LogLevel.info,
  ...msg: Parameters<typeof console.log>[0]
) => {
  if (
    environment.production &&
    !window.location.hostname.includes("netlify.app") &&
    !window.location.hostname.includes("localhost")
  ) {
    return;
  }
  // TODO: sentry for error
  // eslint-disable-next-line no-console
  console[level](...msg);
};

export const logger = {
  debug: loggerFunc.bind(null, LogLevel.debug),
  info: loggerFunc.bind(null, LogLevel.info),
  warn: loggerFunc.bind(null, LogLevel.warn),
  error: loggerFunc.bind(null, LogLevel.error),
};
