import { AuthData } from "./types";

const TOKEN_KEY = "WEB:JWT_DATA";

export const removeJwtToken = () => localStorage.removeItem(TOKEN_KEY);

const parseJwt = (token: string): AuthData["data"] | null => {
  if (token) {
    return JSON.parse(
      decodeURIComponent(window.escape(window.atob(token.split(".")[1])))
    );
  }
  return null;
};

export const isTokenExpired = (token: string) => {
  const decodedJwt = parseJwt(token);
  if (!decodedJwt?.exp) return false;
  const ms = new Date().getTime();
  return ms > decodedJwt.exp;
};

export const setJwtToken = (
  accessToken: string,
  refreshToken: string
): AuthData | null => {
  const data = parseJwt(accessToken);
  if (!data) {
    return null;
  }
  const dataToSave = {
    accessToken,
    refreshToken,
    data,
  };
  localStorage.setItem(TOKEN_KEY, JSON.stringify(dataToSave));
  return dataToSave;
};

export const getJwtToken = (): AuthData | null => {
  const savedData = localStorage.getItem(TOKEN_KEY);
  if (!savedData) {
    return null;
  }
  try {
    return JSON.parse(savedData);
  } catch (error) {
    removeJwtToken();
    return null;
  }
};
