import React, { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import { useGetProfileQuery } from "@web-src/features/auth/authApi";
import { useUpdateProfileMutation } from "@web-src/features/settings/settingsApi";
import { Profile } from "@web-src/features/auth/types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SettingsPaddingSize } from "@web-src/utils/layout";
import { useEnqueueSnackbar } from "@web-src/features/settings/hooks/useSnackbar";
import { useTranslations } from "@jugl-web/utils";
import FormSpinner from "../FormSpinner";

type Inputs = Pick<Profile["general"], "first_name" | "last_name">;

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  gender: yup.string(),
  birth: yup.date(),
});

const PersonalInfoFormContainer = memo(() => {
  const { data } = useGetProfileQuery();
  return <PersonalInfoFormComponent {...{ data }} />;
});

const PersonalInfoFormComponent: React.FC<{
  data: Profile | undefined;
}> = ({ data }) => (
  <Box sx={{ width: "50%", padding: "40px" }}>
    {!data ? <FormSpinner /> : <PersonalInfoForm {...{ data }} />}
  </Box>
);

const PersonalInfoForm: React.FC<{ data: Profile }> = ({ data }) => {
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: data.general.first_name,
      last_name: data.general.last_name,
    },
  });
  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const { first_name: firstName, last_name: lastName } = { ...params };
    const res = await updateProfile({
      user_id: data?.general.id,
      data: {
        type: "general",
        first_name: firstName,
        last_name: lastName,
      },
    });
    if ("data" in res) {
      reset({ first_name: firstName, last_name: lastName });
      showProfileUpdatedSnackbar();
    }
  };
  const { t } = useTranslations();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "70px",
          marginBottom: "50px",
          position: "relative",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <InputLabel>
            <Typography
              sx={{
                fontSize: "12px",
                color: "black",
                textTransform: "uppercase",
              }}
              component="span"
            >
              {t({
                id: "form-controls.first-name.label",
                defaultMessage: "First Name",
              })}
            </Typography>{" "}
            <Typography sx={{ color: "red" }} component="span">
              *
            </Typography>
          </InputLabel>
          <TextField
            variant="standard"
            sx={{ width: "100%" }}
            inputProps={{
              style: { fontSize: 16, fontWeight: "500" },
              ...register("first_name"),
            }}
          />
        </Box>
        <Box sx={{ width: "50%" }}>
          <InputLabel>
            <Typography
              sx={{
                fontSize: "12px",
                color: "black",
                textTransform: "uppercase",
              }}
              component="span"
            >
              {t({
                id: "form-controls.last-name.label",
                defaultMessage: "Last Name",
              })}
            </Typography>{" "}
            <Typography sx={{ color: "red" }} component="span">
              *
            </Typography>
          </InputLabel>
          <TextField
            variant="standard"
            sx={{ width: "100%" }}
            inputProps={{
              style: { fontSize: 16, fontWeight: "500" },
              ...register("last_name"),
            }}
          />
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <Button
          disabled={!isValid || !isDirty || isLoading}
          sx={{ width: `calc(100% - ${SettingsPaddingSize}px)` }}
          size="large"
          variant="contained"
          type="submit"
        >
          {t({
            id: "common.save",
            defaultMessage: "Save",
          })}
        </Button>
      </Box>
    </form>
  );
};

export default PersonalInfoFormContainer;
