import { AppVariant, cx } from "@jugl-web/utils";
import format from "date-fns/format";
import { FC, ReactNode } from "react";
import { Avatar } from "../../Avatar";

export interface TaskActivityItemProps {
  variant: AppVariant;
  username: string;
  userImageUrl: string | null;
  message: ReactNode;
  date: Date;
  className?: string;
}

export const TaskActivityItem: FC<TaskActivityItemProps> = ({
  variant,
  username,
  userImageUrl,
  message,
  date,
  className,
}) => {
  const isMobile = variant === "mobile";

  return (
    <div
      className={cx(
        "bg-grey-100 flex",
        {
          "flex-col gap-2 rounded-xl px-2.5 pt-4 pb-2.5": isMobile,
          "items-center justify-between rounded-lg py-2.5 px-6": !isMobile,
        },
        className
      )}
    >
      <div className="flex items-center gap-3">
        <Avatar
          size="md"
          username={username}
          imageUrl={userImageUrl}
          className="shrink-0 border border-solid border-white"
        />
        <p className="text-dark leading-2 m-0 text-sm">{message}</p>
      </div>
      <time className={cx("text-grey-900 text-xs", isMobile && "self-end")}>
        {format(date, "MMM dd, hh:mm a")}
      </time>
    </div>
  );
};
