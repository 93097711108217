import { cx, useUniqueDOMId } from "@jugl-web/utils";
import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from "react";

type NativeCheckboxProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface CheckboxProps
  extends Omit<NativeCheckboxProps, "type" | "checked"> {
  label?: ReactNode;
  labelClassName?: string;
  isChecked?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, labelClassName, isChecked, className, ...props }, ref) => {
    const id = useUniqueDOMId();
    const hasLabel = !!label;

    const checkbox = (
      <input
        ref={ref}
        id={id}
        type="checkbox"
        checked={isChecked}
        className={cx(
          "form-checkbox h-5 w-5 cursor-pointer rounded",
          "focus-visible:outline-primary-300 focus:ring-0 focus:ring-transparent focus:ring-offset-transparent focus-visible:outline focus-visible:outline-2",
          "checked:bg-primary checked:hover:bg-primary checked:focus:bg-primary",
          "border-grey-500 border-1 border-solid",
          className
        )}
        {...props}
      />
    );

    if (hasLabel) {
      return (
        <div className="flex items-center gap-2">
          {checkbox}
          <label htmlFor={id} className={cx("select-none", labelClassName)}>
            {label}
          </label>
        </div>
      );
    }

    return checkbox;
  }
);
