import { cx, useUniqueDOMId } from "@jugl-web/utils";
import { forwardRef } from "react";
import { FormControlLabel } from "../FormControlLabel";
import { Input, InputProps } from "../Input";
import { Text } from "../Text";

export interface TextFieldProps {
  label: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
  className?: string;
  placeholder?: InputProps["placeholder"];
  value?: InputProps["value"];
  onChange?: InputProps["onChange"];
  errorMessage?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    { label, isRequired, isFullWidth, className, errorMessage, ...props },
    ref
  ) => {
    const inputId = useUniqueDOMId();

    return (
      <div className={cx("flex flex-col items-start", className)}>
        <FormControlLabel
          htmlFor={inputId}
          isRequired={isRequired}
          className="ml-2.5"
        >
          {label}
        </FormControlLabel>
        <Input
          ref={ref}
          id={inputId}
          className={cx(isFullWidth && "w-full")}
          {...props}
        />
        {errorMessage && (
          <Text variant="body3" className="text-tertiary">
            {errorMessage}
          </Text>
        )}
      </div>
    );
  }
);
