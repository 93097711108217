import { Fab } from "@jugl-web/ui-components/cross-platform/Fab";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import Avatar from "@web-src/components/Avatar";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { ReactComponent as MicOnIcon } from "@web-src/features/calls/icons/mic-on.svg";
import cx from "classnames";
import React, { useMemo } from "react";
import { getEntityUserDisplayName } from "@web-src/features/entities/utils";

const UserCallWindow: React.FC<{
  elementRatio?: number;
  userId: string | number;
  playerId?: string;
  isVideoMuted?: boolean;
  isAudioMuted: boolean;
  isSpeaking: boolean;
}> = ({
  elementRatio,
  userId,
  playerId,
  isAudioMuted,
  isVideoMuted,
  isSpeaking,
}) => {
  const { participantsMap } = useEntity();
  const isScreenSharing = useMemo(
    () => userId?.toString().includes("screenSharing_"),
    [userId]
  );
  const avatar = useMemo(() => {
    const id = userId?.toString().replace("screenSharing_", "");
    if (participantsMap[id]) {
      return participantsMap[id].profile?.img;
    }
    return "";
  }, [participantsMap, userId]);
  const name = useMemo(() => {
    const id = userId?.toString().replace("screenSharing_", "");
    if (playerId === "localplayer") {
      return "You";
    }
    if (participantsMap[id]) {
      return getEntityUserDisplayName(participantsMap[id]);
    }
    return userId;
  }, [participantsMap, playerId, userId]);

  const avatarName = useMemo(() => {
    const id = userId?.toString().replace("screenSharing_", "");
    if (participantsMap[id]) {
      return getEntityUserDisplayName(participantsMap[id]);
    }
    return userId;
  }, [participantsMap, userId]);

  const bg = useMemo(
    () => ({
      backgroundImage: `url(${avatar})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }),
    [avatar]
  );
  const dynamicBg = avatar && isVideoMuted ? bg : {};
  return (
    <div
      className={cx(
        "relative box-border h-full w-full overflow-hidden rounded-3xl ",
        {
          "border-primary-500 border-2 border-solid": isSpeaking,
        },
        {
          "h-full": !elementRatio,
        }
      )}
      style={{
        paddingBottom: elementRatio
          ? `calc(100% / ${elementRatio})`
          : undefined,
      }}
    >
      <div
        style={dynamicBg}
        className={cx(
          "absolute bottom-0 left-0 top-0 right-0 z-0",
          {
            "blur-xl": isVideoMuted && avatar,
          },
          { "bg-user-call-1": !avatar && isVideoMuted }
        )}
      />
      <div
        className={cx("absolute top-0 left-0 z-10 h-full w-full", {
          hidden: isVideoMuted,
        })}
        id={playerId}
      />
      <div className="absolute flex h-full w-full  place-items-center justify-center">
        <div className="flex h-[auto] w-[30%] place-items-center justify-center">
          <Avatar
            src={avatar}
            alt={avatarName}
            className={cx(
              {
                "border-primary-500 border-2 border-solid": isSpeaking,
              },
              {
                hidden: !isVideoMuted,
              }
            )}
            sx={{
              width: "100%",
              height: "100%",
              aspectRatio: "1 / 1",
            }}
            fallbackTextProps={{
              sx: {
                fontSize: "30px",
              },
            }}
            imgProps={undefined}
            imageIndex={undefined}
          />
        </div>
      </div>
      <Text
        variant="body1"
        className="text-dark absolute bottom-7 right-6 z-20 max-w-xs truncate rounded-sm bg-white/70 p-2 px-3 font-bold"
      >
        {name}
      </Text>
      <div
        className={cx("absolute bottom-5 left-6 z-20 rounded", {
          hidden: isScreenSharing,
        })}
      >
        <Fab
          icon={<MicOnIcon className="fill-white" />}
          className={cx(
            "cursor-default",
            !isAudioMuted && {
              "bg-grey-background": !isSpeaking,
              "bg-primary": isSpeaking,
            },
            {
              "bg-tertiary-400/80": isAudioMuted,
            }
          )}
        />
      </div>
    </div>
  );
};

export default UserCallWindow;
