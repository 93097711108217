import { RtkEmptySplitApi } from "../../types";
import { serializeSearchParams } from "../../utils";

export enum AnalyticsQuery {
  entitiesCreated = "entities_created",
  entitiesDeleted = "entities_deleted",
  noOfUsersInEntity = "no_of_users_in_entity",
  activeUsers = "active_users",
}

export enum AnalyticsModule {
  task = "task",
  chat = "chat",
  locationTracking = "location_tracking",
  attendance = "attendance",
  assignment = "assignment",
  feed = "feed",
  lesson = "lesson",
}

export enum AnalyticsRangeFilter {
  day = "day",
  month = "month",
  quarter = "quarter",
  year = "year",
}

export enum AnalyticsAppType {
  edu = "edu",
  org = "org",
}

export const addAnalyticsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({});
  const analyticsApi = apiWithTags.injectEndpoints({
    endpoints: (build) => ({
      getAnalytics: build.query<
        // TODO: type
        { count: any[] },
        {
          params: {
            from?: string;
            to?: string;
            query: AnalyticsQuery;
            type?: string;
            module?: AnalyticsModule;
            filter?: keyof typeof AnalyticsRangeFilter;
          };
        }
      >({
        query: ({ params }) => {
          const { from, to, ...restParams } = params;
          const updatedParams = {
            ...restParams,
            from: `${from} 00:00`,
            to: `${to} 23:59`,
          };
          return {
            url: `/admin-dashboard/api/?${serializeSearchParams(
              updatedParams
            )}`,
          };
        },
      }),
    }),
  });

  const { useGetAnalyticsQuery } = analyticsApi;

  return {
    useGetAnalyticsQuery,
  };
};
