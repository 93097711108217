import { Fab } from "@jugl-web/ui-components/cross-platform/Fab";
import cx from "classnames";
import React, { ButtonHTMLAttributes, useMemo } from "react";

export type ToggleButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isOn: boolean;
  onIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  offIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  noFill?: boolean;
};

export const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { onIcon: OnIcon, offIcon: OffIcon, isOn, noFill, ...rest } = props;
  const nativeProps = useMemo(() => {
    const out: Partial<ToggleButtonProps> = { ...rest };
    delete out.offIcon;
    delete out.onIcon;
    delete out.isOn;
    delete out.children;
    delete out.className;
    delete out.noFill;
    return out;
  }, [rest]);

  const Icon = isOn ? OffIcon : OnIcon;

  return (
    <Fab
      icon={
        <Icon
          className={cx(
            { "stroke-grey-500 fill-none": noFill && !isOn },
            { "stroke-dark fill-none": noFill && isOn },
            { "fill-dark-300": !isOn && !noFill },
            { "fill-dark": isOn && !noFill }
          )}
        />
      }
      className={cx({
        "bg-dark/50 hover:brightness-90 active:brightness-110": !isOn,
        "bg-white hover:brightness-90 active:brightness-110": isOn,
      })}
      {...nativeProps}
    />
  );
};
