import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideSideBar, showSideBar } from "../../appSlice";

const SideBarHidden = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideSideBar());
    return () => {
      dispatch(showSideBar());
    };
  }, [dispatch]);
  return children;
};

export default SideBarHidden;
