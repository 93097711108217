import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const StyledFallbackWrapper = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
});

export const StyledFallbackImage = styled("img")({
  width: "100%",
  height: "100%",
  textAlign: "center",
  objectFit: "cover",
  color: "transparent",
  textIndent: "10000px",
});

export const StyledFallbackText = styled(Typography)({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1,
  textTransform: "uppercase",
  fontWeight: 700,
});
