import React, { useContext, useEffect, useMemo, useState } from "react";
import { CallsContext } from "@web-src/features/calls/providers/Calls";
import cx from "classnames";
import { UID } from "agora-rtc-sdk-ng";
import ControlPanel from "@web-src/features/calls/components/ControlPanel/";
import { ReactComponent as GetFullScreenIcon } from "@web-src/features/calls/icons/get-fullscreen.svg";
import { ReactComponent as FullScreenOffIcon } from "@web-src/features/calls/icons/fullscreen-off.svg";
import RemoteUserCallWindow from "../RemoteUserCallWindow/RemoteUserCallWindow";
import LocalUserCallWindow from "../LocalUserCallWindow";
import UsersAvatars from "./UsersAvatars";

const ActiveCallWrapper: React.FC = () => {
  const { activeCall, leaveCall } = useContext(CallsContext);
  const [selectedUser, setSelectedUser] = useState<UID>();
  const [isScreenSharingFullScreen, setScreenSharingFullScreen] =
    useState<boolean>(false);
  useEffect(() => {
    if (activeCall?.localVideoTrack) {
      activeCall?.localVideoTrack.play("localplayer", { fit: "cover" });
    }
  }, [activeCall]);

  const usersCount = useMemo(() => {
    let count = activeCall?.remoteUsers?.length || 0;
    if (selectedUser) {
      count -= 1;
    }
    return count + 1;
  }, [activeCall?.remoteUsers?.length, selectedUser]);

  useEffect(() => {
    const users = activeCall?.remoteUsers || [];
    const screenSharingUser = users.filter((user) =>
      user.uid?.toString().includes("screenSharing_")
    )[0];
    setSelectedUser(screenSharingUser?.uid);
  }, [activeCall?.remoteUsers]);

  const screenSharingUser = useMemo(() => {
    const users = activeCall?.remoteUsers || [];
    const sharingUser = users.filter((user) =>
      user.uid?.toString().includes("screenSharing_")
    )[0];
    return sharingUser;
  }, [activeCall?.remoteUsers]);

  if (!activeCall) {
    return null;
  }

  const handleSettingFullScreen = () => {
    setScreenSharingFullScreen(!isScreenSharingFullScreen);
  };
  return (
    <div className="fixed top-0 left-0 z-10 h-full w-full bg-[#ECEFF0]">
      <div
        className={cx(
          "z-20 h-[calc(100%_-_160px)] w-full px-8 pt-8",
          {
            "grid gap-8": screenSharingUser,
            "grid-cols-[100%_auto]": isScreenSharingFullScreen,
          },
          screenSharingUser &&
            !isScreenSharingFullScreen && {
              "grid-cols-[71%_auto]": usersCount >= 1 && usersCount < 4,
              "grid-cols-[77%_auto]": usersCount === 4,
              "grid-cols-[81%_auto]": usersCount > 4,
            }
        )}
      >
        {screenSharingUser && (
          <div
            className={cx("relative h-full w-full")}
            key={screenSharingUser.uid}
          >
            <span
              className="absolute top-5 right-5 z-20 cursor-pointer"
              onClick={handleSettingFullScreen}
            >
              {isScreenSharingFullScreen ? (
                <FullScreenOffIcon />
              ) : (
                <GetFullScreenIcon />
              )}
            </span>
            <RemoteUserCallWindow
              elementRatio={undefined}
              user={screenSharingUser}
              key={screenSharingUser.uid}
            />
          </div>
        )}
        <div
          className={cx(
            !screenSharingUser && {
              "grid gap-8 ": usersCount > 1,
              "grid-cols-2": usersCount === 2,
              "grid-cols-3": usersCount === 3,
              "auto-rows-fr grid-cols-3": usersCount >= 6,
              "auto-rows-fr grid-cols-12 place-content-baseline":
                usersCount === 5 ||
                usersCount === 7 ||
                usersCount === 8 ||
                usersCount === 10 ||
                usersCount === 11,
              "auto-rows-fr grid-cols-5 place-content-baseline":
                usersCount === 13,
              "grid-cols-10": usersCount >= 12,
              "grid-cols-5 place-content-baseline overflow-auto":
                usersCount > 13,
              "auto-rows-fr grid-cols-2 grid-rows-2": usersCount === 4,
            },
            {
              "flex flex-col gap-4 overflow-clip": screenSharingUser,
              "h-full w-full place-content-center": !screenSharingUser,
            }
          )}
        >
          {activeCall?.remoteUsers?.map((user, i) => {
            if (user.uid === screenSharingUser?.uid) return null;
            return (
              <div
                className={cx("h-full w-full", {
                  "col-span-3": usersCount === 10 || usersCount === 11,
                  "col-span-2": usersCount >= 12,
                  "col-span-4":
                    usersCount === 5 || usersCount === 7 || usersCount === 8,
                  "col-[3/_span_4]":
                    (usersCount === 5 && i === 3) ||
                    (usersCount === 8 && i === 6),
                  "col-[4/_span_3]": usersCount === 10 && i === 8,
                  "col-[3/_span_3]": usersCount === 11 && i === 8,
                  "col-[4/_span_2]": usersCount === 12 && i === 10,
                  "col-[3/_span_2]": usersCount === 13 && i === 10,
                  "col-[2/_span_2]": usersCount === 14 && i === 10,
                  "max-h-[33%] min-h-[18%]": screenSharingUser,
                })}
                key={user.uid}
              >
                <RemoteUserCallWindow
                  elementRatio={
                    selectedUser || usersCount === 1 || usersCount > 3
                      ? undefined
                      : 1.5
                  }
                  user={user}
                  key={user.uid}
                />
              </div>
            );
          })}
          <div
            className={cx("h-full w-full", {
              "col-span-3": usersCount === 10 || usersCount === 11,
              "col-span-2": usersCount >= 12,
              "col-span-4": usersCount === 5 || usersCount === 8,
              "col-[5/_span_4]": usersCount === 7,
              "max-h-[30%]": screenSharingUser,
            })}
          >
            <LocalUserCallWindow
              elementRatio={
                selectedUser || usersCount === 1 || usersCount > 3
                  ? undefined
                  : 1.5
              }
            />
          </div>
        </div>
      </div>
      {isScreenSharingFullScreen && selectedUser && (
        <UsersAvatars users={activeCall?.remoteUsers || []} />
      )}
      <div className="absolute bottom-10 left-1/2 z-20 flex -translate-x-1/2 transform flex-row items-center gap-6">
        {leaveCall && (
          <ControlPanel
            isVideoOn={activeCall.videoEnabled}
            isMicOn={activeCall.audioEnabled}
            isScreenShared={activeCall.screenSharingEnabled}
            toggleVideo={activeCall?.toggleVideo}
            toggleMic={activeCall.toggleAudio}
            toggleScreenShare={activeCall.toggleScreenShare}
            endCall={leaveCall}
          />
        )}
      </div>
    </div>
  );
};

export default ActiveCallWrapper;
