import React, { useState, memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { ReactComponent as CalendarIcon } from "@web-src/images/icons/calendar.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SettingsPaddingSize } from "@web-src/utils/layout";
import { Profile, ProfileCareers } from "@web-src/features/auth/types";
import { useGetProfileQuery } from "@web-src/features/auth/authApi";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import SvgIcon from "@mui/material/SvgIcon";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as BinIcon } from "@web-src/images/icons/delete.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import format from "date-fns/format";
import { isoToLocalDate } from "@web-src/utils/helper";
import {
  useAddInformationMutation,
  useDeleteInformationMutation,
  useUpdateInformationMutation,
} from "@web-src/features/settings/settingsApi";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomDateTimePicker from "@web-src/components/CustomDateTimePicker";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import { useEnqueueSnackbar } from "@web-src/features/settings/hooks/useSnackbar";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as WorkExperienceIcon } from "./images/work-experience.svg";
import FormSpinner from "../FormSpinner";
import DeleteModal from "../DeleteModal";

type Inputs = {
  job_title: string;
  organization: string;
  location: string;
  start_date: Date | undefined;
  end_date: Date | undefined;
  current: boolean;
};

const schema = yup.object().shape({
  job_title: yup.string().required(),
  organization: yup.string().required(),
  location: yup.string().required(),
  start_date: yup.date().required(),
  current: yup.boolean(),
  end_date: yup.date().when("current", {
    is: false,
    then: (schemat) => schemat.required(),
  }),
});

const EditExperienceFormContainer = memo(() => {
  const { data } = useGetProfileQuery();
  return <EditExperienceFormComponent {...{ data }} />;
});

const EditExperienceFormComponent: React.FC<{
  data: Profile | undefined;
}> = ({ data }) => {
  const { t } = useTranslations();
  return (
    <Box sx={{ width: "50%" }}>
      {!data ? (
        <FormSpinner />
      ) : data.careers.length === 0 ? (
        <>
          <Typography sx={{ fontWeight: "500", padding: "40px 40px 0 20px" }}>
            {t({
              id: "experience-form-page.add-work-experience",
              defaultMessage: "Add Work Experience",
            })}
          </Typography>
          <EditExperienceForm type="add" />
        </>
      ) : (
        <EditExperienceList {...{ data }} />
      )}
    </Box>
  );
};

const EditExperienceList: React.FC<{ data: Profile }> = ({ data }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const { t } = useTranslations();
  return (
    <Box>
      <Typography sx={{ fontWeight: "500", padding: "20px 0 0 20px" }}>
        {t({
          id: "experience-form-page.your-work-experience",
          defaultMessage: "Your Work Experience",
        })}
      </Typography>
      {data.careers.map((el) => (
        <EditExperienceListItem key={el.id} data={el} />
      ))}
      {!displayForm && (
        <Button
          sx={{
            marginLeft: "20px",
            marginTop: "20px",
            marginBottom: "40px",
            width: "50%",
          }}
          variant="outlined"
          size="large"
          onClick={() => setDisplayForm(true)}
        >
          {t({
            id: "form-controls.add-new-experience.button",
            defaultMessage: "Add New Experience",
          })}
        </Button>
      )}
      {displayForm && (
        <Box>
          <Typography sx={{ fontWeight: "500", marginLeft: "20px" }}>
            {t({
              id: "experience-form-page.add-work-experience",
              defaultMessage: "Add Work Experience",
            })}
          </Typography>
          <EditExperienceForm
            type="add"
            onSuccess={() => setDisplayForm(false)}
          />
        </Box>
      )}
    </Box>
  );
};

const EditExperienceListItem: React.FC<{ data: ProfileCareers }> = ({
  data,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteExperience, { isLoading }] = useDeleteInformationMutation();
  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();
  const { t } = useTranslations();

  const handleDeleteExperience = async () => {
    const res = await deleteExperience({
      type: "career",
      item_id: data.id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        style={{
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <List sx={{ width: "100%" }}>
            <ListItem
              secondaryAction={
                <IconButton sx={{ borderRadius: "0", padding: "0" }}>
                  <Button
                    disabled={isLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(true);
                    }}
                    startIcon={
                      <SvgIcon component={BinIcon} sx={{ color: "#E55353" }} />
                    }
                  >
                    <Typography
                      sx={{
                        color: "#E55353",
                        fontSize: "14px",
                        textTransform: "uppercase",
                        fontWeight: "500",
                      }}
                    >
                      {t({
                        id: "common.delete",
                        defaultMessage: "Delete",
                      })}
                    </Typography>
                  </Button>
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Box
                  sx={{
                    width: "36px",
                    height: "36px",
                    backgroundColor: "rgba(171, 89, 228, 0.1);",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon component={WorkExperienceIcon} viewBox="0 0 20 20" />
                </Box>
              </ListItemAvatar>
              <ListItemText
                primary={data.title}
                secondary={`${format(
                  isoToLocalDate(data.start),
                  "dd/MM/yyyy"
                )} - ${
                  data.end !== null
                    ? format(isoToLocalDate(data.end), "dd/MM/yyyy")
                    : "Present"
                }`}
              />
            </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <EditExperienceForm
            {...{ data }}
            type="update"
            onSuccess={() => setExpanded(false)}
          />
        </AccordionDetails>
      </Accordion>
      {openModal && (
        <DeleteModal
          isLoading={isLoading}
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={() => handleDeleteExperience()}
          text={t({
            id: "experience-form-page.experience-information-removal-confirmation",
            defaultMessage:
              "Do you really want to remove Work Experience information?",
          })}
        />
      )}
    </>
  );
};

const EditExperienceForm: React.FC<{
  data?: ProfileCareers;
  type: "add" | "update";
  onSuccess?: () => void;
}> = ({ data, type, onSuccess }) => {
  const [addExperience, { isLoading }] = useAddInformationMutation();
  const [updateExperience, { isLoading: isLoadingUpdate }] =
    useUpdateInformationMutation();
  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();
  const { t } = useTranslations();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      job_title: data?.title || "",
      organization: data?.org || "",
      location: data?.location || "",
      start_date: data?.start ? new Date(data.start) : undefined,
      end_date: data?.end ? new Date(data.end) : undefined,
      current: data?.end === null,
    },
  });

  const endDateValue = watch("end_date");
  const currentValue = watch("current");

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const {
      job_title: JobTitle,
      organization,
      location,
      start_date: StartDate,
      end_date: EndDate,
      current,
    } = { ...params };
    const dataForm = new FormData();
    dataForm.set("type", "career");
    dataForm.set("title", JobTitle);
    dataForm.set("org", organization);
    dataForm.set("location", location);
    dataForm.set("start", StartDate?.toISOString() || "");
    let res;
    if (!current) dataForm.set("end", EndDate?.toISOString() || "");
    if (type === "add") {
      res = await addExperience({ data: dataForm });
    } else {
      res = await updateExperience({
        item_id: data?.id,
        data: dataForm,
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      onSuccess?.();
    }
  };

  return (
    <Box sx={{ padding: "20px 40px 40px 40px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "70px",
            marginBottom: "50px",
            position: "relative",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.job-title.label",
                  defaultMessage: "Job Title",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <TextField
              variant="standard"
              sx={{
                width: "100%",
                input: {
                  "&::placeholder": {
                    fontWeight: "400",
                  },
                },
              }}
              inputProps={{
                style: { fontSize: 16, fontWeight: "500" },
                ...register("job_title"),
              }}
              placeholder={t({
                id: "form-controls.job-title.placeholder",
                defaultMessage: "Job Title",
              })}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.organisation.label",
                  defaultMessage: "Organisation",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <TextField
              variant="standard"
              sx={{
                width: "100%",
                input: {
                  "&::placeholder": {
                    fontWeight: "400",
                  },
                },
              }}
              inputProps={{
                style: { fontSize: 16, fontWeight: "500" },
                ...register("organization"),
              }}
              placeholder={t({
                id: "form-controls.organisation.placeholder",
                defaultMessage: "Organisation",
              })}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "70px",
            marginBottom: "50px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.location.label",
                  defaultMessage: "Location / Branch",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <TextField
              variant="standard"
              sx={{
                width: "100%",
                input: {
                  "&::placeholder": {
                    fontWeight: "400",
                  },
                },
              }}
              inputProps={{
                style: { fontSize: 16, fontWeight: "500" },
                ...register("location"),
              }}
              placeholder={t({
                id: "form-controls.location.placeholder",
                defaultMessage: "Location / Branch",
              })}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.start-date.label",
                  defaultMessage: "Start Date",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <Controller
              name="start_date"
              control={control}
              render={({ field }) => (
                <CustomDateTimePicker
                  disableCounter
                  title={t({
                    id: "date-time-picker.select-date",
                    defaultMessage: "Select Date",
                  })}
                  {...field}
                  onChange={(newValue) => {
                    setValue("start_date", newValue, { shouldValidate: true });
                    if (endDateValue && newValue > endDateValue) {
                      setValue("end_date", newValue, { shouldValidate: true });
                    }
                  }}
                  renderInput={
                    <Box
                      sx={{
                        width: "100%",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid black",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        "&:hover": {
                          borderBottom: "2px solid black",
                        },
                      }}
                    >
                      <Box>
                        {!field.value ? (
                          <Typography sx={{ color: "grey.900" }}>
                            DD/MM/YYYY
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: 16, fontWeight: "500" }}>
                            {format(field.value, "dd/MM/yyyy")}
                          </Typography>
                        )}
                      </Box>
                      <SvgIcon
                        component={CalendarIcon}
                        sx={{ color: "grey.700" }}
                      />
                    </Box>
                  }
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.end-date.label",
                  defaultMessage: "End Date",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <Controller
              name="end_date"
              control={control}
              render={({ field }) => (
                <CustomDateTimePicker
                  title={t({
                    id: "date-time-picker.select-date",
                    defaultMessage: "Select Date",
                  })}
                  disableCounter
                  disabled={currentValue}
                  {...field}
                  renderInput={
                    <Box
                      sx={{
                        width: "100%",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid black",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        "&:hover": {
                          borderBottom: "2px solid black",
                        },
                      }}
                    >
                      <Box>
                        {!field.value ? (
                          <Typography
                            sx={{
                              color: currentValue ? "grey.500" : "grey.900",
                            }}
                          >
                            DD/MM/YYYY
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 16,
                              fontWeight: "500",
                              color: currentValue ? "grey.500" : "black",
                            }}
                          >
                            {format(field.value, "dd/MM/yyyy")}
                          </Typography>
                        )}
                      </Box>
                      <SvgIcon
                        component={CalendarIcon}
                        sx={{ color: "grey.700" }}
                      />
                    </Box>
                  }
                />
              )}
            />
          </Box>
          <Box>
            <FormControl>
              <FormControlLabel
                sx={{ margin: "30px 0" }}
                control={
                  <Controller
                    name="current"
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        style={{ padding: "0 10px 0 0" }}
                        {...props}
                        checked={props.value || false}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    {t({
                      id: "form-controls.currently-working-in-role.label",
                      defaultMessage: "I am currently working in this role",
                    })}
                  </Typography>
                }
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Button
            disabled={!isValid || isLoading || isLoadingUpdate || !isDirty}
            sx={{ width: `calc(100% - ${SettingsPaddingSize}px)` }}
            size="large"
            variant="contained"
            type="submit"
          >
            {t({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditExperienceFormContainer;
