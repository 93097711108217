import { emptySplitApi } from "@web-src/features/api/createApi";
import { serializeSearchParams } from "@web-src/utils/helper";
import { ChatMessage, ChatHistoryItem, ChatItem, ChatType } from "./types";
import { messageToConversationLastMessage } from "./utils";

const apiWithTags = emptySplitApi.enhanceEndpoints({});

export const messagesApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    chatHistory: build.query<
      ChatItem[],
      {
        params: {
          entity_id: string;
          time: string;
          limit: number;
          self_chat?: "true";
        };
      }
    >({
      query: ({ params }) => ({
        url: `/api/auth/messages/chat_history?${serializeSearchParams(params)}`,
      }),
      transformResponse: (data: { chat_list: ChatHistoryItem[] }) =>
        data.chat_list
          // TODO: @critical, check with backend
          .filter((item) => !!item.conversation)
          .map((item) => ({
            id: item.conversation?.id,
            img: item.conversation?.img,
            title: item.conversation?.title,
            type: item.conversation?.type || ChatType.chat,
            isSelf: !item.conversation,
            unreadCount: item.unread_count?.unread_count,
            firstUnreadMessage: item.unread_count?.first_item
              ? {
                  msgId: item.unread_count.first_item.msg_id,
                  createdAt: item.unread_count.first_item.created_at,
                }
              : undefined,
            lastMessage: messageToConversationLastMessage(item),
          })),
    }),
    messages: build.query<
      { data: ChatMessage[]; remaining_item: boolean },
      {
        params: {
          entity_id: string;
          from?: string;
          time?: string;
          message_id?: string;
          workspace_id?: string;
          limit: number;
          filter?: string;
        };
      }
    >({
      query: ({ params }) => ({
        url: `/api/auth/messages`,
        params: {
          ...params,
          time: params.time ? params.time.replace("T", " ") : undefined,
        },
      }),
    }),
    messageInfo: build.query<
      {
        data: {
          delivered: { action_at: string; user_id: string }[];
          read: { action_at: string; user_id: string }[];
          participants: number;
        };
      },
      {
        msgId: string;
      }
    >({
      query: ({ msgId }) => ({
        url: `/api/auth/messages/${msgId}/info`,
      }),
    }),
  }),
});

export const {
  useMessageInfoQuery,
  useMessagesQuery,
  useLazyMessagesQuery,
  useChatHistoryQuery,
  useLazyChatHistoryQuery,
} = messagesApi;
