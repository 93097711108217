import React, { useContext, useEffect, useMemo, useState } from "react";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import { CallsContext } from "../../providers/Calls";
import UserCallWindow from "../UserWindow";

const RemoteUserCallWindow: React.FC<{
  user: IAgoraRTCRemoteUser;
  elementRatio?: number;
}> = ({ user, elementRatio }) => {
  const { client, activeCall } = useContext(CallsContext);

  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(true);
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(true);
  const [isSpeaking, setIsSpeaking] = useState(false);

  const id = useMemo(() => (Math.random() * 1e16).toFixed(), []);
  useEffect(() => {
    const userInfoUpdateCallback = (
      uid: string,
      msg:
        | "mute-audio"
        | "mute-video"
        | "enable-local-video"
        | "unmute-audio"
        | "unmute-video"
        | "disable-local-video"
    ) => {
      if (uid !== user.uid) {
        return;
      }
      switch (msg) {
        case "enable-local-video":
        case "unmute-video":
          setIsVideoMuted(false);
          break;
        case "disable-local-video":
        case "mute-video":
          setIsVideoMuted(true);
          break;
        case "unmute-audio":
          setIsAudioMuted(false);
          break;
        case "mute-audio":
          setIsAudioMuted(true);
          break;
        default:
      }
    };

    client?.on("user-info-updated", userInfoUpdateCallback);
    return () => {
      client?.off("user-info-updated", userInfoUpdateCallback);
    };
  }, [activeCall?.userTracks, client, id, user]);
  useEffect(() => {
    // TODO: optimize
    const videoTrack = activeCall?.userTracks[user.uid]?.video;
    if (videoTrack && !videoTrack.isPlaying) {
      videoTrack.play(id, { fit: "contain" });
      setIsVideoMuted(false);
    }
  }, [activeCall, id, user.uid]);

  useEffect(() => {
    // TODO: optimize
    const audioTrack = activeCall?.userTracks[user.uid]?.audio;
    if (audioTrack) {
      audioTrack.play();
      setIsAudioMuted(false);
    }
  }, [activeCall, id, user.uid]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newVolumeLevel = user.audioTrack?.getVolumeLevel() || 0;
      const newTalkingState = newVolumeLevel > 0.6;
      if (isSpeaking !== newTalkingState) setIsSpeaking(newTalkingState);
    }, 500);

    return () => clearInterval(intervalId);
  }, [user, isSpeaking]);
  return (
    <UserCallWindow
      isSpeaking={isSpeaking}
      elementRatio={elementRatio}
      userId={user.uid}
      isAudioMuted={isAudioMuted}
      isVideoMuted={isVideoMuted}
      playerId={id}
    />
  );
};

export default RemoteUserCallWindow;
