import isNil from "lodash/isNil";
import { Fragment, ReactNode } from "react";

export const joinReactNodes = (nodes: ReactNode[], separator: ReactNode) => (
  <>
    {nodes.map((node, index) => (
      <Fragment key={+index}>
        {node}
        {!isNil(nodes[index + 1]) && separator}
      </Fragment>
    ))}
  </>
);
