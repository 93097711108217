export const fieldTypes = [
  "text",
  "mobile",
  "url",
  "dropdown",
  "email",
] as const;
export type FieldTypes = (typeof fieldTypes)[number];

export type FormField = {
  entity_id: string;
  id: string;
  name: string;
  pos: number;
  required: boolean;
  section: "company" | "personal";
  type: FieldTypes;
  is_cust_field: boolean;
  value: string | null | undefined;
};
