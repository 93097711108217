import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as PlayIcon } from "./icons/play.svg";

export interface VideoProps {
  url: string;
}

const Video: React.FC<VideoProps> = ({ url }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoCurrentTime, setVideoCurrentTime] = useState<number | undefined>(
    0
  );
  const [videoDuration, setVideoDuration] = useState<number>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const timerId = useRef<NodeJS.Timer>();

  const handlePlayVideo = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    await videoRef.current?.play();

    setIsVideoPlaying(true);
    setVideoDuration(videoRef.current?.duration);

    timerId.current = setInterval(() => {
      setVideoCurrentTime(videoRef.current?.currentTime);
    }, 1000);
  };

  const handlePauseVideo = async (
    event: React.MouseEvent<HTMLVideoElement, MouseEvent>
  ) => {
    event.stopPropagation();

    await videoRef.current?.pause();

    setIsVideoPlaying(false);
  };

  useEffect(() => {
    if (videoDuration && videoCurrentTime) {
      if (videoDuration === videoCurrentTime) {
        clearInterval(timerId.current);
        setIsVideoPlaying(false);
        setVideoCurrentTime(undefined);
      }
    }

    return () => {
      clearInterval(timerId.current);
    };
  }, [videoCurrentTime, videoDuration]);

  return (
    <div className="bg-secondary-50 relative h-[180px] w-full rounded-[10px]">
      {!isVideoPlaying && (
        <div
          className="bg-dark-100 absolute top-1/2 left-1/2 z-[1] flex h-[50px] w-[50px] -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-opacity-50 p-[15px]"
          onClick={handlePlayVideo}
        >
          <PlayIcon />
        </div>
      )}
      <video
        ref={videoRef}
        src={`${url}#t=0.1`}
        onClick={handlePauseVideo}
        className="h-full w-full rounded-[10px] object-cover"
      >
        <track kind="captions" />
      </video>
    </div>
  );
};

export default Video;
