import { createContext, FC, ReactNode } from "react";
import { AppVariant } from "../types";

export const AppVariantContext = createContext<AppVariant | null>(null);

interface AppVariantProviderProps {
  children: ReactNode;
  variant: AppVariant;
}

export const AppVariantProvider: FC<AppVariantProviderProps> = ({
  children,
  variant,
}) => (
  <AppVariantContext.Provider value={variant}>
    {children}
  </AppVariantContext.Provider>
);
