import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import add from "date-fns/add";
import endOfDay from "date-fns/endOfDay";
import { FC, useState } from "react";
import { Button } from "../../Button";

export interface DueDatePickerProps {
  initialDueDate?: Date | null;
  onApply: (dueDate: Date) => void;
  onClose: () => void;
}

export const DueDatePicker: FC<DueDatePickerProps> = ({
  initialDueDate,
  onApply,
  onClose,
}) => {
  const now = new Date();
  const [internalDueDate, setInternalDueDate] = useState(initialDueDate || now);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setInternalDueDate(endOfDay(date));
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <CalendarPicker
        date={internalDueDate}
        minDate={now}
        maxDate={add(now, { years: 1 })}
        onChange={handleDateChange}
        showDaysOutsideCurrentMonth
      />
      <div className="flex items-center gap-2.5 px-4">
        <Button
          variant="outlined"
          color="primary"
          uppercase
          fullWidth
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          uppercase
          fullWidth
          onClick={() => {
            onApply(internalDueDate);
            onClose();
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
