import { TaskLabel, TaskStatus } from "@jugl-web/rest-api";
import { LiveUpdateEvent, TASK_MODULE } from "../../../live-updates";

export const CREATED_ACTION = "created";
export const UPDATED_ACTION = "updated";
export const DELETED_ACTION = "deleted";
export const ATTACHMENT_DELETED_ACTION = "attachment deleted";

export type TaskLiveUpdateEvent<
  TAction extends string = string,
  TData extends object = object
> = LiveUpdateEvent<TAction, TData, typeof TASK_MODULE>;

export type TaskLiveUpdateCreatedEvent = TaskLiveUpdateEvent<
  typeof CREATED_ACTION,
  {
    id: string;
    entity_id: string;
    label_id: string | null;
    due_at: string | null;
  }
>;

export type TaskLiveUpdateUpdatedEvent = TaskLiveUpdateEvent<
  typeof UPDATED_ACTION,
  {
    id: string;
    entity_id: string;
    old_due_at: string | null;
    old_label_id: string | null;
    label?: TaskLabel | null;
    due_at?: string | null;
    assignee?: "updated";
    checklist?: "updated";
    status?: TaskStatus;
    // ...rest of delta properties that are not relevant at this moment
  }
>;

export type TaskLiveUpdateDeletedEvent = TaskLiveUpdateEvent<
  typeof DELETED_ACTION,
  {
    id: string;
    entity_id: string;
    label_id: string | null;
    due_at: string | null;
  }
>;

export type TaskLiveUpdateAttachmentDeletedEvent = TaskLiveUpdateEvent<
  typeof ATTACHMENT_DELETED_ACTION,
  {
    id: string;
    entity_id: string;
    attach_id: string;
  }
>;
