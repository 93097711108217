import { useSearchInput } from "@jugl-web/utils/hooks";
import { forwardRef } from "react";
import { PlainButton } from "../../cross-platform/PlainButton";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as DeleteLeftIcon } from "./icons/delete-left.svg";
import { ReactComponent as SearchIcon } from "./icons/search.svg";

export interface TopBarSearchInputProps {
  onSearch: (query: string) => void;
  onClose: () => void;
}

export const TopBarSearchInput = forwardRef<
  HTMLInputElement,
  TopBarSearchInputProps
>(({ onSearch, onClose }, ref) => {
  const { inputProps, reset } = useSearchInput({ onSearch });

  const hasValue = inputProps.value.length > 0;

  return (
    <div className="relative w-full">
      <SearchIcon className="pointer-events-none absolute top-1/2 left-3 -translate-y-1/2" />
      <input
        ref={ref}
        type="text"
        className="bg-primary/5 w-full rounded-lg border-none px-10 py-2 text-base font-medium leading-3 text-[#333333] outline-none"
        {...inputProps}
      />
      <PlainButton
        className="absolute top-1/2 right-3 -translate-y-1/2"
        onClick={hasValue ? reset : onClose}
      >
        {hasValue ? <DeleteLeftIcon /> : <CloseIcon />}
      </PlainButton>
    </div>
  );
});
