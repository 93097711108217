import { useCallback, useEffect, useState } from "react";
import { usePagination } from "@jugl-web/utils";
import { EduComment, EduCommentModule } from "./types";
import { PaginatedResponse } from "../../types";
import { addEduCommentsApi } from "./eduCommentsApi";

const pageSize = 20;

export const useEduComments = ({
  entityId,
  noInitialLoad,
  eduCommentsApi,
  objectId,
  module,
  reverse,
}: {
  entityId?: string;
  noInitialLoad?: boolean;
  eduCommentsApi: ReturnType<typeof addEduCommentsApi>;
  objectId?: string;
  module: EduCommentModule;
  reverse?: boolean;
}) => {
  const {
    items,
    addItems,
    setLastPageState,
    lastPageState,
    deleteItem,
    setIsLoadingState,
    isLoading: paginationIsLoading,
    reset,
    isInitialized,
    setInitState,
  } = usePagination<
    EduComment,
    Pick<
      PaginatedResponse<unknown>,
      "page_number" | "page_size" | "total_entries" | "total_pages"
    >
  >(`comments:${module}:${objectId}`);

  const [loadComments, { isLoading }] =
    eduCommentsApi.useLazyListEduCommentsQuery();
  const [createCommentApi, { isLoading: createCommentIsLoading }] =
    eduCommentsApi.useCreateEduCommentMutation();
  const [deleteCommentApi, { isLoading: deleteCommentIsLoading }] =
    eduCommentsApi.useDeleteEduCommentMutation();
  const [getComent] = eduCommentsApi.useLazyGetCommentQuery();

  const [fetchError, setFetchError] = useState(false);

  const handleLoadComments = useCallback(async () => {
    if (
      !entityId ||
      !objectId ||
      (lastPageState && lastPageState.page_number === lastPageState.total_pages)
    ) {
      return;
    }
    setInitState(true);
    setIsLoadingState(true);
    const response = await loadComments({
      entityId,
      objectId,
      module,
      params: {
        page: lastPageState?.page_number ? lastPageState.page_number + 1 : 1,
        page_size: pageSize,
      },
    });
    if (!response.data?.data) {
      setFetchError(true);
      setIsLoadingState(false);
      return;
    }
    const { data, ...lastPageStateToSet } = response.data;
    let dataToSet = data;
    if (reverse) {
      dataToSet = [...data].reverse();
    }
    addItems(
      dataToSet.map((item) => ({ id: item.id, data: item })),
      reverse ? "start" : "end"
    );
    setLastPageState(lastPageStateToSet);
    setIsLoadingState(false);
  }, [
    entityId,
    lastPageState,
    setIsLoadingState,
    loadComments,
    objectId,
    module,
    addItems,
    setLastPageState,
    reverse,
    setInitState,
  ]);

  useEffect(() => {
    if (isInitialized || noInitialLoad) {
      return;
    }
    handleLoadComments();
  }, [
    handleLoadComments,
    lastPageState,
    items?.length,
    paginationIsLoading,
    noInitialLoad,
    fetchError,
    isInitialized,
  ]);

  const createComment = useCallback(
    async (data: Parameters<typeof createCommentApi>[0]["data"]) => {
      if (!entityId || !objectId) {
        return null;
      }
      const response = await createCommentApi({
        entityId,
        objectId,
        module,
        data,
      });
      if ("data" in response) {
        const comment = response.data?.data?.[0];
        if (comment) {
          addItems(
            [{ id: comment.id, data: comment }],
            reverse ? "end" : "start"
          );
        }
        if (lastPageState) {
          setLastPageState({
            ...lastPageState,
            total_entries: lastPageState.total_entries + 1,
          });
        }
      }
      return response;
    },
    [
      addItems,
      createCommentApi,
      entityId,
      lastPageState,
      module,
      objectId,
      reverse,
      setLastPageState,
    ]
  );

  const deleteComment = useCallback(
    async (commentId: string) => {
      if (!entityId || !objectId) {
        return null;
      }
      const response = await deleteCommentApi({
        commentId,
        entityId,
        objectId,
        module,
      });
      if ("data" in response) {
        deleteItem({ itemId: commentId });
        if (lastPageState) {
          setLastPageState({
            ...lastPageState,
            total_entries: lastPageState.total_entries - 1,
          });
        }
      }
      return response;
    },
    [
      entityId,
      objectId,
      deleteCommentApi,
      module,
      deleteItem,
      lastPageState,
      setLastPageState,
    ]
  );

  const addCommentById = useCallback(
    async (id: string) => {
      if (!entityId || !objectId) {
        return;
      }
      const response = await getComent({
        entityId,
        module,
        objectId,
        commentId: id,
      });
      if (!response.data) {
        // TODO: error handling
        return;
      }
      const comment = response.data.data[0];
      if (!comment) {
        return;
      }
      addItems([{ id: comment.id, data: comment }], reverse ? "end" : "start");
      if (lastPageState) {
        setLastPageState({
          ...lastPageState,
          total_entries: lastPageState.total_entries + 1,
        });
      }
    },
    [
      entityId,
      objectId,
      getComent,
      module,
      addItems,
      reverse,
      lastPageState,
      setLastPageState,
    ]
  );

  return {
    items,
    load: handleLoadComments,
    isLoading,
    createCommentIsLoading,
    deleteCommentIsLoading,
    createComment,
    deleteComment,
    totalCount: lastPageState?.total_entries,
    reset,
    addCommentById,
  };
};
