import React, { useState, memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import CustomDateTimePicker from "@web-src/components/CustomDateTimePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SettingsPaddingSize } from "@web-src/utils/layout";
import { Profile, ProfileEducations } from "@web-src/features/auth/types";
import { useGetProfileQuery } from "@web-src/features/auth/authApi";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as CalendarIcon } from "@web-src/images/icons/calendar.svg";
import List from "@mui/material/List";
import SvgIcon from "@mui/material/SvgIcon";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as BinIcon } from "@web-src/images/icons/delete.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import format from "date-fns/format";
import { isoToLocalDate } from "@web-src/utils/helper";
import {
  useAddInformationMutation,
  useDeleteInformationMutation,
  useUpdateInformationMutation,
} from "@web-src/features/settings/settingsApi";
import { useEnqueueSnackbar } from "@web-src/features/settings/hooks/useSnackbar";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as EducationIcon } from "./images/education.svg";
import FormSpinner from "../FormSpinner";
import DeleteModal from "../DeleteModal";

type Inputs = {
  name_degree: string;
  school: string;
  start_date: Date | undefined;
  end_date: Date | undefined;
};

const schema = yup.object().shape({
  name_degree: yup.string().required(),
  school: yup.string().required(),
  start_date: yup.date().required(),
  end_date: yup.date().required(),
});

const EditEducationFormContainer = memo(() => {
  const { data } = useGetProfileQuery();
  return <EditEducationFormComponent {...{ data }} />;
});

const EditEducationFormComponent: React.FC<{
  data: Profile | undefined;
}> = ({ data }) => {
  const { t } = useTranslations();
  return (
    <Box sx={{ width: "50%" }}>
      {!data ? (
        <FormSpinner />
      ) : data.educations.length === 0 ? (
        <>
          <Typography sx={{ fontWeight: "500", padding: "40px 40px 0 20px" }}>
            {t({
              id: "edit-education-form-page.add-new-education",
              defaultMessage: "Add New Education",
            })}
          </Typography>
          <EditEducationForm type="add" />
        </>
      ) : (
        <EditEducationList {...{ data }} />
      )}
    </Box>
  );
};

const EditEducationList: React.FC<{ data: Profile }> = ({ data }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const { t } = useTranslations();
  return (
    <Box>
      <Typography sx={{ fontWeight: "500", padding: "20px 0 0 20px" }}>
        {t({
          id: "edit-education-form-page.your-education",
          defaultMessage: "Your Education",
        })}
      </Typography>
      {data.educations.map((el) => (
        <EditEducationListItem key={el.id} data={el} />
      ))}
      {!displayForm && (
        <Button
          sx={{
            marginLeft: "20px",
            marginTop: "20px",
            marginBottom: "40px",
            width: "50%",
          }}
          variant="outlined"
          size="large"
          onClick={() => setDisplayForm(true)}
        >
          {t({
            id: "form-controls.add-education-point",
            defaultMessage: "Add New Education Point",
          })}
        </Button>
      )}
      {displayForm && (
        <Box>
          <Typography sx={{ fontWeight: "500", marginLeft: "20px" }}>
            {t({
              id: "edit-education-form-page.add-education-point",
              defaultMessage: "Add New Education Point",
            })}
          </Typography>
          <EditEducationForm
            type="add"
            onSuccess={() => setDisplayForm(false)}
          />
        </Box>
      )}
    </Box>
  );
};

const EditEducationListItem: React.FC<{ data: ProfileEducations }> = ({
  data,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteEducation, { isLoading }] = useDeleteInformationMutation();
  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();
  const { t } = useTranslations();

  const handleDeleteEducation = async () => {
    const res = await deleteEducation({
      type: "education",
      item_id: data.id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        style={{
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ flexDirection: "row-reverse" }}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <List sx={{ width: "100%" }}>
            <ListItem
              secondaryAction={
                <IconButton sx={{ borderRadius: "0", padding: "0" }}>
                  <Button
                    disabled={isLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(true);
                    }}
                    startIcon={
                      <SvgIcon component={BinIcon} sx={{ color: "#E55353" }} />
                    }
                  >
                    <Typography
                      sx={{
                        color: "#E55353",
                        fontSize: "14px",
                        textTransform: "uppercase",
                        fontWeight: "500",
                      }}
                    >
                      {t({ id: "common.delete", defaultMessage: "Delete" })}
                    </Typography>
                  </Button>
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Box
                  sx={{
                    width: "36px",
                    height: "36px",
                    backgroundColor: "rgba(34, 197, 158, 0.1)",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon component={EducationIcon} viewBox="0 0 20 20" />
                </Box>
              </ListItemAvatar>
              <ListItemText
                primary={data.name}
                secondary={`${format(
                  isoToLocalDate(data.start),
                  "MMMM/yyyy"
                )} - ${format(isoToLocalDate(data.end), "MMMM/yyyy")}`}
              />
            </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <EditEducationForm
            {...{ data }}
            type="update"
            onSuccess={() => setExpanded(false)}
          />
        </AccordionDetails>
      </Accordion>
      {openModal && (
        <DeleteModal
          isLoading={isLoading}
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={() => handleDeleteEducation()}
          text={t({
            id: "edit-education-form-page.education-information-removal-confirmation",
            defaultMessage:
              "Do you really want to remove the education information?",
          })}
        />
      )}
    </>
  );
};

const EditEducationForm: React.FC<{
  data?: ProfileEducations;
  type: "add" | "update";
  onSuccess?: () => void;
}> = ({ data, type, onSuccess }) => {
  const [addEducation, { isLoading }] = useAddInformationMutation();
  const [updateEducation, { isLoading: isLoadingUpdate }] =
    useUpdateInformationMutation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      name_degree: data?.name || "",
      school: data?.where || "",
      start_date: data?.start ? new Date(data.start) : undefined,
      end_date: data?.end ? new Date(data.end) : undefined,
    },
  });
  const { t } = useTranslations();

  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();
  const startDateValue = watch("start_date");
  const endDateValue = watch("end_date");

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const {
      name_degree: nameDegree,
      school,
      start_date: startDate,
      end_date: endDate,
    } = { ...params };
    let res;
    if (type === "add") {
      res = await addEducation({
        data: {
          type: "education",
          start: startDate,
          end: endDate,
          name: nameDegree,
          where: school,
        },
      });
    } else {
      res = await updateEducation({
        item_id: data?.id,
        data: {
          type: "education",
          start: startDate,
          end: endDate,
          name: nameDegree,
          where: school,
        },
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      onSuccess?.();
    }
  };

  return (
    <Box sx={{ padding: "20px 40px 40px 40px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "70px",
            marginBottom: "50px",
            position: "relative",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.degree-name.label",
                  defaultMessage: "Name of degree / Course",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <TextField
              variant="standard"
              sx={{
                width: "100%",
                input: {
                  "&::placeholder": {
                    fontWeight: "400",
                  },
                },
              }}
              inputProps={{
                style: { fontSize: 16, fontWeight: "500" },
                ...register("name_degree"),
              }}
              placeholder={t({
                id: "form-controls.name-degree.placeholder",
                defaultMessage: "Phd in Computer science engineering",
              })}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.school.label",
                  defaultMessage: "School / College",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <TextField
              variant="standard"
              sx={{
                width: "100%",
                input: {
                  "&::placeholder": {
                    fontWeight: "400",
                  },
                },
              }}
              inputProps={{
                style: { fontSize: 16, fontWeight: "500" },
                ...register("school"),
              }}
              placeholder={t({
                id: "form-controls.name-school.placeholder",
                defaultMessage: "Philips",
              })}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "70px",
            marginBottom: "50px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.start-date.label",
                  defaultMessage: "Start Date",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <Controller
              name="start_date"
              control={control}
              render={({ field }) => (
                <CustomDateTimePicker
                  disableCounter
                  title={t({
                    id: "date-time-picker.select-date",
                    defaultMessage: "Select Date",
                  })}
                  {...field}
                  onChange={(newValue) => {
                    setValue("start_date", newValue, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    if (endDateValue && newValue > endDateValue) {
                      setValue("end_date", newValue, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }
                  }}
                  renderInput={
                    <Box
                      sx={{
                        width: "100%",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid black",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        "&:hover": {
                          borderBottom: "2px solid black",
                        },
                      }}
                    >
                      <Box>
                        {!field.value ? (
                          <Typography sx={{ color: "grey.900" }}>
                            DD/MM/YYYY
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: 16, fontWeight: "500" }}>
                            {format(field.value, "dd/MM/yyyy")}
                          </Typography>
                        )}
                      </Box>
                      <SvgIcon
                        component={CalendarIcon}
                        sx={{ color: "grey.700" }}
                      />
                    </Box>
                  }
                />
              )}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <InputLabel>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  textTransform: "uppercase",
                }}
                component="span"
              >
                {t({
                  id: "form-controls.end-date.label",
                  defaultMessage: "End Date",
                })}
              </Typography>{" "}
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </InputLabel>
            <Controller
              name="end_date"
              control={control}
              render={({ field }) => (
                <CustomDateTimePicker
                  disableCounter
                  title={t({
                    id: "date-time-picker.select-date",
                    defaultMessage: "Select Date",
                  })}
                  {...field}
                  minDate={startDateValue}
                  renderInput={
                    <Box
                      sx={{
                        width: "100%",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid black",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        "&:hover": {
                          borderBottom: "2px solid black",
                        },
                      }}
                    >
                      <Box>
                        {!field.value ? (
                          <Typography sx={{ color: "grey.900" }}>
                            DD/MM/YYYY
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: 16, fontWeight: "500" }}>
                            {format(field.value, "dd/MM/yyyy")}
                          </Typography>
                        )}
                      </Box>
                      <SvgIcon
                        component={CalendarIcon}
                        sx={{ color: "grey.700" }}
                      />
                    </Box>
                  }
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Button
            disabled={!isValid || isLoading || isLoadingUpdate || !isDirty}
            sx={{ width: `calc(100% - ${SettingsPaddingSize}px)` }}
            size="large"
            variant="contained"
            type="submit"
          >
            {t({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditEducationFormContainer;
