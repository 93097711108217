import { skipToken } from "@reduxjs/toolkit/dist/query";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useWorkspaceAllParticipatnsQuery } from "@web-src/features/workspaces/workspacesApi";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { selectActiveChatId, setActiveChatId } from "../chatsSlice";
import useConversations from "./useConversations";

const useActiveChat = () => {
  const dispatch = useDispatch();
  const match = useMatch(`/:entityId/chats/:chatId/*`);
  const activeChatId = useSelector(selectActiveChatId);
  const { conversations } = useConversations();
  const { entity } = useEntity();
  const chatId = activeChatId || match?.params.chatId;
  const chat = useMemo(
    () =>
      chatId ? conversations?.find((item) => item.id === chatId) : undefined,
    [chatId, conversations]
  );
  const meId = useSelector(selectUserId);

  const { data: particiapnts, isFetching: isParticipantsFetching } =
    useWorkspaceAllParticipatnsQuery(
      !entity || !chat || chat?.data?.type !== "muc"
        ? skipToken
        : { id: chat.id, entityId: entity.id }
    );

  const isParticipant = useMemo(() => {
    if (chat?.data?.type === "chat") {
      return undefined;
    }
    return !!particiapnts?.find((item) => item.user_id === meId);
  }, [meId, particiapnts, chat]);

  return {
    chat: chat?.data,
    isParticipant,
    participants:
      isParticipantsFetching || chat?.data?.type === "chat" ? [] : particiapnts,
    setActiveChat: (id: string, navigate = true) => {
      if (navigate) {
        window.history.pushState(undefined, "", `/${entity?.id}/chats/${id}`);
      }
      dispatch(setActiveChatId(id));
    },
  };
};

export default useActiveChat;
