import {
  Avatar,
  Drawer,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { Accordion } from "@jugl-web/ui-components/cross-platform/Accordion";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { EntityParticipantRole } from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import { environment } from "@web-src/environments/environment";
import { ReactComponent as ChatsIcon } from "./icons/chats.svg";
import { ReactComponent as TasksIcon } from "./icons/tasks.svg";
import { ReactComponent as PeopleIcon } from "./icons/people.svg";
import { ReactComponent as CallsIcon } from "./icons/calls.svg";
import { ReactComponent as ScheduleIcon } from "./icons/schedule.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import { ReactComponent as LogoutIcon } from "./icons/logout.svg";
import { ReactComponent as JuglIcon } from "./icons/jugl.svg";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as ChevronRightIcon } from "./icons/chevron-right.svg";
import useEntity from "../../hooks/useEntity";
import { getAppType } from "../../utils";
import { TabRoute, AppType } from "../../types";
import { useLogout } from "../../hooks/useLogout";

type NavigationElements = {
  icon: React.FC;
  title: string;
  onClick?: () => void;
  hidden?: boolean;
  requiredRole?: EntityParticipantRole[];
  accordionElements?: {
    title: string;
    onClick?: () => void;
    hidden?: boolean;
    requiredRole?: EntityParticipantRole[];
  }[];
}[];

export const SideNavigation: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { entity, active } = useEntity();
  const appType = getAppType();
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { t } = useTranslations();
  const handleOrganizationChange = useCallback(() => {
    switch (appType) {
      case AppType.edu:
        if (entity?.role === "admin") {
          navigate(`/${entity?.id}/${TabRoute.eduSchoolEdit}`);
        }
        break;
      case AppType.org:
        navigate(`/org-select`);
        break;
      default:
    }
  }, [entity, appType, navigate]);
  // TODO: temp, add check later
  const isReportsEnabled = true;
  const navigationElements: NavigationElements = useMemo(
    () => [
      {
        icon: ChatsIcon,
        title: t({
          id: "side-navigation-component.chat",
          defaultMessage: "Chat",
        }),
        onClick: () => navigate(`/${active?.id}/chats`),
      },
      {
        icon: TasksIcon,
        title: t({
          id: "side-navigation-component.tasks",
          defaultMessage: "Tasks",
        }),
        accordionElements: [
          {
            title: t({
              id: "side-navigation-component.my-tasks",
              defaultMessage: "My Tasks",
            }),
            onClick: () => navigate(`/${active?.id}/tasks/all`),
          },
          ...(isReportsEnabled
            ? [
                {
                  title: "Tasks dashboard",
                  onClick: () => navigate(`/${active?.id}/reports/tasks`),
                },
                {
                  title: "Timeclock dashboard",
                  onClick: () => navigate(`/${active?.id}/reports/timeclock`),
                },
              ]
            : []),
        ],
      },
      {
        icon: PeopleIcon,
        title: t({
          id: "side-navigation-component.people",
          defaultMessage: "People",
        }),
        onClick: () => navigate(`/${active?.id}/people`),
      },
      {
        icon: CallsIcon,
        title: t({
          id: "side-navigation-component.calls",
          defaultMessage: "Calls",
        }),
        hidden: true,
      },
      {
        icon: ScheduleIcon,
        title: t({
          id: "side-navigation-component.schedule",
          defaultMessage: "Schedule",
        }),
        onClick: () => navigate(`/${active?.id}/schedule`),
        requiredRole: [EntityParticipantRole.admin],
      },
      {
        icon: SettingsIcon,
        title: t({
          id: "side-navigation-component.settings",
          defaultMessage: "Settings",
        }),
        accordionElements: [
          {
            title: t({
              id: "side-navigation-component.profile",
              defaultMessage: "My Profile",
            }),
            onClick: () => navigate(`/${active?.id}/settings/edit-profile`),
          },
          {
            title: t({
              id: "side-navigation-component.business-profile",
              defaultMessage: "Business profile",
            }),
            hidden: true,
          },
          {
            title: t({
              id: "side-navigation-component.working-schedule",
              defaultMessage: "My working schedule",
            }),
            hidden: true,
          },
          {
            title: t({
              id: "side-navigation-component.privacy-policy",
              defaultMessage: "Privacy Policy",
            }),
            onClick: () =>
              window.open(`https://jugl.com/privacy-policy-app/`, "_blank"),
          },
          {
            title: t({
              id: "side-navigation-component.delete-account",
              defaultMessage: "Delete Account",
            }),
            hidden: true,
          },
        ],
      },
      {
        icon: LogoutIcon,
        title: t({
          id: "common.log-out",
          defaultMessage: "Log out",
        }),
        onClick: logout,
      },
    ],
    [t, isReportsEnabled, logout, navigate, active?.id]
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="left"
      className="w-[300px]"
    >
      <div className="flex h-full w-full flex-col">
        <div className="bg-primary-600 flex h-[60px] w-full items-center justify-center shadow-md">
          <div className="flex w-full items-center justify-between px-8">
            <JuglIcon />
            <div
              className="bg-primary-700 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
              onClick={onClose}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="bg-primary-700 w-full grow overflow-y-auto">
          {navigationElements
            .filter(
              (accordionItem) =>
                !accordionItem.hidden &&
                (accordionItem.requiredRole
                  ? entity && accordionItem.requiredRole.includes(entity.role)
                  : true)
            )
            .map((navigationItem) => {
              if (navigationItem.accordionElements) {
                return (
                  <Accordion
                    key={navigationItem.title}
                    variant="web"
                    title={
                      <div className="flex items-center gap-3">
                        {React.createElement(navigationItem.icon)}
                        <span className="text-[13px] font-semibold">
                          {navigationItem.title}
                        </span>
                      </div>
                    }
                    className="hover:bg-primary-800 bg-transparent text-white"
                  >
                    {navigationItem.accordionElements
                      .filter(
                        (accordionItem) =>
                          !accordionItem.hidden &&
                          (accordionItem.requiredRole
                            ? entity &&
                              accordionItem.requiredRole.includes(entity.role)
                            : true)
                      )
                      .map((accordionItem) => (
                        <div
                          key={accordionItem.title}
                          className="hover:bg-primary-800 cursor-pointer bg-transparent py-4 pl-20 text-white"
                          onClick={() => {
                            accordionItem.onClick?.();
                            onClose();
                          }}
                        >
                          <span className="text-[13px] font-semibold">
                            {accordionItem.title}
                          </span>
                        </div>
                      ))}
                  </Accordion>
                );
              }
              return (
                <div
                  key={navigationItem.title}
                  className="hover:bg-primary-800 flex cursor-pointer items-center gap-3 px-8 py-4 text-white"
                  onClick={() => {
                    navigationItem.onClick?.();
                    onClose();
                  }}
                >
                  {React.createElement(navigationItem.icon)}
                  <span className="text-[13px] font-semibold">
                    {navigationItem.title}
                  </span>
                </div>
              );
            })}
        </div>
        <InteractiveContainer
          className="bg-primary-700 border-primary-800 flex h-[139px] w-full items-center justify-between border-t-[1px] border-solid px-8"
          onClick={handleOrganizationChange}
        >
          <div className="flex items-center gap-3 truncate">
            <Avatar
              size="lg"
              imageUrl={entity?.display_pic}
              username={entity?.name || ""}
            />
            <span className="text-primary-50 truncate pr-3 font-semibold">
              {entity?.name}
            </span>
          </div>
          <ChevronRightIcon />
        </InteractiveContainer>
      </div>
    </Drawer>
  );
};
