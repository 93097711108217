import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { WorkspacesApiTag } from "./tags";
import { WorkspaceParticipant } from "./types";

export const addWorkspacesApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [WorkspacesApiTag.participants],
  });

  const workspacesApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getWorkspaceParticipants: builder.query<
        PaginatedResponse<WorkspaceParticipant>,
        {
          entityId: string;
          workspaceId: string;
          params: PaginatedRequestParams;
        }
      >({
        query: ({ entityId, workspaceId, params }) => ({
          url: `/api/auth/entity/${entityId}/workspaces/${workspaceId}/participants`,
          params,
        }),
        providesTags: (_result, _error, { entityId, workspaceId }) => [
          {
            type: WorkspacesApiTag.participants,
            id: `${entityId}${workspaceId}`,
          },
        ],
      }),
    }),
    overrideExisting: true,
  });

  return workspacesApi;
};

export type WorkspacesApi = ReturnType<typeof addWorkspacesApi>;
