import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  EduLesson,
  EduMobileNotification,
  useRestApiProvider,
  useEduLessons,
} from "@jugl-web/rest-api";
import { PaginationItem } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { FirebaseContext } from "@web-src/features/app/providers/Firebase";
import { matchRoutes, useLocation } from "react-router-dom";
import { TabRoute } from "@web-src/features/app/types";
import { useLessons } from "@web-src/features/eduLessons/hooks/useLessons";
import { LessonFilterType } from "../components/LessonFilterForm/LessonFilterForm";

type EduLessonsContextType = {
  lessons?: PaginationItem<EduLesson>[];
  isLoading?: boolean;
  setSearchQuery: (query: string) => void;
  load: ReturnType<typeof useEduLessons>["load"];
  bumpLessonCommentsCount: ReturnType<
    typeof useEduLessons
  >["bumpLessonCommentsCount"];
  totalCount: number;
  deleteLesson: ReturnType<typeof useEduLessons>["deleteLesson"];
  updateLesson: ReturnType<typeof useEduLessons>["updateLesson"];
  searchQuery: string | undefined;
  setFilters: (filter: LessonFilterType | undefined) => void;
  filters: LessonFilterType | undefined;
};

const EduLessonsContext = createContext<EduLessonsContextType>(
  {} as EduLessonsContextType
);

const EduLessonsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { entity } = useEntity();
  const [searchQuery, setSearchQuery] = useState<string>();
  const { eduLessonsApi } = useRestApiProvider();
  const [isInitialLoadTriggered, setIsInitialLoadTriggered] =
    useState<boolean>();
  const [filters, setFilters] = useState<LessonFilterType | undefined>(
    undefined
  );

  const { activeLesson } = useLessons();

  const {
    items: lessons,
    isLoading,
    load,
    addLessonById,
    bumpLessonCommentsCount,
    totalCount,
    deleteLesson,
    updateLesson,
  } = useEduLessons({
    searchQuery,
    eduLessonsApi,
    entityId: entity?.id,
    searchParams: filters && {
      spaces: filters?.classes?.toString(),
      subjects: filters?.subjects?.toString(),
    },
  });

  const location = useLocation();
  const matchedRoutes = matchRoutes(
    [
      { path: `/:entityId/${TabRoute.eduLessons}` },
      { path: `/:entityId/${TabRoute.eduLessons}/:lessonId` },
    ],
    location
  );
  useEffect(() => {
    if (isInitialLoadTriggered || !matchedRoutes?.length) {
      return;
    }
    setIsInitialLoadTriggered(true);
    load();
  }, [matchedRoutes, isInitialLoadTriggered, load]);

  const { notifications$ } = useContext(FirebaseContext);
  useEffect(() => {
    const notificationsSubscription = notifications$?.subscribe((e) => {
      const rawNotification = e.data;
      if (!rawNotification || !rawNotification.result) {
        return;
      }
      const notification = {
        ...rawNotification,
        payload: JSON.parse(rawNotification.result),
      } as EduMobileNotification;
      switch (notification.type) {
        case "lesson": {
          if (!notification.payload.id) {
            break;
          }
          addLessonById(notification.payload.id);
          break;
        }
        case "lesson_cmnt": {
          if (!notification.payload.id) {
            break;
          }
          bumpLessonCommentsCount(
            notification.payload.id,
            notification.payload.id === activeLesson?.id
          );
          break;
        }
        default:
      }
    });
    return () => {
      notificationsSubscription?.unsubscribe();
    };
  }, [
    addLessonById,
    bumpLessonCommentsCount,
    notifications$,
    activeLesson?.id,
  ]);

  const value: EduLessonsContextType = useMemo(
    () => ({
      lessons,
      isLoading,
      setSearchQuery,
      load,
      bumpLessonCommentsCount,
      totalCount,
      deleteLesson,
      updateLesson,
      searchQuery,
      setFilters,
      filters,
    }),
    [
      lessons,
      isLoading,
      setSearchQuery,
      load,
      bumpLessonCommentsCount,
      totalCount,
      deleteLesson,
      updateLesson,
      searchQuery,
      setFilters,
      filters,
    ]
  );

  return (
    <EduLessonsContext.Provider value={value}>
      {children}
    </EduLessonsContext.Provider>
  );
};

export { EduLessonsContext, EduLessonsProvider };
