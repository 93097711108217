import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TaskNotificationsSliceState {
  readTasksById: Record<string, boolean>;
  readNotificationsByEntityId: Record<string, boolean>;
}

interface RootStateWithTaskNotifications {
  taskNotifications: TaskNotificationsSliceState;
}

const initialState: TaskNotificationsSliceState = {
  readTasksById: {},
  readNotificationsByEntityId: {},
};

const taskNotificationsSlice = createSlice({
  name: "taskNotifications",
  initialState,
  reducers: {
    readTask: (state, action: PayloadAction<{ taskId: string }>) => {
      state.readTasksById[action.payload.taskId] = true;
    },
    readNotifications: (state, action: PayloadAction<{ entityId: string }>) => {
      state.readNotificationsByEntityId[action.payload.entityId] = true;
    },
    invalidateNotificationsReadFlag: (
      state,
      action: PayloadAction<{ entityId: string }>
    ) => {
      delete state.readNotificationsByEntityId[action.payload.entityId];
    },
  },
});

export const { readTask, readNotifications, invalidateNotificationsReadFlag } =
  taskNotificationsSlice.actions;

export const selectReadTasksById = (state: RootStateWithTaskNotifications) =>
  state.taskNotifications.readTasksById;

export const selectReadNotificationsByEntityId = (
  state: RootStateWithTaskNotifications
) => state.taskNotifications.readNotificationsByEntityId;

export default taskNotificationsSlice;
