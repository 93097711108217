import { FC, ReactNode, useEffect, useRef } from "react";

export interface ClickAwayListenerProps {
  children: ReactNode;
  triggerEvent?: {
    mouse?: "mousedown" | "click";
    touch?: "touchend" | "touchstart";
  };
  isDisabled?: boolean;
  onClickAway: () => void;
  shouldSkipClick?: (clickedElement: Node) => boolean;
}

const TAP_INTERACTIONS_TIME_LIMIT_MS = 200;

export const ClickAwayListener: FC<ClickAwayListenerProps> = ({
  children,
  triggerEvent,
  isDisabled,
  onClickAway,
  shouldSkipClick,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const lastInteractionTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (isDisabled) {
      return undefined;
    }

    const mouseTriggerEvent = triggerEvent?.mouse || "mousedown";
    const touchTriggerEvent = triggerEvent?.touch || "touchstart";

    const handleSaveLastInteraction = () => {
      lastInteractionTimeRef.current = Date.now();
    };

    if (mouseTriggerEvent === "click") {
      document.addEventListener("mousedown", handleSaveLastInteraction);
    }

    if (touchTriggerEvent === "touchend") {
      document.addEventListener("touchstart", handleSaveLastInteraction);
    }

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
      const clickedElement = event.target as Node;

      const isTap = (() => {
        const lastInteractionTime = lastInteractionTimeRef.current;

        if (lastInteractionTime == null) {
          return true;
        }

        lastInteractionTimeRef.current = null;

        return (
          Date.now() - lastInteractionTime <= TAP_INTERACTIONS_TIME_LIMIT_MS
        );
      })();

      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(clickedElement) &&
        isTap &&
        !shouldSkipClick?.(clickedElement)
      ) {
        onClickAway();
      }
    };

    document.addEventListener(mouseTriggerEvent, handleClickAway);
    document.addEventListener(touchTriggerEvent, handleClickAway);

    return () => {
      if (mouseTriggerEvent === "click") {
        document.removeEventListener("mousedown", handleSaveLastInteraction);
      }

      if (touchTriggerEvent === "touchend") {
        document.removeEventListener("touchstart", handleSaveLastInteraction);
      }

      document.removeEventListener(mouseTriggerEvent, handleClickAway);
      document.removeEventListener(touchTriggerEvent, handleClickAway);
    };
  }, [
    onClickAway,
    isDisabled,
    triggerEvent?.mouse,
    triggerEvent?.touch,
    shouldSkipClick,
  ]);

  return <div ref={wrapperRef}>{children}</div>;
};
