import { LiveUpdateEvent, TASK_MODULE } from "../../../live-updates";
import {
  ATTACHMENT_DELETED_ACTION,
  CREATED_ACTION,
  DELETED_ACTION,
  TaskLiveUpdateAttachmentDeletedEvent,
  TaskLiveUpdateCreatedEvent,
  TaskLiveUpdateDeletedEvent,
  TaskLiveUpdateEvent,
  TaskLiveUpdateUpdatedEvent,
  UPDATED_ACTION,
} from "./types";

export const isTaskLiveUpdateEvent = (
  event: LiveUpdateEvent
): event is TaskLiveUpdateEvent => event.module === TASK_MODULE;

export const isTaskCreatedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateCreatedEvent => event.action === CREATED_ACTION;

export const isTaskUpdatedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateUpdatedEvent => event.action === UPDATED_ACTION;

export const isTaskDeletedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateDeletedEvent => event.action === DELETED_ACTION;

export const isTaskAttachmentDeletedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateAttachmentDeletedEvent =>
  event.action === ATTACHMENT_DELETED_ACTION;
