import { cx } from "@jugl-web/utils";
import {
  CSSProperties,
  ElementType,
  forwardRef,
  HTMLProps,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
} from "react";

export interface InteractiveContainerProps
  extends Omit<HTMLProps<HTMLElement>, "as" | "onClick"> {
  children: ReactNode;
  as?: ElementType;
  isDisabled?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => void;
}

export const InteractiveContainer = forwardRef<
  HTMLElement,
  InteractiveContainerProps
>(
  (
    {
      children,
      as: Component = "div",
      isDisabled,
      className,
      style,
      onClick,
      ...props
    },
    ref
  ) => {
    const handleClick = (event: MouseEvent<HTMLElement>) => {
      if (!isDisabled) {
        onClick?.(event);
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
      if (!isDisabled && event.key === "Enter") {
        onClick?.(event);
      }
    };

    return (
      <Component
        ref={ref}
        role="button"
        tabIndex={isDisabled ? -1 : 0}
        className={cx(!isDisabled && "cursor-pointer", className)}
        style={style}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        {...props}
      >
        {children}
      </Component>
    );
  }
);
