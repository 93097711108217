import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import PropTypes from "prop-types";
import { AppType, PageRoute } from "@web-src/features/app/types";
import { getAppType } from "@web-src/features/app/utils";
import Loader from "@web-src/components/Loader";

function PublicRoute({ component }) {
  const { isAuthenticated } = useSelector(selectAuthState);

  switch (isAuthenticated) {
    case true:
      return (
        <Navigate
          to={{
            pathname: `/${
              getAppType() === AppType.edu
                ? PageRoute.schoolSelect
                : PageRoute.orgSelect
            }`,
          }}
        />
      );
    case false:
      return component;
    default:
      return <Loader sx={{ width: "100vw", height: "100vh" }} />;
  }
}

PublicRoute.propTypes = {
  component: PropTypes.node.isRequired,
};

export default PublicRoute;
