import { OptionsObject, useSnackbar } from "notistack";
import { useCallback } from "react";
import { AppVariant } from "../types";

interface UseToastOptions {
  variant?: AppVariant;
}

export const useToast = ({ variant }: UseToastOptions) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const toast = useCallback(
    (message: string, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        variant: "success",
        hideIconVariant: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: variant === "mobile" ? "bottom" : "top",
        },
        className: "justify-center",
        ...options,
      }),
    [enqueueSnackbar, variant]
  );

  return { toast, closeToast: closeSnackbar };
};
