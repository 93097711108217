import {
  EntityParticipantGender,
  EntityParticipantRole,
  EntitySpace,
} from "../entities";

export enum UserStatus {
  active = "active",
}

export type User = {
  email: string | null;
  entity_id: string;
  id: string;
  info: { email: string };
  mobile: string;
  profile?: {
    dob: string;
    first_name: string | null;
    gender: EntityParticipantGender;
    img: string | null;
    last_name: string | null;
  };
  role: EntityParticipantRole;
  spaces: EntitySpace[];
  status: UserStatus;
  updated_at: string;
  username: string | null;
};
