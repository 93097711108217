import { cx, useUniqueDOMId, useWaveSurfer } from "@jugl-web/utils";
import { HTMLAttributes } from "react";
import { PlainButton } from "../../PlainButton";
import { ReactComponent as PauseRecordingIcon } from "./icons/pause-recording.svg";
import { ReactComponent as PlayRecordingIcon } from "./icons/play-recording.svg";

export interface TaskAudioPlayerProps extends HTMLAttributes<HTMLDivElement> {
  audioUrl: string;
}

export const TaskAudioPlayer = ({
  audioUrl,
  className,
  ...props
}: TaskAudioPlayerProps) => {
  const vizContainerId = useUniqueDOMId();
  const { play, playing, pause, durationStr } = useWaveSurfer(audioUrl, {
    container: `#${vizContainerId}`,
    waveColor: "#D2D2D8",
    progressColor: "#2196F3",
    cursorColor: "rgba(0,0,0,0)",
    cursorWidth: 0,
    barWidth: 3,
    barGap: 3,
    barMinHeight: 7,
    barRadius: 2,
    height: 31,
    barHeight: 2,
    hideScrollbar: true,
  });

  return (
    <div
      className={cx(
        "flex w-full items-center justify-between gap-3",
        className
      )}
      {...props}
    >
      <div className="flex grow items-center gap-2">
        {playing ? (
          <PlainButton onClick={pause}>
            <PauseRecordingIcon />
          </PlainButton>
        ) : (
          <PlainButton onClick={play}>
            <PlayRecordingIcon />
          </PlainButton>
        )}
        <div id={vizContainerId} className="grow" style={{ minHeight: 31 }} />
      </div>
      <time className="text-grey leading-2 text-sm font-medium">
        {durationStr}
      </time>
    </div>
  );
};
