import { useMemo } from "react";
import { useEntityUsersAllQuery } from "@web-src/features/entities/entitiesApi";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { getJwtToken } from "@web-src/features/auth/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const useMe = () => {
  const { entity } = useEntity();
  const meJid = getJwtToken()?.data?.jid;
  const { data: usersResponse, isLoading } = useEntityUsersAllQuery(
    entity ? { entityId: entity.id } : skipToken
  );
  const me = useMemo(
    () => usersResponse?.find((item) => item.id === meJid),
    [usersResponse, meJid]
  );
  return { me, isLoading };
};
