import { Dialog, Transition } from "@headlessui/react";
import { cx } from "@jugl-web/utils";
import { Fragment, MutableRefObject, ReactNode } from "react";
import { Backdrop } from "../Backdrop";

export type DrawerPlacement = "top" | "right" | "bottom" | "left";
export type DrawerSize = "auto" | "md" | "full-screen";

const placementToPositionClasses: Record<DrawerPlacement, string> = {
  top: "inset-x-0 top-0 w-full",
  right: "inset-y-0 right-0 h-full",
  bottom: "inset-x-0 bottom-0 w-full",
  left: "inset-y-0 left-0 h-full",
};

const placementToTransitionClasses: Record<
  DrawerPlacement,
  { from: string; to: string }
> = {
  top: { from: "-translate-y-full", to: "translate-y-0" },
  right: { from: "translate-x-full", to: "translate-x-0" },
  bottom: { from: "translate-y-full", to: "translate-y-0" },
  left: { from: "-translate-x-full", to: "translate-x-0" },
};

const sizeAndPlacementToClasses: Record<
  DrawerSize,
  Record<DrawerPlacement, string>
> = {
  auto: { top: "", right: "", bottom: "", left: "" },
  md: {
    top: "h-[480px]",
    right: "w-[480px]",
    bottom: "h-[480px]",
    left: "w-[480px]",
  },
  "full-screen": {
    top: "h-full",
    right: "w-full",
    bottom: "h-full",
    left: "w-full",
  },
};

export interface DrawerProps {
  children: ReactNode;
  isOpen: boolean;
  placement: DrawerPlacement;
  size?: DrawerSize;
  hasBackdrop?: boolean;
  initialFocus?: MutableRefObject<HTMLElement | null>;
  className?: string;
  onClose: () => void;
}

const DRAWER_TRANSITION_DURATION_CLASS_NAME = "duration-200";

export const DRAWER_TRANSITION_DURATION_MS = parseInt(
  DRAWER_TRANSITION_DURATION_CLASS_NAME.match(/\d+/)?.[0] || "0",
  10
);

export const Drawer = ({
  children,
  isOpen,
  placement,
  size = "auto",
  hasBackdrop = true,
  initialFocus,
  className,
  onClose,
}: DrawerProps) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog
      onClose={onClose}
      initialFocus={initialFocus}
      className="jugl__border-box-component"
    >
      <Transition.Child
        as={Fragment}
        enter={cx("transition-opacity", DRAWER_TRANSITION_DURATION_CLASS_NAME)}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave={cx("transition-opacity", DRAWER_TRANSITION_DURATION_CLASS_NAME)}
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Backdrop isVisible={hasBackdrop} />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter={cx(
          "transition-transform",
          DRAWER_TRANSITION_DURATION_CLASS_NAME
        )}
        enterFrom={placementToTransitionClasses[placement].from}
        enterTo={placementToTransitionClasses[placement].to}
        leave={cx(
          "transition-transform",
          DRAWER_TRANSITION_DURATION_CLASS_NAME
        )}
        leaveFrom={placementToTransitionClasses[placement].to}
        leaveTo={placementToTransitionClasses[placement].from}
      >
        <Dialog.Panel
          className={cx(
            placementToPositionClasses[placement],
            sizeAndPlacementToClasses[size][placement],
            "fixed z-50 bg-white",
            className
          )}
          style={{
            boxShadow:
              size !== "full-screen"
                ? "0px 4px 6px rgba(0, 0, 0, 0.04)"
                : undefined,
          }}
        >
          {children}
        </Dialog.Panel>
      </Transition.Child>
    </Dialog>
  </Transition>
);
