import { useCallback, useEffect, useMemo } from "react";
import { usePagination, usePrevious } from "@jugl-web/utils";
import { isEqual } from "lodash";
import { addEduLessonsApi } from "./eduLessonsApi";
import { EduLesson } from "./types";
import { PaginatedResponse } from "../../types";

const pageSize = 15;

export const useEduLessons = ({
  searchQuery,
  searchParams,
  entityId,
  eduLessonsApi,
  noInitialLoad,
}: {
  searchQuery?: string;
  searchParams?: {
    subjects?: string;
    spaces?: string;
  };
  entityId?: string;
  noInitialLoad?: boolean;
  eduLessonsApi: ReturnType<typeof addEduLessonsApi>;
}) => {
  const {
    items: lessons,
    addItems,
    setLastPageState,
    lastPageState,
    deleteItem,
    setIsLoadingState,
    updateItem,
    isLoading: paginationIsLoading,
    isInitialized,
    bumpItem,
  } = usePagination<
    EduLesson,
    Pick<
      PaginatedResponse<unknown>,
      "page_number" | "page_size" | "total_entries" | "total_pages"
    >
  >("lessons");
  const [loadLessons, { isLoading: isLessonsLoading }] =
    eduLessonsApi.useLazyLessonsListQuery();
  const [createLessonApi, { isLoading: createLessonIsLoading }] =
    eduLessonsApi.useCreateLessonMutation();
  const [deleteLessonApi, { isLoading: deleteLessonIsLoading }] =
    eduLessonsApi.useDeleteLessonMutation();
  const previousSearchParams = usePrevious(searchParams);
  const previousSearchQuery = usePrevious(searchQuery);
  const [getLesson] = eduLessonsApi.useLazyGetLessonQuery();

  const handleLoadLessons = useCallback(async () => {
    if (
      !entityId ||
      (lastPageState && lastPageState.page_number === lastPageState.total_pages)
    ) {
      return;
    }
    setIsLoadingState(true);
    const response = await loadLessons({
      entityId,
      params: {
        page: lastPageState?.page_number ? lastPageState.page_number + 1 : 1,
        page_size: pageSize,
        ...searchParams,
      },
    });
    if (!response.data?.data) {
      addItems([]);
      setIsLoadingState(false);
      return;
    }
    const { data, ...lastPageStateToSet } = response.data;
    addItems(data.map((item) => ({ id: item.id, data: item })));
    setLastPageState(lastPageStateToSet);
    setIsLoadingState(false);
  }, [
    searchParams,
    entityId,
    lastPageState,
    setIsLoadingState,
    loadLessons,
    addItems,
    setLastPageState,
  ]);

  const {
    items: lessonsSearchItems,
    addItems: addSearchItems,
    setLastPageState: setSearchLastPageState,
    lastPageState: searchLastPageState,
    setIsLoadingState: setSearchIsLoadingState,
    isLoading: searchIsLoading,
    reset: resetSearch,
    isInitialized: searchIsInitialized,
  } = usePagination<
    EduLesson,
    Pick<
      PaginatedResponse<unknown>,
      "page_number" | "page_size" | "total_entries" | "total_pages"
    >
  >("lessons:search");
  const [loadSearchLessons, { isLoading: isLessonsSearchLoading }] =
    eduLessonsApi.useLazyLessonsSearchQuery();

  const handleLoadSearchLessons = useCallback(async () => {
    if (
      !entityId ||
      (!searchQuery && !searchParams) ||
      (searchLastPageState &&
        searchLastPageState.page_number === searchLastPageState.total_pages) ||
      searchIsLoading
    ) {
      return;
    }
    setSearchIsLoadingState(true);
    const loadFunc = searchQuery ? loadSearchLessons : loadLessons;
    const reqParams = {
      page: searchLastPageState?.page_number
        ? searchLastPageState.page_number + 1
        : 1,
      page_size: pageSize,
      search_term: searchQuery || "",
      ...searchParams,
    };
    if (searchQuery) {
      reqParams.search_term = searchQuery;
    }
    const response = await loadFunc({
      entityId,
      params: reqParams,
    });
    if (!response.data?.data) {
      setSearchIsLoadingState(false);
      return;
    }
    const { data, ...lastPageStateToSet } = response.data;
    addSearchItems(data.map((item) => ({ id: item.id, data: item })));
    setSearchLastPageState(lastPageStateToSet);
    setSearchIsLoadingState(false);
  }, [
    loadLessons,
    searchIsLoading,
    setSearchIsLoadingState,
    searchParams,
    entityId,
    searchLastPageState,
    loadSearchLessons,
    searchQuery,
    addSearchItems,
    setSearchLastPageState,
  ]);

  useEffect(() => {
    handleLoadSearchLessons();
  }, [searchLastPageState, handleLoadSearchLessons]);

  useEffect(() => {
    if (
      !isEqual(searchParams, previousSearchParams) ||
      searchQuery !== previousSearchQuery
    ) {
      resetSearch();
    }
  }, [
    isInitialized,
    previousSearchParams,
    resetSearch,
    searchParams,
    searchQuery,
    previousSearchQuery,
  ]);

  useEffect(() => {
    if (
      noInitialLoad ||
      paginationIsLoading ||
      lessons?.length ||
      lastPageState ||
      isInitialized
    ) {
      return;
    }
    handleLoadLessons();
  }, [
    handleLoadLessons,
    lastPageState,
    lessons?.length,
    paginationIsLoading,
    noInitialLoad,
    isInitialized,
  ]);

  const items = useMemo(
    () => (searchQuery || searchParams ? lessonsSearchItems : lessons),
    [searchQuery, searchParams, lessons, lessonsSearchItems]
  );

  const load = useMemo(
    () =>
      searchQuery || searchParams ? handleLoadSearchLessons : handleLoadLessons,
    [searchQuery, searchParams, handleLoadSearchLessons, handleLoadLessons]
  );

  const isLoading = useMemo(
    () =>
      searchQuery || searchParams ? isLessonsSearchLoading : isLessonsLoading,
    [searchQuery, searchParams, isLessonsSearchLoading, isLessonsLoading]
  );

  const totalCount = useMemo(
    () =>
      searchQuery || searchParams
        ? searchLastPageState?.total_entries || 0
        : lastPageState?.total_entries || 0,
    [searchQuery, searchParams, searchLastPageState, lastPageState]
  );

  const createLesson = useCallback(
    async (data: Parameters<typeof createLessonApi>[0]["data"]) => {
      if (!entityId) {
        return null;
      }
      const response = await createLessonApi({
        entityId,
        data,
      });
      if ("data" in response) {
        const lesson = response.data?.data;
        if (lesson) {
          addItems([{ id: lesson.id, data: lesson }], "start");
        }
      }
      return response;
    },
    [addItems, createLessonApi, entityId]
  );

  const addLessonById = useCallback(
    async (id: string) => {
      if (!entityId) {
        return;
      }
      const response = await getLesson({ entityId, lessonId: id });
      if (!response.data) {
        // TODO: error handling
        return;
      }
      // TODO: re-check with backend
      const lesson = { ...response.data.data };
      lesson.unread_lesson = true;
      addItems([{ id: lesson.id, data: lesson }], "start");
    },
    [addItems, entityId, getLesson]
  );

  const bumpLessonCommentsCount = useCallback(
    async (id: string, dontSetNewIndicator?: boolean) => {
      const findedLesson = items.find((item) => item.id === id)?.data;
      if (!entityId || !findedLesson) {
        return;
      }
      const lesson = await getLesson({ entityId, lessonId: id });
      if (!lesson.data) {
        // TODO: error handling
        return;
      }
      const unreadComments = findedLesson.unread_cmnt_cnt || 0;
      const updatedLesson: EduLesson = {
        ...lesson.data.data,
        unread_cmnt_cnt: dontSetNewIndicator
          ? unreadComments
          : unreadComments + 1,
      };
      updateItem({
        id: updatedLesson.id,
        data: updatedLesson,
      });
      bumpItem(updatedLesson.id);
    },
    [items, updateItem, bumpItem, entityId, getLesson]
  );

  const deleteLesson = useCallback(
    async (lessonId: string) => {
      if (!entityId) {
        return null;
      }
      const response = await deleteLessonApi({ lessonId, entityId });
      if ("data" in response) {
        deleteItem({ itemId: lessonId });
      }
      return response;
    },
    [deleteItem, deleteLessonApi, entityId]
  );

  const updateLesson = useCallback(
    (id: string, lesson: EduLesson) => {
      updateItem({ id, data: lesson });
    },
    [updateItem]
  );

  return {
    items,
    load,
    isLoading,
    totalCount,
    deleteLesson,
    createLesson,
    updateLesson,
    allLoadedItems: lessons,
    createLessonIsLoading,
    deleteLessonIsLoading,
    addLessonById,
    bumpLessonCommentsCount,
    isInitialized,
    deleteItem,
    searchIsInitialized,
  };
};
