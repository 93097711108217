import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePagination } from "@jugl-web/utils";
import {
  selectActiveLesson,
  setActiveLesson as setActiveLessonAction,
} from "../lessonSlice";
import { Lesson } from "../types";

export const useLessons = () => {
  const activeLessonId = useSelector(selectActiveLesson);
  const dispatch = useDispatch();
  const { items: lessons } = usePagination<Lesson, unknown>("lessons");

  const savedActiveLesson = useRef<{ [key: string]: Lesson }>({});

  const activeLesson = useMemo(() => {
    if (!activeLessonId) {
      return undefined;
    }
    const foundLesson = lessons?.find(
      (item) => item.id === activeLessonId
    )?.data;
    if (foundLesson) {
      savedActiveLesson.current = {
        ...savedActiveLesson.current,
        [activeLessonId]: foundLesson,
      };
    }
    return foundLesson || savedActiveLesson.current[activeLessonId];
  }, [activeLessonId, lessons]);

  const setActiveLesson = useCallback(
    (id?: string) => {
      dispatch(setActiveLessonAction(id));
    },
    [dispatch]
  );

  return {
    activeLesson,
    setActiveLesson,
  };
};
