import { useCallback, useEffect, useMemo, useState } from "react";
import { usePagination } from "@jugl-web/utils";
import { addEduFeedsApi } from "./eduFeedsApi";
import { EduFeed } from "./types";
import { PaginatedResponse } from "../../types";

const pageSize = 15;

export const useEduFeeds = ({
  searchQuery,
  entityId,
  eduFeedsApi,
  noInitialLoad,
}: {
  searchQuery?: string;
  entityId?: string;
  noInitialLoad?: boolean;
  eduFeedsApi: ReturnType<typeof addEduFeedsApi>;
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const {
    items: feeds,
    addItems,
    setLastPageState,
    lastPageState,
    deleteItem,
    setIsLoadingState,
    isLoading: paginationIsLoading,
    updateItem,
    bumpItem,
    reset,
  } = usePagination<
    EduFeed,
    Pick<
      PaginatedResponse<unknown>,
      "page_number" | "page_size" | "total_entries" | "total_pages"
    >
  >(`feeds:${entityId}`);
  const [loadFeeds, { isLoading: isFeedsLoading }] =
    eduFeedsApi.useLazyFeedsListQuery();
  const [createFeedApi, { isLoading: createFeedIsLoading }] =
    eduFeedsApi.useCreateFeedMutation();
  const [deleteFeedApi, { isLoading: deleteFeedIsLoading }] =
    eduFeedsApi.useDeleteFeedMutation();
  const [getFeed] = eduFeedsApi.useLazyGetFeedQuery();

  const handleLoadFeeds = useCallback(async () => {
    if (
      !entityId ||
      (lastPageState && lastPageState.page_number === lastPageState.total_pages)
    ) {
      return;
    }
    if (!isInitialized) {
      setIsInitialized(true);
    }
    setIsLoadingState(true);
    const response = await loadFeeds({
      entityId,
      params: {
        page: lastPageState?.page_number ? lastPageState.page_number + 1 : 1,
        page_size: pageSize,
      },
    });
    if (!response.data?.data) {
      setIsLoadingState(false);
      return;
    }
    const { data, ...lastPageStateToSet } = response.data;
    addItems(data.map((item) => ({ id: item.id, data: item })));
    setLastPageState(lastPageStateToSet);
    setIsLoadingState(false);
  }, [
    entityId,
    lastPageState,
    isInitialized,
    setIsLoadingState,
    loadFeeds,
    addItems,
    setLastPageState,
  ]);

  const {
    items: feedsSearchItems,
    addItems: addSearchItems,
    setLastPageState: setSearchLastPageState,
    lastPageState: searchLastPageState,
    reset: resetSearch,
  } = usePagination<
    EduFeed,
    Pick<
      PaginatedResponse<unknown>,
      "page_number" | "page_size" | "total_entries" | "total_pages"
    >
  >("feeds:search");
  const [loadSearchFeeds, { isLoading: isFeedsSearchLoading }] =
    eduFeedsApi.useLazyFeedsSearchQuery();

  const handleLoadSearchFeeds = useCallback(async () => {
    if (!entityId || !searchQuery) {
      return;
    }
    const response = await loadSearchFeeds({
      entityId,
      params: {
        page: searchLastPageState?.page_number
          ? searchLastPageState.page_number + 1
          : 1,
        page_size: pageSize,
        search_term: searchQuery,
      },
    });
    if (!response.data?.data) {
      return;
    }
    const { data, ...lastPageStateToSet } = response.data;
    addSearchItems(data.map((item) => ({ id: item.id, data: item })));
    setSearchLastPageState(lastPageStateToSet);
  }, [
    entityId,
    searchLastPageState,
    loadSearchFeeds,
    searchQuery,
    addSearchItems,
    setSearchLastPageState,
  ]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (!searchQuery && feedsSearchItems) {
      resetSearch();
    } else if (searchQuery && !isFeedsLoading && !feedsSearchItems?.length) {
      handleLoadSearchFeeds();
    }
  }, [
    searchQuery,
    handleLoadSearchFeeds,
    isFeedsLoading,
    feedsSearchItems,
    resetSearch,
    isInitialized,
  ]);

  useEffect(() => {
    if (
      noInitialLoad ||
      paginationIsLoading ||
      feeds?.length ||
      lastPageState ||
      isInitialized
    ) {
      return;
    }
    handleLoadFeeds();
  }, [
    handleLoadFeeds,
    lastPageState,
    feeds?.length,
    paginationIsLoading,
    noInitialLoad,
    isInitialized,
  ]);

  const items = useMemo(
    () => (searchQuery ? feedsSearchItems : feeds),
    [searchQuery, feeds, feedsSearchItems]
  );

  const load = useMemo(
    () => (searchQuery ? handleLoadSearchFeeds : handleLoadFeeds),
    [searchQuery, handleLoadSearchFeeds, handleLoadFeeds]
  );

  const isLoading = useMemo(
    () => (searchQuery ? isFeedsSearchLoading : isFeedsLoading),
    [searchQuery, isFeedsSearchLoading, isFeedsLoading]
  );

  const totalCount = useMemo(
    () =>
      searchQuery
        ? searchLastPageState?.total_entries || 0
        : lastPageState?.total_entries || 0,
    [searchQuery, searchLastPageState, lastPageState]
  );

  const createFeed = useCallback(
    async (data: Parameters<typeof createFeedApi>[0]["data"]) => {
      if (!entityId) {
        return null;
      }
      const response = await createFeedApi({
        entityId,
        data,
      });
      if ("data" in response) {
        const feed = response.data?.data;
        if (feed) {
          addItems([{ id: feed.id, data: feed }], "start");
        }
      }
      return response;
    },
    [addItems, createFeedApi, entityId]
  );

  const deleteFeed = useCallback(
    async (feedId: string) => {
      if (!entityId) {
        return null;
      }
      const response = await deleteFeedApi({ feedId, entityId });
      if ("data" in response) {
        deleteItem({ itemId: feedId });
      }
      return response;
    },
    [deleteItem, deleteFeedApi, entityId]
  );

  const updateFeed = useCallback(
    (id: string, feed: EduFeed) => {
      updateItem({ id, data: feed });
    },
    [updateItem]
  );

  const addFeedById = useCallback(
    async (id: string) => {
      if (!entityId) {
        return;
      }
      const response = await getFeed({ entityId, feedId: id });
      if (!response.data) {
        // TODO: error handling
        return;
      }
      // TODO: re-check with backend
      const feed = { ...response.data.data };
      feed.unread_post = true;
      addItems([{ id: feed.id, data: feed }], "start");
    },
    [addItems, entityId, getFeed]
  );

  const bumpFeedCommentsCount = useCallback(
    (id: string, dontSetNewIndicator?: boolean) => {
      const feed = items.find((item) => item.id === id)?.data;
      if (!feed) {
        return;
      }
      const updatedFeed: EduFeed = {
        ...feed,
        comments_cnt: (feed.comments_cnt || 0) + 1,
      };
      if (!dontSetNewIndicator) {
        updatedFeed.unread_cmnt = true;
      }
      updateItem({
        id: updatedFeed.id,
        data: updatedFeed,
      });
      bumpItem(updatedFeed.id);
    },
    [items, updateItem, bumpItem]
  );

  const clearIndicators = useCallback(
    (id: string) => {
      const feed = items.find((item) => item.id === id)?.data;
      if (!feed) {
        return;
      }
      updateItem({
        id: feed.id,
        data: {
          ...feed,
          unread_cmnt: false,
          unread_post: false,
        },
      });
    },
    [items, updateItem]
  );

  return {
    items,
    load,
    isLoading,
    totalCount,
    updateFeed,
    deleteFeed,
    createFeed,
    allLoadedItems: feeds,
    createFeedIsLoading,
    deleteFeedIsLoading,
    isInitialized,
    bumpFeedCommentsCount,
    addFeedById,
    clearIndicators,
    reset,
    deleteItem,
  };
};
