export enum Options {
  AM = "AM",
  PM = "PM",
}

export enum TimeName {
  Date = "date",
  DayPeriod = "dayPeriod",
  Hour = "hour",
  Minute = "minute",
  Month = "month",
  Year = "year",
}
