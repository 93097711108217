import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import authSlice from "@web-src/features/auth/authSlice";
import chatsSlice from "@web-src/features/chats/chatsSlice";
import lessonSlice from "@web-src/features/eduLessons/lessonSlice";
import appSlice from "@web-src/features/app/appSlice";
import assignmentSlice from "@web-src/features/eduAssignments/assignmentSlice";
import librarySlice from "@web-src/features/library/librarySlice";
import tasksNotificationsSlice from "@jugl-web/domain-resources/tasks/taskNotificationsSlice";
import { emptySplitApi } from "@web-src/features/api/createApi";
import { paginationSlice } from "@jugl-web/utils";

const combinedReducers = combineReducers({
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  [appSlice.name]: appSlice.reducer,
  [chatsSlice.name]: chatsSlice.reducer,
  [lessonSlice.name]: lessonSlice.reducer,
  [assignmentSlice.name]: assignmentSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [librarySlice.name]: librarySlice.reducer,
  [tasksNotificationsSlice.name]: tasksNotificationsSlice.reducer,
  [paginationSlice.name]: paginationSlice.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "auth/logout/fulfilled") {
    state = undefined;
  }
  return combinedReducers(state, action);
};

const middleware = [emptySplitApi.middleware];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
