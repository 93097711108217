import { styled } from "@mui/material/styles";

import Stack from "@mui/material/Stack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@web-src/components/IconButton";
import { grey, primary } from "@web-src/themes/colors";

export const arrowDownStyle = styled(KeyboardArrowDownIcon)({
  color: primary[100],
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "24px",
});

export const TimeSeparator = styled("div")({
  fontSize: "36px",
  fontWeight: "bold",
  display: "block",
  verticalAlign: "top",
  marginTop: "-6px",
  textAlign: "center",
});

export const StackWrapper = styled(Stack)({
  "& .MuiInputBase-root.Mui-focused": {
    background: primary[50],
    color: primary[500],
  },
  "& .MuiButtonBase-root.MuiIconButton-root": {
    background: "none",
  },
  "& .arrowIcon": {
    color: grey[700],
    display: "block",
    margin: "auto",
    "&:hover, &:active": {
      color: primary[500],
    },
  },
});

export const HiddenButton = styled(IconButton)({
  display: "none",
});
