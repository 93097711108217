import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

type TriggerNotificationParams = {
  body?: string;
  tag?: string;
  onClick?: () => void;
};

const PushNotificationsContext = createContext<{
  triggerPushNotification?: (
    title: string,
    params?: TriggerNotificationParams
  ) => void;
}>({});

const PushNotificationsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [canPlaySound, setCanPlaySound] = useState<boolean>(false);
  useEffect(() => {
    const mouseOverCallback = () => {
      setCanPlaySound(true);
      document.body.removeEventListener("click", mouseOverCallback);
    };
    document.body.addEventListener("click", mouseOverCallback);
  }, []);
  const triggerPushNotification = useCallback(
    (title: string, params?: TriggerNotificationParams) => {
      if (canPlaySound) {
        new Audio("/notification.mp3").play();
      }
      if (document.visibilityState === "visible") {
        return;
      }
      // TODO: permissions
      // eslint-disable-next-line no-new
      const notification = new Notification(title, {
        body: params?.body,
        tag: params?.tag,
      });
      notification.onclick = () => {
        window.focus();
        params?.onClick?.();
      };
    },
    [canPlaySound]
  );
  const value = useMemo(
    () => ({ triggerPushNotification }),
    [triggerPushNotification]
  );
  return (
    <PushNotificationsContext.Provider value={value}>
      {children}
    </PushNotificationsContext.Provider>
  );
};

export { PushNotificationsContext, PushNotificationsProvider };
