import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { ReactComponent as ChevronDown } from "@web-src/features/calls/icons/chevron-down.svg";
import { ReactComponent as CloseIcon } from "@web-src/features/calls/icons/close.svg";
import { ReactComponent as MicIcon } from "@web-src/features/calls/icons/mic-on-settings.svg";
import { ReactComponent as BlurIcon } from "@web-src/features/calls/icons/settings-blur.svg";
import { ReactComponent as ImageBgIcon } from "@web-src/features/calls/icons/settings-image-bg.svg";
import { ReactComponent as NoSymbolIcon } from "@web-src/features/calls/icons/settings-no-symbol.svg";
import { ReactComponent as CameraIcon } from "@web-src/features/calls/icons/video-on-settings.svg";
import cx from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RadioGroup, Tab } from "@headlessui/react";
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import AgoraRTC, { ICameraVideoTrack } from "agora-rtc-sdk-ng";
import { CallsContext } from "../../providers/Calls";

const virtualBackgroundExtension = new VirtualBackgroundExtension();
AgoraRTC.registerExtensions([virtualBackgroundExtension]);
const processor = virtualBackgroundExtension.createProcessor();
processor.init("../../assets/agora-wasm.wasm");
processor.setOptions({ type: "blur", blurDegree: 2 });
processor.enable();

const ActiveCallDeviceSelector: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const getSettings =
    localStorage.getItem("calls_settings") &&
    JSON.parse(localStorage.getItem("calls_settings") || "{}");
  const { audioDevices, videoDevices, activeCall } = useContext(CallsContext);
  const [selectedPanel, setSelectedPanel] = useState<number>(0);
  const [selectedAudio, setSelectedAudio] = useState<string>();
  const [selectedVideo, setSelectedVideo] = useState<string>();
  const [storedSettings, setStoredSettings] = useState<{
    camera_id: string;
    microphone_id: string;
  }>(getSettings);
  const [cameraSettings, setCameraSettings] = useState<string>(
    activeCall?.processor.enabled ? "blur" : "none"
  );
  const localSettingsVideoTrack = useRef<ICameraVideoTrack>();
  const videoImageBgInput: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  useEffect(() => {
    if (selectedAudio && activeCall?.audioEnabled) {
      activeCall?.localAudioTrack?.setDevice(selectedAudio);
    }
    if (selectedVideo && activeCall?.videoEnabled) {
      activeCall?.localVideoTrack?.setDevice(selectedVideo);
    }
  }, [
    activeCall?.videoEnabled,
    activeCall?.localVideoTrack,
    selectedVideo,
    selectedAudio,
    activeCall?.audioEnabled,
    activeCall?.localAudioTrack,
  ]);

  useEffect(() => {
    if (storedSettings) {
      const isCameraPresent = videoDevices?.find(
        (camera) => camera.deviceId === storedSettings?.camera_id
      );
      const isMicrophonePresent = audioDevices?.find(
        (mic) => mic.deviceId === storedSettings?.microphone_id
      );
      if (isCameraPresent) {
        setSelectedVideo(storedSettings.camera_id);
      }
      if (isMicrophonePresent) {
        setSelectedAudio(storedSettings.microphone_id);
      }
    }
  }, [audioDevices, storedSettings, videoDevices]);

  useEffect(() => () => localSettingsVideoTrack?.current?.close(), []);
  useEffect(() => {
    (async () => {
      if (videoDevices && isOpen && !localSettingsVideoTrack.current) {
        const settingsVideoTrack = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: "720p",
        });
        localSettingsVideoTrack.current = settingsVideoTrack;
      }
      if (selectedPanel === 1 && localSettingsVideoTrack.current) {
        if (selectedVideo) {
          localSettingsVideoTrack?.current?.setDevice(selectedVideo);
        }
        localSettingsVideoTrack?.current?.play("settingslocalplayer", {
          fit: "cover",
        });

        localSettingsVideoTrack.current
          ?.pipe(processor)
          .pipe(localSettingsVideoTrack.current?.processorDestination);
      }
      if (!isOpen || selectedPanel !== 1) {
        await localSettingsVideoTrack.current?.close();
        localSettingsVideoTrack.current = undefined;
      }
    })();
  }, [
    selectedPanel,
    videoDevices,
    isOpen,
    localSettingsVideoTrack,
    selectedVideo,
  ]);

  const loadImage = async (url: string) => {
    const bgImage = new Image();
    bgImage.src = url;
    bgImage.crossOrigin = "anonymous";
    await bgImage.decode();
    return bgImage;
  };

  const handleVideoBackgroundImage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const file = e && e?.target?.files?.length && e.target.files[0];
    const blobURL = (file && URL.createObjectURL(file)) || "";
    const image = await loadImage(blobURL);
    const options = { type: "img", source: image };
    await processor.setOptions(options);
    await activeCall?.processor.setOptions(options);
    processor.enable();
    activeCall?.processor.enable();
    if (videoImageBgInput && videoImageBgInput.current) {
      videoImageBgInput.current.value = "";
    }
  };
  const handleBackgroundBlur = () => {
    const options = { type: "blur", blurDegree: 2 };
    processor.setOptions({ type: "blur", blurDegree: 2 });
    activeCall?.processor.setOptions(options);
    processor.enable();
    activeCall?.processor.enable();
  };
  const handleNoVideoFilters = () => {
    activeCall?.processor.disable();
    processor.disable();
  };
  const handleCameraSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    activeCall?.localVideoTrack?.setDevice(e.target.value);
    localSettingsVideoTrack?.current?.setDevice(e.target.value);
    const settingsNewState = { ...storedSettings, camera_id: e.target.value };
    setSelectedVideo(e.target.value);
    setStoredSettings(settingsNewState);
    localStorage.setItem("calls_settings", JSON.stringify(settingsNewState));
  };
  const handleMicrophoneSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    activeCall?.localAudioTrack?.setDevice(e.target.value);
    setSelectedAudio(e.target.value);
    const settingsNewState = {
      ...storedSettings,
      microphone_id: e.target.value,
    };
    setStoredSettings(settingsNewState);
    localStorage.setItem("calls_settings", JSON.stringify(settingsNewState));
  };
  return (
    <div
      className={cx(
        "relative flex h-[380px] w-[590px] flex-row gap-6 rounded-xl bg-white ",
        {
          hidden: !isOpen,
        }
      )}
    >
      <Tab.Group selectedIndex={selectedPanel} onChange={setSelectedPanel}>
        <div className="border-grey min-h-[300px] border-r-[1px] border-l-0 border-b-0 border-t-0 border-solid border-opacity-10 px-4">
          <Text variant="h4" className="mr-8">
            Settings
          </Text>
          <Tab.List>
            <Tab className="ui-selected:bg-primary-50 flex w-full cursor-pointer flex-row items-center justify-start gap-2 rounded-lg border-transparent bg-transparent px-2 py-1">
              <MicIcon className="fill-primary" />
              <Text variant="body3">Audio</Text>
            </Tab>

            <Tab className="ui-selected:bg-primary-50 flex w-full cursor-pointer flex-row items-center justify-start gap-2 rounded-lg border-transparent bg-transparent px-2 py-1">
              <CameraIcon className="fill-primary" />
              <Text variant="body3">Video</Text>
            </Tab>
          </Tab.List>
        </div>
        <div className="absolute right-7 top-7">
          <CloseIcon onClick={onClose} className="cursor-pointer" />
        </div>
        <Tab.Panels className="overflow-hidden">
          <Tab.Panel className="min-w-max pr-16 pt-16">
            {audioDevices && (
              <div className="flex max-w-full flex-row items-center">
                <div className="border-grey-400 relative w-[340px] overflow-hidden border border-t-0 border-l-0 border-r-0 border-solid py-2 pr-8">
                  <label
                    htmlFor="id_audio"
                    className="relative flex flex-col gap-2"
                  >
                    <span
                      className="text-primary pl-2 text-xs uppercase"
                      style={{ fontVariant: "all-small-caps" }}
                    >
                      select audio
                    </span>
                    <select
                      className="text-dark w-[320px] cursor-pointer appearance-none text-ellipsis border-none bg-transparent py-1 pr-2 pl-3 text-base font-medium leading-tight focus:outline-none"
                      title="audio"
                      id="id_audio"
                      value={selectedAudio}
                      onChange={handleMicrophoneSelect}
                    >
                      <option disabled selected={!selectedAudio}>
                        Select audio input
                      </option>

                      {audioDevices.map((item) => (
                        <option key={item.deviceId} value={item.deviceId}>
                          {item.label} {item.label}
                        </option>
                      ))}
                    </select>
                  </label>
                  <ChevronDown className="fill-grey absolute bottom-2 right-0 mr-1 cursor-pointer" />
                </div>
              </div>
            )}
          </Tab.Panel>
          <Tab.Panel className="min-w-max pr-16 pt-16">
            {videoDevices && (
              <div className="mb-8 flex flex-row items-center">
                <div className="border-grey-400 relative w-[340px] border border-t-0 border-l-0 border-r-0 border-solid py-2">
                  <label htmlFor="id_video" className="flex flex-col gap-2">
                    <span
                      className="text-primary pl-2 text-xs uppercase"
                      style={{ fontVariant: "all-small-caps" }}
                    >
                      select video
                    </span>
                    <select
                      className="text-dark w-[320px] cursor-pointer appearance-none overflow-hidden text-ellipsis border-none bg-transparent py-1 px-2 pl-3 text-base font-medium leading-tight focus:outline-none"
                      title="video"
                      id="id_video"
                      value={selectedVideo}
                      onChange={handleCameraSelect}
                      placeholder="test"
                    >
                      <option disabled selected={!selectedVideo}>
                        Select video input
                      </option>
                      {videoDevices.map((item) => (
                        <option key={item.deviceId} value={item.deviceId}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </label>
                  <ChevronDown className="fill-grey absolute bottom-2 right-0 mr-1 cursor-pointer" />
                </div>
              </div>
            )}
            <span style={{ fontVariant: "all-small-caps" }}>
              <Text variant="text2Regular" className="text-dark ml-2 uppercase">
                Apply visual effects
              </Text>
            </span>
            <div className="mb-4 flex flex-row gap-4">
              <div
                className="h-[180px] w-[340px] rounded-lg border-2"
                id="settingslocalplayer"
              />
              <RadioGroup
                value={cameraSettings}
                onChange={setCameraSettings}
                className="flex flex-col gap-4"
              >
                <RadioGroup.Option value="image-background">
                  <span className="ui-checked:bg-primary-50 bg-grey-100 ui-checked:border-solid border-primary ui-checked:stroke-primary stroke-grey relative flex cursor-pointer rounded-lg border-[1px] py-3 px-4">
                    <input
                      type="file"
                      accept="image/*"
                      className="absolute top-0 bottom-0 right-0 left-0 z-20 opacity-0"
                      onChange={handleVideoBackgroundImage}
                      ref={videoImageBgInput}
                    />
                    <ImageBgIcon />
                  </span>
                </RadioGroup.Option>
                <RadioGroup.Option value="blur" onClick={handleBackgroundBlur}>
                  <span className="ui-checked:bg-primary-50 bg-grey-100 ui-checked:border-solid border-primary ui-checked:fill-primary flex cursor-pointer rounded-lg border-[1px] py-3 px-4">
                    <BlurIcon />
                  </span>
                </RadioGroup.Option>
                <RadioGroup.Option value="none" onClick={handleNoVideoFilters}>
                  <span className="ui-checked:bg-primary-50 bg-grey-100 ui-checked:border-solid border-primary ui-checked:stroke-primary stroke-grey flex cursor-pointer rounded-lg border-[1px] py-3 px-4">
                    <NoSymbolIcon />
                  </span>
                </RadioGroup.Option>
              </RadioGroup>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ActiveCallDeviceSelector;
