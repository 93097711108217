import { AxiosResponse } from "axios";

type APIError = AxiosResponse<{
  errors: {
    error_code: string;
    error_description: string;
    error_details: string;
  };
}>;

export function isAPIError(error: unknown): error is APIError {
  return !!(error && typeof error === "object" && "data" in error);
}
