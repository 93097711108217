import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "@web-src/features/app/components/PrivateRoute";
import EditEducationForm from "../components/EditEducationForm";
import CurrentCityForm from "../components/CurrentCityForm";
import ExperienceForm from "../components/ExperienceForm";
import ServiceForm from "../components/ServiceForm";
import AchievementsForm from "../components/AchievementsForm";
import CertificationsForm from "../components/CertificationsForm";
import AwardsForm from "../components/AwardsForm";
import BirthPlaceForm from "../components/BirthPlaceForm";
import { EditProfileTiles } from "../types";
import PersonalInfoForm from "../components/PersonalInfoForm";

export default [
  <Route
    key={EditProfileTiles.PersonalInfo}
    path={EditProfileTiles.PersonalInfo}
    element={<PrivateRoute component={<PersonalInfoForm />} />}
  />,
  <Route
    key={EditProfileTiles.Education}
    path={EditProfileTiles.Education}
    element={<PrivateRoute component={<EditEducationForm />} />}
  />,
  <Route
    key={EditProfileTiles.CurrentCity}
    path={EditProfileTiles.CurrentCity}
    element={<PrivateRoute component={<CurrentCityForm />} />}
  />,
  <Route
    key={EditProfileTiles.BirthPlace}
    path={EditProfileTiles.BirthPlace}
    element={<PrivateRoute component={<BirthPlaceForm />} />}
  />,
  <Route
    key={EditProfileTiles.Awards}
    path={EditProfileTiles.Awards}
    element={<PrivateRoute component={<AwardsForm />} />}
  />,
  <Route
    key={EditProfileTiles.Certifications}
    path={EditProfileTiles.Certifications}
    element={<PrivateRoute component={<CertificationsForm />} />}
  />,
  <Route
    key={EditProfileTiles.Achievements}
    path={EditProfileTiles.Achievements}
    element={<PrivateRoute component={<AchievementsForm />} />}
  />,
  <Route
    key={EditProfileTiles.Service}
    path={EditProfileTiles.Service}
    element={<PrivateRoute component={<ServiceForm />} />}
  />,
  <Route
    key={EditProfileTiles.Experience}
    path={EditProfileTiles.Experience}
    element={<PrivateRoute component={<ExperienceForm />} />}
  />,
];
