import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { UsersApiTag } from "./tags";
import { User, UserStatus } from "./types";

export const addUsersApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [UsersApiTag.users],
  });

  const usersApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getUsers: builder.query<
        PaginatedResponse<User>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            role?: string;
            spaces?: string;
            status?: UserStatus;
            time?: string;
            sort_by?: "name";
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/users`,
          params,
        }),
        providesTags: (_result, _error, { entityId, params }) => [
          {
            type: UsersApiTag.users,
            id: `${entityId}${JSON.stringify(params)}`,
          },
        ],
      }),
      getCPanelUsers: builder.query<
        PaginatedResponse<User>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            role?: string;
            spaces?: string;
            status?: string;
            time?: string;
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/cpanel/users`,
          params,
        }),
        providesTags: (_result, _error, { entityId, params }) => [
          {
            type: UsersApiTag.users,
            id: `${entityId}${JSON.stringify(params)}`,
          },
        ],
      }),
    }),
    overrideExisting: true,
  });

  return usersApi;
};

export type UsersApi = ReturnType<typeof addUsersApi>;
