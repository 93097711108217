import { AppType } from "@web-src/features/app/types";

export type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

export type Environment = {
  apiUrl: string;
  wsDomain: string;
  production: boolean;
  appType: AppType;
  firebaseConfig: FirebaseConfig;
  firebaseVapidKey: string;
  firebaseTokenId: string;
  sentryDsn: string;
  geocodingKey: string;
  mapTilerKey: string;
  agoraAppId: string;
  supersetDomain: string;
  isInternationalizationEnabled: boolean;
  supersetDashboardIds: { [key: string]: string };
};

export const devApiUrl = "https://api-dev.jugl.com";

export const devWsDomain = "api-dev.jugl.com";

export const devFirebaseConfig: FirebaseConfig = {
  apiKey: "AIzaSyBRxeHe9et0iQSg8hjqABHLneRB16KpKpw",
  authDomain: "joiint-dev.firebaseapp.com",
  projectId: "joiint-dev",
  storageBucket: "joiint-dev.appspot.com",
  messagingSenderId: "575187276418",
  appId: "1:575187276418:web:2f89ba70d82ce3532dda9b",
  measurementId: "G-2328P2KTQ6",
};

export const devFirebaseVapidKey =
  "BM6ys8ozWaGT4ekoj8HSsQ5oUQbPcn9IYGOt9z8TV2y1eDkNERdwEnBj20uzOeFDCRKlBxE3dNiksCrKnO39CWA";

export const devSentryDsn =
  "https://10aac763af2041d89aa189eab27eebaa@o1211156.ingest.sentry.io/4504668249587712";

export const devFirebaseTokenId = "com.jugl.web.dev";

export const devAgoraAppId = "3dde46fad16d4a7b9a9e9f0376306dd8";

export const devSupersetDomain = "https://analytics-dev.jugl.com";
export const prodSupersetDomain = "https://analytics.jugl.com";

export const prodApiUrl = "https://api.jugl.com";

export const prodWsDomain = "api.jugl.com";

export const prodFirebaseConfig: FirebaseConfig = {
  apiKey: "AIzaSyDQA0ddPIu0a0po7SryyTQKsF1aZCYi0y8",
  authDomain: "joiint-prod.firebaseapp.com",
  projectId: "joiint-prod",
  storageBucket: "joiint-prod.appspot.com",
  messagingSenderId: "1005857424797",
  appId: "1:1005857424797:web:d24a8b57b9a789a6e21b74",
  measurementId: "G-S1Z092BL1C",
};

export const prodFirebaseVapidKey =
  "BNFS9GATzcBtaCvS19S-Zc751ZD9rM3mkbvMSBtPsP1iiTBhf1yODVABGmX7NganW2t37XYQUIdPyPb6-OZZMWE";

export const prodSentryDsn =
  "https://92555c4a40eb4c71b5bad6c367febba3@o1211156.ingest.sentry.io/4504668252471296";

export const prodFirebaseTokenId = "com.jugl.web";

export const geocodingKey = "AIzaSyCoYdp_FO7BA69iS1fe0W3Hz5ueIoHOtmM";

export const mapTilerKey = "o3Co0WVwgIDPLG1L8IlJ";

export const prodAgoraAppId = "1fbe2021f4724da9b1681c7141f3bf2d";

export const devSupersetDashboardIds = {
  tasks: "bfc9ccae-db4b-4b40-b317-690668764f70",
  timeclock: "68895246-e916-4514-b6e1-062590ee6063",
};

export const prodSupersetDashboardIds = {
  tasks: "12c3c5c8-62c9-46ba-83f0-850d0326b80f",
  timeclock: "e2eeccc0-f32e-4a2a-a76a-52fd4beb7eee",
};
