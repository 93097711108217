import { useCallback, useEffect, useMemo } from "react";
import { usePagination, usePrevious } from "@jugl-web/utils";
import isEqual from "lodash/isEqual";
import { addEduAssignmentsApi } from "./eduAssignmentsApi";
import { EduAssignment, EduAssignmentCategory } from "./types";
import { PaginatedResponse } from "../../types";

const pageSize = 20;

export const useEduAssignments = ({
  searchParams,
  entityId,
  eduAssignmentsApi,
  noInitialLoad,
  category,
}: {
  searchParams?: {
    spaces?: string | "all";
    subjects?: string | "all";
    search_term?: string;
  };
  entityId?: string;
  noInitialLoad?: boolean;
  eduAssignmentsApi: ReturnType<typeof addEduAssignmentsApi>;
  category?: EduAssignmentCategory;
}) => {
  const {
    items: assignments,
    addItems,
    setLastPageState,
    lastPageState,
    setIsLoadingState,
    updateItem,
    bumpItem,
    reset,
    isInitialized,
    setInitState,
    deleteItem,
  } = usePagination<
    EduAssignment,
    Pick<
      PaginatedResponse<unknown>,
      "page_number" | "page_size" | "total_entries" | "total_pages"
    >
  >(`assignments${category ? `:${category}` : ""}`);
  const [
    loadAssignments,
    { isLoading: isAssignmentsLoading, isError: isAssignmentsError },
  ] = eduAssignmentsApi.useLazyAssignmentsListQuery();
  const [createAssignmentApi, { isLoading: createAssignmentIsLoading }] =
    eduAssignmentsApi.useCreateAssignmentMutation();
  const [
    createAssignmentSubmissionApi,
    { isLoading: createAssignmentSubmissionIsLoading },
  ] = eduAssignmentsApi.useCreateAssignmentSubmissionMutation();
  const [getAssignment] = eduAssignmentsApi.useLazyGetAssignmentQuery();
  const [deleteAssignmentApi] = eduAssignmentsApi.useDeleteAssignmentMutation();

  const handleLoadAssignments = useCallback(async () => {
    if (
      !entityId ||
      (lastPageState && lastPageState.page_number === lastPageState.total_pages)
    ) {
      return;
    }
    if (!isInitialized) {
      setInitState(true);
    }

    setIsLoadingState(true);
    const response = await loadAssignments({
      entityId,
      params: {
        page: lastPageState?.page_number ? lastPageState.page_number + 1 : 1,
        page_size: pageSize,
        category,
        subjects: "all",
        spaces: "all",
      },
    });
    if (!response.data?.data) {
      setIsLoadingState(false);
      return;
    }
    const { data, ...lastPageStateToSet } = response.data;
    addItems(data.map((item) => ({ id: item.id, data: item })));
    setLastPageState(lastPageStateToSet);
    setIsLoadingState(false);
  }, [
    entityId,
    lastPageState,
    isInitialized,
    setIsLoadingState,
    loadAssignments,
    category,
    addItems,
    setLastPageState,
    setInitState,
  ]);

  const {
    items: assignmentsSearchItems,
    addItems: addSearchItems,
    setLastPageState: setSearchLastPageState,
    lastPageState: searchLastPageState,
    reset: resetSearch,
    isLoading: searchIsLoading,
    setIsLoadingState: setSearchIsLoadingState,
    isInitialized: searchIsInitialized,
    setInitState: setSearchIsInitialized,
  } = usePagination<
    EduAssignment,
    Pick<
      PaginatedResponse<unknown>,
      "page_number" | "page_size" | "total_entries" | "total_pages"
    >
  >(`assignments:search${category ? `:${category}` : ""}`);
  const [loadSearchAssignments, { isLoading: isAssignmentsSearchLoading }] =
    eduAssignmentsApi.useLazyAssignmentsSearchQuery();
  const previousSearchParams = usePrevious(searchParams);

  const handleLoadSearchAssignments = useCallback(async () => {
    if (
      !entityId ||
      !searchParams ||
      (searchLastPageState &&
        searchLastPageState.page_number === searchLastPageState.total_pages) ||
      searchIsLoading ||
      noInitialLoad
    ) {
      return;
    }
    setSearchIsLoadingState(true);
    if (!searchIsInitialized) {
      setSearchIsInitialized(true);
    }
    const loadFunc = searchParams.search_term
      ? loadSearchAssignments
      : loadAssignments;
    const response = await loadFunc({
      entityId,
      params: {
        page: searchLastPageState?.page_number
          ? searchLastPageState.page_number + 1
          : 1,
        page_size: pageSize,
        ...searchParams,
        category,
      },
    });
    if (!response.data?.data) {
      setSearchIsLoadingState(false);
      return;
    }
    const { data, ...lastPageStateToSet } = response.data;
    addSearchItems(data.map((item) => ({ id: item.id, data: item })));
    setSearchLastPageState(lastPageStateToSet);
    setSearchIsLoadingState(false);
  }, [
    addSearchItems,
    category,
    entityId,
    loadAssignments,
    loadSearchAssignments,
    searchIsInitialized,
    searchIsLoading,
    searchLastPageState,
    searchParams,
    setSearchIsInitialized,
    setSearchIsLoadingState,
    setSearchLastPageState,
    noInitialLoad,
  ]);

  useEffect(() => {
    handleLoadSearchAssignments();
  }, [searchLastPageState, handleLoadSearchAssignments]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (!isEqual(searchParams, previousSearchParams)) {
      resetSearch();
    }
  }, [isInitialized, resetSearch, searchParams, previousSearchParams]);

  useEffect(() => {
    if (noInitialLoad || isInitialized) {
      return;
    }
    handleLoadAssignments();
  }, [handleLoadAssignments, noInitialLoad, isInitialized]);

  const items = useMemo(
    () => (searchParams ? assignmentsSearchItems : assignments),
    [searchParams, assignments, assignmentsSearchItems]
  );

  const load = useMemo(
    () => (searchParams ? handleLoadSearchAssignments : handleLoadAssignments),
    [searchParams, handleLoadSearchAssignments, handleLoadAssignments]
  );

  const isLoading = useMemo(
    () => (searchParams ? isAssignmentsSearchLoading : isAssignmentsLoading),
    [searchParams, isAssignmentsSearchLoading, isAssignmentsLoading]
  );

  const totalCount = useMemo(
    () =>
      searchParams
        ? searchLastPageState?.total_entries || 0
        : lastPageState?.total_entries || 0,
    [searchParams, searchLastPageState, lastPageState]
  );

  const createAssignment = useCallback(
    async (data: Parameters<typeof createAssignmentApi>[0]["data"]) => {
      if (!entityId) {
        return null;
      }
      const response = await createAssignmentApi({
        entityId,
        data,
      });
      if ("data" in response) {
        const assignment = response.data?.data;
        if (assignment) {
          addItems([{ id: assignment.id, data: assignment }], "start");
        }
      }
      return response;
    },
    [addItems, createAssignmentApi, entityId]
  );

  const deleteAssignment = useCallback(
    async (assignmentId: string) => {
      if (!assignmentId || !entityId) {
        return null;
      }
      const response = await deleteAssignmentApi({ assignmentId, entityId });
      if ("data" in response) {
        deleteItem({ itemId: assignmentId });
      }
      return response;
    },
    [deleteItem, deleteAssignmentApi, entityId]
  );

  const updateAssignment = useCallback(
    (id: string, assignment: EduAssignment) => {
      updateItem({ id, data: assignment });
    },
    [updateItem]
  );

  const createAssignmentSubmission = useCallback(
    async (
      assignmentId: string,
      data: Parameters<typeof createAssignmentSubmissionApi>[0]["data"]
    ) => {
      if (!entityId) {
        return null;
      }
      const response = await createAssignmentSubmissionApi({
        entityId,
        assignmentId,
        data,
      });
      if ("data" in response) {
        const assignment = assignments.find((item) => item.id === assignmentId);
        if (assignment) {
          updateAssignment(assignmentId, {
            ...assignment.data,
            submission_data: [response.data],
          });
        }
      }
      return response;
    },
    [assignments, createAssignmentSubmissionApi, entityId, updateAssignment]
  );

  const visitAssignment = useCallback(
    (assignmentId: string) => {
      const assignment = assignments?.find((item) => item.id === assignmentId);
      if (
        assignment &&
        (assignment.data.unread_cmnt || assignment?.data.unread_assign)
      ) {
        updateItem({
          id: assignmentId,
          data: {
            ...assignment.data,
            unread_cmnt: false,
            unread_assign: false,
          },
        });
      }
    },
    [assignments, updateItem]
  );

  const visitSubmissions = useCallback(
    (assignmentId: string) => {
      const assignment = assignments?.find((item) => item.id === assignmentId);
      if (assignment && assignment.data.unread_sub) {
        updateItem({
          id: assignmentId,
          data: {
            ...assignment.data,
            unread_sub: false,
          },
        });
      }
    },
    [assignments, updateItem]
  );

  const addAssignmentById = useCallback(
    async (id: string) => {
      if (!entityId) {
        return;
      }
      const response = await getAssignment({ entityId, assignmentId: id });
      if (!response.data) {
        // TODO: error handling
        return;
      }
      // TODO: re-check with backend
      const assignment = { ...response.data.data };
      assignment.unread_assign = true;
      addItems([{ id: assignment.id, data: assignment }], "start");
    },
    [addItems, entityId, getAssignment]
  );

  const submittedAssignment = useCallback(
    async (id: string) => {
      if (!entityId) {
        return;
      }
      const response = await getAssignment({ entityId, assignmentId: id });
      if (!response.data) {
        // TODO: error handling
        return;
      }
      // TODO: re-check with backend
      const assignment = { ...response.data.data };
      updateItem({
        id,
        data: assignment,
      });
    },
    [updateItem, entityId, getAssignment]
  );

  const showSubmittedAssignmentIndicator = useCallback(
    async (id: string, state: boolean) => {
      const assignment = items.find((item) => item.id === id)?.data;
      if (!assignment) {
        return;
      }
      updateItem({
        id,
        data: {
          ...assignment,
          unread_sub: state,
        },
      });
    },
    [items, updateItem]
  );

  const bumpAssignmentComments = useCallback(
    (id: string, dontSetNewIndicator?: boolean) => {
      const assignment = items.find((item) => item.id === id)?.data;
      if (!assignment) {
        return;
      }
      const updatedAssignment: EduAssignment = {
        ...assignment,
      };
      if (!dontSetNewIndicator) {
        updatedAssignment.unread_cmnt = true;
      }
      updateItem({
        id: updatedAssignment.id,
        data: updatedAssignment,
      });
      bumpItem(updatedAssignment.id);
    },
    [items, updateItem, bumpItem]
  );

  return {
    items,
    load,
    isLoading,
    totalCount,
    createAssignment,
    updateAssignment,
    allLoadedItems: assignments,
    createAssignmentIsLoading,
    isError: isAssignmentsError,
    isInitialized,
    createAssignmentSubmissionIsLoading,
    createAssignmentSubmission,
    visitAssignment,
    visitSubmissions,
    addAssignmentById,
    submittedAssignment,
    bumpAssignmentComments,
    reset,
    deleteItem,
    deleteAssignment,
    showSubmittedAssignmentIndicator,
  };
};
