import React, { useMemo, memo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getAppType } from "@web-src/features/app/utils";
import { AppType } from "@web-src/features/app/types";
import Lottie from "react-lottie";
import { cx } from "@jugl-web/utils";
import loaderAnimation from "./animations/loaderAnimation.json";

const Loader: React.FC<{ className?: string }> = ({ className }) => {
  const $displayLoader = useMemo(() => {
    switch (getAppType()) {
      case AppType.edu:
        return <CircularProgress />;
      case AppType.org:
        return (
          <Lottie
            options={{
              animationData: loaderAnimation,
            }}
            height={100}
            width={100}
          />
        );
      default:
        return null;
    }
  }, []);

  return (
    <div
      className={cx(
        "flex h-full w-full items-center justify-center",
        className
      )}
    >
      {$displayLoader}
    </div>
  );
};

export default memo(Loader);
