import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@web-src/store";
import { Lesson } from "./types";

type LessonsState = {
  activeLesson?: Lesson["id"];
};

const initialState: LessonsState = {};

const lessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    setActiveLesson(state, { payload: lesson }) {
      state.activeLesson = lesson;
    },
  },
});

export const selectActiveLesson = ({ lesson: { activeLesson } }: RootState) =>
  activeLesson;

export const { setActiveLesson } = lessonSlice.actions;
export default lessonSlice;
