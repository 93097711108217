import { useCallback, useContext } from "react";
import {
  ChatMessage,
  ChatMessageAction,
  ChatMessageActionType,
  ChatMessagePayloadAttachment,
  ChatMessagePayloadAttachmentType,
  ChatMessagePayloadPushType,
  ChatMessagePayloadType,
  ChatMessageType,
  PheonixPushAction,
  SendMessagePheonixResponse,
} from "@web-src/features/chats/types";
import { PhoenixSocketContext } from "@web-src/features/chats/providers/PheonixSocket";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useSelector } from "react-redux";
import { v4 as uuid4 } from "uuid";
import { selectUserId } from "@web-src/features/auth/authSlice";
import {
  getFileSizeByUrl,
  getVideoDimensionsByUrl,
  getImageDimensionsByUrl,
  usePaginationUpdate,
} from "@jugl-web/utils";
import { useTusUpload } from "@jugl-web/domain-resources/files/hooks/useTusUpload";
import { FilesModule } from "@jugl-web/domain-resources/files/types";
import { getAttachmentTypeByMimeType } from "../utils";
import useConversations from "./useConversations";

type SendMessageProps = {
  to: string;
  body?: string;
  clientMsgId?: string;
  type: ChatMessageType;
  extraPayload?: Partial<ChatMessage["payload"]>;
  file?: File;
  action?: ChatMessageAction;
  forwarded?: boolean;
  gif?: string;
  attachmentType?: ChatMessagePayloadAttachmentType;
  conference?: boolean;
};

type SendMessageResponseType =
  | "success"
  | "error"
  | "timeout"
  | "file-upload-error";

interface SendMessageResponse {
  type: SendMessageResponseType;
  message: Partial<ChatMessage>;
  retryFileUpload?: () => void;
  resend?: (timeout: number) => void;
  error?: unknown;
}

const useSendMessage = () => {
  const { channel } = useContext(PhoenixSocketContext);
  const { entity } = useEntity();
  const meId = useSelector(selectUserId);
  const { addItems, changeItemId, updateItem } =
    usePaginationUpdate<Partial<ChatMessage>>();
  const { conversations } = useConversations();
  const { uploadFile } = useTusUpload({
    entityId: entity?.id,
    module: FilesModule.attachment,
  });

  const sendMessage = useCallback(
    async (props: SendMessageProps): Promise<SendMessageResponse> => {
      const {
        to,
        body,
        type,
        extraPayload,
        clientMsgId,
        file,
        action,
        gif,
        attachmentType,
        conference,
      } = props;
      const chatName = conversations.find((item) =>
        type === ChatMessageType.chat ? item.id === meId : item.id === to
      )?.data.title;
      const paginationId = `messages:${to}`;
      if (!entity?.id) {
        throw new Error("No active entity");
      }
      if (!channel) {
        throw new Error("No channel");
      }

      let attachment: ChatMessagePayloadAttachment | undefined;
      if (file) {
        const attachmentUrl = URL.createObjectURL(file);
        attachment = {
          type: attachmentType || getAttachmentTypeByMimeType(file.type),
          name: file.name,
          size: file.size,
          mimetype: file.type,
          caption: body,
          uid: "",
          _local_url: URL.createObjectURL(file),
        };
        if (file.type.startsWith("image")) {
          const dimensions = await getImageDimensionsByUrl(attachmentUrl);
          attachment.width = dimensions.width;
          attachment.height = dimensions.height;
        } else if (file.type.startsWith("video")) {
          const dimensions = await getVideoDimensionsByUrl(attachmentUrl);
          attachment.width = dimensions.width;
          attachment.height = dimensions.height;
        }
      }
      if (gif) {
        attachment = {
          url: gif,
          type: ChatMessagePayloadAttachmentType.gifDirectUrl,
          name: "gif",
          size: await getFileSizeByUrl(gif),
          mimetype: "image/gif",
          ...(await getImageDimensionsByUrl(gif)),
          uid: "",
        };
      }

      const tempMsgId = clientMsgId || `temp-${uuid4()}`;
      const messageToSend: Partial<ChatMessage> = {
        to,
        from: meId,
        type,
        entity_id: entity?.id,
        payload: {
          version: 1,
          push_type: ChatMessagePayloadPushType.silent,
          title: chatName || "New Message",
          type: ChatMessagePayloadType.message,
          body: attachment?.caption ? undefined : body,
          attachments: attachment ? [attachment] : undefined,
          ...extraPayload,
          client_msg_id: tempMsgId,
        },
      };

      if (
        action?.message &&
        messageToSend.payload &&
        action?.type === ChatMessageActionType.reply
      ) {
        messageToSend.payload.reply_attributes_map = {
          reply_msg_id: action.message.msg_id,
          reply_msg_body: action.message.payload.body,
          reply_msg_user: action.message.from,
          reply_attachments: action.message.payload.attachments?.map((item) => {
            // Remove local stuff
            const result = { ...item };
            delete result?._local_url;
            delete result?._progress;
            delete result?._preview_url;
            delete result?._stream_url;
            return result;
          }),
        };
      }

      if (conference && messageToSend.payload) {
        messageToSend.payload.conference = {
          channel: uuid4(),
        };
      }

      addItems({
        paginationId,
        itemsToAdd: [
          {
            id: tempMsgId,
            data: {
              // Copy to be able to modify inner objects later
              ...JSON.parse(JSON.stringify(messageToSend)),
              timestamp: new Date().toISOString().replace("T", " "),
              is_read_by_self: true,
              _pending: true,
            },
          },
        ],
      });

      const messageAttachment = messageToSend.payload?.attachments?.[0];
      if (file && messageAttachment) {
        try {
          // TODO:
          const fileResponse: any = await uploadFile({
            file,
            addPath: true,
            onProgress: (bytesSend, bytesTotal) => {
              if (!messageAttachment) {
                return;
              }
              messageAttachment._progress = Math.round(
                (bytesSend / bytesTotal) * 100
              );
              updateItem({
                paginationId,
                merge: true,
                item: {
                  id: tempMsgId,
                  data: {
                    payload: {
                      attachments: [messageAttachment],
                    },
                  },
                },
              });
            },
          });
          if (
            messageAttachment.type !==
            ChatMessagePayloadAttachmentType.gifDirectUrl
          ) {
            messageAttachment.url = fileResponse.url;
            messageAttachment.uid = fileResponse.uid;
          }
        } catch (error) {
          updateItem({
            paginationId,
            merge: true,
            item: {
              id: tempMsgId,
              data: {
                payload: {
                  attachments: [
                    {
                      ...messageAttachment,
                      _error: true,
                    },
                  ],
                },
                _error: true,
              },
            },
          });
          return {
            type: "file-upload-error",
            message: messageToSend,
            retryFileUpload: () =>
              sendMessage({ ...props, clientMsgId: tempMsgId }),
          };
        }
      }

      if (messageToSend.payload?.attachments) {
        // Remove local stuff
        messageToSend.payload.attachments =
          messageToSend.payload.attachments.map((item) => {
            const result = { ...item };
            delete result?._local_url;
            delete result?._progress;
            delete result?._preview_url;
            delete result?._stream_url;
            return result;
          });
      }

      const message = channel.push(PheonixPushAction.transport, messageToSend);

      return new Promise((resolve) => {
        message.receive("ok", (e: SendMessagePheonixResponse) => {
          changeItemId({ paginationId, oldId: tempMsgId, newId: e.msg_id });
          updateItem({
            paginationId,
            merge: true,
            item: {
              id: e.msg_id,
              data: { msg_id: e.msg_id, _pending: false, _error: false },
            },
          });
          resolve({
            type: "success",
            message: messageToSend,
            resend: message.resend,
          });
        });
        const handleError = () => {
          updateItem({
            paginationId,
            merge: true,
            item: {
              id: tempMsgId,
              data: { _pending: false, _error: true },
            },
          });
        };
        message.receive("error", (error) => {
          handleError();
          resolve({
            type: "error",
            error,
            message: messageToSend,
            resend: message.resend,
          });
        });
        message.receive("timeout", (error) => {
          handleError();
          resolve({
            type: "timeout",
            error,
            message: messageToSend,
            resend: message.resend,
          });
        });
      });
    },
    [
      conversations,
      entity?.id,
      channel,
      meId,
      addItems,
      uploadFile,
      changeItemId,
      updateItem,
    ]
  );
  return sendMessage;
};

export default useSendMessage;
