import {
  getDueDateInvalidationTags,
  getLabelInvalidationTags,
  TasksApiTag,
  TASK_TAGS_CREATORS,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { useAppVariant } from "@jugl-web/utils";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { TagDescription } from "@reduxjs/toolkit/dist/query";
import isUndefined from "lodash/isUndefined";
import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { filter, Subject } from "rxjs";
import { LiveUpdateEvent } from "../../../live-updates";
import { invalidateNotificationsReadFlag } from "../../taskNotificationsSlice";
import {
  TaskLiveUpdateAttachmentDeletedEvent,
  TaskLiveUpdateCreatedEvent,
  TaskLiveUpdateDeletedEvent,
  TaskLiveUpdateEvent,
  TaskLiveUpdateUpdatedEvent,
} from "./types";
import {
  isTaskAttachmentDeletedEvent,
  isTaskCreatedEvent,
  isTaskDeletedEvent,
  isTaskLiveUpdateEvent,
  isTaskUpdatedEvent,
} from "./utils";

export interface TaskLiveUpdatesManagerProps {
  events$: Subject<LiveUpdateEvent>;
}

export const TaskLiveUpdatesManager: FC<TaskLiveUpdatesManagerProps> = ({
  events$,
}) => {
  const { tasksApi } = useRestApiProvider();
  const { isMobile } = useAppVariant();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<ThunkDispatch<any, unknown, AnyAction>>();

  /**
   * The following functions are responsible for manually updating cached data
   * or invalidating RTK query tags (which, in turn, are responsible for
   * triggering specific requests) based on incoming update events through socket
   *
   * -- Types of tasks aggregations --
   * Mobile: all, status based, due date based (overdue, no due date), label based
   * Web: due date based (overdue, no due date), label based
   *
   * -- Properties whose change can cause a task relocation --
   * status, due date, label, assignee, checklist assignee
   */

  const handleTaskCreatedEvent = useCallback(
    (event: TaskLiveUpdateCreatedEvent) => {
      const tagsToInvalidate: TagDescription<TasksApiTag>[] = [];

      // After adding a new task, we always want to refresh the "All Tasks"
      // tab in mobile app
      if (isMobile) {
        tagsToInvalidate.push(TASK_TAGS_CREATORS.ALL_TASKS);
      }

      // Then, we want to refresh all the due-date-based and label-based tabs
      // where the task may appear
      tagsToInvalidate.push(
        ...getDueDateInvalidationTags(event.data.due_at),
        ...getLabelInvalidationTags(event.data.label_id)
      );

      dispatch(tasksApi.util.invalidateTags(tagsToInvalidate));
    },
    [dispatch, tasksApi.util, isMobile]
  );

  const handleTaskUpdatedEvent = useCallback(
    (event: TaskLiveUpdateUpdatedEvent) => {
      const { id: taskId } = event.data;

      // After updating a task, we always want to update the tag corresponding to
      // its ID to ensure that the task details data is always up-to-date
      const tagsToInvalidate: TagDescription<TasksApiTag>[] = [
        TASK_TAGS_CREATORS.TASK_ID(taskId),
        { type: TasksApiTag.taskActivity, id: taskId },
      ];

      if (isMobile) {
        // For the mobile app, we always need to refresh the "All Tasks" tab
        tagsToInvalidate.push(TASK_TAGS_CREATORS.ALL_TASKS);

        // If the status changes, we need to update the "Completed" tab,
        // as well as all the others tabs (due-date-based and label-based)
        // because we don't have knowledge about the previous status
        // of the task and where it might appear after change
        if (event.data.status) {
          tagsToInvalidate.push(
            TASK_TAGS_CREATORS.COMPLETED_TASKS,
            ...getDueDateInvalidationTags(event.data.old_due_at),
            ...getLabelInvalidationTags(event.data.old_label_id)
          );
        }
      }

      // In case of a change in assignee (or checklist assignee), meaning
      // we could have been assigned or unassigned from a task, we need to
      // update all the due-date-based and label-based aggregations in which
      // the task could have appeared or from which it could have disappeared
      if (event.data.assignee || event.data.checklist) {
        tagsToInvalidate.push(
          ...getDueDateInvalidationTags(event.data.old_due_at),
          ...getLabelInvalidationTags(event.data.old_label_id)
        );
      }

      // In case of a due date change, we update all aggregations based on
      // the due date, both for the new value (where the task will appear) and
      // the previous value (from which the task will disappear)
      if (!isUndefined(event.data.due_at)) {
        tagsToInvalidate.push(
          ...getDueDateInvalidationTags(event.data.old_due_at),
          ...getDueDateInvalidationTags(event.data.due_at)
        );
      }

      // If the label changes, the situation is analogous to when the due date
      // field changes
      if (!isUndefined(event.data.label)) {
        tagsToInvalidate.push(
          ...getLabelInvalidationTags(event.data.old_label_id),
          ...getLabelInvalidationTags(event.data.label)
        );
      }

      dispatch(tasksApi.util.invalidateTags(tagsToInvalidate));
    },
    [dispatch, tasksApi.util, isMobile]
  );

  const handleTaskDeletedEvent = useCallback(
    (event: TaskLiveUpdateDeletedEvent) => {
      // If a task is deleted, we invalidate the tag corresponding to its ID to
      // ensure it disappears from every collection that contains this task
      dispatch(
        tasksApi.util.invalidateTags([
          TASK_TAGS_CREATORS.TASK_ID(event.data.id),
        ])
      );
    },
    [dispatch, tasksApi.util]
  );

  // When an attachment is deleted, we manually remove it from the cached data
  // associated with that specific task
  const handleTaskAttachmentDeletedEvent = useCallback(
    (event: TaskLiveUpdateAttachmentDeletedEvent) => {
      const {
        id: taskId,
        entity_id: entityId,
        attach_id: attachmentId,
      } = event.data;

      const updateTaskAction = tasksApi.util.updateQueryData(
        "getTask",
        { entityId, taskId },
        (task) => {
          const index = task.attachments.findIndex(
            (attachment) => attachment.id === attachmentId
          );

          task.attachments.splice(index, 1);
        }
      );

      dispatch(updateTaskAction);
    },
    [dispatch, tasksApi]
  );

  // Every time a task live update arrives, we manually mark notifications
  // as unread, triggering the red indicator without the need to send requests
  // to the backend
  const markNotificationsAsUnread = useCallback(
    (entityId: string) => {
      const hasUnreadNotificationsUpdateAction = tasksApi.util.updateQueryData(
        "hasUnreadNotifications",
        { entityId },
        () => true
      );

      const invalidateNotificationsReadFlagAction =
        invalidateNotificationsReadFlag({ entityId });

      dispatch(hasUnreadNotificationsUpdateAction);
      dispatch(invalidateNotificationsReadFlagAction);
    },
    [dispatch, tasksApi.util]
  );

  const onTaskLiveUpdateEvent = useCallback(
    (event: TaskLiveUpdateEvent<string, object>) => {
      if (isTaskCreatedEvent(event)) {
        handleTaskCreatedEvent(event);
        markNotificationsAsUnread(event.data.entity_id);
        return;
      }

      if (isTaskUpdatedEvent(event)) {
        handleTaskUpdatedEvent(event);
        markNotificationsAsUnread(event.data.entity_id);
        return;
      }

      if (isTaskDeletedEvent(event)) {
        handleTaskDeletedEvent(event);
        markNotificationsAsUnread(event.data.entity_id);
        return;
      }

      if (isTaskAttachmentDeletedEvent(event)) {
        handleTaskAttachmentDeletedEvent(event);
        return;
      }

      // eslint-disable-next-line no-console
      console.warn(
        `Unhandled task live update event action: ${event.action}`,
        event
      );
    },
    [
      handleTaskCreatedEvent,
      handleTaskUpdatedEvent,
      handleTaskDeletedEvent,
      handleTaskAttachmentDeletedEvent,
      markNotificationsAsUnread,
    ]
  );

  useEffect(() => {
    const subscription = events$
      .pipe(filter(isTaskLiveUpdateEvent))
      .subscribe(onTaskLiveUpdateEvent);

    return () => {
      subscription.unsubscribe();
    };
  }, [events$, onTaskLiveUpdateEvent]);

  return null;
};
