import React from "react";
import { UseFormSetValue } from "react-hook-form";
import Box from "@mui/material/Box";
import { primary, grey } from "@web-src/themes/colors";
import Typography from "@mui/material/Typography";
import { TimeName } from "./types";

const SwitchDayPeriod: React.FC<{
  formSetValue: UseFormSetValue<{
    date: Date | null;
    dayPeriod: string;
    hour: string;
    minute: string;
    month: number;
    year: number;
  }>;
  name: TimeName;
  chosen: string;
}> = ({ formSetValue, name, chosen }) => (
  <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
    {["AM", "PM"].map((item) => (
      <Typography
        key={item}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "42px",
          width: "45px",
          border: `1px solid ${chosen === item ? primary[500] : grey[200]}`,
          borderRadius: "8px",
          cursor: "pointer",
        }}
        onClick={() => formSetValue?.(name, item)}
      >
        {item}
      </Typography>
    ))}
  </Box>
);

export default SwitchDayPeriod;
