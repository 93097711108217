import React, { memo, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetProfileQuery } from "@web-src/features/auth/authApi";
import { Profile } from "@web-src/features/auth/types";
import {
  useAddInformationMutation,
  useDeleteInformationMutation,
  useUpdateInformationMutation,
} from "@web-src/features/settings/settingsApi";
import { SettingsPaddingSize } from "@web-src/utils/layout";
import { useEnqueueSnackbar } from "@web-src/features/settings/hooks/useSnackbar";
import { useTranslations } from "@jugl-web/utils";
import FormSpinner from "../FormSpinner";
import DeleteModal from "../DeleteModal";

type Inputs = {
  country: string;
  state: string;
  city: string;
};

const schema = yup.object().shape({
  country: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
});

const EditBirthPlaceFormContainer = memo(() => {
  const { data } = useGetProfileQuery();
  return <EditBirthPlaceFormComponent {...{ data }} />;
});

const EditBirthPlaceFormComponent: React.FC<{
  data: Profile | undefined;
}> = ({ data }) => (
  <Box sx={{ width: "50%" }}>
    {!data ? (
      <FormSpinner />
    ) : (
      <EditBirthPlaceForm data={data} type={!data.native ? "add" : "update"} />
    )}
  </Box>
);

const EditBirthPlaceForm: React.FC<{
  data: Profile;
  type: "add" | "update";
}> = ({ data, type }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    values: {
      country: data.native?.country || "",
      state: data.native?.state || "",
      city: data.native?.city || "",
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const [addBirth, { isLoading }] = useAddInformationMutation();
  const [updateBirth, { isLoading: isLoadingUpdate }] =
    useUpdateInformationMutation();
  const [deleteBirth, { isLoading: isLoadingDelete }] =
    useDeleteInformationMutation();
  const { showProfileUpdatedSnackbar } = useEnqueueSnackbar();
  const { t } = useTranslations();

  const onSubmit: SubmitHandler<Inputs> = async (params) => {
    const { city, state, country } = { ...params };
    let res;
    if (type === "add") {
      res = await addBirth({
        data: {
          type: "native",
          city,
          state,
          country_name: country,
        },
      });
    } else {
      res = await updateBirth({
        item_id: data.native?.id,
        data: {
          type: "native",
          city,
          state,
          country_name: country,
        },
      });
    }
    if ("data" in res) {
      showProfileUpdatedSnackbar();
    }
  };

  const onDeleteBirth = async () => {
    const res = await deleteBirth({
      type: "native",
      item_id: data.native?.id,
    });
    if ("data" in res) {
      showProfileUpdatedSnackbar();
      setOpenModal(false);
    }
  };

  return (
    <>
      <Typography sx={{ fontWeight: "500", padding: "40px 40px 0 20px" }}>
        {t({
          id: "birth-place-form-page.birth-place",
          defaultMessage: "Add Native / Birth Place",
        })}
      </Typography>
      <Box sx={{ padding: "20px 40px 40px 40px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "70px",
              marginBottom: "50px",
              position: "relative",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <InputLabel>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                  component="span"
                >
                  {t({
                    id: "form-controls.country.label",
                    defaultMessage: "Country",
                  })}
                </Typography>{" "}
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </InputLabel>
              <TextField
                placeholder={t({
                  id: "form-controls.country-name.placeholder",
                  defaultMessage: "Country Name",
                })}
                variant="standard"
                sx={{
                  width: "100%",
                  input: {
                    "&::placeholder": {
                      fontWeight: "400",
                    },
                  },
                }}
                inputProps={{
                  style: { fontSize: 16, fontWeight: "500" },
                  ...register("country"),
                }}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <InputLabel>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                  component="span"
                >
                  {t({
                    id: "form-controls.state.label",
                    defaultMessage: "State",
                  })}
                </Typography>{" "}
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </InputLabel>
              <TextField
                placeholder={t({
                  id: "form-controls.state-name.placeholder",
                  defaultMessage: "State Name",
                })}
                variant="standard"
                sx={{
                  width: "100%",
                  input: {
                    "&::placeholder": {
                      fontWeight: "400",
                    },
                  },
                }}
                inputProps={{
                  style: { fontSize: 16, fontWeight: "500" },
                  ...register("state"),
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "70px",
              marginBottom: "50px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <InputLabel>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                  component="span"
                >
                  {t({
                    id: "form-controls.city.label",
                    defaultMessage: "City",
                  })}
                </Typography>{" "}
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </InputLabel>
              <TextField
                placeholder={t({
                  id: "form-controls.city-name.placeholder",
                  defaultMessage: "City Name",
                })}
                variant="standard"
                sx={{
                  width: `calc(100% - ${SettingsPaddingSize}px)`,
                  input: {
                    "&::placeholder": {
                      fontWeight: "400",
                    },
                  },
                }}
                inputProps={{
                  style: { fontSize: 16, fontWeight: "500" },
                  ...register("city"),
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              marginBottom: "50px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <Button
                disabled={!isValid || isLoading || isLoadingUpdate || !isDirty}
                size="large"
                variant="contained"
                type="submit"
                sx={{ width: `calc(100%  - ${SettingsPaddingSize}px)` }}
              >
                {t({
                  id: "common.save",
                  defaultMessage: "Save",
                })}
              </Button>
            </Box>
            {data.native !== null && (
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  disabled={isLoadingDelete}
                  variant="outlined"
                  size="large"
                  sx={{ width: `calc(100%  - ${SettingsPaddingSize}px)` }}
                  onClick={() => setOpenModal(true)}
                >
                  {t({
                    id: "birth-place-form-page.delete-current-city-info",
                    defaultMessage: "Delete Current City Info",
                  })}
                </Button>
              </Box>
            )}
          </Box>
        </form>
      </Box>
      {openModal && (
        <DeleteModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={() => onDeleteBirth()}
          isLoading={isLoadingDelete}
          text={t({
            id: "birth-place-form-page.city-information-removal-confirmation",
            defaultMessage:
              "Do you really want to remove current city information?",
          })}
        />
      )}
    </>
  );
};

export default EditBirthPlaceFormContainer;
