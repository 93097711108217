import React, { useMemo, useRef, useState } from "react";
import { Listbox } from "@headlessui/react";
import { Virtuoso } from "react-virtuoso";
import classNames from "classnames";
import { useTranslations } from "@jugl-web/utils";
import parsePhoneNumber from "libphonenumber-js";
import { Country } from "./types";
import { ReactComponent as ArrowIcon } from "./arrow-bottom.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries = require("countries-phone-masks");

export const PhoneInput: React.FC<{
  value?: { phone: string; code: string; iso: string };
  placeholder?: string;
  onChange: ({
    iso,
    phone,
    code,
    isValid,
  }: {
    iso: string;
    phone: string;
    code: string;
    isValid: boolean;
  }) => void;
  onFocus?: () => void;
}> = ({
  value = { phone: "", code: "+91", iso: "IN" },
  onChange,
  onFocus,
  placeholder,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>("");
  const { t } = useTranslations();

  const handleCountrySelect = (e: string) => {
    const [iso, code] = e.split("-");
    const phoneNumber = parsePhoneNumber(`${code}${value.phone}`);
    onChange({
      ...value,
      isValid: phoneNumber?.isPossible() || phoneNumber?.isValid() || false,
      code,
      iso,
    });
  };
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value.includes("_")
      ? e.target.value
      : e.target.value.replace(/\D+/g, "");
    const phoneNumber = parsePhoneNumber(`${value.code}${phone}`);
    onChange({
      ...value,
      isValid: phoneNumber?.isPossible() || phoneNumber?.isValid() || false,
      phone,
    });
  };
  const preparedCountries: Country[] = useMemo(() => {
    const newArray = [...countries];
    const indexes: number[] = [];

    newArray.forEach((item, index) => {
      if (item.iso === "IN" || item.iso === "US") {
        indexes.push(index);
      }
    });

    indexes.forEach((index) => {
      const item = newArray.splice(index, 1)[0];
      newArray.unshift(item);
    });

    return newArray;
  }, []);
  const searchedCountries = useMemo(
    () =>
      preparedCountries.filter(({ name }) =>
        name.toLocaleLowerCase().includes(search.toLowerCase())
      ),
    [preparedCountries, search]
  );
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSelect = () => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  return (
    <div className="flex items-center gap-2">
      <Listbox
        value={`${value.iso}-${value.code}`}
        onChange={handleCountrySelect}
      >
        <Listbox.Button
          className="flex cursor-pointer items-center border-0 bg-transparent py-4 pl-4 text-base font-medium"
          onClick={handleSelect}
        >
          {value.code} <ArrowIcon className={classNames("ml-3")} />
        </Listbox.Button>
        {/* TODO: check position and height of list  */}
        <Listbox.Options className="border-grey-300 absolute top-[calc(100vh_/_2_+_34px)] z-20 flex max-h-[300px] flex-col overflow-x-auto rounded-2xl border bg-white p-0 shadow-[0px_4px_18px_rgba(18,22,34,0.05)] focus:outline-none">
          <div className="mx-4 mt-[18px] mb-2 flex items-center">
            <SearchIcon />
            <input
              value={search}
              onChange={handleSearchChange}
              className="placeholder:text-grey-1000 ml-2 w-full border-none focus:shadow-none focus:outline-0 focus:ring-0"
              placeholder={t({
                id: "common.search-with-ellipsis",
                defaultMessage: "Search...",
              })}
              ref={inputRef}
            />
          </div>
          <div className="my-1 h-[1px] w-full bg-gray-300" />
          <Virtuoso
            style={{ height: "300px", width: "300px" }}
            data={searchedCountries}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(index, { name, code, iso, flag }: Country) => (
              <>
                <Listbox.Option
                  value={`${iso}-${code}`}
                  className={({ active }) =>
                    `relative cursor-pointer select-none
                      ${active && "bg-primary-50"}`
                  }
                >
                  <div className="flex justify-between px-4 py-[6px]">
                    <div className="flex">
                      <img
                        src={flag}
                        alt={name}
                        className="mr-[10px] w-[28px]"
                      />
                      {`${name} (${iso})`}
                    </div>
                    <span>{code}</span>
                  </div>
                </Listbox.Option>
                {index === 1 && !search && (
                  <span className="border-grey-300 mx-4 my-[6px] flex border-[1px] border-solid" />
                )}
              </>
            )}
          />
        </Listbox.Options>
      </Listbox>
      <div className="bg-grey-300 h-[30px] w-[1px]" />
      <input
        value={value.phone}
        onChange={handlePhoneChange}
        onFocus={onFocus}
        placeholder={placeholder}
        className="flex-1 rounded-[10px] border-none py-3 text-base font-medium focus:outline-none"
      />
    </div>
  );
};

export default PhoneInput;
