import format from "date-fns/format";
import getMonth from "date-fns/getMonth";
import setMonth from "date-fns/setMonth";

export const getMonthName = (monthIndex: number) =>
  format(setMonth(new Date(), monthIndex), "MMMM");

export const getMonthShortName = (monthIndex: number) =>
  format(setMonth(new Date(), monthIndex), "MMM");

export const getCurrentMonthName = () => getMonthName(getMonth(new Date()));
