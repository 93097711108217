// Local storage keys
export const TASKS_FILTERS_BY_ENTITY_ID_KEY =
  "JUGL__TASKS_FILTERS_BY_ENTITY_ID";
export const TASKS_VIEW_MODE_KEY = "JUGL__TASKS_VIEW_MODE";
export const TASKS_OVERDUE_TASKS_COLUMN_TAB = "TASKS_OVERDUE_TASKS_COLUMN_TAB";
export const TASKS_SELECTED_MONTH_KEY = "JUGL__TASKS_SELECTED_MONTH_KEY";
export const SESSION_ID = "JUGL__SESSION_ID";

export const getStorageItemWithFallback = <TItem>(
  key: string,
  fallback: TItem
): TItem => {
  const rawItem = localStorage.getItem(key);

  if (!rawItem) {
    return fallback;
  }

  try {
    return JSON.parse(rawItem) as TItem;
  } catch {
    return fallback;
  }
};

export const saveItemToStorage = <TItem>(key: string, item: TItem) => {
  localStorage.setItem(key, JSON.stringify(item));
};
