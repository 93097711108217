import React, { forwardRef, useMemo } from "react";
import { merge } from "lodash";
import TextField from "@mui/material/TextField";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import { grey } from "@web-src/themes/colors";
import { useForwardedRef } from "@bedrock-layout/use-forwarded-ref";
import { SxProps } from "@mui/material";
import { FieldError, UseFormSetValue } from "react-hook-form";
import { StackWrapper } from "./styles";
import { TimeName } from "./types";

const CounterInput = forwardRef<
  HTMLInputElement,
  {
    formSetValue?: UseFormSetValue<{
      date: Date | null;
      dayPeriod: string;
      hour: string;
      minute: string;
      month: number;
      year: number;
    }>;
    name: TimeName;
    zPad?: number;
    min?: string | number;
    max?: string | number;
    error?: FieldError | boolean | undefined;
    sx: SxProps;
  }
>((props, ref) => {
  const innerRef = useForwardedRef<HTMLInputElement>(ref);
  const { formSetValue, name, zPad, min, max, error, sx } = props;
  const nativeProps = useMemo(() => {
    const val = { ...props };
    delete val.formSetValue;
    delete val.zPad;
    return val;
  }, [props]);
  const handleArrowClick = (num: number) => {
    if (!innerRef.current) {
      return;
    }
    const value = parseInt(innerRef.current?.value, 10);
    let newValue;
    if (!Number.isNaN(value)) {
      newValue = value + num;
    } else {
      newValue = 0;
    }
    if (
      (min !== undefined && newValue < min) ||
      (max !== undefined && newValue > max)
    ) {
      return;
    }
    if (zPad) {
      newValue = `0000${newValue}`.slice(-zPad);
    }
    innerRef.current.value = `${newValue}`;
    if (!newValue) return;
    formSetValue?.(name, newValue, { shouldValidate: true });
  };
  return (
    <StackWrapper sx={sx}>
      <IconButton size="small" onClick={handleArrowClick.bind(null, 1)}>
        <KeyboardArrowUpIcon className="arrowIcon" sx={{ color: grey[700] }} />
      </IconButton>
      <TextField
        sx={{
          height: "42px",
          width: "auto",
          fieldset: {
            borderColor: grey[200],
          },
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        }}
        variant="outlined"
        error={!!error}
        type="number"
        inputRef={innerRef}
        inputProps={merge(
          {
            sx: {
              padding: 0,
              height: "44px",
              textAlign: "center",
              fontWeight: 500,
              fontSize: "20px",
            },
          },
          nativeProps
        )}
      />
      <IconButton size="small" onClick={handleArrowClick.bind(null, -1)}>
        <KeyboardArrowDownIcon
          className="arrowIcon"
          sx={{ color: grey[700] }}
        />
      </IconButton>
    </StackWrapper>
  );
});

export default CounterInput;
