import { AppType } from "@web-src/features/app/types";

export enum EntitySpaceType {
  class = "class",
  dept = "dept",
  group = "group",
}

export type EntitySpace = {
  entity_id: string;
  id: string;
  info: { batch: string; title: string };
  type: EntitySpaceType;
};

export type EntityProp = {
  id: string;
  value: string;
};

export enum EntityPropType {
  subject = "subject",
}

export enum EntityParticipantRole {
  admin = "admin",
  member = "member",
  teacher = "teacher",
  student = "student",
}

export enum EntityStatus {
  active = "active",
}

export enum EntityAuthMode {
  by_username_pass = "by_username_pass",
}

export type Entity = {
  // TODO: enum
  auth_mode: ["by_username_pass"];
  country: string;
  desc: string;
  banner_img: string | null;
  display_pic: string | null;
  id: string;
  info: { email: string; homepage: string; address: string };
  name: string;
  parent_id: string | null;
  role: EntityParticipantRole;
  status: EntityStatus;
  task_count: number;
  type: AppType;
  user_count: number;
};

export enum EntityParticipantGender {
  Male = "Male",
  Female = "Female",
}

export enum EntityParticipantStatus {
  active = "active",
  inactive = "inactive",
  suspended = "suspnd",
}

export type EntityUserProfile = {
  dob: string;
  first_name: string;
  gender: EntityParticipantGender;
  img: null | string;
  last_name: string;
};

export type EntityUser = {
  email: string;
  entity_id: string;
  id: string;
  info: null;
  jid: string;
  mobile: string;
  password: null;
  profile: EntityUserProfile | null;
  role: EntityParticipantRole;
  spaces?: EntitySpace[];
  status: EntityParticipantStatus;
  username: string | null;
};

export type EntityUpdate = {
  country?: string;
  info?: { address: string };
  desc: string;
  name: string;
  type: AppType;
};
export interface EntityUsersUpdate extends Partial<EntityUser> {
  user_id: string;
  space?: string[];
}
