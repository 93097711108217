import { Popover } from "@headlessui/react";
import { Fab } from "@jugl-web/ui-components/cross-platform/Fab";
import { ReactComponent as CallCutIcon } from "@web-src/features/calls/icons/call-cut.svg";
import { ReactComponent as MicOnIcon } from "@web-src/features/calls/icons/mic-on.svg";
import { ReactComponent as ScreenShareOnIcon } from "@web-src/features/calls/icons/screen-share-on.svg";
import { ReactComponent as SettingsIcon } from "@web-src/features/calls/icons/settings.svg";
import { ReactComponent as VideoOnIcon } from "@web-src/features/calls/icons/video-on.svg";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import ActiveCallDeviceSelector from "../ActiveCallDeviceSelector";
import { ToggleButton } from "./ToggleButton";

const ControlPanel: React.FC<{
  toggleVideo: () => void;
  toggleMic: () => void;
  toggleScreenShare: () => void;
  endCall: () => void;
  isVideoOn: boolean;
  isMicOn: boolean;
  isScreenShared: boolean;
}> = ({
  toggleMic,
  toggleScreenShare,
  toggleVideo,
  endCall,
  isVideoOn,
  isMicOn,
  isScreenShared,
}) => {
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);

  const [settingsBtnRef, setSettingsBtnRef] =
    useState<HTMLButtonElement | null>();
  const [settingsPopoverRef, setSettingsPopoverRef] =
    useState<HTMLDivElement | null>();

  const handleToggleSettings = () => {
    setSettingsOpen(!settingsPopoverRef);
  };
  const handleEndCall = () => {
    endCall();
    window.close();
  };
  const { styles, attributes, forceUpdate } = usePopper(
    settingsBtnRef,
    settingsPopoverRef,
    {
      placement: "top",
      modifiers: [
        {
          name: "preventOverflow",
        },
        {
          name: "offset",
          options: {
            offset: [0, 20],
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (settingsPopoverRef && settingsBtnRef && forceUpdate) {
      forceUpdate();
    }
  }, [forceUpdate, settingsBtnRef, settingsPopoverRef, isSettingsOpen]);
  return (
    <>
      <ToggleButton
        onClick={toggleVideo}
        isOn={isVideoOn}
        onIcon={VideoOnIcon}
        offIcon={VideoOnIcon}
      />
      <ToggleButton
        onClick={toggleMic}
        isOn={isMicOn}
        onIcon={MicOnIcon}
        offIcon={MicOnIcon}
      />
      <Popover className="relative">
        <Popover.Button
          className="border-0"
          ref={setSettingsBtnRef}
          onClick={handleToggleSettings}
        >
          <ToggleButton
            isOn={Boolean(settingsPopoverRef)}
            onIcon={SettingsIcon}
            offIcon={SettingsIcon}
            noFill
          />
        </Popover.Button>
        <Popover.Panel
          className="absolute bottom-20 z-50 min-w-[325px]"
          ref={setSettingsPopoverRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <ActiveCallDeviceSelector
            isOpen={isSettingsOpen}
            onClose={() => settingsBtnRef?.click()}
          />
        </Popover.Panel>
      </Popover>
      <ToggleButton
        onClick={toggleScreenShare}
        isOn={isScreenShared}
        onIcon={ScreenShareOnIcon}
        offIcon={ScreenShareOnIcon}
        noFill
      />
      <Fab
        icon={<CallCutIcon />}
        size="lg"
        variant="danger"
        onClick={handleEndCall}
      />
    </>
  );
};

export default ControlPanel;
