import useEntity from "@web-src/features/app/hooks/useEntity";
import { useLazyGetProfileQuery } from "@web-src/features/auth/authApi";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { getUserProfileDisplayName } from "@web-src/features/users/utils";
import { getAppType } from "@web-src/features/app/utils";
import { getEntityUserDisplayName } from "@web-src/features/entities/utils";
import useActiveChat from "./useActiveChat";
import useConversations from "./useConversations";
import { ChatType } from "../types";

const useNavigateToChat = () => {
  const navigate = useNavigate();
  const { entity, participants } = useEntity();
  const { setActiveChat } = useActiveChat();
  const { conversations, addConversation } = useConversations();
  const [getProfile] = useLazyGetProfileQuery();
  const navigateToChat = useCallback(
    async (chatId: string) => {
      // TODO: error handling
      if (!conversations.find((item) => item.id === chatId)) {
        if (getAppType() === "edu") {
          const participant = participants?.find((user) => user.id === chatId);
          if (participant) {
            addConversation?.({
              params: {
                id: participant.id,
                type: ChatType.chat,
                title: getEntityUserDisplayName(participant),
              },
            });
          }
        } else {
          const resp = await getProfile({ params: { user_id: chatId } });
          if (resp?.data) {
            addConversation?.({
              params: {
                id: resp.data.user_id,
                type: ChatType.chat,
                title: getUserProfileDisplayName(resp.data),
                img: resp.data.general.img,
              },
            });
          }
        }
      }
      setActiveChat(chatId);
      navigate(`/${entity?.id}/chats/${chatId}`);
    },
    [
      participants,
      addConversation,
      conversations,
      entity?.id,
      getProfile,
      navigate,
      setActiveChat,
    ]
  );
  return navigateToChat;
};

export default useNavigateToChat;
