import {
  offset,
  Placement,
  useFloating,
  flip,
  shift,
} from "@floating-ui/react-dom";
import { Popover as HeadlessUIPopover, Portal } from "@headlessui/react";
import { assignRefs, cx } from "@jugl-web/utils";
import { forwardRef, ReactNode, Ref } from "react";

interface RenderTriggerProps {
  Trigger: (typeof HeadlessUIPopover)["Button"];
  triggerRef: Ref<HTMLButtonElement>;
  isOpen: boolean;
}

interface ChildrenProps {
  isOpen: boolean;
  onClose: () => void;
}

export type PopoverProps = {
  children: ReactNode | ((props: ChildrenProps) => ReactNode);
  placement: Placement;
  isDisabled?: boolean;
  className?: string;
  renderTrigger: (props: RenderTriggerProps) => JSX.Element;
};

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    { children, placement, isDisabled, className, renderTrigger },
    forwardedRef
  ) => {
    const { refs, floatingStyles } = useFloating<HTMLButtonElement>({
      placement,
      middleware: [flip(), shift(), offset({ mainAxis: 4 })],
    });

    return (
      <HeadlessUIPopover className="relative">
        {({ open, close }) => (
          <>
            {renderTrigger({
              Trigger: HeadlessUIPopover.Button,
              triggerRef: refs.setReference,
              isOpen: open,
            })}
            {!isDisabled && (
              <Portal>
                <HeadlessUIPopover.Panel
                  as="div"
                  ref={assignRefs([refs.setFloating, forwardedRef])}
                  className={cx("absolute z-50 rounded-lg bg-white", className)}
                  style={{
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.12)",
                    ...floatingStyles,
                  }}
                >
                  {typeof children === "function"
                    ? children({ isOpen: open, onClose: close })
                    : children}
                </HeadlessUIPopover.Panel>
              </Portal>
            )}
          </>
        )}
      </HeadlessUIPopover>
    );
  }
);
