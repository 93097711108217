import { CallsContext } from "@web-src/features/calls/providers/Calls";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

const Conference = () => {
  const { joinCall } = useContext(CallsContext);
  const { channelId } = useParams();

  useEffect(() => {
    if (channelId && joinCall) {
      joinCall(channelId);
    }
  }, [channelId, joinCall]);
  return <></>;
};

export default Conference;
