export enum ProfileVisibility {
  visible = "1",
  invisible = "0",
}

// TODO: re-check
export enum ProfileGender {
  male = "male",
  female = "female",
}

export type ProfileEducations = {
  end: string | null;
  grade: string | null;
  id: string;
  name: string | null;
  specialization: string | null;
  start: string | null;
  visibility: ProfileVisibility;
  where: string | null;
};

export type ProfileLocation = {
  city: string | null;
  country: string | null;
  id: string;
  moved_in: string | null;
  moved_out: string | null;
  state: string | null;
  visibility: ProfileVisibility;
};

export type ProfileCareers = {
  current: any;
  end: string | null;
  id: string;
  location: string | null;
  org: string | null;
  start: string | null;
  title: string | null;
  visibility: ProfileVisibility;
};

export type ProfileServices = {
  id: string;
  img: string | null;
  ref: string | null;
  service_type: string | null;
  since: string | null;
  until: string | null;
  visibility: ProfileVisibility;
};

export type ProfileAchievements = {
  date: string | null;
  id: string;
  img: string | null;
  ref: string | null;
  title: string | null;
  visibility: ProfileVisibility;
};

export type ProfileCertificates = {
  completion: string | null;
  expires_on: string | null;
  id: string;
  img: string | null;
  issued_by: string | null;
  name: string | null;
  ref: any;
  visibility: ProfileVisibility;
};

export type ProfileAwards = {
  id: string;
  name: string | null;
  date: string | null;
  awarded_by: string | null;
  img: string | null;
  visibility: ProfileVisibility | null;
};

export type ProfileNative = {
  city: string | null;
  country: string | null;
  id: string;
  state: string | null;
  visibility: ProfileVisibility;
};

export type Profile = {
  // TODO: types
  achievements: ProfileAchievements[];
  awards: ProfileAwards[];
  businesses: any[];
  careers: ProfileCareers[];
  certificates: ProfileCertificates[];
  educations: ProfileEducations[];
  dept: any;
  general: {
    // TODO: type
    desc: any;
    dob: string | null;
    first_name: string;
    gender: ProfileGender | null;
    id: string;
    img: string | null;
    last_name: string;
    mobile: string;
    nickname: string;
    visibility: {
      first_name: ProfileVisibility;
      parent: ProfileVisibility;
    };
  };
  // TODO: type
  group_form: any;
  id: string;
  // TODO: type
  locations: ProfileLocation[];
  native: ProfileNative | null;
  services: ProfileServices[];
  space: string[];
  user_domain: string;
  user_id: string;
};
