import { emptySplitApi } from "@web-src/features/api/createApi";
import { PaginatedResponse } from "@web-src/features/api/types";
import { serializeSearchParams } from "@web-src/utils/helper";
import { Workspace, WorkspaceParticipant } from "./types";

export enum WorkspacesApiTags {
  workspacesList = "WorkspacesList",
  workspacesListAll = "WorkspacesListAll",
  workspaceParticipantsAll = "WorkspaceParticipantsAll",
}

const apiWithTags = emptySplitApi.enhanceEndpoints({
  addTagTypes: [
    WorkspacesApiTags.workspacesList,
    WorkspacesApiTags.workspacesListAll,
    WorkspacesApiTags.workspaceParticipantsAll,
  ],
});

export type CreateGroupParams = {
  admin_only_msging: boolean;
  description: string;
  spam_sense: number;
  title: string;
  allow_msg_moderation: boolean;
  // TODO: enums
  type: "group";
  user_info_visibility: "mobile_number_and_nickname";
};

// TODO: review tags
export const workspacesApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    workspacesList: build.query<
      PaginatedResponse<Workspace>,
      { entityId: string }
    >({
      query: ({ entityId }) => ({
        url: `/api/auth/entity/${entityId}/workspaces`,
      }),
      providesTags: [WorkspacesApiTags.workspacesList],
    }),
    createWorkspace: build.mutation<
      void,
      {
        data: {
          opts: Partial<CreateGroupParams>;
          participants?: string[];
          display_pic_file?: File;
        };
        entityId: string;
      }
    >({
      query: ({ data, entityId }) => {
        const formData = new FormData();
        formData.set("opts", JSON.stringify(data.opts));
        formData.set("participants", JSON.stringify(data.participants));
        if (data.display_pic_file) {
          formData.set("display_picture_file", data.display_pic_file);
        }
        return {
          url: `/api/auth/entity/${entityId}/workspaces`,
          data: formData,
          method: "POST",
        };
      },
      invalidatesTags: [
        WorkspacesApiTags.workspacesList,
        WorkspacesApiTags.workspacesListAll,
      ],
    }),
    updateWorkspace: build.mutation<
      void,
      {
        data: {
          opts: Partial<CreateGroupParams>;
          participants?: string[];
          display_pic_file?: File;
        };
        entityId: string;
        id: string;
      }
    >({
      query: ({ data, entityId, id }) => {
        const formData = new FormData();
        formData.set("opts", JSON.stringify(data.opts));
        formData.set("participants", JSON.stringify(data.participants));
        if (data.display_pic_file) {
          formData.set("display_picture_file", data.display_pic_file);
        }
        return {
          url: `/api/auth/entity/${entityId}/workspaces/${id}`,
          data: formData,
          method: "PUT",
        };
      },
      invalidatesTags: [
        WorkspacesApiTags.workspacesList,
        WorkspacesApiTags.workspacesListAll,
      ],
    }),
    leaveWorkspace: build.mutation<
      void,
      {
        id: string;
        userId: string;
        entityId: string;
      }
    >({
      query: ({ id, userId, entityId }) => ({
        url: `/api/auth/entity/${entityId}/workspaces/${id}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: (e1, e2, arg) => [
        {
          type: WorkspacesApiTags.workspaceParticipantsAll,
          id: `${arg.id}-${arg.entityId}`,
        },
      ],
    }),
    deleteWorkspace: build.mutation<
      void,
      {
        id: string;
        entityId: string;
      }
    >({
      query: ({ id, entityId }) => ({
        url: `/api/auth/entity/${entityId}/workspaces/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        WorkspacesApiTags.workspacesList,
        WorkspacesApiTags.workspacesListAll,
      ],
    }),
    addWorkspaceParticipants: build.mutation<
      void,
      {
        participants: string[];
        entityId: string;
        id: string;
      }
    >({
      query: ({ participants, id, entityId }) => ({
        url: `/api/auth/entity/${entityId}/workspaces/${id}/add`,
        method: "POST",
        data: {
          participants,
        },
      }),
      invalidatesTags: (e1, e2, arg) => [
        {
          type: WorkspacesApiTags.workspaceParticipantsAll,
          id: `${arg.id}-${arg.entityId}`,
        },
      ],
    }),
    workspaceAllParticipatns: build.query<
      WorkspaceParticipant[],
      { entityId: string; id: string }
    >({
      queryFn: async (
        { entityId, id },
        _queryApi,
        _extraOptions,
        baseQuery
      ) => {
        // NOTE: load all pages
        let mergedParticipants: WorkspaceParticipant[] = [];
        const resp = await baseQuery(
          `/api/auth/entity/${entityId}/workspaces/${id}/participants`
        );
        mergedParticipants = [...resp.data.data];
        const totalPages = resp.data.total_pages;
        let nextPage = 2;
        const extraQueries = [];
        while (nextPage <= totalPages) {
          extraQueries.push(
            serializeSearchParams({
              page: nextPage,
            })
          );
          nextPage += 1;
        }
        const extraResponses = await Promise.all(
          extraQueries.map((query) =>
            baseQuery(
              `/api/auth/entity/${entityId}/workspaces/${id}/participants?${query}`
            )
          )
        );
        extraResponses.forEach((extraResp) => {
          mergedParticipants = [...mergedParticipants, ...extraResp.data.data];
        });
        return {
          data: mergedParticipants,
        };
      },
      providesTags: (e1, e2, arg) => [
        {
          type: WorkspacesApiTags.workspaceParticipantsAll,
          id: `${arg.id}-${arg.entityId}`,
        },
      ],
    }),
    workspacesListAll: build.query<Workspace[], { entityId: string }>({
      queryFn: async ({ entityId }, _queryApi, _extraOptions, baseQuery) => {
        // NOTE: load all pages
        let mergedWorkspaces: Workspace[] = [];
        const resp = await baseQuery(`/api/auth/entity/${entityId}/workspaces`);
        mergedWorkspaces = [...resp.data.data];
        const totalPages = resp.data.total_pages;
        let nextPage = 2;
        const extraQueries = [];
        while (nextPage <= totalPages) {
          extraQueries.push(
            serializeSearchParams({
              page: nextPage,
            })
          );
          nextPage += 1;
        }
        const extraResponses = await Promise.all(
          extraQueries.map((query) =>
            baseQuery(`/api/auth/entity/${entityId}/workspaces?${query}`)
          )
        );
        extraResponses.forEach((extraResp) => {
          mergedWorkspaces = [...mergedWorkspaces, ...extraResp.data.data];
        });
        return {
          data: mergedWorkspaces,
        };
      },
      providesTags: [WorkspacesApiTags.workspacesListAll],
    }),
  }),
});

export const {
  useWorkspacesListQuery,
  useWorkspacesListAllQuery,
  useCreateWorkspaceMutation,
  useWorkspaceAllParticipatnsQuery,
  useLeaveWorkspaceMutation,
  useAddWorkspaceParticipantsMutation,
  useDeleteWorkspaceMutation,
  useUpdateWorkspaceMutation,
} = workspacesApi;
