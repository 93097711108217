import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { TaskStatus, TaskType } from "../tasks/types";
import { TasksTemplatesApiTag } from "./tags";
import { TaskTemplate, TaskTemplateChecklist } from "./types";

interface CreateOrUpdateTaskTemplate {
  name: string;
  due_in: number | null;
  label_id?: string;
  checklist: TaskTemplateChecklist;
}

interface CreateTemplateQueryArg {
  entityId: string;
  template: CreateOrUpdateTaskTemplate;
  voiceTitleFile?: File;
}

interface UpdateTemplateQueryArg extends CreateTemplateQueryArg {
  templateId: string;
}

export const addTasksTemplatesApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [TasksTemplatesApiTag.template],
  });

  const tasksTemplatesApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getTemplates: builder.query<
        PaginatedResponse<TaskTemplate>,
        {
          entityId: string;
          params?: PaginatedRequestParams<{ search_term?: string }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/task`,
          params: { type: TaskType.template, ...params },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.data.map((template) => ({
                  type: TasksTemplatesApiTag.template,
                  id: template.id,
                })),
                { type: TasksTemplatesApiTag.template, id: "LIST" },
              ]
            : [{ type: TasksTemplatesApiTag.template, id: "LIST" }],
      }),

      createTemplate: builder.mutation<TaskTemplate, CreateTemplateQueryArg>({
        query: ({ entityId, template, voiceTitleFile }) => {
          const formData = new FormData();

          const transformedTemplate = {
            ...template,
            status: TaskStatus.notStarted,
            type: TaskType.template,
          };

          formData.append("task_attrs", JSON.stringify(transformedTemplate));

          if (voiceTitleFile) {
            formData.append("voice_title_file", voiceTitleFile);
          }

          return {
            url: `/api/auth/entity/${entityId}/task`,
            method: "POST",
            data: formData,
          };
        },
        invalidatesTags: () => [
          { type: TasksTemplatesApiTag.template, id: "LIST" },
        ],
      }),

      updateTemplate: builder.mutation<
        { task: TaskTemplate },
        UpdateTemplateQueryArg
      >({
        query: ({ entityId, templateId, template, voiceTitleFile }) => {
          const formData = new FormData();

          formData.append("task_attrs", JSON.stringify(template));

          if (voiceTitleFile) {
            formData.append("voice_title_file", voiceTitleFile);
          }

          return {
            url: `/api/auth/entity/${entityId}/task/${templateId}`,
            method: "PUT",
            data: formData,
          };
        },
        invalidatesTags: () => [
          { type: TasksTemplatesApiTag.template, id: "LIST" },
        ],
      }),

      deleteTemplate: builder.mutation<
        void,
        { entityId: string; templateId: string }
      >({
        query: ({ entityId, templateId }) => ({
          url: `/api/auth/entity/${entityId}/task/${templateId}`,
          method: "DELETE",
        }),
        invalidatesTags: [{ type: TasksTemplatesApiTag.template, id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

  return tasksTemplatesApi;
};

export type TasksTemplatesApi = ReturnType<typeof addTasksTemplatesApi>;
